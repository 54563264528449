import { Column, Summary, TotalItem } from "devextreme-react/data-grid";
import { forwardRef, useRef } from "react";
import { MxpGrid } from "../../../../../components/grid";
import {
  useExcluirRegistroGrid,
  usePropagarReferenciaGrid,
} from "../../../../../hooks/grid.hooks";
import DocumentoFiscalSelecaoMdfeGridModel from "../../../../../models/api/nota-fiscal/nota-fiscal-selecao-mdfe";
import { IGridSelecao } from "../../../../../models/shared/ui/formularios";
import NotaFiscalService from "../../../../../services/nota-fiscal/nota-fiscal.service";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { formatarNumero } from "../../../../../utils/formatadores/formatador-de-numeros";
import { GridBaseProps } from "../../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";

const service = new NotaFiscalService();

const dataSource = service.GetGridSelecaoParaMdfeSource();

const nameOfGridHandler = criarNameof<DocumentoFiscalSelecaoMdfeGridModel>();
const colunas = [
  <Column
    key={nameOfGridHandler("numero")}
    dataField={nameOfGridHandler("numero")}
    {...obterConfiguracaoColuna("codigoNumerico")}
    caption="Número"
  />,
  <Column
    key={nameOfGridHandler("serie")}
    dataField={nameOfGridHandler("serie")}
    {...obterConfiguracaoColuna("stringP")}
    caption="Série"
  />,
  <Column
    key={nameOfGridHandler("chaveAcesso")}
    dataField={nameOfGridHandler("chaveAcesso")}
    {...obterConfiguracaoColuna("chaveAcesso")}
  />,
  <Column
    key={nameOfGridHandler("dataEmissao")}
    dataField={nameOfGridHandler("dataEmissao")}
    {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
    caption="Emissão"
  />,
  <Column
    key={nameOfGridHandler("destinatario")}
    dataField={nameOfGridHandler("destinatario")}
    caption="Destinatário"
    allowResizing
  />,
  <Column
    key={nameOfGridHandler("municipioDestino")}
    dataField={nameOfGridHandler("municipioDestino")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Município de destino"
  />,
  <Column
    key={nameOfGridHandler("ufDestino")}
    dataField={nameOfGridHandler("ufDestino")}
    {...obterConfiguracaoColuna("stringP")}
    caption="UF de destino"
  />,
  <Column
    key={nameOfGridHandler("valor")}
    dataField={nameOfGridHandler("valor")}
    {...obterConfiguracaoColuna("monetario")}
    caption="Valor"
    cellRender={({ data }) => {
      const dados = data as DocumentoFiscalSelecaoMdfeGridModel;
      return formatarNumero(dados.valor, 2);
    }}
  />,
  <Column
    key={nameOfGridHandler("pesoLiquido")}
    dataField={nameOfGridHandler("pesoLiquido")}
    {...obterConfiguracaoColuna("peso")}
    caption="Peso líquido"
    cellRender={({ data }) => {
      const dados = data as DocumentoFiscalSelecaoMdfeGridModel;
      return formatarNumero(dados.pesoLiquido, 3);
    }}
  />,
  <Column
    key={nameOfGridHandler("pesoBruto")}
    dataField={nameOfGridHandler("pesoBruto")}
    {...obterConfiguracaoColuna("peso")}
    caption="Peso bruto"
    cellRender={({ data }) => {
      const dados = data as DocumentoFiscalSelecaoMdfeGridModel;
      return formatarNumero(dados.pesoBruto, 3);
    }}
  />,
  <Summary key={"totalItem"}>
    <TotalItem
      column={nameOfGridHandler("valor")}
      summaryType="sum"
      customizeText={(data: any) => formatarNumero(data.value, 2)}
    />
    <TotalItem
      column={nameOfGridHandler("pesoLiquido")}
      summaryType="sum"
      customizeText={(data: any) => formatarNumero(data.value, 3)}
    />
    <TotalItem
      column={nameOfGridHandler("pesoBruto")}
      summaryType="sum"
      customizeText={(data: any) => formatarNumero(data.value, 3)}
    />
  </Summary>,
];

export const SelecaoNotasMDFe = forwardRef((props: GridBaseProps, ref) => {
  const gridRef = useRef<IGridSelecao>(null);
  usePropagarReferenciaGrid(ref, gridRef);

  const handleExcluirRegistro = useExcluirRegistroGrid(async () => {
    exibirNotificacaoToast({
      mensagem: "Funcionalidade não implementada.",
      tipo: TipoNotificacao.Informacao,
    });

    return;
  });

  return (
    <>
      <MxpGrid<DocumentoFiscalSelecaoMdfeGridModel>
        dataSource={dataSource}
        id={"documento-fiscal-selecao-mdfe-grid"}
        ref={gridRef}
        colunas={colunas}
        exibirIconeEditarMenuAcoes={false}
        excluirRegistro={handleExcluirRegistro}
        nomeDoArquivoAoExportar={NomesTelas.centrosCusto}
      />
    </>
  );
});
