import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import { useCallback, useMemo, useRef, useState } from "react";
import ProvedorAjuda from "../../../../../../components/ajuda/provedor-ajuda";
import ModalAccordion from "../../../../../../components/dialogos/modal-accordion";
import {
  AccordionDataSource,
  AccordionModel,
} from "../../../../../../components/dialogos/modal-accordion/modal-accordion";
import GridColunaAcoes from "../../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { useRegistrarAtalhosGrid } from "../../../../../../hooks/atalhos.hooks";
import { useMenuDeContextosGrid } from "../../../../../../hooks/menus.hooks";
import { useAppDispatch } from "../../../../../../hooks/store.hooks";
import { PermissoesEfdReinf } from "../../../../../../models/permissoes/fiscal/efd-reinf/permissoes-efd-reinf";
import GetColunasDeAuditoria from "../../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import { bloquearUI, desbloquearUI } from "../../../../../../store/ui/ui.slice";
import {
  checarResponse,
  checarResponseExibeMensagemExclusaoDeSucesso,
  checarResponseExibeMensagemExecutadoComSucesso,
  tratarErroApi,
} from "../../../../../../utils/api/api-utils";
import criarNameof from "../../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../../utils/common/nomes-telas";
import { JanelasDeNotificacaoTitulos } from "../../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../../utils/common/permissoes-utils";
import { ItemContextMenuMxp } from "../../../../../../utils/context-menu/context-menu-utils";
import {
  GestorEventoClickMultiplasLinhas,
  GestorEventoClickUnicaLinha,
} from "../../../../../../utils/context-menu/gestor-evento-click";
import { exibirConfirmacao } from "../../../../../../utils/dialogos";
import { formatarDataAno4digitos } from "../../../../../../utils/formatadores/formatador-de-datas";
import { formatarNumeroMonetario } from "../../../../../../utils/formatadores/formatador-de-numeros";
import GridBuilder from "../../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../../utils/grid/padroes-colunas";
import {
  EfdReinf2020GridModel,
  InfosR2020EnvioReinf,
} from "../../../models/efd-reinf-2020/efd-reinf-2020";
import { EfdReinfNumeracaoTipo } from "../../../models/efd-reinf.enums";
import EfdReinfServico from "../../../servicos/efd-reinf.service new";

const service = new EfdReinfServico(EfdReinfNumeracaoTipo.R2020);
const dataSource = service.ObterDataSourceParaGrid<EfdReinf2020GridModel>();

const nameOfGridHandler = criarNameof<EfdReinf2020GridModel>();

function obterDadosAccordionEnvioReinf(
  info: InfosR2020EnvioReinf
): AccordionModel[] {
  const accordionModel: AccordionModel[] = [];

  const obterMensagemBase = (apelido: string, valorRetido: number) => {
    return `Cliente: ${apelido}. Valor retido: ${formatarNumeroMonetario(
      valorRetido
    )}`;
  };

  if (info.registrosJaEnviados.length > 0) {
    accordionModel.push({
      grupoDeMensagensTitulo: `Não foi possível enviar ${info.registrosJaEnviados.length} eventos R-2020. Pois já foram enviados com sucesso:`,
      mensagens: info.registrosJaEnviados.map((x) => {
        return `${obterMensagemBase(
          x.apelidoCliente,
          x.valorRetido
        )}. Data do recibo: ${formatarDataAno4digitos(x.reciboData)}. Recibo: ${
          x.recibo
        }`;
      }),
    });
  }

  if (info.registrosComDivergenciaNaApuracao.length > 0) {
    accordionModel.push({
      grupoDeMensagensTitulo: `Não foi possível enviar ${info.registrosComDivergenciaNaApuracao.length} eventos R-2020. Pois a data inicial e data final da apuração não pertencem ao mesmo período (mês/ano), o que geraria rejeição por parte da SEFAZ:`,
      mensagens: info.registrosComDivergenciaNaApuracao.map((x) => {
        return `${obterMensagemBase(
          x.apelidoCliente,
          x.valorRetido
        )}. Apuração: ${formatarDataAno4digitos(
          x.dataInicial
        )} a ${formatarDataAno4digitos(x.dataFinal)}`;
      }),
    });
  }

  if (info.registrosEnviadosComSucesso.length > 0) {
    accordionModel.push({
      grupoDeMensagensTitulo: `${info.registrosEnviadosComSucesso.length} envios foram realizados com sucesso. Clique aqui para ver os eventos enviados:`,
      mensagens: info.registrosEnviadosComSucesso.map((x) => {
        return `${obterMensagemBase(
          x.apelidoCliente,
          x.valorRetido
        )}. Data do recibo: ${formatarDataAno4digitos(x.reciboData)}. Recibo: ${
          x.recibo
        }`;
      }),
    });
  }

  if (info.registrosComErros.length > 0) {
    accordionModel.push({
      grupoDeMensagensTitulo: `${info.registrosComErros.length} envios não foram realizados com sucesso. Clique aqui para ver o motivo dos erros:`,
      mensagens: info.registrosComErros.map((x) => {
        return `${obterMensagemBase(x.apelidoCliente, x.valorRetido)}. Erro: ${
          x.codigoErro
        } - ${x.motivoErro}`;
      }),
    });
  }

  if (info.registrosEmProcessamento.length > 0) {
    accordionModel.push({
      grupoDeMensagensTitulo: `${info.registrosEmProcessamento.length} envios estão com o lote em processamento na SEFAZ. Clique aqui para ver os eventos:`,
      mensagens: info.registrosEmProcessamento.map((x) => {
        return `${obterMensagemBase(x.apelidoCliente, x.valorRetido)}`;
      }),
    });
  }

  return accordionModel;
}

export default function GridEfdReinf2020() {
  const gridRef = useRef<DataGridRef>(null);

  const [modalAccordionVisivel, setModalAccordionVisivel] = useState(false);
  const [
    detalhamentoEnvioReinfMensagensAccordion,
    setDetalhamentoEnvioReinfMensagensAccordion,
  ] = useState<AccordionDataSource>();

  const dispatch = useAppDispatch();

  const handleExcluirRegistro = useCallback(
    async (registro: EfdReinf2020GridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesEfdReinf.Excluir,
        ])
      ) {
        return;
      }

      const excluir = await exibirConfirmacao(
        "Confirmar exclusão",
        obterMensagemExclusao(registro)
      );

      if (excluir) {
        const resposta = await service.Excluir(registro.id);

        if (resposta) {
          checarResponseExibeMensagemExclusaoDeSucesso(resposta);
          handleAtualizarGrid();
        }
      }
    },
    []
  );

  function obterMensagemExclusao(registro: EfdReinf2020GridModel) {
    return `Tem certeza que deseja excluir o registro com o valor retido ${formatarNumeroMonetario(
      Number(registro.valorRetido)
    )}?`;
  }

  async function enviarEvento(registros?: EfdReinf2020GridModel[]) {
    if (
      !verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesEfdReinf.Enviar,
      ])
    ) {
      return;
    }

    if (registros && registros.length > 0) {
      try {
        dispatch(bloquearUI("Enviando..."));
        const resposta = await service.Enviar<InfosR2020EnvioReinf>(
          registros.map((x) => x.id)
        );
        checarResponse(resposta);
        if (resposta.sucesso) {
          if (resposta.model) {
            setModalAccordionVisivel(true);
            setDetalhamentoEnvioReinfMensagensAccordion({
              model: obterDadosAccordionEnvioReinf(resposta.model),
            });
          }
        }
      } catch (erro) {
        tratarErroApi(erro);
      } finally {
        dispatch(desbloquearUI());
      }
    }
  }

  const handleConsultarEvento = useCallback(
    async (registro: EfdReinf2020GridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesEfdReinf.Consultar,
        ])
      ) {
        return;
      }
      if (registro.id) {
        try {
          const resposta = await service.Consultar(registro.id);

          if (resposta.sucesso) {
            checarResponseExibeMensagemExecutadoComSucesso(resposta);
          }
        } catch (erro) {
          tratarErroApi(erro);
        }
      }
    },
    []
  );

  const acoesDeContexto: ItemContextMenuMxp[] = [
    {
      text: "Ações",
      icon: "ic-material-symbols-outlined ic-vertical",
      hint: "Menu com opções de ação",
      exibirNaLinhaDaGrid: "menuDeContexto",
      exibirNoMenuPrincipal: true,
      items: [
        {
          text: "Enviar evento R-2020 - Retenção contribuição previdenciária - Prestadores de serviços",
          hint: "Enviar evento",
          gestorEventoClick: new GestorEventoClickMultiplasLinhas(
            enviarEvento,
            () => gridController
          ),
        },
        {
          text: "Consultar evento R-2020 - Retenção contribuição previdenciária - Prestadores de serviços",
          hint: "Consultar evento",
          gestorEventoClick: new GestorEventoClickUnicaLinha(
            handleConsultarEvento,
            () => gridController
          ),
        },
      ],
    },
  ];

  useMenuDeContextosGrid(acoesDeContexto);

  const gridController = new GridController<EfdReinf2020GridModel>(() =>
    gridRef.current?.instance()
  );

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar("efd-reinf-2020", () =>
      gridRef.current?.instance()
    )
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.efdReinf2020)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .definirMenuDeContexto(acoesDeContexto)
      .build();
  }, []);

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  useRegistrarAtalhosGrid<EfdReinf2020GridModel>({
    controller: gridController,
    handleExcluir: handleExcluirRegistro,
  });

  function handlerFecharModalAccordion() {
    setModalAccordionVisivel(false);
    setDetalhamentoEnvioReinfMensagensAccordion(undefined);
  }

  return (
    <>
      <ProvedorAjuda id={"tooltips-grid-efd-reinf-2020"}>
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          {GridColunaAcoes<EfdReinf2020GridModel>({
            handleExcluir: handleExcluirRegistro,
          })}
          <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
          <Column
            key={nameOfGridHandler("tipoDeArquivo")}
            dataField={nameOfGridHandler("tipoDeArquivo")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Tipo de arquivo"
            visible={false}
          />
          <Column
            key={nameOfGridHandler("apuracaoDataInicial")}
            dataField={nameOfGridHandler("apuracaoDataInicial")}
            {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
            caption="Data inicial da apuração"
            sortIndex={0}
            sortOrder="desc"
            width={200}
          />
          <Column
            key={nameOfGridHandler("apuracaoDataFinal")}
            dataField={nameOfGridHandler("apuracaoDataFinal")}
            {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
            caption="Data final da apuração"
            sortIndex={0}
            sortOrder="desc"
            width={200}
          />
          <Column
            key={nameOfGridHandler("clienteRazaoSocial")}
            dataField={nameOfGridHandler("clienteRazaoSocial")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Razão social do cliente"
          />
          <Column
            key={nameOfGridHandler("clienteCnpj")}
            dataField={nameOfGridHandler("clienteCnpj")}
            {...obterConfiguracaoColuna("cnpjFormatado")}
            caption="CNPJ do cliente"
          />
          <Column
            key={nameOfGridHandler("baseDeCalculo")}
            dataField={nameOfGridHandler("baseDeCalculo")}
            {...obterConfiguracaoColuna("monetario")}
            caption="Base de cálculo"
          />
          <Column
            key={nameOfGridHandler("valorRetido")}
            dataField={nameOfGridHandler("valorRetido")}
            {...obterConfiguracaoColuna("monetario")}
            caption="Valor retido"
          />
          <Column
            key={nameOfGridHandler("dataRecibo")}
            dataField={nameOfGridHandler("dataRecibo")}
            {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
            caption="Data do recibo"
          />
          <Column
            key={nameOfGridHandler("recibo")}
            dataField={nameOfGridHandler("recibo")}
            {...obterConfiguracaoColuna("stringG")}
            caption="Recibo"
          />
          <Column
            key={nameOfGridHandler("situacaoDecodificada")}
            dataField={nameOfGridHandler("situacaoDecodificada")}
            {...obterConfiguracaoColuna("stringP")}
            caption="Situação"
          />
          <Column
            key={nameOfGridHandler("codigoDoErro")}
            dataField={nameOfGridHandler("codigoDoErro")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Código do erro"
          />
          <Column
            key={nameOfGridHandler("motivoDoErro")}
            dataField={nameOfGridHandler("motivoDoErro")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Motivo do erro"
          />
          {GetColunasDeAuditoria()}
        </DataGrid>
      </ProvedorAjuda>

      <ModalAccordion
        modalAccordionVisivel={modalAccordionVisivel}
        handlerFecharModalAccordion={handlerFecharModalAccordion}
        dataSource={detalhamentoEnvioReinfMensagensAccordion?.model}
        modalTitulo={
          detalhamentoEnvioReinfMensagensAccordion?.accordionTitulo ??
          JanelasDeNotificacaoTitulos.Atencao
        }
        accordionId="accordion-detalhamento-envio-reinf-R2020"
        itemDoAccordionAltura="auto"
      />
    </>
  );
}
