import { Button } from "devextreme-react";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import FormMxp from "../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../components/layout/form/styles";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import { CallBackModal } from "../../../../../models/shared/ui/callback-modal";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import { ProvedorLinkAjuda } from "../../../../../utils/help/provedor-links";
import { renderToStringClient } from "../../../../../utils/react/react-utils";
import GridAjudaPaginas from "../../componentes/grid";

interface AjudaPaginasPageProps {
  codigo: string;
  callBackFecharModal?: (info: CallBackModal) => void;
}

export default function AjudaPaginasPage({
  codigo,
  callBackFecharModal,
}: AjudaPaginasPageProps) {
  function fechar(info: CallBackModal) {
    if (callBackFecharModal) {
      callBackFecharModal(info);
    }
  }

  async function onLimparCacheLocal() {
    const confirmacao = await exibirConfirmacao(
      "Confirmar limpeza",
      renderToStringClient(
        <>
          Tem certeza de que deseja excluir o cache local dos links de ajuda?
          <br />
          <br />
          <i>
            Essa operação visa apenas facilitar testes internos e apagará apenas
            os dados armazenados localmente.
            <br />
            Se o seu intuito é limpar o cache no servidor, basta
            adicionar/editar/excluir algum registro nessa página.
          </i>
        </>
      )
    );

    if (confirmacao) {
      ProvedorLinkAjuda.limparCache();
      exibirNotificacaoToast({
        mensagem: "Limpeza realizada com sucesso! Favor atualizar a página.",
        tipo: TipoNotificacao.Sucesso,
      });
    }
  }

  function handleCancelar() {
    fechar({ concluido: false, precisaAtualizar: false });
  }

  return (
    <>
      <ContainerFormMxp>
        <FormMxp carregando={false}>
          <ProvedorAjuda id="listagem-links-ajuda-pagina">
            <GridAjudaPaginas codigo={codigo} />
          </ProvedorAjuda>
        </FormMxp>
        <ToolbarMxp>
          <Button
            key="btn-limpar"
            type="normal"
            text="Limpar cache local"
            visible
            onClick={onLimparCacheLocal}
          />
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ContainerFormMxp>
    </>
  );
}
