import { useCallback, useContext } from "react";
import { FragmentoCaminhoTagViewModel } from "../../models/relatorio-personalizado.viewmodel";
import { DicionarioTagsContext } from "../dicionario-tags-schema-provider";
import { IconeDivisao, ZonaClicavel } from "./styles";

interface BreadCrumbCaminhoTagsProps {
  navegacao: FragmentoCaminhoTagViewModel;
}

export default function BreadCrumbCaminhoTags({
  navegacao,
}: BreadCrumbCaminhoTagsProps) {
  const { navegar } = useContext(DicionarioTagsContext);

  const handleClick = useCallback(() => {
    navegar(navegacao);
  }, [navegacao, navegar]);

  return (
    <span>
      <IconeDivisao className="fa-solid fa-chevron-right" />
      <ZonaClicavel onClick={handleClick} title="Ir para">
        {navegacao.nome}
      </ZonaClicavel>
    </span>
  );
}
