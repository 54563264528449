import { CoresChip } from "../../../../../components/mxp/chip/cores";
import SelectItem from "../../../../../models/shared/ui/select-item";
import {
  gerarLookupDoDicionario,
  gerarSelectItemDoDicionario,
} from "../../../../../utils/common/enum-utils";
import { EstocagemTipo } from "../../../../itens/item/models/item.enums";

export enum EstadoDecodificado {
  Sugerida = "Sugerida",
  AProduzir = "A produzir",
  Fechada = "Fechada",
  Cancelada = "Cancelada",
  Suspensa = "Suspensa sugerida",
}

export enum OrdemDeProducaoEstado {
  Sugerida = 83,
  AProduzir = 70,
  Fechada = 67,
  Cancelada = 75,
  Suspensa = 66,
}

export enum EstocagemDoProduto {
  Codigo = 78, // 'N' = 78
  Interno = 73, // 'I' = 73
  Lote = 76, // 'L' = 76
  Serie = 83, // 'S' = 83
}

export enum OrdemDeProducaoTipoRelacionamento {
  Vinculo,
  Reserva,
  ReservaAtendida,
}

const mensagemBase =
  "para: {Pedido de venda} #{Número do item do pedido} ({Quantidade}) - {Cliente}.";
export const OrdemDeProducaoTipoRelacionamentoDescricao = {
  [OrdemDeProducaoTipoRelacionamento.Vinculo]: `Vinculo ${mensagemBase}`,
  [OrdemDeProducaoTipoRelacionamento.Reserva]: `Reserva ${mensagemBase}`,
  [OrdemDeProducaoTipoRelacionamento.ReservaAtendida]: `Reserva atendida ${mensagemBase}`,
};

export class EstocagemDoProdutoHelper {
  private static readonly tipoDescricao: Record<EstocagemDoProduto, string> = {
    [EstocagemDoProduto.Codigo]: "Código do item",
    [EstocagemDoProduto.Interno]: "Lote interno",
    [EstocagemDoProduto.Lote]: "Lote do fabricante",
    [EstocagemDoProduto.Serie]: "Número de série",
  };

  public static ConverterEstocagemDeItemParaProduto(
    source?: EstocagemTipo | null
  ): EstocagemDoProduto | undefined {
    switch (source) {
      case EstocagemTipo.Codigo:
        return EstocagemDoProduto.Codigo;
      case EstocagemTipo.Interno:
        return EstocagemDoProduto.Interno;
      case EstocagemTipo.LoteFabricante:
        return EstocagemDoProduto.Lote;
      case EstocagemTipo.Serie:
        return EstocagemDoProduto.Serie;
      default:
        return undefined; // Handle unmatched cases if necessary
    }
  }

  public static getDescricao(tipo: EstocagemDoProduto): string {
    return this.tipoDescricao[tipo] || "Descrição desconhecida";
  }

  public static AsSelectItems(): SelectItem[] {
    return gerarSelectItemDoDicionario(this.tipoDescricao);
  }

  public static AsLookup() {
    return gerarLookupDoDicionario(this.tipoDescricao);
  }
}

export class OrdemDeProducaoEstadoHelper {
  private static readonly tipoDescricao: Record<OrdemDeProducaoEstado, string> =
    {
      [OrdemDeProducaoEstado.Sugerida]: EstadoDecodificado.Sugerida,
      [OrdemDeProducaoEstado.AProduzir]: EstadoDecodificado.AProduzir,
      [OrdemDeProducaoEstado.Fechada]: EstadoDecodificado.Fechada,
      [OrdemDeProducaoEstado.Cancelada]: EstadoDecodificado.Cancelada,
      [OrdemDeProducaoEstado.Suspensa]: EstadoDecodificado.Suspensa,
    };

  public static readonly coresPorEstado: Record<EstadoDecodificado, CoresChip> =
    {
      [EstadoDecodificado.Sugerida]: CoresChip.Cinza,
      [EstadoDecodificado.AProduzir]: CoresChip.Verde,
      [EstadoDecodificado.Fechada]: CoresChip.Azul,
      [EstadoDecodificado.Cancelada]: CoresChip.Amarelo,
      [EstadoDecodificado.Suspensa]: CoresChip.CinzaClaro,
    };

  public static getDescricao(tipo: OrdemDeProducaoEstado): string {
    return (
      OrdemDeProducaoEstadoHelper.tipoDescricao[tipo] ||
      "Descrição desconhecida"
    );
  }

  public static AsSelectItems(): SelectItem[] {
    return gerarSelectItemDoDicionario(this.tipoDescricao);
  }
}
