import { useMemo } from "react";
import { FieldValues } from "react-hook-form";
import { DataSourceOpcoesBuilder } from "../../../../../../utils/grid/data-source-factory";
import { OrdemDeProducaoGridModel } from "../../../models/ordem-de-producao.api";
import ComboOrdemDeProducaoMxp, {
  ComboOrdemDeProducaoMxpProps,
} from "../select-box-padrao";

interface ComboOrdemDeProducaoPorIdMxpProps<T extends FieldValues>
  extends ComboOrdemDeProducaoMxpProps<T> {
  ordemDeProducaoId: number;
}

export default function ComboOrdemDeProducaoPorIdMxp<T extends FieldValues>(
  props: ComboOrdemDeProducaoPorIdMxpProps<T>
) {
  const dataSourceOpcoes = useMemo(() => {
    return {
      ...props.dataSourceOpcoes,
      camposFiltro: [
        ...(props.dataSourceOpcoes?.camposFiltro ?? []),
        {
          campo: "idOrdemDeProducao",
          operador: "=",
          valor: props.ordemDeProducaoId,
        },
      ],
    } as DataSourceOpcoesBuilder<OrdemDeProducaoGridModel>;
  }, [props.dataSourceOpcoes, props.ordemDeProducaoId]);

  return (
    <ComboOrdemDeProducaoMxp
      {...props}
      dataSourceOpcoes={dataSourceOpcoes}
    ></ComboOrdemDeProducaoMxp>
  );
}
