import { useMemo } from "react";
import { FieldValues, UseControllerProps } from "react-hook-form";
import { FormSelectBoxLazyMxp } from "../../../../components/formularios";
import { ItemSelectionChangedType } from "../../../../components/formularios/selectbox-lazy-mxp";
import { EstoqueGridModel } from "../../../../models/api/estoque/estoque";
import { DataSourceOpcoesBuilder } from "../../../../utils/grid/data-source-factory";
import GridEstoque from "../componentes/grids/grid-padrao";
import { EstoqueService } from "../service/estoque.service";

interface ComboEstoqueMxpProps<T extends FieldValues>
  extends UseControllerProps<T> {
  titulo: string;
  tituloSeletor?: string;
  requerido?: boolean;
  filtrosNoCliente?: any[] | undefined;
  dataSourceOpcoes?: DataSourceOpcoesBuilder<EstoqueGridModel>;
  somenteLeitura?: boolean;
  onSelectionChanged?:
    | ((e: ItemSelectionChangedType<EstoqueGridModel>) => Promise<void>)
    | (() => void);
}

const estoqueService = new EstoqueService();

export default function ComboEstoqueMxp<T extends FieldValues>(
  props: ComboEstoqueMxpProps<T>
) {
  const [dataSourceItem, configuracoesExibicaoEBuscaItem] = useMemo(
    () => estoqueService.GetDadosSelectBoxEstoque(props.dataSourceOpcoes),
    [props.dataSourceOpcoes]
  );

  return (
    <FormSelectBoxLazyMxp
      name={props.name}
      titulo={props.titulo}
      control={props.control}
      requerido={props.requerido}
      configuracoesExibicaoEBusca={configuracoesExibicaoEBuscaItem}
      somenteLeitura={props.somenteLeitura}
      onSelectionChanged={props.onSelectionChanged}
      dataSource={dataSourceItem}
      seletorConfig={{
        modo: "selecaoUnica",
        titulo: props.tituloSeletor ?? "Selecionar estoque",
        componenteGrid: (
          <GridEstoque
            filtrosNoServidor={props.dataSourceOpcoes?.camposFiltro}
            filtrosNoCliente={props.filtrosNoCliente}
          />
        ),
      }}
      labelSemDados="Sem dados"
    />
  );
}
