import SelectItem from "../../../../../models/shared/ui/select-item";
import { gerarSelectItemDoDicionario } from "../../../../../utils/common/enum-utils";

export enum EstadoDecodificado {
  Cancelado = "Cancelado",
  Digitacao = "Digitação",
  Normal = "Normal",
}

export enum LoteEstado {
  Cancelado = "K",
  Digitacao = "D",
  Normal = "N",
}

export class LoteEstadoHelper {
  private static readonly tipoDescricao: Record<LoteEstado, string> = {
    [LoteEstado.Cancelado]: EstadoDecodificado.Cancelado,
    [LoteEstado.Digitacao]: EstadoDecodificado.Digitacao,
    [LoteEstado.Normal]: EstadoDecodificado.Normal,
  };

  public static getDescricao(tipo: LoteEstado): string {
    return LoteEstadoHelper.tipoDescricao[tipo] || "Descrição desconhecida";
  }

  public static AsSelectItems(): SelectItem[] {
    return gerarSelectItemDoDicionario(this.tipoDescricao);
  }
}
