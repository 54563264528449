import { DataGridRef } from "devextreme-react/cjs/data-grid";
import { useContext, useEffect } from "react";
import { JanelasDeNotificacaoTitulos } from "../../../utils/common/notificacoes-utils";
import { exibirAlerta } from "../../../utils/dialogos";
import BotaoCancelarMxp from "../../botoes/botao-cancelar-mxp";
import BotaoConfirmarMxp from "../../botoes/botao-confirmar-mxp";
import FormMxp from "../../layout/form";
import { ContainerFormMxp } from "../../layout/form/styles";
import { ModalMxp } from "../../layout/modal-mxp";
import ToolbarMxp from "../../layout/toolbar-mxp";
import { ContextoSeletor } from "../contexto-seletor";

export type ModoDeSelecaoSeletor = "selecaoUnica" | "selecaoMultipla";

interface SeletorMxpProps {
  visivel: boolean;
  modo: ModoDeSelecaoSeletor;
  handleFechar: () => void;
  componenteGrid: JSX.Element;
  handleConfirmar: (sucesso: boolean, dados: any[]) => void;
  titulo: string;
}

function definirAltura(elemento: HTMLElement) {
  elemento.style.height = "77vh";
}

function definirModoDeSelecaoGrid(
  refGrid: DataGridRef,
  modo: ModoDeSelecaoSeletor
) {
  switch (modo) {
    case "selecaoUnica":
      refGrid.instance().option("selection", { mode: "single" });
      break;
    case "selecaoMultipla":
      refGrid.instance().option("selection", { mode: "multiple" });
      break;
  }
}

export default function SeletorMxp(props: SeletorMxpProps) {
  const { refGrid, filtroPadraoNoCliente } = useContext(ContextoSeletor);

  function CarregarGrid() {
    if (!refGrid) {
      return;
    }

    resetarEstadoGrid();

    definirAltura(refGrid.instance().element() as HTMLElement);
    definirModoDeSelecaoGrid(refGrid, props.modo);

    // Define a confirmação com o duplo clique da linha
    refGrid.instance().option("onRowDblClick", handleConfirmar);
  }

  useEffect(() => {
    CarregarGrid();
    () => resetarEstadoGrid();
  }, [refGrid, props.visivel]);

  function resetarEstadoGrid() {
    if (!refGrid) {
      return;
    }

    refGrid.instance().clearSelection();
    if (filtroPadraoNoCliente) {
      refGrid.instance().option("filterValue", filtroPadraoNoCliente);
    }
    refGrid.instance().pageIndex(0);
    refGrid.instance().resetOption("onRowDblClick");
    refGrid.instance().resetOption("selection");
  }

  async function handleConfirmar() {
    const selecao = (await refGrid?.instance().getSelectedRowsData()) ?? [];

    if (!selecao.length) {
      await exibirAlerta(
        JanelasDeNotificacaoTitulos.Atencao,
        `Selecione ${
          props.modo == "selecaoMultipla" ? "ao menos" : ""
        } um registro!`
      );
      return;
    }

    props.handleConfirmar(true, selecao);
  }

  return (
    <ModalMxp
      titulo={props.titulo}
      visivel={props.visivel}
      handleFechar={props.handleFechar}
      altura={"90vh"}
    >
      <ContainerFormMxp>
        <FormMxp carregando={false}>{props.componenteGrid}</FormMxp>
        <ToolbarMxp>
          <BotaoConfirmarMxp handleClick={handleConfirmar} />
          <BotaoCancelarMxp handleClick={props.handleFechar} />
        </ToolbarMxp>
      </ContainerFormMxp>
    </ModalMxp>
  );
}
