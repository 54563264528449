import { Propriedade, SchemaTags } from "../models/relatorio-personalizado.api";
import { DicionarioTiposTags } from "../models/relatorio-personalizado.constantes";
import { NivelHierarquicoTagViewModel } from "../models/relatorio-personalizado.viewmodel";

export class DicionarioTagsAdapter {
  public static propriedadeToNivelHierarquicoTagViewModel(
    propriedade: Propriedade,
    schema: SchemaTags
  ): NivelHierarquicoTagViewModel {
    const tipo = schema.tipos.filter((t) => t.nome == propriedade.nomeTipo)[0];
    return {
      nome: propriedade.nome,
      nomeAmigavel: propriedade.nomeAmigavel,
      nomeTipo: propriedade.nomeTipo,
      nomeTipoAmigavel: tipo.nomeAmigavel,
      tipoDados: DicionarioTiposTags.get(tipo.tipoDados)!,
      TipoDadosAbstraido: tipo.tipoDados,
      descricao: propriedade.descricao ?? tipo.descricao,
      colecao: propriedade.colecao,
      nullavel: propriedade.nullavel,
      modificadores: [...tipo.modificadores, ...propriedade.modificadores],
      itensEnum: tipo.valoresEnum,
      campoAdicional: tipo.campoAdicional,
      objetoComplexo: tipo.objetoComplexo,
    };
  }
}
