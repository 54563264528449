import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import { useMemo, useRef } from "react";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import { useSeletorGridMxp } from "../../../../../hooks/seletor.hooks";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import criarNameof from "../../../../../utils/common/cria-name-of";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import { CentroDeCustosGridModel } from "../../models/centro-de-custos";
import CentroDeCustosService from "../../servicos/centro-de-custos.service";

const service = new CentroDeCustosService();
const nameOfGridHandler = criarNameof<CentroDeCustosGridModel>();

export default function GridCentroDeCustos() {
  const gridRef = useRef<DataGridRef>(null);

  const dataSource = service.ObterDataSourceParaGrid<CentroDeCustosGridModel>();

  useSeletorGridMxp(() => gridRef.current);

  const gridController = new GridController<CentroDeCustosGridModel>(() =>
    gridRef.current?.instance()
  );

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar("centro-de-custos", () =>
      gridRef.current?.instance()
    )
      .definirStyles({ height: "100%" })
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirOrdenacao()
      .build();
  }, []);

  return (
    <>
      <ProvedorAjuda id={"tooltips-grid-centro-de-custos"}>
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          <Column
            key={nameOfGridHandler("codigo")}
            dataField={nameOfGridHandler("codigo")}
            {...obterConfiguracaoColuna("stringP")}
            caption="Código"
          />
          <Column
            key={nameOfGridHandler("classificacao")}
            dataField={nameOfGridHandler("classificacao")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Classificação"
            sortOrder="asc"
          />
          <Column
            key={nameOfGridHandler("descricao")}
            dataField={nameOfGridHandler("descricao")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Descrição"
          />
          {GetColunasDeAuditoria()}
        </DataGrid>
      </ProvedorAjuda>
    </>
  );
}
