import { createContext, PropsWithChildren, useState } from "react";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { SchemaTags } from "../../models/relatorio-personalizado.api";
import { FragmentoCaminhoTagViewModel } from "../../models/relatorio-personalizado.viewmodel";

interface DicionarioTagsContextProps {
  schema: SchemaTags;
  navegacao: FragmentoCaminhoTagViewModel[];
  definirSchema: (schema: SchemaTags) => void;
  navegar: (fragmento: FragmentoCaminhoTagViewModel) => void;
  irParaRaiz: () => void;
  obterTag: (nivel: number, nome: string) => string;
  fechar: (nivel: number) => void;
}

const ProfundidadeMaximaNavegacao = 11;

const DefaultValue: DicionarioTagsContextProps = {
  schema: { propriedades: [], tipos: [] },
  navegacao: [{ nivel: 0, tipoDeclarante: null, nome: null }],
  definirSchema: () => {
    /*ignorado*/
  },
  navegar: () => {
    /*ignorado*/
  },
  irParaRaiz: () => {
    /*ignorado*/
  },
  obterTag: () => "",
  fechar: () => {
    /*ignorado*/
  },
};

export const DicionarioTagsContext =
  createContext<DicionarioTagsContextProps>(DefaultValue);

export default function DicionarioTagsSchemaProvider({
  children,
}: PropsWithChildren) {
  const [schema, setSchema] = useState<SchemaTags>(DefaultValue.schema);
  const [navegacao, setNavegacao] = useState<FragmentoCaminhoTagViewModel[]>(
    DefaultValue.navegacao
  );

  const definirSchema = (schema: SchemaTags) => {
    setSchema(schema);
    setNavegacao(DefaultValue.navegacao);
  };

  const navegar = (fragmento: FragmentoCaminhoTagViewModel) => {
    if (fragmento.nivel >= ProfundidadeMaximaNavegacao) {
      exibirNotificacaoToast({
        mensagem: "Nível de navegação máxima atingido.",
        tipo: TipoNotificacao.Advertencia,
      });
      return;
    }

    const itens = navegacao
      .filter((x) => x.nivel < fragmento.nivel)
      .sort((a, b) => a.nivel - b.nivel);

    setNavegacao([...itens, fragmento]);
  };

  const irParaRaiz = () =>
    navegar({ nivel: 0, tipoDeclarante: null, nome: null });

  const fechar = (nivel: number) => {
    if (nivel == 0) {
      irParaRaiz();
    } else {
      const itens = navegacao
        .filter((x) => x.nivel < nivel)
        .sort((a, b) => a.nivel - b.nivel);

      setNavegacao(itens);
    }
  };

  const obterTag = (nivel: number, nome: string) => {
    const itens: string[] = [];

    for (let i = 0; i <= nivel; i++) {
      const n = navegacao.filter((x) => x.nivel == i)[0];

      if (n.nome) {
        itens.push(n.nome);
      }
    }
    itens.push(nome);
    return `[[${itens.join(".")}]]`;
  };

  const model: DicionarioTagsContextProps = {
    navegacao,
    schema,
    definirSchema,
    navegar,
    irParaRaiz,
    obterTag,
    fechar,
  };

  return (
    <DicionarioTagsContext.Provider value={model}>
      {children}
    </DicionarioTagsContext.Provider>
  );
}
