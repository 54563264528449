import DataSource from "devextreme/data/data_source";
import {
  assertConfiguracaoExibicaoEBuscaType,
  ConfiguracaoExibicaoEBusca,
} from "../../../../components/formularios/selectbox-lazy-mxp";
import getApi from "../../../../configs/api";
import {
  IdStringResponse,
  ResponseModel,
} from "../../../../models/api/comum/response-base";
import { SelectBoxLazyOpcoes } from "../../../../models/api/comum/selectboxlazy-options";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import { criarDataSourceSelectBoxLazy } from "../../../../utils/api/api-utils";
import { formatarItem } from "../../../../utils/formatadores/formatador-de-identidades";
import criarDatasourceGrid from "../../../../utils/grid/back-end-grid-utils";
import { DataSourceOpcoesBuilder } from "../../../../utils/grid/data-source-factory";
import { ItemGridModel } from "../models/item.api";

export class ItemService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.Item);
  }

  public GetDadosSelectBoxItem(
    dataSourceOpcoes?: DataSourceOpcoesBuilder<ItemGridModel>
  ): [
    DataSource<ItemGridModel, any>,
    ConfiguracaoExibicaoEBusca<ItemGridModel>
  ] {
    const dataSource = this.ObterDataSourceParaSelectBoxLazy<ItemGridModel>({
      quantidadeRegistros: dataSourceOpcoes?.quantidadeRegistros,
      camposRetorno: dataSourceOpcoes?.camposRetorno || [
        "id",
        "codigo",
        "descricao",
        "procedencia",
      ],
      camposOrdenacao: dataSourceOpcoes?.camposOrdenacao || [
        { campo: "codigo", desc: false },
      ],
      camposFiltro: dataSourceOpcoes?.camposFiltro,
    });

    const configs = assertConfiguracaoExibicaoEBuscaType<ItemGridModel>({
      nomeCampoChave: "id",
      expressaoDeBusca: ["codigo", "descricao"],
      nomeCampoExibicao: (c) => {
        if (c) {
          return formatarItem({
            codigo: c.codigo ?? "",
            descricao: c.descricao,
          });
        }

        return "";
      },
    });

    return [dataSource, configs];
  }

  public async obterIdPorCodigo(codigos: string[]) {
    const api = getApi();
    const response = await api.post<ResponseModel<IdStringResponse[]>>(
      `${this._nomeEndpoint}/ObterIdPorCodigo`,
      Array.from(new Set(codigos))
    );

    const map = {} as Record<string, number>;
    if (!response.data.sucesso) {
      return map;
    }
    for (const x of response.data.model) {
      map[x.valor] = x.id;
    }
    return map;
  }

  public GetDataSourceSelectBoxLazyItensInsumos(opcoes: SelectBoxLazyOpcoes) {
    const camposSelect = JSON.stringify(opcoes.camposRetorno);

    const dataSource = criarDatasourceGrid(
      `${this._nomeEndpoint}/GridItensDosInsumos?select=${camposSelect}`
    );

    return criarDataSourceSelectBoxLazy(dataSource, opcoes);
  }

  public GetGridSourceItensInsumos() {
    return criarDatasourceGrid(`${this._nomeEndpoint}/GridItensDosInsumos`);
  }
}
