import DataGrid, { Column, DataGridRef } from "devextreme-react/cjs/data-grid";
import ArrayStore from "devextreme/data/array_store";
import { useCallback, useEffect, useMemo, useRef } from "react";
import ProvedorAjuda from "../../../../../../../components/ajuda/provedor-ajuda";
import GridColunaAcoes from "../../../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { Modal } from "../../../../../../../components/layout/modal";
import { ModalMxp } from "../../../../../../../components/layout/modal-mxp";
import { useRegistrarAtalhosGrid } from "../../../../../../../hooks/atalhos.hooks";
import { useControlarFormDeEdicao } from "../../../../../../../hooks/form.hooks";
import { useParametroId } from "../../../../../../../hooks/route.hooks";
import { DemonstrativoSaldosGridModel } from "../../../../../../../models/api/demonstrativo-saldos/demonstrativo-saldos-comun";
import { ResultadoAcaoFormulario } from "../../../../../../../models/shared/ui/formularios";
import GetColunasDeAuditoria from "../../../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import criarNameof from "../../../../../../../utils/common/cria-name-of";
import NomesModais from "../../../../../../../utils/common/nomes-modais";
import NormalizaTituloModal from "../../../../../../../utils/common/normaliza-titulo";
import GridBuilder from "../../../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../../../utils/grid/grid-controller";
import { GridBaseProps } from "../../../../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../../../../utils/grid/padroes-colunas";
import UrlUtils from "../../../../../../../utils/url/url-utils";
import { DemonstrativoSaldosParteBLalurService } from "../../../../servicos/demonstrativo-saldos-parte-b-lalur";
import ModalEdicaoDemonstrativosSaldosLalur from "./modal-edicao-demonstrativo-saldos";

const service = new DemonstrativoSaldosParteBLalurService();
const nameOfGridHandler = criarNameof<DemonstrativoSaldosGridModel>();

interface ModalDemonstrativoSaldosParteBLalurProps extends GridBaseProps {
  modalVisivel: boolean;
  apuracaoLalurIrpjId: number;
  handleCallbackModal: () => void;
  titulo: string;
}

export default function ModalDemonstrativoSaldosParteBLalur(
  props: ModalDemonstrativoSaldosParteBLalurProps
) {
  const gridRef = useRef<DataGridRef>(null);
  const parametroId = useParametroId();

  const {
    idRegistroEmEdicao,
    setIdRegistroEmEdicao,
    modalVisivel,
    encerrarEdicao,
  } = useControlarFormDeEdicao(NaN);

  const handleEditarRegistro = useCallback(
    (registro: DemonstrativoSaldosGridModel) => {
      setIdRegistroEmEdicao(registro.id);
    },
    []
  );

  const dataSource = useMemo(
    () =>
      !props.modalVisivel
        ? new ArrayStore()
        : service.ObterDataSourceParaGrid<DemonstrativoSaldosGridModel>([
            {
              campo: "apuracaoId",
              operador: "=",
              valor: props.apuracaoLalurIrpjId,
            },
          ]),
    [props.modalVisivel, props.apuracaoLalurIrpjId]
  );

  const gridController = new GridController<DemonstrativoSaldosGridModel>(() =>
    gridRef.current?.instance()
  );

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "demonstrativo-saldos-parte-b-lalur",
      () => gridRef.current?.instance(),
      true
    )
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(props.titulo)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirOrdenacao()
      .definirDuploCliqueLinha(handleEditarRegistro)
      .build();
  }, [props.modalVisivel, props.apuracaoLalurIrpjId]);

  useEffect(() => {
    if (parametroId) {
      setIdRegistroEmEdicao(parametroId);
    }
  }, []);

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  useRegistrarAtalhosGrid<DemonstrativoSaldosGridModel>({
    controller: gridController,
    handleEditar: handleEditarRegistro,
  });

  const handleCallbackFormulario = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      encerrarEdicao();

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }

      UrlUtils.RemoverParametroId(parametroId);
    },
    [parametroId]
  );

  const handleFecharModal = useCallback(() => {
    encerrarEdicao();
    UrlUtils.RemoverParametroId(parametroId);
  }, [parametroId]);

  return (
    <>
      <Modal
        titulo={props.titulo}
        visivel={props.modalVisivel}
        onFechar={props.handleCallbackModal}
        altura={"90vh"}
      >
        <ProvedorAjuda id={"tooltips-grid-demonstrativo-saldos-parte-b-lalur"}>
          <DataGrid ref={gridRef} {...configuracoesGrid}>
            {GridColunaAcoes<DemonstrativoSaldosGridModel>({
              handleEditar: handleEditarRegistro,
            })}
            <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
            <Column
              key={nameOfGridHandler("contaDaParteB")}
              dataField={nameOfGridHandler("contaDaParteB")}
              {...obterConfiguracaoColuna("stringM")}
              caption="Conta da parte B"
            />
            <Column
              key={nameOfGridHandler("saldoInicial")}
              dataField={nameOfGridHandler("saldoInicial")}
              {...obterConfiguracaoColuna("monetarioBalanceteCD")}
              caption="Saldo inicial"
              width={125}
            />
            <Column
              key={nameOfGridHandler("lancamentoDaParteA")}
              dataField={nameOfGridHandler("lancamentoDaParteA")}
              {...obterConfiguracaoColuna("monetarioBalanceteCD")}
              caption="Lançamento da parte A"
              width={190}
            />
            <Column
              key={nameOfGridHandler("lancamentoDaParteB")}
              dataField={nameOfGridHandler("lancamentoDaParteB")}
              {...obterConfiguracaoColuna("monetarioBalanceteCD")}
              caption="Lançamento da parte B"
              width={190}
            />
            <Column
              key={nameOfGridHandler("saldoFinal")}
              dataField={nameOfGridHandler("saldoFinal")}
              {...obterConfiguracaoColuna("monetarioBalanceteCD")}
              caption="Saldo final"
              width={125}
            />
            {GetColunasDeAuditoria()}
          </DataGrid>
        </ProvedorAjuda>

        <ModalMxp
          titulo={NormalizaTituloModal.Normalizar(
            idRegistroEmEdicao,
            NomesModais.demonstrativoDeSaldosParteBLalur
          )}
          visivel={modalVisivel}
          handleFechar={handleFecharModal}
          largura={"max(30vw, 600px)"}
          altura={"auto"}
        >
          <ModalEdicaoDemonstrativosSaldosLalur
            idRegistroEmEdicao={idRegistroEmEdicao}
            handleCallback={handleCallbackFormulario}
          />
        </ModalMxp>
      </Modal>
    </>
  );
}
