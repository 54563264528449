import { useCallback, useContext } from "react";
import FormMxp from "../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../components/layout/form/styles";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import RelatorioPersonalizadoContexto from "../../../../relatorio/relatorio-personalizado/contexts/relatorio-personalizado.context";
import { FinalidadeModeloDeRelatorioTituloHelper } from "../../models/modelo-de-relatorio.enums";
import GridModeloDeRelatorio from "../grid";

export default function ModalGerenciarModelosDeRelatoriosPersonalizados() {
  const {
    finalidade,
    modalConfigurarModeloDeRelatorioVisivel,
    definirModalConfigurarModeloDeRelatorioVisivel,
  } = useContext(RelatorioPersonalizadoContexto);

  const handleFechar = useCallback(() => {
    definirModalConfigurarModeloDeRelatorioVisivel(false);
  }, []);

  return (
    <>
      <ModalMxp
        titulo={`Modelos de relatórios de ${FinalidadeModeloDeRelatorioTituloHelper.obterTitulo(
          finalidade!
        )}`}
        visivel={modalConfigurarModeloDeRelatorioVisivel}
        handleFechar={handleFechar}
      >
        <ContainerFormMxp>
          <FormMxp carregando={false}>
            <GridModeloDeRelatorio finalidade={finalidade!} />
          </FormMxp>
        </ContainerFormMxp>
      </ModalMxp>
    </>
  );
}
