import { TextBox } from "devextreme-react";
import { memo, useContext, useState } from "react";
import { funcaoOrdernarAlfabeticamente } from "../../../../../utils/strings/string-utils";
import { DicionarioTagsAdapter } from "../../adapters/dicionario-tags.adapter";
import CardPropriedade from "../card-propriedade";
import { DicionarioTagsContext } from "../dicionario-tags-schema-provider";
import {
  Container,
  Fechar,
  Rolagem,
  TextBoxBuscaContainer,
  Titulo,
  TituloContainer,
  TituloTexto,
} from "./styles";

interface FragmentoNavegacaoProps {
  nivel: number;
  nome: string | null;
  tipoDeclarante: string | null;
}

const SaoIguais = (
  antigo: FragmentoNavegacaoProps,
  novo: FragmentoNavegacaoProps
) =>
  antigo.nivel == novo.nivel &&
  antigo.nome == novo.nome &&
  antigo.tipoDeclarante == novo.tipoDeclarante;

const FragmentoNavegacao = memo(
  ({ nivel, tipoDeclarante, nome }: FragmentoNavegacaoProps) => {
    const { schema, fechar } = useContext(DicionarioTagsContext);
    const [termoBusca, setTermoBusca] = useState("");
    const propriedades = schema.propriedades
      .filter((p) => p.nomeTipoDeclarante == tipoDeclarante)
      .map((p) =>
        DicionarioTagsAdapter.propriedadeToNivelHierarquicoTagViewModel(
          p,
          schema
        )
      )
      .sort((a, b) =>
        funcaoOrdernarAlfabeticamente(a.nomeAmigavel, b.nomeAmigavel)
      );

    const filtrado = termoBusca
      ? propriedades.filter((x) =>
          x.nomeAmigavel
            .toLocaleLowerCase()
            .includes(termoBusca.toLocaleLowerCase())
        )
      : propriedades;

    const handleFechar = () => {
      fechar(nivel);
    };

    return (
      <Container>
        <TituloContainer>
          <Titulo>
            {nome ? (
              <TituloTexto>{nome}</TituloTexto>
            ) : (
              <span className="fa-solid fa-house" />
            )}
          </Titulo>
          <a onClick={handleFechar} title="Fechar">
            <Fechar className="fa-solid fa-xmark" />
          </a>
        </TituloContainer>
        <TextBoxBuscaContainer>
          <TextBox
            placeholder="Pesquisar"
            showClearButton
            value={termoBusca}
            valueChangeEvent="keyup"
            onValueChange={(e) => setTermoBusca(e)}
          />
        </TextBoxBuscaContainer>
        <Rolagem>
          {filtrado.map((p) => (
            <CardPropriedade
              key={p.nomeAmigavel}
              propriedade={p}
              nivel={nivel}
            />
          ))}
        </Rolagem>
      </Container>
    );
  },
  SaoIguais
);

export default FragmentoNavegacao;
