import { Column } from "devextreme-react/cjs/data-grid";
import { EstoqueGridModel } from "../../../../../models/api/estoque/estoque";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import criarNameof from "../../../../../utils/common/cria-name-of";
import { gerarLookupDoDicionario } from "../../../../../utils/common/enum-utils";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import { EstoqueTipoHelper } from "../../models/estoque.enums";

const nameOfGridHandler = criarNameof<EstoqueGridModel>();

export const colunasEstoques = [
  <Column
    key={nameOfGridHandler("codigoItem")}
    dataField={nameOfGridHandler("codigoItem")}
    {...obterConfiguracaoColuna("stringM")}
    allowEditing={false}
    caption="Item"
  />,
  <Column
    key={nameOfGridHandler("descricaoItem")}
    dataField={nameOfGridHandler("descricaoItem")}
    {...obterConfiguracaoColuna("stringGG")}
    allowEditing={false}
    caption="Descrição"
  />,
  <Column
    key={nameOfGridHandler("grupo")}
    dataField={nameOfGridHandler("grupo")}
    {...obterConfiguracaoColuna("stringM")}
    allowEditing={false}
    caption="Grupo"
    visible={false}
    width={"auto"}
  />,
  <Column
    key={nameOfGridHandler("quantidade")}
    dataField={nameOfGridHandler("quantidade")}
    {...obterConfiguracaoColuna("quantidade")}
    allowEditing={false}
    caption="Qt em estoque"
    width={"auto"}
  />,
  <Column
    key={nameOfGridHandler("unidadeMedidaEngenharia")}
    dataField={nameOfGridHandler("unidadeMedidaEngenharia")}
    {...obterConfiguracaoColuna("stringP")}
    allowEditing={false}
    caption="Unid"
    width={"auto"}
  />,
  <Column
    key={nameOfGridHandler("custoDeAquisicaoOuOrcamentacao")}
    dataField={nameOfGridHandler("custoDeAquisicaoOuOrcamentacao")}
    {...obterConfiguracaoColuna("monetario")}
    allowEditing={false}
    caption="Custo de Aquisição (R$)"
    visible={false}
    width={"auto"}
  />,
  <Column
    key={nameOfGridHandler("estoque")}
    dataField={nameOfGridHandler("estoque")}
    {...obterConfiguracaoColuna("stringM")}
    allowEditing={false}
    caption="Estoque"
  />,
  <Column
    key={nameOfGridHandler("enderecoDeEstoque")}
    dataField={nameOfGridHandler("enderecoDeEstoque")}
    {...obterConfiguracaoColuna("stringM")}
    allowEditing={false}
    caption="Endereço de estoque"
    width={180}
  />,
  <Column
    key={nameOfGridHandler("tipo")}
    dataField={nameOfGridHandler("tipo")}
    {...obterConfiguracaoColuna("stringMG")}
    allowEditing={false}
    lookup={gerarLookupDoDicionario(EstoqueTipoHelper.estoqueTipoDescricao)}
  />,
  <Column
    key={nameOfGridHandler("empresaDonaApelido")}
    dataField={nameOfGridHandler("empresaDonaApelido")}
    {...obterConfiguracaoColuna("stringMG")}
    allowEditing={false}
    caption="Dono"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("empresaPosseApelido")}
    dataField={nameOfGridHandler("empresaPosseApelido")}
    {...obterConfiguracaoColuna("stringMG")}
    allowEditing={false}
    caption="Em poder de"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("quantidadeUnidadeDeVenda")}
    dataField={nameOfGridHandler("quantidadeUnidadeDeVenda")}
    {...obterConfiguracaoColuna("quantidade")}
    allowEditing={false}
    caption="Qt da unid de venda"
    visible={false}
    width={"auto"}
  />,
  <Column
    key={nameOfGridHandler("unidadeMedidaVenda")}
    dataField={nameOfGridHandler("unidadeMedidaVenda")}
    {...obterConfiguracaoColuna("stringM")}
    allowEditing={false}
    caption="Unid de venda"
    visible={false}
    width={"auto"}
  />,
  <Column
    key={nameOfGridHandler("precoVendaUnitario")}
    dataField={nameOfGridHandler("precoVendaUnitario")}
    {...obterConfiguracaoColuna("monetario")}
    allowEditing={false}
    caption="Preço de venda unitário (R$)"
    visible={false}
    width={"auto"}
  />,
  <Column
    key={nameOfGridHandler("precoVendaTotal")}
    dataField={nameOfGridHandler("precoVendaTotal")}
    {...obterConfiguracaoColuna("monetario")}
    allowEditing={false}
    caption="Preço de venda total (R$)"
    visible={false}
    width={"auto"}
  />,
  <Column
    key={nameOfGridHandler("qualidade")}
    dataField={nameOfGridHandler("qualidade")}
    {...obterConfiguracaoColuna("stringM")}
    allowEditing={false}
    caption="Qualidade"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("dataFabricacao")}
    dataField={nameOfGridHandler("dataFabricacao")}
    {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
    allowEditing={false}
    caption="Data de fabricação"
    width={175}
  />,
  <Column
    key={nameOfGridHandler("dataVencimento")}
    dataField={nameOfGridHandler("dataVencimento")}
    {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
    allowEditing={false}
    caption="Data de vencimento"
    visible={false}
    width={175}
  />,
  <Column
    key={nameOfGridHandler("dataReanalise")}
    dataField={nameOfGridHandler("dataReanalise")}
    {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
    allowEditing={false}
    caption="Data de reanálise"
    visible={false}
    width={160}
  />,
  <Column
    key={nameOfGridHandler("loteInterno")}
    dataField={nameOfGridHandler("loteInterno")}
    {...obterConfiguracaoColuna("stringM")}
    allowEditing={false}
    caption="Lote Interno"
  />,
  <Column
    key={nameOfGridHandler("loteFabricanteNumeroSerie")}
    dataField={nameOfGridHandler("loteFabricanteNumeroSerie")}
    {...obterConfiguracaoColuna("stringM")}
    allowEditing={false}
    caption="Lote do fabricante/Numero de série"
    width={"auto"}
  />,
  <Column
    key={nameOfGridHandler("observacaoLote")}
    dataField={nameOfGridHandler("observacaoLote")}
    {...obterConfiguracaoColuna("stringM")}
    allowEditing={false}
    caption="Observação do lote"
    visible={false}
    width={"auto"}
  />,
  <Column
    key={nameOfGridHandler("fabricante")}
    dataField={nameOfGridHandler("fabricante")}
    {...obterConfiguracaoColuna("stringM")}
    allowEditing={false}
    caption="Fabricante"
  />,
  <Column
    key={nameOfGridHandler("custoUnitario")}
    dataField={nameOfGridHandler("custoUnitario")}
    {...obterConfiguracaoColuna("monetario")}
    allowEditing={false}
    caption="Custo unitário (R$)"
    width={"auto"}
  />,
  <Column
    key={nameOfGridHandler("custoTotal")}
    dataField={nameOfGridHandler("custoTotal")}
    {...obterConfiguracaoColuna("monetario")}
    allowEditing={false}
    caption="Custo total (R$)"
    width={"auto"}
  />,
  <Column
    key={nameOfGridHandler("pesoLiquidoTotal")}
    dataField={nameOfGridHandler("pesoLiquidoTotal")}
    {...obterConfiguracaoColuna("peso")}
    allowEditing={false}
    caption="Peso líquido total (Kg)"
    visible={false}
    width={"auto"}
  />,
  <Column
    key={nameOfGridHandler("pesoLiquidoUnitario")}
    dataField={nameOfGridHandler("pesoLiquidoUnitario")}
    {...obterConfiguracaoColuna("peso")}
    allowEditing={false}
    caption="Peso líquido unitário (Kg)"
    visible={false}
    width={"auto"}
  />,
  <Column
    key={nameOfGridHandler("pesoBrutoUnitario")}
    dataField={nameOfGridHandler("pesoBrutoUnitario")}
    {...obterConfiguracaoColuna("peso")}
    allowEditing={false}
    caption="Peso bruto unitário (Kg)"
    visible={false}
    width={"auto"}
  />,
  <Column
    key={nameOfGridHandler("pesoBrutoTotal")}
    dataField={nameOfGridHandler("pesoBrutoTotal")}
    {...obterConfiguracaoColuna("peso")}
    allowEditing={false}
    caption="Peso bruto total (Kg)"
    visible={false}
    width={"auto"}
  />,
  <Column
    key={nameOfGridHandler("estoqueMinimo")}
    dataField={nameOfGridHandler("estoqueMinimo")}
    {...obterConfiguracaoColuna("quantidade")}
    caption="Estoque mínimo"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("descricaoComplementarItem")}
    dataField={nameOfGridHandler("descricaoComplementarItem")}
    {...obterConfiguracaoColuna("stringGG")}
    allowEditing={false}
    caption="Descrição complementar do item"
    visible={false}
  />,
  ...GetColunasDeAuditoria(),
];
