import { useCallback } from "react";
import ImportadorDeRegistros, {
  colunaParaBoolean,
  colunaParaNumero,
  MxpSchemaImportar,
} from "../../../../../components/formularios/importador";
import { CadastrarEmMassaResponse } from "../../../../../models/api/comum/cadastrar-varios";
import { ResponseModel } from "../../../../../models/api/comum/response-base";
import {
  quantidadeValorMaximo,
  quantidadeValorMinimo,
} from "../../../../../utils/common/constantes";
import yupPt from "../../../../../utils/validacao/validacao";
import { InsumoDaOrdemDeProducaoService } from "../../../insumo-da-ordem-de-producao/servicos/insumo-da-ordem-de-producao";
import { OrdemDeProducaoBaixarVariasExcelRequest } from "../../models/ordem-de-producao.api";

const service = new InsumoDaOrdemDeProducaoService();

const schema: MxpSchemaImportar<OrdemDeProducaoBaixarVariasExcelRequest> = {
  "Ordem de produção *": {
    prop: "numeroOrdemDeProducao",
    type: colunaParaNumero,
    dataType: "number",
  },
  "Insumo *": {
    prop: "codigoInsumo",
    type: String,
    dataType: "string",
  },
  "Operação *": {
    prop: "numeroOperacaoDaOrdemDeProducao",
    type: colunaParaNumero,
    dataType: "number",
  },
  "Lote/número de série": {
    prop: "loteOuNumeroDeSerie",
    type: String,
    dataType: "string",
  },
  "Conta de estoque": {
    prop: "apelidoContaDeEstoque",
    type: String,
    dataType: "number",
  },
  "Endereço de estoque": {
    prop: "codigoEnderecoDeEstoque",
    type: String,
    dataType: "number",
  },
  Qualidade: {
    prop: "codigoQualidade",
    type: String,
    dataType: "string",
  },
  Dono: {
    prop: "apelidoDonoEstoque",
    type: String,
    dataType: "string",
  },
  Quantidade: {
    prop: "quantidade",
    type: colunaParaNumero,
    dataType: "number",
  },
  "Baixar qtde maior do que o previsto": {
    prop: "baixarQuantidadeMaiorQuePrevisto",
    type: colunaParaBoolean,
    dataType: "boolean",
  },
  "Consumir para qual número de série": {
    prop: "numeroDeSerieDestino",
    type: String,
    dataType: "string",
  },
  "Manter em estoque separado *": {
    prop: "manterEmEstoqueSeparado",
    type: colunaParaBoolean,
    dataType: "boolean",
  },
  "Forçar conclusão da baixa se qtde consumida menor que a prevista": {
    prop: "forcarConclusaoSeQuantidadeMenorQuePrevista",
    type: colunaParaBoolean,
    dataType: "boolean",
  },
};

const schemaYup = yupPt.object({
  numeroOrdemDeProducao: yupPt
    .number()
    .default(undefined)
    .transform((parsedValue, originalValue) =>
      originalValue === "" ? null : parsedValue
    )
    .required()
    .integer()
    .typeError("Número da ordem de produção inválido")
    .label("Ordem de produção"),
  codigoInsumo: yupPt.string().required().min(1).label("Insumo"),
  numeroOperacaoDaOrdemDeProducao: yupPt
    .number()
    .default(undefined)
    .transform((parsedValue, originalValue) =>
      originalValue === "" ? null : parsedValue
    )
    .required()
    .integer()
    .typeError("Número da operação inválido")
    .label("Operação"),
  quantidade: yupPt
    .number()
    .required()
    .transform((parsedValue, originalValue) =>
      originalValue === "" ? null : parsedValue
    )
    .moreThan(quantidadeValorMinimo.toNumber())
    .lessThan(quantidadeValorMaximo.toNumber())
    .label("Quantidade"),
  manterEmEstoqueSeparado: yupPt
    .bool()
    .required()
    .label("Manter em estoque separado"),
});

export interface ModalBaixaExcelProps {
  visivel: boolean;
  onFechar: () => void;
  onImportar: (resultado: ResponseModel<CadastrarEmMassaResponse[]>) => void;
}

export default function ModalBaixaExcel({
  visivel,
  onFechar,
  onImportar,
}: ModalBaixaExcelProps) {
  const handleImportarExcel = useCallback(
    async (registros: OrdemDeProducaoBaixarVariasExcelRequest[]) => {
      const resultado = await service.baixarEmMassaExcel(registros);
      onImportar(resultado);
      return resultado;
    },
    [onImportar]
  );

  return (
    <ImportadorDeRegistros
      titulo="Importar baixas para ordens de produção de arquivo Excel"
      onFechar={onFechar}
      nomeDaPlanilha="Baixas Ordem de Produção"
      visivel={visivel}
      onImportar={handleImportarExcel}
      modeloUrl={"../modelosXlsx/Planilha_Modelo_Baixa_para_OP_MAXIPROD.xlsx"}
      schema={schema}
      schemaYup={schemaYup}
    />
  );
}
