import AuditavelGridModel from "../../../../models/api/comum/auditavel-grid";
import { FinalidadeTipo } from "./conta-contabil.enum";

export enum ContaContabilTipo {
  Analitica = 65,
  Sintetica = 83,
}

export enum ObrigatoriedadeCentroDeCustos {
  Obrigatoria = 83,
  Opcional = 79,
  Proibida = 78,
}

export interface ContaContabilGridModel extends AuditavelGridModel {
  classificacao: string;
  descricao: string;
  apelido: string;
  podeMovimentarEstoque: boolean;
  finalidade: FinalidadeTipo;
  codigo: string | null;
  tipo: ContaContabilTipo;
  ativo: boolean;
  obrigatoriedadeCentroDeCustos: ObrigatoriedadeCentroDeCustos;
}
