import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { FormMultiSelectBox } from "../../../../../../components/formularios";
import { MulltiSelectDataSource } from "../../../../../../components/formularios/multiselectbox";
import TabContainer from "../../../../../../components/layout/tab-container";
import { DadosRastreabilidadePorNumeroDeSerieRequest } from "../../../../../estoque/lote/componentes/formulario-cadastro-de-rastreabilidade";
import { ConcluirOrdemDeProducaoRequest } from "../../../models/ordem-de-producao.api";

export default function RastreabilidadePorNumeroDeSerie() {
  const { control, getValues, setValue, watch } =
    useFormContext<ConcluirOrdemDeProducaoRequest>();

  const dataSource = useMemo(() => {
    const numerosDeSerie = getValues("rastreabilidadeNumeroDeSerie");

    return {
      dataSource: numerosDeSerie,
      valueExpr: "id",
      displayExpr: "numeroDeSerie",
    } as MulltiSelectDataSource<DadosRastreabilidadePorNumeroDeSerieRequest>;
  }, [getValues, watch("rastreabilidadeNumeroDeSerie")]);

  useEffect(() => {
    setValue(
      "quantidade",
      getValues("rastreabilidadeNumeroDeSerieSelecionados")?.length || 0
    );
  }, [setValue, getValues, watch("rastreabilidadeNumeroDeSerieSelecionados")]);

  return (
    <TabContainer>
      <FormMultiSelectBox
        control={control}
        name="rastreabilidadeNumeroDeSerieSelecionados"
        titulo="Selecione os números de série"
        habilitaBusca
        dataSource={dataSource}
        tipoBusca="contains"
        maxDisplayedTags={100}
        modoSelecao={"lista"}
      />
    </TabContainer>
  );
}
