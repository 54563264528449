import styled from "styled-components";

export const IconeDivisao = styled.span`
  margin-left: 6px;
  margin-right: 6px;
  color: silver;
`;

export const ZonaClicavel = styled.span`
  cursor: pointer;

  &:hover {
    color: blue;
  }
`;
