import { StringsComum } from "../../../features/comum/strings";
import { LinkButton } from "../../templates-celulas-grid/celula-controle-edicao-mxp/styles";

interface BotaoEditarColunaAcaoGridMxpProps {
  onClick: () => void;
  tooltipCustom?: string;
  desabilitado?: boolean;
}

export default function BotaoEditarColunaAcaoGridMxp(
  props: BotaoEditarColunaAcaoGridMxpProps
) {
  return (
    <LinkButton
      onClick={!props.desabilitado ? props.onClick : undefined}
      title={props.tooltipCustom ?? StringsComum.editar}
      className="btn-editar-linha"
      isDisabled={props.desabilitado}
    >
      <i className="ic-material-symbols-outlined ic-edit icone-linha-grid"></i>
    </LinkButton>
  );
}
