import { yupResolver } from "@hookform/resolvers/yup";
import { Button, TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/cjs/accordion";
import { useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import ProvedorAjuda from "../../../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../../../components/botoes/botao-cancelar-mxp";
import {
  FormCheckBox,
  FormGrupo,
  FormNumberBox,
  FormTextBoxSimples,
} from "../../../../../../components/formularios";
import FormMxp from "../../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../../components/layout/toolbar-mxp";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../../models/shared/ui/formularios";
import {
  checarErrosDeConfirmacaoResponse,
  checarResponse,
  tratarErroApi,
} from "../../../../../../utils/api/api-utils";
import {
  quantidadeValorMaximo,
  quantidadeValorMinimo,
} from "../../../../../../utils/common/constantes";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../../utils/common/notificacoes-utils";
import {
  FormataDescricao,
  FormatadoresSelectBox,
} from "../../../../../../utils/formatadores/formatador-de-selectbox";
import { FinalidadeTipo } from "../../../../../contabilidade/conta-contabil/models/conta-contabil.enum";
import SelecionarEstoqueDestino, {
  yupCampoEmpresaDonaId,
  yupCampoEmpresaPosseId,
} from "../../../../../estoque/estoque-movimentacao/componentes/partes/selecionar-estoque-destino/selecionar-estoque-destino";
import { EstoqueMovimentacaoTipo } from "../../../../../estoque/estoque-movimentacao/models/estoque-movimentacao.enums";
import RastreabilidadePorLote, {
  novaRastreabilidadeLote,
  yupRastreabilidadeLote,
} from "../../../../../estoque/lote/componentes/formulario-cadastro-de-rastreabilidade";
import { EstocagemTipo } from "../../../../../itens/item/models/item.enums";
import { ConcluirOrdemDeProducaoRequest } from "../../../models/ordem-de-producao.api";
import { OrdemDeProducaoService } from "../../../servicos/ordem-de-producao.service";
import ContextoOrdemDeProducaoGrid from "../../provedor-contexto-ordem-de-producao/contexto-ordem-de-producao-grid";
import RastreabilidadePorNumeroDeSerie from "./selecionar-numeros-de-serie";

const service = new OrdemDeProducaoService();

const novoRegistro: ConcluirOrdemDeProducaoRequest = {
  idOrdemDeProducao: 0,
  itemId: 0,
  quantidade: 0,
  itemEstocagemTipo: EstocagemTipo.Codigo,
  itemUsaQuantidadeInteira: false,
  fecharOp: false,
  forcarConclusao: false,
  destino: EstoqueMovimentacaoTipo.Estoque,
  idContaContabilDestino: undefined,
  finalidadeContaContabil: FinalidadeTipo.Estoque,
  empresaDonaId: undefined,
  empresaPosseId: undefined,
  codigoItem: "",
  codigoOrdemDeProducao: "",
  rastreabilidadeLote: novaRastreabilidadeLote,
  rastreabilidadeNumeroDeSerie: [],
};

let fecharOp: boolean = true;
let quantidadeDaOp: number = 0;

export default function FormConcluirOrdemDeProducao(
  props: IFormularioEditavelBase
) {
  const [carregando, setCarregando] = useState(false);

  const schema = yup.object<ConcluirOrdemDeProducaoRequest>().shape({
    idOrdemDeProducao: yup.number().required().positive().integer(),
    itemId: yup.number().required().positive().integer(),
    quantidade: yup
      .number()
      .required()
      .lessThan(quantidadeValorMaximo.toNumber())
      .test(
        "is-integer",
        "Quantidade deve ser um número inteiro",
        function (value) {
          const { itemUsaQuantidadeInteira } = this.parent;
          return itemUsaQuantidadeInteira ? Number.isInteger(value) : true;
        }
      )
      .when(["fecharOp"], (valores, schema) => {
        const [fecharOp] = valores as [boolean];
        if (fecharOp) {
          return schema.min(quantidadeValorMinimo.toNumber());
        } else {
          return schema.moreThan(quantidadeValorMinimo.toNumber());
        }
      }),
    destino: yup
      .number()
      .oneOf([
        EstoqueMovimentacaoTipo.Estoque,
        EstoqueMovimentacaoTipo.ContaContabil,
      ]),
    idContaContabilDestino: yup.number().required().positive().integer(),
    empresaDonaId: yupCampoEmpresaDonaId(),
    empresaPosseId: yupCampoEmpresaPosseId(),
    rastreabilidadeLote: yup
      .object()
      .when(["fecharOp", "quantidade"], (valores, schema) => {
        const [fecharOp, quantidade] = valores;
        if (fecharOp && quantidade == 0) {
          return schema.notRequired();
        } else {
          return yupRastreabilidadeLote(
            (): EstocagemTipo => getValues("itemEstocagemTipo")
          );
        }
      }),
  });

  const hookForm = useForm<ConcluirOrdemDeProducaoRequest>({
    resolver: yupResolver(schema),
  });

  const { register, control, getValues, handleSubmit, reset, watch, setValue } =
    hookForm;

  const { atualizaGridDeOrdemDeProducao } = useContext(
    ContextoOrdemDeProducaoGrid
  );

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    preencherTela();

    return () => limparTela();
  }, [props.idRegistroEmEdicao]);

  async function preencherTela() {
    if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    } else {
      limparTela();
    }
  }

  async function carregarRegistroDoId() {
    try {
      setCarregando(true);

      // Obter dados para concluir a ordem de produção
      const resposta = await service.ObterDadosParaConcluirOrdemDeProducao(
        props.idRegistroEmEdicao
      );
      checarResponse(resposta);

      if (!resposta.sucesso) {
        return;
      }

      const model = resposta.model;

      // Atualizar rastreabilidade do lote
      model.rastreabilidadeLote = novaRastreabilidadeLote;
      if (model.rastreabilidadeLoteId !== undefined) {
        model.rastreabilidadeLote.criarNovo = false;
        model.rastreabilidadeLote.loteId = model.rastreabilidadeLoteId;
      }

      // Atualizar variáveis globais
      quantidadeDaOp = model.quantidade;
      fecharOp = model.fecharOp;

      // Resetar o formulário com o modelo atualizado
      reset(model);
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  function limparTela() {
    reset(novoRegistro);
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();
    try {
      const podeConcluir =
        await service.VerificarSePodeConcluirOpSemForcarConclusao(model);
      if (!podeConcluir.sucesso) {
        const confirmacaoForcar = await checarErrosDeConfirmacaoResponse(
          podeConcluir
        );
        if (!confirmacaoForcar) {
          return;
        }
        model.forcarConclusao = true;
      }
      const resposta = await service.Concluir(model);
      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: "Conclusão de ordem de produção realizada com sucesso.",
          tipo: TipoNotificacao.Sucesso,
        });

        atualizaGridDeOrdemDeProducao();

        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  function callBackUnprocessableEntity() {
    fechar(ResultadoAcaoFormulario.AcaoConcluida);
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  useEffect(() => {
    if (!fecharOp) {
      return;
    }

    setValue("fecharOp", getValues("quantidade") >= quantidadeDaOp);
  }, [setValue, getValues, watch("quantidade")]);

  return (
    <ContainerFormMxp>
      <FormMxp carregando={carregando}>
        <FormProvider {...hookForm}>
          <ProvedorAjuda id="edit-form-concluir-ordem-de-producao">
            <input
              type="hidden"
              {...register("idOrdemDeProducao")}
              defaultValue={0}
            />
            <input type="hidden" {...register("itemId")} defaultValue={0} />
            <input type="hidden" {...register("codigoItem")} />
            <input type="hidden" {...register("codigoOrdemDeProducao")} />
            <FormGrupo>
              <Linha>
                <Coluna md={7}>
                  <FormTextBoxSimples
                    titulo="Ordem de produção"
                    requerido
                    valor={FormataDescricao(
                      FormatadoresSelectBox.CodigoDescricaoTraco,
                      getValues("codigoOrdemDeProducao"),
                      getValues("codigoItem")
                    )}
                    somenteLeitura
                  />
                </Coluna>
                <Coluna md={3}>
                  <FormNumberBox
                    name="quantidade"
                    titulo="Quantidade"
                    control={control}
                    minimo={quantidadeValorMinimo.toNumber()}
                    quantidadeIncrementarDecrementar={1}
                    maximo={quantidadeValorMaximo.toNumber()}
                    somenteLeitura={
                      watch("itemEstocagemTipo") == EstocagemTipo.Serie
                    }
                    requerido
                  />
                </Coluna>
                <Coluna md={2} centralizar>
                  <FormCheckBox
                    name="fecharOp"
                    titulo="Fechar"
                    control={control}
                  />
                </Coluna>
              </Linha>
              <SelecionarEstoqueDestino hookForms={hookForm as any} />
              {(watch("itemEstocagemTipo") == EstocagemTipo.LoteFabricante ||
                watch("itemEstocagemTipo") == EstocagemTipo.Serie) && (
                <TabPanel
                  deferRendering={false}
                  showNavButtons
                  swipeEnabled={false}
                  itemTitleRender={(item) => item.text}
                >
                  {watch("itemEstocagemTipo") ==
                    EstocagemTipo.LoteFabricante && (
                    <Item text="Rastreabilidade">
                      <RastreabilidadePorLote hookForms={hookForm as any} />
                    </Item>
                  )}
                  {watch("itemEstocagemTipo") == EstocagemTipo.Serie && (
                    <Item text="Rastreabilidade">
                      <RastreabilidadePorNumeroDeSerie />
                    </Item>
                  )}
                </TabPanel>
              )}
            </FormGrupo>
          </ProvedorAjuda>
        </FormProvider>
      </FormMxp>
      <ToolbarMxp>
        <Button
          type="success"
          text={"Concluir"}
          icon={"save"}
          onClick={handleSubmit(handleSalvar) as any}
        />
        <BotaoCancelarMxp handleClick={handleCancelar} />
      </ToolbarMxp>
    </ContainerFormMxp>
  );
}
