import differenceInHours from "date-fns/differenceInHours";
import format from "date-fns/format";
import { Button } from "devextreme-react";
import TabPanel, { Item, TabPanelRef } from "devextreme-react/tab-panel";
import React, { ReactNode, useRef, useState } from "react";
import ProvedorAjuda from "../../../../components/ajuda/provedor-ajuda";
import PdfViewer from "../../../../components/arquivo/pdf-viewer";
import { ColunaFlex } from "../../../../components/layout/coluna-flex";
import FormAvancado from "../../../../components/layout/form-avancado";
import { Modal } from "../../../../components/layout/modal";
import TabContainer from "../../../../components/layout/tab-container";
import useEffectOnLoad from "../../../../hooks/effect.hooks";
import { useCarregarDadosDoModelo } from "../../../../hooks/form.hooks";
import { useAppDispatch, useAppSelector } from "../../../../hooks/store.hooks";
import AuditavelDTO from "../../../../models/api/comum/auditavel-dto";
import {
  SituacaoMDFe,
  situacaoMDFeDecodificada,
} from "../../../../models/api/mdfe/mdfe-enums";
import { MDFeCompletoDTO } from "../../../../models/api/mdfe/mdfe-request-response";
import { AmbienteMDFeOpcoes } from "../../../../models/api/tokens/configuracoes";
import { PermissoesMDF } from "../../../../models/permissoes/fiscal/mdfe/permissoes-mdf";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import { IFormulario } from "../../../../models/shared/ui/formularios";
import { EditFormCancelamentoMDFe } from "../../../../parts/fiscal/mdfe/edit-form-cancelamento";
import { EditFormEncerramentoMDFe } from "../../../../parts/fiscal/mdfe/edit-form-encerramento";
import { ComponentAsyncLoader } from "../../../../parts/utils/load-on-demand";
import MDFeAbaCondutor from "../../../../parts/vendas/mdfe/abas/condutores";
import MDFeAbaDadosGerais from "../../../../parts/vendas/mdfe/abas/dados-gerais";
import MDFeAbaDocumentosFiscais from "../../../../parts/vendas/mdfe/abas/documentos-fiscais";
import { MDFeAbaObservacoes } from "../../../../parts/vendas/mdfe/abas/observacoes";
import MDFeAbaPercurso from "../../../../parts/vendas/mdfe/abas/percurso";
import MDFeAbaDadosVeiculos from "../../../../parts/vendas/mdfe/abas/veiculos";
import InfosCabecalhoMDFe from "../../../../parts/vendas/mdfe/infos-cabecalho-mdfe";
import QuadroAmbienteHomologacao from "../../../../parts/vendas/mdfe/quadro-ambiente-homologacao";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../services/comum/serviceBase";
import { default as ConfiguracoesServico } from "../../../../services/configuracoes/configuracoes.service";
import APIMdfe from "../../../../services/mdfe/mdfe.service";
import store from "../../../../store";
import {
  carregar,
  definirDataEmissao,
  novoDocumento,
} from "../../../../store/mdfe/mdfe.slice";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import { aguardar } from "../../../../utils/common/common-utils";
import {
  JanelasDeNotificacaoTitulos,
  TipoNotificacao,
  default as exibirNotificacaoToast,
  default as exibirNotificaoToast,
} from "../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../utils/dialogos";
import {
  baixarXmlMdfe,
  visualizarImpressaoPdfDamdfe,
} from "../../../../utils/especifico/mdfe/mdfe-utils";
import { renderToStringClient } from "../../../../utils/react/react-utils";
import { definirIndiceSelecionadoTabPanel } from "../../../../utils/tab-panel";
import { VisibilidadeBotoesMDFe } from "./constantes";
import { CabecadoMDFe } from "./styles";

interface EditFormMDFeProps {
  idRegistro: number;
  isModal?: boolean;
  callBackAbrirModal?: (info: CallBackModal) => void;
  callBackFecharModal?: (info: CallBackModal) => void;
}

const nomeEndpoint = NomesEndpoints.MDFe;

const APIConfiguracoes = new ConfiguracoesServico();

const rederizarTitulo = (item: any) => item.text;

export const EditFormMDFe = ({
  idRegistro,
  isModal,
  callBackFecharModal,
}: EditFormMDFeProps) => {
  const [modalPdfViewerVisivel, setModalPdfViewerVisivel] = useState(false);
  const [documentoPdfDamdfe, setDocumentoPdfDamdfe] = useState("");

  const [modalEncerramentoVisivel, setModalEncerramentoVisivel] =
    useState(false);

  const [modalCancelamentoVisivel, setModalCancelamentoVisivel] =
    useState(false);

  const [carregando, setCarregando] = useState(true);
  const [dadosAuditoria, setDadosAuditoria] = useState<AuditavelDTO>();
  const [idRegistroEmEdicao, setIdRegistroEmEdicao] = useState(NaN);
  const [ambienteMdfe, setAmbienteMdfe] = useState<AmbienteMDFeOpcoes | null>(
    null
  );

  const dispatch = useAppDispatch();
  const infoTitulo = useAppSelector((state) => {
    const doc = state.mdfe.documentoAtual;
    return {
      numero: doc.numero,
      serie: doc.serie,
      situacao: doc.situacao,
      motivoRejeicao: doc.motivoRejeicao,
      chaveAcesso: doc.chaveAcesso,
    };
  });
  const visibilidadeBotoes = useAppSelector((state) =>
    VisibilidadeBotoesMDFe.get(state.mdfe.documentoAtual.situacao)
  );

  const documento = useAppSelector((state) => {
    const doc = state.mdfe.documentoAtual;
    return {
      id: doc.id,
      situacao: doc.situacao,
      numeroMdfe: doc.numero,
    };
  });

  const telaSomenteLeitura = useAppSelector((state) => {
    return state.mdfe.documentoAtual.situacao != SituacaoMDFe.EmEdicao;
  });

  useEffectOnLoad(() => {
    APIConfiguracoes.ObterConfiguracoesMdfe().then((resposta) => {
      setAmbienteMdfe(resposta.ambiente);
    });
  });

  const tabPanelRef = useRef<TabPanelRef>(null);

  useCarregarDadosDoModelo(idRegistro, carregarTela);

  async function carregarTela() {
    try {
      if (!carregando) {
        setCarregando(true);
      }
      if (idRegistro > 0) {
        await carregarModel();
      } else {
        dispatch(novoDocumento());
        setDadosAuditoria(undefined);
      }
      setIdRegistroEmEdicao(idRegistro);
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      definirIndiceSelecionadoTabPanel(tabPanelRef.current, 0);
      setCarregando(false);
    }
  }

  async function carregarModel() {
    try {
      const resposta = await APIBase.obterPorId<MDFeCompletoDTO>(
        idRegistro,
        nomeEndpoint
      );
      checarResponse(resposta);
      dispatch(carregar(resposta.model));
      setDadosAuditoria({
        criacaoUsuarioApelido: resposta.model.criacaoUsuarioApelido,
        criacaoData: resposta.model.criacaoData,
        alteracaoUsuarioApelido: resposta.model.alteracaoUsuarioApelido,
        ultimaAlteracaoData: resposta.model.ultimaAlteracaoData,
      });
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    }
  }

  function fechar(info: CallBackModal) {
    if (callBackFecharModal) {
      callBackFecharModal(info);
    }
  }

  function callBackUnprocessableEntity() {
    fechar({
      concluido: false,
      precisaAtualizar: true,
    });
  }

  async function handleCancelar() {
    if (forms.some((f) => f.current?.isDirty())) {
      const confirmacao = await exibirConfirmacao(
        "Aviso",
        "Há dados não salvos. Deseja cancelar?"
      );

      if (!confirmacao) {
        return;
      }
    }

    fechar({ concluido: false, precisaAtualizar: false });
  }

  async function formsValidos(): Promise<boolean> {
    setCarregando(true);
    forms.forEach((f) => {
      f.current?.requestSubmit();
    });

    await aguardar(50); //Apenas para garantir que a store esteja atualizada

    let valido = true;

    for (let i = 0; i < forms.length; i++) {
      valido = forms[i].current?.valido() ?? false;

      if (!valido) {
        definirIndiceSelecionadoTabPanel(tabPanelRef.current, i);
        break;
      }
    }

    const state = store.getState();

    const haDocumentos =
      state.mdfe.documentoAtual.descarregamentos.length > 0 &&
      state.mdfe.documentoAtual.descarregamentos.every(
        (x) => (x.documentosVinculados?.length ?? 0) > 0
      );

    if (!haDocumentos) {
      if (valido) {
        definirIndiceSelecionadoTabPanel(
          tabPanelRef.current,
          forms.indexOf(refDocumentosFiscais)
        );
      }

      valido = false;
      exibirNotificacaoToast({
        mensagem:
          'Não é possível salvar MDF-e sem um documento fiscal informado. Vincule ao menos um documento fiscal na aba "Documentos fiscais"',
        tipo: TipoNotificacao.Erro,
      });
    }

    const haMunicipios = state.mdfe.documentoAtual.carregamentos.length > 0;

    if (!haMunicipios) {
      if (valido) {
        definirIndiceSelecionadoTabPanel(
          tabPanelRef.current,
          forms.indexOf(refPercursos)
        );
      }

      valido = false;
      exibirNotificacaoToast({
        mensagem:
          'Não é possível salvar MDF-e sem um município de carregamento informado. Vincule ao menos um município de carregamento na aba de "Percurso"',
        tipo: TipoNotificacao.Erro,
      });
    }

    const haCondutores =
      state.mdfe.documentoAtual.modalRodoviario.condutores.length > 0;

    if (!haCondutores) {
      if (valido) {
        definirIndiceSelecionadoTabPanel(
          tabPanelRef.current,
          forms.indexOf(refCondutores)
        );
      }

      valido = false;
      exibirNotificacaoToast({
        mensagem:
          'Não é possível salvar MDF-e sem um condutor informado. Vincule ao menos um condutor na aba de "Condutores"',
        tipo: TipoNotificacao.Erro,
      });
    }

    setCarregando(false);
    return valido;
  }

  async function inserirOuAtualizarMdfe() {
    setCarregando(true);
    try {
      const model = store.getState().mdfe.documentoAtual;

      const registroJaGravado = idRegistroEmEdicao > 0;
      const resposta = registroJaGravado
        ? await APIMdfe.atualizar(model)
        : await APIMdfe.cadastrar(model);

      if (checarResponse(resposta)) {
        dispatch(carregar(resposta.model));
        setIdRegistroEmEdicao(resposta.model.id);

        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar({
          concluido: true,
          precisaAtualizar: true,
        });
      }
      fechar({
        concluido: true,
        precisaAtualizar: false,
      });
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  async function onClickSalvar() {
    if (await formsValidos()) {
      await inserirOuAtualizarMdfe();
    }
  }

  async function onClickValidar() {
    const possuiPermissao = verificaComNotificacaoSeUsuarioPossuiPermissoes([
      PermissoesMDF.Validar,
    ]);

    if (!possuiPermissao) {
      return;
    }

    // Sanity check
    if (idRegistroEmEdicao == 0) {
      exibirNotificaoToast({
        mensagem: "Grave o registro antes de validá-lo.",
        tipo: TipoNotificacao.Erro,
      });

      return;
    }

    if (await formsValidos()) {
      try {
        await verificarEAtualizarDataEmissao();

        setCarregando(true);
        const model = store.getState().mdfe.documentoAtual;

        const resposta = await APIMdfe.validar(model);

        if (checarResponse(resposta)) {
          dispatch(carregar(resposta.model));
          setIdRegistroEmEdicao(resposta.model.id);

          exibirNotificaoToast({
            mensagem: resposta.mensagem,
            tipo: TipoNotificacao.Sucesso,
          });
        }
      } catch (erro) {
        tratarErroApi(erro, callBackUnprocessableEntity);
      } finally {
        setCarregando(false);
      }
    }
  }

  async function verificarEAtualizarDataEmissao() {
    const dataEmissao = new Date(
      store.getState().mdfe.documentoAtual.dataHoraEmissao
    );

    const dataAtual = new Date();

    if (differenceInHours(dataAtual, dataEmissao) >= 24) {
      const mensagem = renderToStringClient(
        <>
          O campo data/hora de emissão (
          {format(dataEmissao, "dd/MM/yy HH:mm:ss")}) está mais de 24 horas
          atrasada em relação à data/hora atual, isso pode <br /> resultar na
          rejeição 228 - Data de emissão muito atrasada. Deseja atualizar a
          data/hora de emissão para atual?
        </>
      );

      const atualizarData = await exibirConfirmacao(
        JanelasDeNotificacaoTitulos.Atencao,
        mensagem
      );

      if (atualizarData) {
        const dataAtualFormatada = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
        dispatch(definirDataEmissao(dataAtualFormatada));
      }
    }
  }

  async function onClickAutorizar() {
    try {
      const permissao =
        ambienteMdfe == AmbienteMDFeOpcoes.Homologacao
          ? PermissoesMDF.EnviarEmHomologacao
          : PermissoesMDF.EnviarEmProducao;

      const possuiPermissao = verificaComNotificacaoSeUsuarioPossuiPermissoes([
        permissao,
      ]);

      if (!possuiPermissao) {
        return;
      }

      setCarregando(true);
      const resposta = await APIMdfe.autorizar(documento.id);
      checarResponse(resposta);
      if (resposta.sucesso) {
        if (resposta.model.rejeicao) {
          exibirNotificacaoToast({
            mensagem: resposta.model.rejeicao,
            tipo: TipoNotificacao.Erro,
          });
        }
        dispatch(carregar(resposta.model.documento));
      }
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function onClickEncerrar() {
    if (
      verificaComNotificacaoSeUsuarioPossuiPermissoes([PermissoesMDF.Encerrar])
    ) {
      setModalEncerramentoVisivel(true);
    }
  }

  function onClickCancelarMDFe() {
    if (
      verificaComNotificacaoSeUsuarioPossuiPermissoes([PermissoesMDF.Cancelar])
    ) {
      setModalCancelamentoVisivel(true);
    }
  }

  async function onClickVisualizarPDF() {
    const { id, situacao } = documento;

    await visualizarImpressaoPdfDamdfe(
      id,
      situacao,
      setModalPdfViewerVisivel,
      setDocumentoPdfDamdfe
    );
  }

  function handlePdfViewerModalCallBack() {
    setModalPdfViewerVisivel(false);
    setDocumentoPdfDamdfe("");
  }

  async function onClickBaixarXML() {
    const { id, situacao } = documento;

    await baixarXmlMdfe(id, situacao);
  }

  async function onClickDesfazerValidacao() {
    if (
      documento.situacao != SituacaoMDFe.Validado &&
      documento.situacao != SituacaoMDFe.Rejeitado
    ) {
      exibirNotificaoToast({
        mensagem: `Só é possível desfazer validação de MDF-e na situação "${
          situacaoMDFeDecodificada[SituacaoMDFe.Validado]
        }" ou "${situacaoMDFeDecodificada[SituacaoMDFe.Rejeitado]}".`,
        tipo: TipoNotificacao.Erro,
      });

      return;
    }
    const confirmacao = await exibirConfirmacao(
      "Desfazer validação",
      "Deseja desfazer a validação do MDF-e?"
    );

    if (!confirmacao) {
      return;
    }

    try {
      const resposta = await APIMdfe.desfazerValidacao(documento.id);
      checarResponse(resposta);
      if (resposta.sucesso) {
        dispatch(carregar(resposta.model));
      }
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    }
  }

  async function onClickConsultarSituacao() {
    try {
      setCarregando(true);
      const resposta = await APIMdfe.consultarSituacao(documento.id);
      checarResponse(resposta);
      if (resposta.sucesso) {
        dispatch(carregar(resposta.model));

        exibirNotificaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
      }
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function handleModalEncerramentoCallBack() {
    setModalEncerramentoVisivel(false);
  }

  function handleModalCancelamentoCallBack() {
    setModalCancelamentoVisivel(false);
  }

  const botoes: ReactNode[] = [
    <Button
      key="btn-salvar"
      type="success"
      text="Salvar"
      icon="save"
      visible={visibilidadeBotoes?.salvar}
      onClick={onClickSalvar}
    />,
    <Button
      key="btn-cancelar"
      type="normal"
      text="Cancelar"
      icon="close"
      visible={visibilidadeBotoes?.cancelar}
      onClick={handleCancelar}
    />,
    <Button
      key="btn-consultar-situacao"
      type="normal"
      text="Consultar situação"
      icon="refresh"
      visible={visibilidadeBotoes?.consultarSituacao}
      onClick={onClickConsultarSituacao}
    />,
    <Button
      key="btn-validar"
      type="normal"
      text="Validar"
      icon="check"
      visible={visibilidadeBotoes?.validar && idRegistroEmEdicao > 0}
      onClick={onClickValidar}
    />,
    <Button
      key="btn-autorizar"
      type="success"
      text="Autorizar"
      visible={visibilidadeBotoes?.autorizar}
      onClick={onClickAutorizar}
    />,
    <Button
      key="btn-desfazerValidacao"
      type="normal"
      text="Desfazer validação"
      visible={visibilidadeBotoes?.desfazerValidacao}
      onClick={onClickDesfazerValidacao}
    />,
    <Button
      key="btn-encerrar"
      type="normal"
      text="Encerrar"
      visible={visibilidadeBotoes?.encerrar}
      onClick={onClickEncerrar}
    />,
    <Button
      key="btn-cancelar-mdfe"
      type="normal"
      text="Cancelar MDF-e"
      visible={visibilidadeBotoes?.cancelarMdfe}
      onClick={onClickCancelarMDFe}
    />,
    <Button
      key="btn-visualizar-pdf"
      type="normal"
      text="Visualizar PDF"
      visible={visibilidadeBotoes?.visualizarPDF}
      onClick={onClickVisualizarPDF}
      icon="print"
    />,
    <Button
      key="btn-baixar-xml"
      type="normal"
      text="Baixar XML"
      visible={visibilidadeBotoes?.baixarXML}
      onClick={onClickBaixarXML}
      icon="download"
    />,
  ];

  // Gera referência de um componente filho
  const refDadosGerais = useRef<IFormulario>();
  const refDocumentosFiscais = useRef<IFormulario>();
  const refVeiculos = useRef<IFormulario>();
  const refPercursos = useRef<IFormulario>();
  const refCondutores = useRef<IFormulario>();
  const refObservacoes = useRef<IFormulario>();

  //Manter o array na mesma ordem que está na tela
  const forms = [
    refDadosGerais,
    refDocumentosFiscais,
    refVeiculos,
    refPercursos,
    refCondutores,
    refObservacoes,
  ];

  return (
    <>
      <ColunaFlex id="edit-form-mdfe">
        <FormAvancado
          modal={isModal}
          carregando={carregando}
          auditoria={dadosAuditoria}
          toolbarItens={botoes.filter(
            (x) => React.isValidElement(x) && x.props?.visible === true
          )}
        >
          <ProvedorAjuda id="edit-form-mdfe">
            <CabecadoMDFe>
              <InfosCabecalhoMDFe
                situacao={documento.situacao}
                serie={infoTitulo.serie}
                numero={infoTitulo.numero}
                motivoRejeicao={infoTitulo.motivoRejeicao}
                chaveAcesso={infoTitulo.chaveAcesso}
              />
            </CabecadoMDFe>
            <TabPanel
              deferRendering={false}
              ref={tabPanelRef}
              showNavButtons
              swipeEnabled={false}
              itemTitleRender={rederizarTitulo}
            >
              <Item text="Dados gerais">
                <MDFeAbaDadosGerais
                  ref={refDadosGerais}
                  idRegistro={idRegistroEmEdicao}
                  abaSomenteLeitura={telaSomenteLeitura}
                />
              </Item>
              <Item text="Documentos fiscais">
                <ComponentAsyncLoader>
                  <MDFeAbaDocumentosFiscais
                    ref={refDocumentosFiscais}
                    idRegistro={idRegistroEmEdicao}
                    abaSomenteLeitura={telaSomenteLeitura}
                  />
                </ComponentAsyncLoader>
              </Item>
              <Item text="Veículos">
                <MDFeAbaDadosVeiculos
                  ref={refVeiculos}
                  idRegistro={idRegistroEmEdicao}
                  abaSomenteLeitura={telaSomenteLeitura}
                />
              </Item>
              <Item text="Percurso">
                <MDFeAbaPercurso
                  ref={refPercursos}
                  idRegistro={idRegistroEmEdicao}
                  somenteLeitura={telaSomenteLeitura}
                  isModal
                />
              </Item>
              <Item text="Condutores">
                <ComponentAsyncLoader>
                  <MDFeAbaCondutor
                    ref={refCondutores}
                    idRegistro={idRegistroEmEdicao}
                    somenteLeitura={telaSomenteLeitura}
                    isModal
                    style={{
                      minHeight: "18em",
                      maxHeight: "calc(100vh - 30em)",
                    }}
                  />
                </ComponentAsyncLoader>
              </Item>
              <Item text="Observações">
                <TabContainer>
                  <MDFeAbaObservacoes
                    ref={refObservacoes}
                    idRegistro={idRegistroEmEdicao}
                    abaSomenteLeitura={telaSomenteLeitura}
                  />
                </TabContainer>
              </Item>
            </TabPanel>
            {ambienteMdfe == AmbienteMDFeOpcoes.Homologacao && (
              <QuadroAmbienteHomologacao />
            )}
          </ProvedorAjuda>
        </FormAvancado>
        {/*Modal Encerramento*/}
        <EditFormEncerramentoMDFe
          visivel={modalEncerramentoVisivel}
          idRegistroEdicao={idRegistroEmEdicao}
          configuracoesModal={{ larguraMaxima: "400px" }}
          callBackFecharModal={handleModalEncerramentoCallBack}
        />

        {/*Modal Cancelamento*/}
        <EditFormCancelamentoMDFe
          visivel={modalCancelamentoVisivel}
          idRegistroEdicao={idRegistroEmEdicao}
          configuracoesModal={{ larguraMaxima: "400px" }}
          callBackFecharModal={handleModalCancelamentoCallBack}
        />

        {/*Modal*/}
        <Modal
          titulo={"Visualizar PDF"}
          visivel={modalPdfViewerVisivel}
          largura={1366}
          altura={768}
          onFechar={handlePdfViewerModalCallBack}
        >
          <PdfViewer documento={documentoPdfDamdfe} />
        </Modal>
      </ColunaFlex>
    </>
  );
};

export default EditFormMDFe;
