import { useMemo } from "react";
import { FieldValues, UseControllerProps } from "react-hook-form";
import { FormSelectBoxLazyMxp } from "../../../../../components/formularios";
import { DataSourceOpcoesBuilder } from "../../../../../utils/grid/data-source-factory";
import { OperacaoDaOrdemDeProducaoGridModel } from "../../models/operacao-de-ordem-de-producao";
import { OperacaoDeOrdemDeProducaoServico } from "../../servicos/operacao-de-ordem-de-producao-service";
import GridOperacaoDaOrdemDeProducao from "../grid";

interface ComboOperacaoMxpProps<T extends FieldValues>
  extends UseControllerProps<T> {
  titulo: string;
  requerido?: boolean;
  somenteLeitura?: boolean;
  onSelectionChanged?: ((e: any) => Promise<void>) | (() => void);
  tituloSeletor?: string;
  filtrosNoCliente?: any[] | undefined;
  dataSourceOpcoes?: DataSourceOpcoesBuilder<OperacaoDaOrdemDeProducaoGridModel>;
}

interface ComboOperacaoPorOpMxpProps<T extends FieldValues>
  extends ComboOperacaoMxpProps<T> {
  idOrdemDeProducao: number;
}

const operacaoService = new OperacaoDeOrdemDeProducaoServico();

export default function ComboOperacaoPorOpMxp<T extends FieldValues>(
  props: ComboOperacaoPorOpMxpProps<T>
) {
  const dataSourceOpcoes = useMemo(() => {
    return {
      ...props.dataSourceOpcoes,
      camposFiltro: [
        ...(props.dataSourceOpcoes?.camposFiltro ?? []),
        {
          campo: "ordemDeProducaoId",
          operador: "=",
          valor: props.idOrdemDeProducao,
        },
      ],
    } as DataSourceOpcoesBuilder<OperacaoDaOrdemDeProducaoGridModel>;
  }, [props.idOrdemDeProducao]);

  return <ComboOperacaoMxp {...props} dataSourceOpcoes={dataSourceOpcoes} />;
}

export function ComboOperacaoMxp<T extends FieldValues>(
  props: ComboOperacaoMxpProps<T>
) {
  const [dataSourceOperacao, configuracoesExibicaoEBuscaOperacao] = useMemo(
    () =>
      operacaoService.GetDadosSelectBoxOperacaoDaOrdemDeProducao(
        props.dataSourceOpcoes
      ),
    [props.dataSourceOpcoes]
  );

  return (
    <FormSelectBoxLazyMxp
      name={props.name}
      titulo={props.titulo}
      control={props.control}
      requerido={props.requerido}
      somenteLeitura={props.somenteLeitura}
      onSelectionChanged={props.onSelectionChanged}
      configuracoesExibicaoEBusca={configuracoesExibicaoEBuscaOperacao}
      dataSource={dataSourceOperacao}
      seletorConfig={{
        modo: "selecaoUnica",
        titulo: props.tituloSeletor ?? "Selecionar operação",
        componenteGrid: (
          <GridOperacaoDaOrdemDeProducao
            filtrosNoServidor={props.dataSourceOpcoes?.camposFiltro}
            filtrosNoCliente={props.filtrosNoCliente}
          />
        ),
      }}
      labelSemDados="Sem dados"
    />
  );
}
