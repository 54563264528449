import SelectItem from "../../../../../models/shared/ui/select-item";
import { gerarSelectItemDoDicionario } from "../../../../../utils/common/enum-utils";

export enum RetiradaDeMaterialOuAparaValorTipo {
  CadastroDoItem = 1,
  Manual = 2,
  MediaDasBaixas = 3,
}

export class RetiradaDeMaterialOuAparaValorTipoHelper {
  public static tipoDescricao: {
    [key in RetiradaDeMaterialOuAparaValorTipo]: string;
  } = {
    [RetiradaDeMaterialOuAparaValorTipo.CadastroDoItem]: "Cadastrado no item",
    [RetiradaDeMaterialOuAparaValorTipo.Manual]: "Manual",
    [RetiradaDeMaterialOuAparaValorTipo.MediaDasBaixas]:
      "Média das baixas de insumo",
  };

  public static getDescricao(tipo: RetiradaDeMaterialOuAparaValorTipo): string {
    return this.tipoDescricao[tipo] || "Descrição desconhecida";
  }

  public static AsSelectItems(): SelectItem[] {
    return gerarSelectItemDoDicionario(this.tipoDescricao);
  }
}
