import { AxiosResponse, HttpStatusCode } from "axios";
import getApi from "../../../../configs/api";
import {
  ResponseBase,
  ResponseModel,
} from "../../../../models/api/comum/response-base";
import { ExtrasLogin } from "../../../../models/dto/sessao/sessao";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import {
  tratarErroApi,
  tratarErroApiComoToast,
} from "../../../../utils/api/api-utils";
import {
  AcaoAdicionalLoginDto,
  DadosSessaoResponse,
  PayloadRequest,
  TokenBypassRequest,
  TokenFrontRequest,
  TokenResponse,
  TrocaEmpresaLoginRequest,
} from "../models/login.api";

export default class TokenService {
  private _nomeEndpoint: NomesEndpoints;

  constructor() {
    this._nomeEndpoint = NomesEndpoints.Login;
  }

  public async getToken(
    request: TokenFrontRequest
  ): Promise<[TokenResponse | undefined, ExtrasLogin | undefined]> {
    try {
      const api = getApi();

      const response = await api.post<TokenFrontRequest, AxiosResponse>(
        `/${this._nomeEndpoint}/GetTokenCaptchaAsync`,
        request
      );

      return [response.data as TokenResponse, undefined];
    } catch (error) {
      const erro = error as any;
      if (erro?.response?.status == HttpStatusCode.PreconditionRequired) {
        return [
          undefined,
          {
            acaoAdicional: erro.response!
              .data as ResponseModel<AcaoAdicionalLoginDto>,
          },
        ];
      }

      tratarErroApiComoToast(error);
      const msg =
        (error as any)?.response?.data?.mensagem ?? (error as any)?.message;
      return [undefined, { mensagemErro: msg }];
    }
  }

  public async getTokenPassandoInformacoesExcluirSessoes(
    request: TokenFrontRequest,
    excluirSessoes: boolean,
    token: string
  ): Promise<TokenResponse | undefined> {
    try {
      const api = getApi();
      const response = await api.post<
        TokenFrontRequest,
        AxiosResponse<TokenResponse>
      >(`/${this._nomeEndpoint}/GetTokenCaptchaAsync`, request, {
        headers: {
          DeleteAnterior: String(excluirSessoes),
          DeleteAnteriorToken: token,
        },
      });

      return response.data;
    } catch (error) {
      tratarErroApi(error);
    }
  }

  public async getTokenAutoLogin(
    payload: string
  ): Promise<TokenResponse | undefined> {
    const request: PayloadRequest = {
      payload: payload,
    };
    try {
      const api = getApi();
      const response = await api.post<
        PayloadRequest,
        AxiosResponse<TokenResponse>
      >(`/${this._nomeEndpoint}/GetTokenAutologin`, request);
      return response.data;
    } catch (error) {
      tratarErroApi(error);
    }
  }

  public async getTokenSSO(
    request: TokenBypassRequest
  ): Promise<TokenResponse | undefined> {
    try {
      const api = getApi();
      const response = await api.post<
        TokenBypassRequest,
        AxiosResponse<TokenResponse>
      >(`/${this._nomeEndpoint}/GetTokenFromString`, request);
      return response.data;
    } catch (error) {
      tratarErroApi(error);
    }
  }

  public async TrocaEmpresaLogin(
    trocaEmpresaRequest: TrocaEmpresaLoginRequest
  ): Promise<TokenResponse | undefined> {
    try {
      const api = getApi();
      const response = await api.post<
        TrocaEmpresaLoginRequest,
        AxiosResponse<ResponseModel<TokenResponse>>
      >(`/${this._nomeEndpoint}/TrocarEmpresa`, trocaEmpresaRequest);

      return response.data.model as TokenResponse;
    } catch (error) {
      tratarErroApi(error);
    }
  }

  public async getDadosSessao(
    tokenResponse: TokenResponse
  ): Promise<DadosSessaoResponse | undefined> {
    try {
      const api = getApi(tokenResponse);
      const response = await api.get<ResponseModel<DadosSessaoResponse>>(
        `/${this._nomeEndpoint}/ObterDadosSessao`
      );

      return response.data.model as DadosSessaoResponse;
    } catch (error) {
      tratarErroApi(error);
    }
  }

  public async efetuarLogoffPayload(payload: string) {
    const api = getApi();
    const url = `/${
      this._nomeEndpoint
    }/LogoffOauth?payload=${encodeURIComponent(payload)}`;
    const response = await api.get<ResponseBase>(url);
    return response.data;
  }

  public async efetuarLogoff() {
    const api = getApi();
    const url = `/${this._nomeEndpoint}/Logoff`;
    const response = await api.get<ResponseBase>(url);
    return response.data;
  }

  public async validarPayloadOauthNaoAutenticado(payload: string) {
    const api = getApi();
    const response = await api.get<ResponseBase>(
      `/${
        this._nomeEndpoint
      }/ValidarPayloadNaoAutenticado?payload=${encodeURIComponent(payload)}`
    );

    return response.data;
  }

  public async validarPayloadOauthAutenticado(payload: string) {
    const api = getApi();
    const response = await api.get<ResponseModel<string>>(
      `/${
        this._nomeEndpoint
      }/ValidarPayloadAutenticado?payload=${encodeURIComponent(payload)}`
    );

    return response.data;
  }

  public async obterPayloadCallBackOauth() {
    const api = getApi();
    const response = await api.get<ResponseModel<string>>(
      `/${this._nomeEndpoint}/ObterPayloadCallBackOauth`
    );

    return response.data;
  }
}
