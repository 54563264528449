import { useEffect, useRef } from "react";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";

interface VisualizarPdfProps {
  visivel: boolean;
  handleFechar: () => void;
  arquivo?: Blob;
  titulo?: string;
}

export default function VisualizadorDePdf(props: VisualizarPdfProps) {
  const embedRef = useRef<HTMLEmbedElement>(null);

  useEffect(() => {
    if (!props.visivel && embedRef.current?.src) {
      URL.revokeObjectURL(embedRef.current.src); // Utilizado para liberar URL
    }
  }, [props.visivel]);

  return (
    <ModalMxp
      titulo={props.titulo ?? "Visualizador de PDF"}
      visivel={props.visivel}
      handleFechar={props.handleFechar}
      altura={"90vh"}
      largura={"90vw"}
    >
      {props.arquivo && (
        <embed
          ref={embedRef}
          src={URL.createObjectURL(props.arquivo)}
          width={"100%"}
          height={"100%"}
          type="application/pdf"
        />
      )}
    </ModalMxp>
  );
}
