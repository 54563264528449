import { checarResponse } from "../../../../utils/api/api-utils";
import { JanelasDeNotificacaoTitulos } from "../../../../utils/common/notificacoes-utils";
import { exibirAlerta } from "../../../../utils/dialogos";
import ReservaService from "../service/reserva.service";

const service = new ReservaService();

export async function podeReservarOp(idOrdemDeProducao: number) {
  const response = await service.ObterQuantidadeDisponivelParaOrdemDeProducao(
    idOrdemDeProducao
  );

  if (!checarResponse(response)) {
    return false;
  }

  if (response.model <= 0) {
    await exibirAlerta(
      JanelasDeNotificacaoTitulos.Atencao,
      "A ordem de produção selecionada possui toda a sua quantidade reservada."
    );
    return false;
  }

  return true;
}
