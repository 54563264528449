import { useCallback, useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import {
  FormNumberBox,
  FormSelectBox,
} from "../../../../../../../components/formularios";
import { ItemSelectionChangedType } from "../../../../../../../components/formularios/selectbox-lazy-mxp";
import {
  Coluna,
  Linha,
} from "../../../../../../../components/layout/grid-system";
import { quantidadeValorMaximo } from "../../../../../../../utils/common/constantes";
import { formatarNumeroQuantidade } from "../../../../../../../utils/formatadores/formatador-de-numeros";
import { DataSourceOpcoesBuilder } from "../../../../../../../utils/grid/data-source-factory";
import ComboInsumoPorOpMxp from "../../../../../insumo-da-ordem-de-producao/componentes/select-box-insumo-por-op";
import { InsumoDaOrdemDeProducaoGridModel } from "../../../../../insumo-da-ordem-de-producao/models/insumo-da-ordem-de-producao";
import { InsumoDaOrdemDeProducaoService } from "../../../../../insumo-da-ordem-de-producao/servicos/insumo-da-ordem-de-producao";
import { RetiradaDeMaterialRequest } from "../../../../models/ordem-de-producao.api";
import {
  RetiradaDeMaterialOuAparaValorTipo,
  RetiradaDeMaterialOuAparaValorTipoHelper,
} from "../../../../utils/enums/retirada-de-material-ou-apara.enums";

interface ComponenteValorUnitarioProps {
  ordemDeProducaoId: number;
  valorDoItem: number;
  somenteLeitura?: boolean;
  hookForms: UseFormReturn<RetiradaDeMaterialRequest>;
}

const insumoService = new InsumoDaOrdemDeProducaoService();

const insumosComQuantidadeBaixada: DataSourceOpcoesBuilder<InsumoDaOrdemDeProducaoGridModel> =
  {
    camposFiltro: [{ campo: "quantidadeBaixada", operador: ">", valor: 0 }],
  };

export default function ComponenteValorUnitario(
  props: ComponenteValorUnitarioProps
) {
  const { control, setValue, getValues, watch } = props.hookForms;
  const [mediaDasBaixas, setMediaDasBaixas] = useState<number>(0);

  useEffect(() => {
    if (
      watch("valorUnitarioTipoOrigem") ==
      RetiradaDeMaterialOuAparaValorTipo.CadastroDoItem
    ) {
      setValue("valorUnitario", props.valorDoItem);
    }
    if (
      watch("valorUnitarioTipoOrigem") ==
      RetiradaDeMaterialOuAparaValorTipo.MediaDasBaixas
    ) {
      setValue("valorUnitario", mediaDasBaixas);
    }
  }, [props.valorDoItem, mediaDasBaixas, watch, setValue]);

  useEffect(() => {
    if (!getValues("idInsumoOrigemValor")) {
      setMediaDasBaixas(0);
    }
  }, [watch, setMediaDasBaixas, getValues]);

  const onInsumoChanged = useCallback(
    async (e: ItemSelectionChangedType<InsumoDaOrdemDeProducaoGridModel>) => {
      if (!e?.selectedItem) {
        setMediaDasBaixas(0);
        return;
      }

      const valor = await insumoService.ObterValorMedioDasBaixasDoInsumo(
        e.selectedItem.id
      );

      setMediaDasBaixas(valor);
    },
    [setMediaDasBaixas]
  );

  return (
    <Linha>
      <Coluna md={3}>
        <FormSelectBox
          name="valorUnitarioTipoOrigem"
          titulo="Valor unitário"
          somenteLeitura={props.somenteLeitura}
          control={control}
          dataSource={RetiradaDeMaterialOuAparaValorTipoHelper.AsSelectItems()}
          requerido
        />
      </Coluna>

      {watch("valorUnitarioTipoOrigem") ==
        RetiradaDeMaterialOuAparaValorTipo.MediaDasBaixas && (
        <Coluna md={6}>
          <ComboInsumoPorOpMxp
            name="idInsumoOrigemValor"
            titulo="Insumo"
            somenteLeitura={props.somenteLeitura}
            idOrdemDeProducao={props.ordemDeProducaoId}
            onSelectionChanged={onInsumoChanged}
            dataSourceOpcoes={insumosComQuantidadeBaixada}
            control={control}
          />
        </Coluna>
      )}
      <Coluna md={3}>
        <FormNumberBox
          name="valorUnitario"
          titulo="ㅤ"
          control={control}
          formato={formatarNumeroQuantidade}
          minimo={0}
          maximo={quantidadeValorMaximo.toNumber()}
          somenteLeitura={
            watch("valorUnitarioTipoOrigem") !=
              RetiradaDeMaterialOuAparaValorTipo.Manual || props.somenteLeitura
          }
          quantidadeIncrementarDecrementar={1}
        />
      </Coluna>
    </Linha>
  );
}
