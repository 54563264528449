import { ToolbarItem } from "devextreme-react/cjs/autocomplete";
import { useState } from "react";
import * as yup from "yup";
import ModalAccordion from "../../../../../components/dialogos/modal-accordion";
import {
  AccordionDataSource,
  AccordionModel,
} from "../../../../../components/dialogos/modal-accordion/modal-accordion";
import { FormDateBox } from "../../../../../components/formularios";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import { Modal } from "../../../../../components/layout/modal";
import { useHookForms } from "../../../../../hooks/form.hooks";
import { useAppDispatch } from "../../../../../hooks/store.hooks";
import { bloquearUI, desbloquearUI } from "../../../../../store/ui/ui.slice";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import { JanelasDeNotificacaoTitulos } from "../../../../../utils/common/notificacoes-utils";
import { formatarNumeroMonetario } from "../../../../../utils/formatadores/formatador-de-numeros";
import { CalcularDepreciacaoResponse } from "../../models/imobilizado";
import { CalcularDepeciacaoViewModel } from "../../models/imobilizado.viewmodel";
import { ImobilizadoService } from "../../servicos/imobilizado.service";

interface ModalCalcularDepreciacaoProps {
  onFechar: () => void;
  idsCalcular: number[];
}

const imobilizadoService = new ImobilizadoService();

const novoRegistro: CalcularDepeciacaoViewModel = {
  dataContabilizacao: "",
};

export default function ModalCalcularDepreciacao({
  idsCalcular,
  onFechar,
}: ModalCalcularDepreciacaoProps) {
  const visivel = idsCalcular.length > 0;
  const dispatch = useAppDispatch();
  const [detalhamentoDeprecicaoAccordion, setDetalhamentoDepreciacaoAccordion] =
    useState<AccordionDataSource>();

  let formRef: HTMLFormElement;
  function setFormRef(r: HTMLFormElement) {
    formRef = r;
  }

  function handlerFecharModalAccordion() {
    setDetalhamentoDepreciacaoAccordion(undefined);
    onFechar();
    reset(novoRegistro);
  }

  function obterDadosAccordionCalculoDepreciacao(
    model: CalcularDepreciacaoResponse
  ): AccordionModel[] {
    const dados: AccordionModel[] = [];

    if (model.processadosComSucesso.length > 0) {
      const plural = model.processadosComSucesso.length > 1;
      dados.push({
        grupoDeMensagensTitulo: `${
          model.processadosComSucesso.length
        } imobilizado${plural ? "s" : ""} fo${plural ? "ram" : "i"} depreciado${
          plural ? "s" : ""
        } com sucesso. Clique aqui para ver mais.`,
        mensagens: model.processadosComSucesso.map(
          (p) =>
            `Lançamento Nº ${p.numeroLancamentoContabil} , ${p.numero} - ${
              p.descricao
            }, Valor R$ ${formatarNumeroMonetario(p.valor ?? 0)}`
        ),
      });
    }

    if (model.processadosComErros.length > 0) {
      const plural = model.processadosComErros.length > 1;
      dados.push({
        grupoDeMensagensTitulo: `${
          model.processadosComErros.length
        } imobilizado${plural ? "s" : ""} não fo${
          plural ? "ram" : "i"
        } depreciado${
          plural ? "s" : ""
        } com sucesso. Clique aqui para ver o motivo.`,
        mensagens: model.processadosComErros.map(
          (p) => `${p.numero} - ${p.descricao}, Erro: ${p.motivo}`
        ),
      });
    }

    return dados;
  }

  async function calcularDepreciacao() {
    try {
      dispatch(bloquearUI("Processando..."));
      const dados = await imobilizadoService.CalcularDepreciacao({
        ids: idsCalcular,
        dataContabilizacao: getValues("dataContabilizacao"),
      });

      if (checarResponse(dados)) {
        const dadosAccordion = obterDadosAccordionCalculoDepreciacao(
          dados.model
        );
        setDetalhamentoDepreciacaoAccordion({ model: dadosAccordion });
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      dispatch(desbloquearUI());
    }
  }

  function handleSalvar() {
    formRef?.requestSubmit();
  }

  const schema = yup
    .object()
    .shape<Record<keyof CalcularDepeciacaoViewModel, yup.AnySchema>>({
      dataContabilizacao: yup.string().required(),
    });

  const hookForm = useHookForms(schema);
  const { control, handleSubmit, getValues, reset } = hookForm;

  return (
    <>
      <Modal
        titulo="Calcular depreciação"
        onFechar={onFechar}
        visivel={visivel}
        largura={300}
        componentesAdicionais={() => [
          <ToolbarItem
            key={"calcular-depreciacao-salvar"}
            widget="dxButton"
            toolbar="bottom"
            location="before"
            options={{
              text: "Salvar",
              type: "success",
              icon: "save",
              stylingMode: "contained",
              onClick: handleSalvar,
            }}
          ></ToolbarItem>,

          <ToolbarItem
            key={"calcular-depreciacao-cancelar"}
            widget="dxButton"
            toolbar="bottom"
            location="before"
            options={{
              text: "Cancelar",
              type: "normal",
              icon: "close",
              stylingMode: "contained",
              onClick: onFechar,
            }}
          ></ToolbarItem>,
        ]}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <form ref={setFormRef} onSubmit={handleSubmit(calcularDepreciacao)}>
            <Linha>
              <Coluna>
                <FormDateBox
                  name="dataContabilizacao"
                  titulo="Data da contabilização"
                  control={control}
                  requerido
                  aceitaValorCustomizado
                  aceitaDigitacaoComMascara
                />
              </Coluna>
            </Linha>
          </form>
        </div>
      </Modal>
      <ModalAccordion
        modalAccordionVisivel={!!detalhamentoDeprecicaoAccordion}
        handlerFecharModalAccordion={handlerFecharModalAccordion}
        dataSource={detalhamentoDeprecicaoAccordion?.model}
        modalTitulo={
          detalhamentoDeprecicaoAccordion?.accordionTitulo ??
          JanelasDeNotificacaoTitulos.Atencao
        }
        accordionId="accordion-resultado-calculo-depreciacao"
        itemDoAccordionAltura="auto"
      />
    </>
  );
}
