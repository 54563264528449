import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  min-width: 20em;
  max-width: 40em;
  width: 20em;
  margin: 1px;
  border: 1px solid silver;
  border-radius: 2px;
  background-color: #eff1f3;
  resize: horizontal;
  overflow: hidden;
`;

export const TextBoxBuscaContainer = styled.div`
  background-color: white;
  padding-left: 3px;
  padding-right: 3px;
`;

export const TituloContainer = styled.div`
  padding: 0.4em;
  overflow: hidden;
`;

export const Fechar = styled.span`
  float: right;
  color: gray;
  cursor: pointer;

  &:hover {
    color: blue;
  }
`;

export const Titulo = styled.div`
  float: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(100% - 15px) !important;
`;

export const TituloTexto = styled.span`
  font-weight: bold;
  margin-bottom: 3px;
`;

export const Rolagem = styled.div`
  height: calc(100% - 21px);
  overflow: scroll;
`;
