import DataGrid, { Column, DataGridRef } from "devextreme-react/cjs/data-grid";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useControlarFormDeEdicao } from "../../../../../hooks/form.hooks";
import { useMenuDeContextosGrid } from "../../../../../hooks/menus.hooks";
import { useParametroId } from "../../../../../hooks/route.hooks";
import { useAppDispatch } from "../../../../../hooks/store.hooks";
import { decodificaBooleanoEmSimNao } from "../../../../../models/api/comum/decodificadores";
import { PermissoesImobilizado } from "../../../../../models/permissoes/contabilidade/imobilizado/permissoes-imobilizado";
import { ResultadoAcaoFormulario } from "../../../../../models/shared/ui/formularios";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import { bloquearUI, desbloquearUI } from "../../../../../store/ui/ui.slice";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesModais from "../../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import NormalizaTituloModal from "../../../../../utils/common/normaliza-titulo";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { ItemContextMenuMxp } from "../../../../../utils/context-menu/context-menu-utils";
import { GestorEventoClickMultiplasLinhas } from "../../../../../utils/context-menu/gestor-evento-click";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import UrlUtils from "../../../../../utils/url/url-utils";
import { ImobilizadoGridModel } from "../../models/imobilizado";
import { ImobilizadoService } from "../../servicos/imobilizado.service";
import EditFormImobilizado from "../formulario";
import ModalCalcularDepreciacao from "../modal-calcular-depreciacao";

const service = new ImobilizadoService();

const dataSource = service.ObterDataSourceParaGrid<ImobilizadoGridModel>();
const nameof = criarNameof<ImobilizadoGridModel>();

function obterMensagemExclusao(registro: ImobilizadoGridModel) {
  return `Tem certeza que deseja excluir o registro ${registro.descricao}?`;
}

export default function GridImobilizado() {
  const gridRef = useRef<DataGridRef>(null);
  const parametroId = useParametroId();

  const {
    idRegistroEmEdicao,
    setIdRegistroEmEdicao,
    modalVisivel,
    encerrarEdicao,
  } = useControlarFormDeEdicao(NaN);

  const [idsCalculoDepreciacao, setIdsCalculoDepreciacao] = useState<number[]>(
    []
  );

  const dispatch = useAppDispatch();

  const handleNovoRegistro = useCallback(() => {
    if (
      !verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesImobilizado.InserirEditar,
      ])
    ) {
      return;
    }
    setIdRegistroEmEdicao(0);
  }, []);

  const handleEditarRegistro = useCallback((registro: ImobilizadoGridModel) => {
    if (
      !verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesImobilizado.InserirEditar,
      ])
    ) {
      return;
    }
    setIdRegistroEmEdicao(registro.id);
  }, []);

  const handleExcluirRegistro = useCallback(
    async (registro: ImobilizadoGridModel) => {
      try {
        dispatch(bloquearUI());
        if (
          !verificaComNotificacaoSeUsuarioPossuiPermissoes([
            PermissoesImobilizado.Excluir,
          ])
        ) {
          return;
        }

        const excluir = await exibirConfirmacao(
          "Confirmar exclusão",
          obterMensagemExclusao(registro)
        );

        if (excluir) {
          const resposta = await service.Excluir(registro.id);

          if (resposta) {
            checarResponseExibeMensagemExclusaoDeSucesso(resposta);
            handleAtualizarGrid();
          }
        }
      } catch (erro) {
        tratarErroApi(erro);
      } finally {
        dispatch(desbloquearUI());
      }
    },
    []
  );

  async function gerarDepreciacao(registros?: ImobilizadoGridModel[]) {
    if (
      !verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesImobilizado.GerarDepreciacao,
      ])
    ) {
      return;
    }

    if (registros && registros.length > 0) {
      setIdsCalculoDepreciacao(registros.map((x) => x.id));
    }
  }

  const gridController = useMemo(
    () =>
      new GridController<ImobilizadoGridModel>(() =>
        gridRef.current?.instance()
      ),
    []
  );

  const acoesDeContexto: ItemContextMenuMxp[] = useMemo(
    () => [
      {
        text: "Ações",
        icon: "ic-material-symbols-outlined ic-vertical",
        hint: "Menu com opções de ação",
        exibirNoMenuPrincipal: true,
        items: [
          {
            text: "Gerar depreciação",
            gestorEventoClick: new GestorEventoClickMultiplasLinhas(
              gerarDepreciacao,
              () => gridController
            ),
          },
        ],
      },
    ],
    [gridController]
  );

  function handleFecharModalCalcularDepreciacao() {
    setIdsCalculoDepreciacao([]);
  }

  useMenuDeContextosGrid(acoesDeContexto);

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar("imobilizado", () => gridRef.current?.instance())
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.imobilizado)
      .definirBotaoNovo(handleNovoRegistro)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .definirMenuDeContexto(acoesDeContexto)
      .definirDuploCliqueLinha(handleEditarRegistro)
      .build();
  }, []);

  useEffect(() => {
    if (parametroId) {
      setIdRegistroEmEdicao(parametroId);
    }
  }, [parametroId, setIdRegistroEmEdicao]);

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  useRegistrarAtalhosGrid<ImobilizadoGridModel>({
    controller: gridController,
    handleNovo: handleNovoRegistro,
    handleEditar: handleEditarRegistro,
    handleExcluir: handleExcluirRegistro,
  });

  const handleCallbackFormulario = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      encerrarEdicao();

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }

      UrlUtils.RemoverParametroId(parametroId);
    },
    [parametroId]
  );

  const handleFecharModal = useCallback(() => {
    encerrarEdicao();
    UrlUtils.RemoverParametroId(parametroId);
  }, [parametroId]);

  return (
    <>
      <ProvedorAjuda id={"tooltips-grid-imobilizado"}>
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          {GridColunaAcoes<ImobilizadoGridModel>({
            handleEditar: handleEditarRegistro,
            handleExcluir: handleExcluirRegistro,
          })}
          <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
          <Column
            key={nameof("numeroPatrimonio")}
            dataField={nameof("numeroPatrimonio")}
            {...obterConfiguracaoColuna("stringG")}
            caption="Número"
            width={130}
          />
          <Column
            key={nameof("item")}
            dataField={nameof("item")}
            {...obterConfiguracaoColuna("stringG")}
            caption="Item"
            width={130}
          />
          <Column
            key={nameof("descricao")}
            dataField={nameof("descricao")}
            {...obterConfiguracaoColuna("stringXG")}
            caption="Descrição"
            visible={false}
          />
          <Column
            key={nameof("estado")}
            dataField={nameof("estado")}
            {...obterConfiguracaoColuna("stringG")}
            caption="Estado"
            width={100}
            visible={false}
          />
          <Column
            key={nameof("valorAquisicao")}
            dataField={nameof("valorAquisicao")}
            {...obterConfiguracaoColuna("monetario")}
            caption="Valor da aquisição"
            width={170}
          />
          <Column
            key={nameof("dataAquisicao")}
            dataField={nameof("dataAquisicao")}
            {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
            caption="Data da aquisição"
            width={160}
          />
          <Column
            key={nameof("contaContabil")}
            dataField={nameof("contaContabil")}
            {...obterConfiguracaoColuna("stringMG")}
            caption="Conta contábil"
            width={180}
            visible={false}
          />
          <Column
            key={nameof("utilizacaoDoBem")}
            dataField={nameof("utilizacaoDoBem")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Utilização do bem"
            width={170}
            visible={false}
          />
          <Column
            key={nameof("centroCustos")}
            dataField={nameof("centroCustos")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Centro de custos"
            visible={false}
          />
          <Column
            key={nameof("contaDeDebitoDaDepreciacao")}
            dataField={nameof("contaDeDebitoDaDepreciacao")}
            {...obterConfiguracaoColuna("stringG")}
            caption="Conta de débito da depreciação"
            width={240}
            visible={false}
          />
          <Column
            key={nameof("centroDeCustosDeDebitoDaDepreciacao")}
            dataField={nameof("centroDeCustosDeDebitoDaDepreciacao")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Centro de custos de débito da depreciação"
            width={310}
            visible={false}
          />
          <Column
            key={nameof("contaDeCreditoDaDepreciacao")}
            dataField={nameof("contaDeCreditoDaDepreciacao")}
            {...obterConfiguracaoColuna("stringG")}
            caption="Conta de crédito da depreciação"
            width={250}
            visible={false}
          />
          <Column
            key={nameof("centroDeCustosDeCreditoDaDepreciacao")}
            dataField={nameof("centroDeCustosDeCreditoDaDepreciacao")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Centro de custos de crédito da depreciação"
            width={310}
            visible={false}
          />
          <Column
            key={nameof("percentualDeDepreciacao")}
            dataField={nameof("percentualDeDepreciacao")}
            {...obterConfiguracaoColuna("percentual")}
            caption="Percentual de depreciação"
            width={210}
          />
          <Column
            key={nameof("tipoDeDepreciacao")}
            dataField={nameof("tipoDeDepreciacao")}
            {...obterConfiguracaoColuna("stringMG")}
            caption="Tipo de depreciação"
            visible={false}
          />
          <Column
            key={nameof("vidaUtilEmMeses")}
            dataField={nameof("vidaUtilEmMeses")}
            caption="Vida útil (em meses)"
            width={180}
            dataType="number"
            format={{ precision: 0 }}
            visible={false}
          />
          <Column
            key={nameof("valorDaParcelaDeDepreciacao")}
            dataField={nameof("valorDaParcelaDeDepreciacao")}
            {...obterConfiguracaoColuna("monetario")}
            caption="Valor da parcela de depreciação"
            width={240}
          />
          <Column
            key={nameof("valorResidual")}
            dataField={nameof("valorResidual")}
            {...obterConfiguracaoColuna("monetario")}
            caption="Valor residual"
            visible={false}
            width={140}
          />
          <Column
            key={nameof("valorDepreciado")}
            dataField={nameof("valorDepreciado")}
            {...obterConfiguracaoColuna("monetario")}
            caption="Valor depreciado"
            width={160}
            visible={false}
          />
          <Column
            key={nameof("vidaRestanteEmMeses")}
            dataField={nameof("vidaRestanteEmMeses")}
            caption="Vida restante (em meses)"
            width={210}
            dataType="number"
            format={{ precision: 0 }}
          />
          <Column
            key={nameof("dataUltimaDepreciacao")}
            dataField={nameof("dataUltimaDepreciacao")}
            {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
            caption="Data da última depreciação"
            width={220}
          />
          <Column
            key={nameof("estadoDaDepreciacao")}
            dataField={nameof("estadoDaDepreciacao")}
            {...obterConfiguracaoColuna("stringG")}
            caption="Estado da depreciação"
          />
          <Column
            key={nameof("sujeitoAoCiap")}
            dataField={nameof("sujeitoAoCiap")}
            {...obterConfiguracaoColuna("boolSimNao")}
            caption="Sujeito ao CIAP"
            width={150}
            cellRender={({ data }) => {
              const dados = data as ImobilizadoGridModel;
              return decodificaBooleanoEmSimNao(dados.sujeitoAoCiap);
            }}
          />
          <Column
            key={nameof("estadoDoCiap")}
            dataField={nameof("estadoDoCiap")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Estado do CIAP"
            width={150}
          />
          <Column
            key={nameof("parcelasDoCiap")}
            dataField={nameof("parcelasDoCiap")}
            caption="Parcelas do CIAP"
            width={160}
            dataType="number"
            format={{ precision: 0 }}
          />
          <Column
            key={nameof("parcelasApropriadas")}
            dataField={nameof("parcelasApropriadas")}
            caption="Parcelas apropriadas"
            width={180}
            dataType="number"
            format={{ precision: 0 }}
          />
          <Column
            key={nameof("dataEmissao")}
            dataField={nameof("dataEmissao")}
            {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
            caption="Data emissão"
            width={150}
            visible={false}
          />
          <Column
            key={nameof("numero")}
            dataField={nameof("numero")}
            {...obterConfiguracaoColuna("stringG")}
            caption="Número"
            width={100}
            visible={false}
          />
          <Column
            key={nameof("serie")}
            dataField={nameof("serie")}
            {...obterConfiguracaoColuna("serie")}
            caption="Série"
            width={100}
            visible={false}
          />
          <Column
            key={nameof("chaveAcesso")}
            dataField={nameof("chaveAcesso")}
            {...obterConfiguracaoColuna("chaveAcesso")}
            visible={false}
          />
          <Column
            key={nameof("baseDeCalculoIcms")}
            dataField={nameof("baseDeCalculoIcms")}
            {...obterConfiguracaoColuna("monetario")}
            caption={"Base de cálculo do ICMS"}
            width={200}
            visible={false}
          />
          <Column
            key={nameof("aliquotaIcms")}
            dataField={nameof("aliquotaIcms")}
            {...obterConfiguracaoColuna("percentual")}
            visible={false}
            width={160}
            caption={"Alíquota do ICMS"}
          />
          <Column
            key={nameof("valorDoIcms")}
            dataField={nameof("valorDoIcms")}
            {...obterConfiguracaoColuna("monetario")}
            visible={false}
            width={150}
            caption={"Valor do ICMS"}
          />
          <Column
            key={nameof("valorDoIcmsSt")}
            dataField={nameof("valorDoIcmsSt")}
            {...obterConfiguracaoColuna("monetario")}
            visible={false}
            width={160}
            caption={"Valor do ICMS ST"}
          />
          <Column
            key={nameof("valorDoIcmsFrete")}
            dataField={nameof("valorDoIcmsFrete")}
            {...obterConfiguracaoColuna("monetario")}
            visible={false}
            width={190}
            caption={"Valor do ICMS do frete"}
          />
          <Column
            key={nameof("valorDoIcmsDifal")}
            dataField={nameof("valorDoIcmsDifal")}
            {...obterConfiguracaoColuna("monetario")}
            visible={false}
            width={180}
            caption={"Valor do ICMS DIFAL"}
          />
          {GetColunasDeAuditoria()}
        </DataGrid>
      </ProvedorAjuda>

      <ModalMxp
        titulo={NormalizaTituloModal.Normalizar(
          idRegistroEmEdicao,
          NomesModais.imobilizado
        )}
        visivel={modalVisivel}
        handleFechar={handleFecharModal}
        largura={"max(40vw, 900px)"}
        altura={"auto"}
      >
        <EditFormImobilizado
          idRegistroEmEdicao={idRegistroEmEdicao}
          handleCallback={handleCallbackFormulario}
        />
      </ModalMxp>

      <ModalCalcularDepreciacao
        idsCalcular={idsCalculoDepreciacao}
        onFechar={handleFecharModalCalcularDepreciacao}
      />
    </>
  );
}
