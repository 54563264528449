import DataSource from "devextreme/data/data_source";
import {
  assertConfiguracaoExibicaoEBuscaType,
  ConfiguracaoExibicaoEBusca,
} from "../../../../components/formularios/selectbox-lazy-mxp";
import { EstoqueGridModel } from "../../../../models/api/estoque/estoque";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import { DataSourceOpcoesBuilder } from "../../../../utils/grid/data-source-factory";

export class EstoqueService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.Estoque);
  }

  public GetDadosSelectBoxEstoque(
    dataSourceOpcoes?: DataSourceOpcoesBuilder<EstoqueGridModel>
  ): [
    DataSource<EstoqueGridModel, any>,
    ConfiguracaoExibicaoEBusca<EstoqueGridModel>
  ] {
    const dataSource = this.ObterDataSourceParaSelectBoxLazy<EstoqueGridModel>({
      quantidadeRegistros: dataSourceOpcoes?.quantidadeRegistros,
      camposRetorno: dataSourceOpcoes?.camposRetorno || [
        "id",
        "estoque",
        "codigoItem",
        "enderecoDeEstoque",
        "loteFabricanteNumeroSerie",
      ],
      camposOrdenacao: dataSourceOpcoes?.camposOrdenacao || [
        { campo: "codigoItem", desc: false },
      ],
      camposFiltro: dataSourceOpcoes?.camposFiltro,
    });

    const configs = assertConfiguracaoExibicaoEBuscaType<EstoqueGridModel>({
      nomeCampoChave: "id",
      expressaoDeBusca: ["codigoItem", "loteFabricanteNumeroSerie"],
      nomeCampoExibicao: (c) => {
        if (c) {
          const loteOuNumeroDeSerie = c.loteFabricanteNumeroSerie
            ? `- ${c.loteFabricanteNumeroSerie}`
            : "";

          const codigoEnderecoDeEstoque = c.enderecoDeEstoque
            ? `- ${c.enderecoDeEstoque}`
            : "";

          return `${c.codigoItem} ${loteOuNumeroDeSerie} ${codigoEnderecoDeEstoque} - ${c.estoque}`;
        }

        return "";
      },
    });

    return [dataSource, configs];
  }
}
