import { Menu } from "devextreme-react";
import { useEffect, useState } from "react";
import AjudaPaginasPage from "../../../features/ajuda/ajuda-paginas/paginas/listagem";
import { useAppSelector } from "../../../hooks/store.hooks";
import { navegarParaPaginaExterna } from "../../../utils/common/menu-utils";
import NomesTelas from "../../../utils/common/nomes-telas";
import { ItemContextMenu } from "../../../utils/context-menu/context-menu-utils";
import DefineTituloDaAba from "../../../utils/header/define-titulo-da-aba";
import { ProvedorLinkAjuda } from "../../../utils/help/provedor-links";
import { Modal } from "../modal";
import "./pagina.scss";

interface PaginaProps {
  id: string | null;
  children: React.ReactNode;
  titulo?: string;
  area?: string;
  subMenuItems?: ItemContextMenu[];
  omitirLinksDeAjuda?: boolean;
}

export default function Pagina({
  id,
  children,
  area,
  titulo,
  subMenuItems,
  omitirLinksDeAjuda,
}: PaginaProps) {
  DefineTituloDaAba(titulo);
  const [editorVisivel, setEditorVisivel] = useState(false);
  const [menus, setMenus] = useState<ItemContextMenu[]>([]);
  const master = useAppSelector(
    (state) => state.sessao.dadosSessao?.usuario.isUsuarioMaster
  );

  const linkHomeHelp = process.env.REACT_APP_HELP_HOME as string;

  useEffect(() => {
    carregarMenus();
  }, [subMenuItems]);

  async function carregarMenus() {
    let exibirAjuda = false;
    const links: ItemContextMenu[] = [];

    if (id && !omitirLinksDeAjuda) {
      const provedor = await ProvedorLinkAjuda.criarProvedor();
      const dados = provedor.obterLinks(id);
      exibirAjuda = !!dados.length;

      for (const link of dados) {
        links.push({
          text: link.textoExibicao,
          hint: link.textoExibicao,
          name: link.textoExibicao,
          onClick: () =>
            navegarParaPaginaExterna(`${linkHomeHelp}${link.slug}`),
        });
      }
    }

    if (master && !omitirLinksDeAjuda) {
      links.push({
        text: "Editar links de ajuda [master]",
        name: "Editar links de ajuda [master]",
        onClick: editarLinks,
      });
      exibirAjuda = true;
    }

    const itens = subMenuItems ?? [];

    if (exibirAjuda) {
      const sessaoAjuda: ItemContextMenu = {
        icon: "help",
        hint: "Ajuda",
        text: "\0",
        name: "\0",
        items: links,
      };

      itens.push(sessaoAjuda);
    }

    setMenus(itens);
  }

  function editarLinks() {
    setEditorVisivel(true);
  }

  return (
    <>
      <header className={"sub-header-component"}>
        {titulo && (
          <div className={"titulo"}>
            {area && <p>{area + " > "}</p>}
            <div className={"titulo-wrapper"}>
              <h3>{titulo}</h3>
            </div>
          </div>
        )}
        {titulo && !!menus.length && <div className={"separador"}>|</div>}

        <Menu
          items={menus}
          displayExpr="name"
          showFirstSubmenuMode="onHover"
          showSubmenuMode="onHover"
          orientation={"horizontal"}
          hideSubmenuOnMouseLeave={false}
        />
      </header>
      <div style={{ padding: "5px 15px" }}>{children}</div>

      {master && !!id && (
        <Modal
          titulo={NomesTelas.linkAjudaPaginaMaster}
          visivel={editorVisivel}
          mostrarSombreado
          fecharAoClicarFora={false}
          onFechar={() => {
            setEditorVisivel(false);
          }}
        >
          <AjudaPaginasPage
            callBackFecharModal={() => setEditorVisivel(false)}
            codigo={id}
          />
        </Modal>
      )}
    </>
  );
}
