import { yupResolver } from "@hookform/resolvers/yup";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  FormSelectBox,
  FormTextBox,
} from "../../../../../components/formularios";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import TabContainer from "../../../../../components/layout/tab-container";
import Sessao from "../../../../../components/organizacao/sessao";
import { MensagensPadraoYup } from "../../../../../features/comum/utils/yup/mensagens";
import { useCarregarDadosDoModelo } from "../../../../../hooks/form.hooks";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/store.hooks";
import {
  ModalidadeTransporteMDFe,
  TipoEmitenteMDFe,
} from "../../../../../models/api/mdfe/mdfe-enums";
import { IFormulario } from "../../../../../models/shared/ui/formularios";
import SelectItem from "../../../../../models/shared/ui/select-item";
import { MDFeAbaPercursoViewModel } from "../../../../../models/viewmodels/vendas/mdfe/mdfe-edit-form-view-model";
import APIUF from "../../../../../services/uf/uf.service";
import store from "../../../../../store";
import { definirDadosPercurso } from "../../../../../store/mdfe/mdfe.slice";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import { checarSeFormFoiModificado } from "../../../../../utils/common/form-utils";
import { GridProps } from "../../../../../utils/grid/grid-utils";
import { ComponentAsyncLoader } from "../../../../utils/load-on-demand";
import { GridCarregamentos } from "./grid-carregamentos";
import { GridPercursos } from "./grid-percursos";

interface DadosGeraisProps extends GridProps {
  idRegistro: number;
}

// Cria um componente referenciável
export const MDFeAbaPercurso = forwardRef((props: DadosGeraisProps, ref) => {
  const model: MDFeAbaPercursoViewModel = useAppSelector((state) => {
    return {
      id: state.mdfe.documentoAtual.id,
      idUfFim: state.mdfe.documentoAtual.idUfFim,
      idUfInicio: state.mdfe.documentoAtual.idUfInicio,
      cepLocalCarregamento: state.mdfe.documentoAtual.cepLocalCarregamento,
      cepLocalDescarregamento:
        state.mdfe.documentoAtual.cepLocalDescarregamento,
    };
  });

  const dispatch = useAppDispatch();

  const [ufs, setUfs] = useState<SelectItem[]>([]);

  useCarregarDadosDoModelo(props.idRegistro, carregarTela);

  async function carregarTela() {
    reset(model);
    await carregarUfs();
  }

  async function carregarUfs() {
    try {
      const resposta = await APIUF.obterListagemSimples();
      checarResponse(resposta);
      setUfs(
        resposta.model.map((x) => ({
          valor: x.valor,
          descricao: x.descricao,
        }))
      );
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  const cepLocalRequerido =
    store.getState().mdfe.documentoAtual.modalidadeTransporte ===
      ModalidadeTransporteMDFe.Rodoviario &&
    (store.getState().mdfe.documentoAtual.tipoEmitente ===
      TipoEmitenteMDFe.PrestadorServico ||
      (store.getState().mdfe.documentoAtual.tipoEmitente ===
        TipoEmitenteMDFe.TransportadorCargaPropria &&
        store.getState().mdfe.documentoAtual.tipoTransportador != null)) &&
    store
      .getState()
      .mdfe.documentoAtual.descarregamentos.filter(
        (x) => (x.documentosVinculados?.length ?? 0) > 0
      ).length === 1;

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    idUfInicio: yup
      .number()
      .required(MensagensPadraoYup.campoObrigatorio)
      .moreThan(0, MensagensPadraoYup.campoObrigatorio)
      .integer()
      .test({
        message: "O valor não pode estar presente nas UFs do percurso.",
        test: (v) => {
          return !store
            .getState()
            .mdfe.documentoAtual.percursos.some((x) => x.idUf == v);
        },
      }),
    idUfFim: yup
      .number()
      .required(MensagensPadraoYup.campoObrigatorio)
      .moreThan(0, MensagensPadraoYup.campoObrigatorio)
      .integer()
      .test({
        message: "O valor não pode estar presente nas UFs do percurso.",
        test: (v) => {
          return !store
            .getState()
            .mdfe.documentoAtual.percursos.some((x) => x.idUf == v);
        },
      }),
    cepLocalCarregamento: yup
      .string()
      .nullable()
      .test({
        message: MensagensPadraoYup.campoObrigatorio,
        test: (v) => {
          return !(!v && cepLocalRequerido);
        },
      }),
    cepLocalDescarregamento: yup
      .string()
      .nullable()
      .test({
        message: MensagensPadraoYup.campoObrigatorio,
        test: (v) => {
          return !(!v && cepLocalRequerido);
        },
      }),
  });

  const { reset, control, formState, handleSubmit, watch } =
    useForm<MDFeAbaPercursoViewModel>({
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: yupResolver(schema),
    });

  let form: HTMLFormElement | null;

  function handleSubmitInterno(data: MDFeAbaPercursoViewModel) {
    dispatch(definirDadosPercurso(data));
  }

  // Repassar referências para componente pai
  useImperativeHandle(
    ref,
    (): IFormulario => ({
      requestSubmit() {
        form?.requestSubmit();
      },
      valido() {
        form?.requestSubmit();
        return Object.keys(formState.errors).length == 0;
      },
      isDirty() {
        return checarSeFormFoiModificado(formState);
      },
    })
  );

  watch((dados) => {
    const state = store.getState().mdfe.documentoAtual;

    if (
      dados.idUfFim != state.idUfFim ||
      dados.idUfInicio != state.idUfInicio
    ) {
      dispatch(definirDadosPercurso(dados as MDFeAbaPercursoViewModel));
    }
  });

  return (
    <TabContainer>
      <form
        ref={(ref) => (form = ref)}
        onSubmit={handleSubmit(handleSubmitInterno)}
      >
        <Sessao>
          <Linha>
            <Coluna md={6}>
              <FormSelectBox
                name="idUfInicio"
                titulo="UF de origem"
                toolTip="UF de origem"
                control={control}
                exibirBotaoLimpar
                dataSource={ufs}
                somenteLeitura={props.somenteLeitura}
                habilitaBusca
                tipoBusca="contains"
              />
            </Coluna>
            <Coluna md={6}>
              <FormSelectBox
                name="idUfFim"
                titulo="UF de destino"
                toolTip="UF de destino"
                control={control}
                exibirBotaoLimpar
                dataSource={ufs}
                somenteLeitura={props.somenteLeitura}
                habilitaBusca
                tipoBusca="contains"
              />
            </Coluna>
          </Linha>
        </Sessao>
        <ComponentAsyncLoader>
          <Linha>
            <Coluna md={6}>
              {/* Grid Carregamentos */}
              <GridCarregamentos
                somenteLeitura={props.somenteLeitura}
                isModal={props.isModal}
                style={{
                  minHeight: "18em",
                  maxHeight: "calc(100vh - 45em)",
                }}
              />
            </Coluna>
            <Coluna md={6}>
              {/* Grid Percurso */}
              <GridPercursos
                somenteLeitura={props.somenteLeitura}
                isModal={props.isModal}
                style={{
                  minHeight: "18em",
                  maxHeight: "calc(100vh - 45em)",
                }}
              />
            </Coluna>
          </Linha>
        </ComponentAsyncLoader>
        <Sessao>
          <Linha>
            <Coluna md={3}>
              <FormTextBox
                name="cepLocalCarregamento"
                titulo="CEP do local de carregamento"
                control={control}
                requerido={cepLocalRequerido}
                tamanhoMaximo={8}
                mask="00.000-000"
              />
            </Coluna>
            <Coluna md={3}>
              <FormTextBox
                name="cepLocalDescarregamento"
                titulo={"CEP do local de descarregamento"}
                control={control}
                requerido={cepLocalRequerido}
                tamanhoMaximo={8}
                mask="00.000-000"
              />
            </Coluna>
          </Linha>
        </Sessao>
      </form>
    </TabContainer>
  );
});

export default MDFeAbaPercurso;
