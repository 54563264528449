import { Menu } from "devextreme-react";
import { useContext, useEffect, useState } from "react";
import { ItemContextMenuNativo } from "../../../utils/context-menu/context-menu-utils";
import { convertItemContextMenu } from "../../../utils/grid/grid-builder";
import ContextoMenus from "../../menus/contexto-menus";

export default function MenuMxp() {
  const [menus, setMenus] = useState<ItemContextMenuNativo[]>([]);
  const { itensMenu } = useContext(ContextoMenus);

  useEffect(() => {
    carregarMenus();
  }, [itensMenu]);

  async function carregarMenus() {
    const itensAdicionar = itensMenu
      ?.filter((x) => x.exibirNoMenuPrincipal)
      .map(convertItemContextMenu);

    const itens = [...(itensAdicionar ?? [])];

    setMenus(itens);
  }

  return (
    <>
      <Menu
        items={menus}
        displayExpr="text"
        showFirstSubmenuMode="onHover"
        showSubmenuMode="onHover"
        orientation={"horizontal"}
        hideSubmenuOnMouseLeave={false}
        className="menu-superior-form"
      />
      <hr className="separador-menu-superior"></hr>
    </>
  );
}
