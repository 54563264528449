import { Popover } from "devextreme-react";
import { memo, useContext } from "react";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { TipoDadosAbstraido } from "../../models/relatorio-personalizado.enums";
import { NivelHierarquicoTagViewModel } from "../../models/relatorio-personalizado.viewmodel";
import { DicionarioTagsContext } from "../dicionario-tags-schema-provider";
import ToolTipTag from "../toop-tip-tag";
import {
  Container,
  CopiarContainer,
  IconeContainer,
  IconeSetaContainer,
  TituloContainer,
  TituloPropComplexa,
  TituloPropSimples,
} from "./styles";

interface CardPropriedadeProps {
  propriedade: NivelHierarquicoTagViewModel;
  nivel: number;
}

function SaoIguais(antigo: CardPropriedadeProps, novo: CardPropriedadeProps) {
  return (
    antigo.nivel == novo.nivel &&
    antigo.propriedade.nomeAmigavel == novo.propriedade.nomeAmigavel &&
    antigo.propriedade.nomeTipo == novo.propriedade.nomeTipo
  );
}

const CardPropriedade = memo(({ propriedade, nivel }: CardPropriedadeProps) => {
  const { obterTag, navegar } = useContext(DicionarioTagsContext);
  const tag = obterTag(nivel, propriedade.nomeAmigavel);
  // eslint-disable-next-line
  const idElemento = tag.replace(/[\[\]\.]/g, "");

  function handleClick() {
    if (propriedade.objetoComplexo) {
      navegar({
        nivel: nivel + 1,
        nome: propriedade.nomeAmigavel,
        tipoDeclarante: propriedade.nomeTipo,
      });
    }
  }

  function handleCopiar() {
    navigator.clipboard.writeText(tag);
    exibirNotificacaoToast({
      mensagem: "Tag copiada para área de transferência.",
      tipo: TipoNotificacao.Informacao,
    });
  }

  const obterIcone = () => {
    switch (propriedade.TipoDadosAbstraido) {
      case TipoDadosAbstraido.Texto:
        return "fa-solid fa-quote-left";
      case TipoDadosAbstraido.Inteiro:
        return "fa-solid fa-hashtag";
      case TipoDadosAbstraido.Decimal:
        return "fa-solid fa-calculator";
      case TipoDadosAbstraido.DataHora:
        return "fa-solid fa-calendar-days";
      case TipoDadosAbstraido.Tempo:
        return "fa-solid fa-clock";
      case TipoDadosAbstraido.Binario:
        return "fa-solid fa-file";
      case TipoDadosAbstraido.Enumerador:
        return "fa-solid fa-list";
      case TipoDadosAbstraido.Booleano:
        return "fa-solid fa-check";
      case TipoDadosAbstraido.Complexo:
        return "fa-solid fa-cube";
      default:
        return "fa-solid fa-circle-info";
    }
  };

  return (
    <>
      <Container onClick={handleClick} clickavel={propriedade.objetoComplexo}>
        <IconeContainer id={idElemento}>
          <span className={obterIcone()} />
        </IconeContainer>
        <TituloContainer>
          {propriedade.objetoComplexo ? (
            <TituloPropComplexa>{propriedade.nomeAmigavel}</TituloPropComplexa>
          ) : (
            <TituloPropSimples>{propriedade.nomeAmigavel}</TituloPropSimples>
          )}
        </TituloContainer>
        {propriedade.objetoComplexo ? (
          <IconeSetaContainer>
            <span className="fa-solid fa-chevron-right" />
          </IconeSetaContainer>
        ) : (
          <CopiarContainer
            title="Copiar valor da tag"
            className="botao-copiar"
            onClick={handleCopiar}
          >
            <span className="fa-regular fa-copy" />
          </CopiarContainer>
        )}
      </Container>
      <Popover
        target={`#${idElemento}`}
        showEvent={{ name: "dxhoverstart", delay: 400 }}
        hideEvent="dxhoverend"
        maxWidth={400}
      >
        <ToolTipTag tag={tag} propriedade={propriedade} />
      </Popover>
    </>
  );
}, SaoIguais);

export default CardPropriedade;
