import { PropsWithChildren, useCallback, useState } from "react";
import { ItemContextMenuMxp } from "../../../utils/context-menu/context-menu-utils";
import ContextoMenuEdicaoOrdemDeProducao, {
  ItensMenuEdicaoOrdemDeProducao,
} from "../contexto-menu-op";

export function ProvedorMenuEdicaoOrdemDeProducao(
  props: PropsWithChildren<any>
) {
  const [menu, setMenu] = useState<ItensMenuEdicaoOrdemDeProducao>(
    new ItensMenuEdicaoOrdemDeProducao()
  );

  const addItensMenu = useCallback((itens: ItemContextMenuMxp[]) => {
    setMenu((menu) => {
      const itensAtualizados = menu.itens.map((itemExistente) => {
        // Procura se existe um item novo com o mesmo name
        const itemNovo = itens.find((item) => item.name === itemExistente.name);
        // Se encontrou, retorna o novo, senão mantém o existente
        return itemNovo || itemExistente;
      });

      // Adiciona os itens novos que não existiam antes
      const itensIneditos = itens.filter(
        (itemNovo) =>
          !menu.itens.some(
            (itemExistente) => itemExistente.name === itemNovo.name
          )
      );

      return { itens: [...itensAtualizados, ...itensIneditos] };
    });
  }, []);

  return (
    <ContextoMenuEdicaoOrdemDeProducao.Provider
      value={{
        menu,
        addItensMenu,
      }}
    >
      {props.children}
    </ContextoMenuEdicaoOrdemDeProducao.Provider>
  );
}
