import { format } from "date-fns";
import { useEffect, useState } from "react";
import * as yup from "yup";
import {
  FormDateBox,
  FormGrupo,
  FormTextBoxSimples,
} from "../../../../components/formularios";
import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../components/layout/form-base2";
import { Coluna, Linha } from "../../../../components/layout/grid-system";
import { useHookForms } from "../../../../hooks/form.hooks";
import { useAppDispatch, useAppSelector } from "../../../../hooks/store.hooks";
import { MDFeEncerramentoRequestDTO } from "../../../../models/api/mdfe/mdfe-request-response";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import API from "../../../../services/mdfe/mdfe.service";
import { carregar } from "../../../../store/mdfe/mdfe.slice";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";

export const EditFormEncerramentoMDFe = (props: FormularioEdicaoBaseProps) => {
  const dispatch = useAppDispatch();

  const [carregando, setCarregando] = useState(false);

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    dataHoraEncerramento: yup
      .string()
      .required()
      .test(
        "data-encerramento-nao-pode-ser-antes-data-autorizacao",
        "Data de encerramento não pode ser anterior a data de autorização.",
        function (value) {
          const dataAutorizacao = new Date(
            dadosMDFe.dataAutorizacaoMDFe ?? dadosMDFe.dataEmissaoMDFe
          );
          return !value || new Date(value) >= dataAutorizacao;
        }
      ),
  });

  const dadosMDFe = useAppSelector((state) => {
    return {
      idMdfeDocumentoAtual: state.mdfe.documentoAtual.id,
      numeroMDFe: state.mdfe.documentoAtual.numero,
      serieMDFe: state.mdfe.documentoAtual.serie,
      dataAutorizacaoMDFe:
        state.mdfe.documentoAtual.autorizacao?.dataRecebimento,
      dataEmissaoMDFe: state.mdfe.documentoAtual.dataHoraEmissao,
    };
  });

  const novoRegistro: MDFeEncerramentoRequestDTO = {
    id: dadosMDFe.idMdfeDocumentoAtual,
    dataHoraEncerramento: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
  };

  const hookForm = useHookForms(schema);

  const { control, handleSubmit, getValues, reset, register } = hookForm;

  useEffect(() => {
    if (props.visivel) {
      carregarTela();
    }
  }, [props.visivel]);

  async function carregarTela() {
    setCarregando(true);
    reset(novoRegistro);
    setCarregando(false);
  }

  async function handleEncerrar() {
    try {
      setCarregando(true);
      const model = getValues();
      const resposta = await API.encerrarMDFe(
        dadosMDFe.idMdfeDocumentoAtual,
        format(new Date(model.dataHoraEncerramento), "yyyy-MM-dd'T'HH:mm:ss")
      );
      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar({
          concluido: true,
          precisaAtualizar: false,
        });
        dispatch(carregar(resposta.model.documento));
      }
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      props.callBackFecharModal(info);
    }
  }

  function handleCancelar() {
    fechar({ concluido: false, precisaAtualizar: false });
  }

  function callBackUnprocessableEntity() {
    fechar({
      concluido: false,
      precisaAtualizar: true,
    });
  }

  return (
    <>
      <FormBase2
        visivel={props.visivel}
        carregando={carregando}
        onClickSalvar={handleSubmit(handleEncerrar)}
        onClickCancelar={handleCancelar}
        configuracoesModal={props.configuracoesModal}
        modoEdicao={props.idRegistroEdicao == 0 ? "criar" : "editar"}
        titulo={"Encerramento de MDF-e"}
      >
        <input type="hidden" {...register("id")} defaultValue={0} />
        <FormGrupo>
          <Linha>
            <Coluna md={6}>
              <FormTextBoxSimples
                titulo="Número"
                valor={dadosMDFe.numeroMDFe?.toString() ?? ""}
                toolTip="Campo referente ao número da MDF-e"
                tipo="text"
                somenteLeitura={true}
              />
            </Coluna>
            <Coluna md={6}>
              <FormTextBoxSimples
                titulo="Série"
                valor={dadosMDFe.serieMDFe}
                toolTip="Campo referente a série do documento MDF-e"
                tipo="text"
                somenteLeitura={true}
              />
            </Coluna>
          </Linha>
          <Linha>
            <Coluna md={12}>
              <FormDateBox
                name={"dataHoraEncerramento"}
                titulo="Data/hora"
                toolTip="Data e hora de encerramento da MDF-e,"
                requerido
                exibirBotaoLimpar
                control={control}
                aceitaValorCustomizado={false}
                aceitaDigitacaoComMascara={true}
              />
            </Coluna>
          </Linha>
        </FormGrupo>
      </FormBase2>
    </>
  );
};
