import { DataSourceFiltragem } from "../../../../../utils/grid/data-source-factory";
import { EmpresaGridModel } from "../../models/empresa-grid";

export default class EmpresaFiltrosHelpers {
  static readonly FiltrarAtivos: DataSourceFiltragem<EmpresaGridModel>[] = [
    {
      campo: "ativo",
      operador: "=",
      valor: true,
    },
  ];
  static readonly FiltrarClientes: DataSourceFiltragem<EmpresaGridModel>[] = [
    {
      campo: "cliente",
      operador: "=",
      valor: true,
    },
  ];
  static readonly FiltrarProprias: DataSourceFiltragem<EmpresaGridModel>[] = [
    {
      campo: "propria",
      operador: "=",
      valor: true,
    },
  ];
}
