import { TipoDadosAbstraido } from "./relatorio-personalizado.enums";

export const DicionarioTiposTags = new Map<TipoDadosAbstraido, string>([
  [TipoDadosAbstraido.Complexo, "Objeto"],
  [TipoDadosAbstraido.Texto, "Texto"],
  [TipoDadosAbstraido.Enumerador, "Enumerador"],
  [TipoDadosAbstraido.Inteiro, "Número inteiro"],
  [TipoDadosAbstraido.Decimal, "Número decimal"],
  [TipoDadosAbstraido.DataHora, "Data e hora"],
  [TipoDadosAbstraido.Booleano, "Booleano"],
  [TipoDadosAbstraido.Tempo, "Tempo"],
  [TipoDadosAbstraido.Binario, "Binário"],
]);
