import criarNameof from "../../../../../utils/common/cria-name-of";
import { LoteGridModel } from "../../models/lote.api";
import { LoteEstado, LoteEstadoHelper } from "../enums/lote-enums";

const nameof = criarNameof<LoteGridModel>();

export default class FiltrosGridLote {
  static readonly lotesComEstadoNormal: any[] = [
    nameof("estadoDecodificado"),
    "=",
    LoteEstadoHelper.getDescricao(LoteEstado.Normal),
  ];
}
