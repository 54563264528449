import styled from "styled-components";

export const ContainerPrincipal = styled.div`
  display: "flex";
  flex-direction: "column";
`;

interface ContainerProps {
  modal?: boolean;
}

export const ContainerCards = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  height: ${(props) =>
    props.modal ? "calc(100vh - 200px)" : "calc(100vh - 120px)"};
  min-height: 20em;
  padding-bottom: 4px;
  overflow-x: overlay;
  overflow-y: hidden;
`;
