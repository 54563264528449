import { useContext, useEffect, useState } from "react";
import PainelCarregamento from "../../../../../components/layout/painel-carregamento";
import { tratarErroApi } from "../../../../../utils/api/api-utils";
import { FinalidadeModeloRelatorio } from "../../models/relatorio-personalizado.enums";
import RelatorioPersonalizadoService from "../../servicos/relatorio-personalizado.service";
import CaminhoTags from "../caminho-tags";
import { DicionarioTagsContext } from "../dicionario-tags-schema-provider";
import FragmentoNavegacao from "../fragmento-navegacao";
import { ContainerCards, ContainerPrincipal } from "./styles";

interface DicionarioDeTagsPageProps {
  tipo: FinalidadeModeloRelatorio;
  modal?: boolean;
}

const serviceRelatorio = new RelatorioPersonalizadoService();

export default function PainelPrincipalDicionarioTags({
  tipo,
  modal,
}: DicionarioDeTagsPageProps) {
  let painel: HTMLDivElement | null;

  const [carregando, setCarregando] = useState(true);
  const { definirSchema, navegacao } = useContext(DicionarioTagsContext);

  useEffect(() => {
    carregarDadosSchema(tipo);
    // eslint-disable-next-line
  }, [tipo]);

  useEffect(() => {
    if (painel) {
      painel.scrollTo({ left: painel.clientWidth });
    }
  }, [navegacao]);

  async function carregarDadosSchema(tipo: FinalidadeModeloRelatorio) {
    setCarregando(true);
    try {
      const dados = await serviceRelatorio.obterSchema(tipo);
      definirSchema(dados.model);
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  return (
    <ContainerPrincipal>
      <CaminhoTags />
      <ContainerCards ref={(r) => (painel = r)} modal={modal}>
        {carregando ? (
          <PainelCarregamento />
        ) : (
          navegacao.map((n) => (
            <FragmentoNavegacao
              key={`${n.tipoDeclarante}-${n.nome}-${n.nivel}`}
              nivel={n.nivel}
              tipoDeclarante={n.tipoDeclarante}
              nome={n.nome}
            />
          ))
        )}
      </ContainerCards>
    </ContainerPrincipal>
  );
}
