import DataSource from "devextreme/data/data_source";
import {
  ConfiguracaoExibicaoEBusca,
  assertConfiguracaoExibicaoEBuscaType,
} from "../../../../components/formularios/selectbox-lazy-mxp";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import { DataSourceOpcoesBuilder } from "../../../../utils/grid/data-source-factory";
import { ContatoGridModel } from "../models/contato";

export default class ContatoServico extends ServiceBase {
  constructor() {
    super(NomesEndpoints.Contato);
  }

  public GetDadosSelectBoxContato(
    dataSourceOpcoes?: DataSourceOpcoesBuilder<ContatoGridModel>
  ): [
    DataSource<ContatoGridModel, any>,
    ConfiguracaoExibicaoEBusca<ContatoGridModel>
  ] {
    const dataSource = this.ObterDataSourceParaSelectBoxLazy<ContatoGridModel>({
      camposRetorno: dataSourceOpcoes?.camposRetorno || ["id", "nome"],
      camposOrdenacao: dataSourceOpcoes?.camposOrdenacao || [
        { campo: "nome", desc: false },
      ],
      camposFiltro: dataSourceOpcoes?.camposFiltro,
    });

    const configs = assertConfiguracaoExibicaoEBuscaType<ContatoGridModel>({
      nomeCampoChave: "id",
      expressaoDeBusca: ["nome"],
      nomeCampoExibicao: (c) => {
        if (c) {
          return `${c.nome}`;
        }

        return "";
      },
    });

    return [dataSource, configs];
  }
}
