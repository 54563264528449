import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import DataSourceFactory, {
  DataSourceFiltragem,
} from "../../../../utils/grid/data-source-factory";
import { InsumosFilhosDoInsumoAlternativoGridModel } from "../models/estrutura-de-produto.api";

export default class EstruturaDeProdutoServico extends ServiceBase {
  constructor() {
    super(NomesEndpoints.EstruturaDeProduto);
  }

  public ObterDataSourceDaDaEstruturaDoItemPaiAlternativo(
    insumoItemPaiId: number,
    quantidadeInsumo: number,
    quantidadeASelecionarInsumoAlternativo: number,
    filtroPadrao?: DataSourceFiltragem<InsumosFilhosDoInsumoAlternativoGridModel>[]
  ) {
    return DataSourceFactory.CriarParaGrid<
      InsumosFilhosDoInsumoAlternativoGridModel,
      number
    >(
      `${this._nomeEndpoint}/GridSelecaoItensFilhosDoInsumoItemPaiAlternativo?insumoItemPaiId=${insumoItemPaiId}&quantidadeInsumo=${quantidadeInsumo}`,
      filtroPadrao,
      undefined,
      (data: InsumosFilhosDoInsumoAlternativoGridModel[]) => {
        const proporcaoParaItemPai = data[0]?.proporcaoParaItemPai ?? 1;

        if (data.length > 0) {
          data[0].quantidadeASelecionar =
            quantidadeASelecionarInsumoAlternativo * proporcaoParaItemPai;
        }

        return data;
      }
    );
  }
}
