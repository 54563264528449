import TabContainer from "../../../../../../../components/layout/tab-container";
import ProvedorMenus from "../../../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../../../components/seguranca/requer-permissao";
import { PermissoesReserva } from "../../../../../../../models/permissoes/estoque/reserva/permissoes-reserva";
import { gridEmbutidaStyle } from "../../../../../../comum/utils/styles/grid";
import GridEmbutidaReservasDaOrdemDeProducao from "../../../grid-embutida/reservas-da-ordem-de-producao";

interface OrdemDeProducaoAbaReservasProps {
  ordemDeProducaoId: number;
  ordemDeProducaoCodigo: number | undefined;
  ordemDeProducaoEhSugerida: boolean;
  ordemDeProducaoIdItem: number;
}

export default function OrdemDeProducaoAbaReservas(
  props: OrdemDeProducaoAbaReservasProps
) {
  return (
    <TabContainer>
      <ProvedorMenus>
        <RequerPermissao
          codigoPermissoes={[PermissoesReserva.ConsultarReservasEVinculos]}
        >
          <GridEmbutidaReservasDaOrdemDeProducao
            {...props}
            style={gridEmbutidaStyle}
          />
        </RequerPermissao>
      </ProvedorMenus>
    </TabContainer>
  );
}
