import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import { useCallback, useMemo, useRef, useState } from "react";
import ProvedorAjuda from "../../../../../../components/ajuda/provedor-ajuda";
import ModalAccordion from "../../../../../../components/dialogos/modal-accordion";
import {
  AccordionDataSource,
  AccordionModel,
} from "../../../../../../components/dialogos/modal-accordion/modal-accordion";
import GridColunaAcoes from "../../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { useRegistrarAtalhosGrid } from "../../../../../../hooks/atalhos.hooks";
import { useMenuDeContextosGrid } from "../../../../../../hooks/menus.hooks";
import { useAppDispatch } from "../../../../../../hooks/store.hooks";
import { PermissoesEfdReinf } from "../../../../../../models/permissoes/fiscal/efd-reinf/permissoes-efd-reinf";
import GetColunasDeAuditoria from "../../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import { bloquearUI, desbloquearUI } from "../../../../../../store/ui/ui.slice";
import {
  checarResponse,
  checarResponseExibeMensagemExclusaoDeSucesso,
  checarResponseExibeMensagemExecutadoComSucesso,
  tratarErroApi,
} from "../../../../../../utils/api/api-utils";
import criarNameof from "../../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../../utils/common/nomes-telas";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../../utils/common/permissoes-utils";
import { ItemContextMenuMxp } from "../../../../../../utils/context-menu/context-menu-utils";
import {
  GestorEventoClickMultiplasLinhas,
  GestorEventoClickUnicaLinha,
} from "../../../../../../utils/context-menu/gestor-evento-click";
import { exibirConfirmacao } from "../../../../../../utils/dialogos";
import { formatarDataAno4digitos } from "../../../../../../utils/formatadores/formatador-de-datas";
import { formatarCPF } from "../../../../../../utils/formatadores/formatador-de-strings";
import GridBuilder from "../../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../../utils/grid/padroes-colunas";
import {
  EfdReinf4010GridModel,
  InfosR4010EnvioReinf,
} from "../../../models/efd-reinf-4010/efd-reinf-4010";
import { EfdReinfNumeracaoTipo } from "../../../models/efd-reinf.enums";
import EfdReinfServico from "../../../servicos/efd-reinf.service new";

const service = new EfdReinfServico(EfdReinfNumeracaoTipo.R4010);
const dataSource = service.ObterDataSourceParaGrid<EfdReinf4010GridModel>();

const nameOfGridHandler = criarNameof<EfdReinf4010GridModel>();

function obterDadosAccordionEnvioReinf(
  info: InfosR4010EnvioReinf
): AccordionModel[] {
  const accordionModel: AccordionModel[] = [];

  const obterMensagemBase = (apelido: string, cpf: string) => {
    return `Fornecedor: ${apelido}. CPF: ${formatarCPF(cpf)}`;
  };

  if (info.registrosJaEnviados.length > 0) {
    accordionModel.push({
      grupoDeMensagensTitulo: `Não foi possível enviar ${info.registrosJaEnviados.length} eventos R-4010. Pois já foram enviados com sucesso:`,
      mensagens: info.registrosJaEnviados.map((x) => {
        return `${obterMensagemBase(
          x.apelidoFornecedor,
          x.cpf
        )}. Data do recibo: ${formatarDataAno4digitos(x.reciboData)}. Recibo: ${
          x.recibo
        }`;
      }),
    });
  }

  if (info.registrosComDivergenciaNaApuracao.length > 0) {
    accordionModel.push({
      grupoDeMensagensTitulo: `Não foi possível enviar ${info.registrosComDivergenciaNaApuracao.length} eventos R-4010. Pois a data inicial e data final da apuração não pertencem ao mesmo período (mês/ano), o que geraria rejeição por parte da SEFAZ:`,
      mensagens: info.registrosComDivergenciaNaApuracao.map((x) => {
        return `${obterMensagemBase(
          x.apelidoFornecedor,
          x.cpf
        )}. Apuração: ${formatarDataAno4digitos(
          x.dataInicial
        )} a ${formatarDataAno4digitos(x.dataFinal)}`;
      }),
    });
  }

  if (info.registrosEnviadosComSucesso.length > 0) {
    accordionModel.push({
      grupoDeMensagensTitulo: `${info.registrosEnviadosComSucesso.length} envios foram realizados com sucesso. Clique aqui para ver os eventos enviados:`,
      mensagens: info.registrosEnviadosComSucesso.map((x) => {
        return `${obterMensagemBase(
          x.apelidoFornecedor,
          x.cpf
        )}. Data do recibo: ${formatarDataAno4digitos(x.reciboData)}. Recibo: ${
          x.recibo
        }`;
      }),
    });
  }

  if (info.registrosComErros.length > 0) {
    accordionModel.push({
      grupoDeMensagensTitulo: `${info.registrosComErros.length} envios não foram realizados com sucesso. Clique aqui para ver o motivo dos erros:`,
      mensagens: info.registrosComErros.map((x) => {
        return `${obterMensagemBase(x.apelidoFornecedor, x.cpf)}. Erro: ${
          x.codigoErro
        } - ${x.motivoErro}`;
      }),
    });
  }

  if (info.registrosEmProcessamento.length > 0) {
    accordionModel.push({
      grupoDeMensagensTitulo: `${info.registrosEmProcessamento.length} envios estão com o lote em processamento na SEFAZ. Clique aqui para ver os eventos:`,
      mensagens: info.registrosEmProcessamento.map((x) => {
        return `${obterMensagemBase(x.apelidoFornecedor, x.cpf)}`;
      }),
    });
  }

  return accordionModel;
}

export default function GridEfdReinf4010() {
  const gridRef = useRef<DataGridRef>(null);

  const [modalAccordionVisivel, setModalAccordionVisivel] = useState(false);
  const [dadosAccordion, setDadosAccordion] = useState<AccordionDataSource>();

  const dispatch = useAppDispatch();

  const handleExcluirRegistro = useCallback(
    async (registro: EfdReinf4010GridModel) => {
      try {
        dispatch(bloquearUI());
        if (
          !verificaComNotificacaoSeUsuarioPossuiPermissoes([
            PermissoesEfdReinf.Excluir,
          ])
        ) {
          return;
        }

        const excluir = await exibirConfirmacao(
          "Confirmar exclusão",
          obterMensagemExclusao(registro)
        );

        if (excluir) {
          const resposta = await service.Excluir(registro.id);

          if (resposta) {
            checarResponseExibeMensagemExclusaoDeSucesso(resposta);
            handleAtualizarGrid();
          }
        }
      } catch (erro) {
        tratarErroApi(erro);
      } finally {
        dispatch(desbloquearUI());
      }
    },
    []
  );

  function obterMensagemExclusao(registro: EfdReinf4010GridModel) {
    return `Tem certeza que deseja excluir o registro do fornecedor ${registro.fornecedorRazaoSocial}?`;
  }

  async function enviarEvento(registros?: EfdReinf4010GridModel[]) {
    if (registros && registros.length > 0) {
      try {
        dispatch(bloquearUI());
        if (
          !verificaComNotificacaoSeUsuarioPossuiPermissoes([
            PermissoesEfdReinf.Enviar,
          ])
        ) {
          return;
        }
        const resposta = await service.Enviar<InfosR4010EnvioReinf>(
          registros.map((x) => x.id)
        );

        if (resposta.sucesso) {
          checarResponse(resposta);
          if (resposta.model) {
            setDadosAccordion({
              model: obterDadosAccordionEnvioReinf(resposta.model),
            });
            setModalAccordionVisivel(true);
          }
        }
      } catch (erro) {
        tratarErroApi(erro);
      } finally {
        dispatch(desbloquearUI());
      }
    }
  }

  const handleConsultarEvento = useCallback(
    async (registro: EfdReinf4010GridModel) => {
      if (registro.id) {
        dispatch(bloquearUI());
        if (
          !verificaComNotificacaoSeUsuarioPossuiPermissoes([
            PermissoesEfdReinf.Consultar,
          ])
        ) {
          return;
        }
        try {
          const resposta = await service.Consultar(registro.id);

          if (resposta.sucesso) {
            checarResponseExibeMensagemExecutadoComSucesso(resposta);
          }
        } catch (erro) {
          tratarErroApi(erro);
        } finally {
          dispatch(desbloquearUI());
        }
      }
    },
    []
  );

  const acoesDeContexto: ItemContextMenuMxp[] = [
    {
      text: "Ações",
      icon: "ic-material-symbols-outlined ic-vertical",
      hint: "Menu com opções de ação",
      exibirNaLinhaDaGrid: "menuDeContexto",
      exibirNoMenuPrincipal: true,
      items: [
        {
          text: "Enviar evento R-4010 - Pagamentos/créditos a beneficiário pessoa física",
          hint: "Enviar evento",
          gestorEventoClick: new GestorEventoClickMultiplasLinhas(
            enviarEvento,
            () => gridController
          ),
        },
        {
          text: "Consultar evento R-4010 - Pagamentos/créditos a beneficiário pessoa física",
          hint: "Consultar evento",
          gestorEventoClick: new GestorEventoClickUnicaLinha(
            handleConsultarEvento,
            () => gridController
          ),
        },
      ],
    },
  ];

  useMenuDeContextosGrid(acoesDeContexto);

  const gridController = new GridController<EfdReinf4010GridModel>(() =>
    gridRef.current?.instance()
  );

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar("efd-reinf-4010", () =>
      gridRef.current?.instance()
    )
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.efdReinf4010)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .definirMenuDeContexto(acoesDeContexto)
      .build();
  }, []);

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  useRegistrarAtalhosGrid<EfdReinf4010GridModel>({
    controller: gridController,
    handleExcluir: handleExcluirRegistro,
  });

  function handleFecharModalAccordion() {
    setModalAccordionVisivel(false);
    setDadosAccordion(undefined);
  }

  return (
    <>
      <ProvedorAjuda id={"tooltips-grid-efd-reinf-4010"}>
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          {GridColunaAcoes<EfdReinf4010GridModel>({
            handleExcluir: handleExcluirRegistro,
          })}
          <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
          <Column
            key={nameOfGridHandler("tipoDeArquivo")}
            dataField={nameOfGridHandler("tipoDeArquivo")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Tipo de arquivo"
            visible={false}
            width={150}
          />
          <Column
            key={nameOfGridHandler("apuracaoDataInicial")}
            dataField={nameOfGridHandler("apuracaoDataInicial")}
            {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
            caption="Data inicial da apuração"
            sortIndex={0}
            sortOrder="desc"
            width={200}
          />
          <Column
            key={nameOfGridHandler("apuracaoDataFinal")}
            dataField={nameOfGridHandler("apuracaoDataFinal")}
            {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
            caption="Data final da apuração"
            sortIndex={0}
            sortOrder="desc"
            width={200}
          />
          <Column
            key={nameOfGridHandler("fornecedorRazaoSocial")}
            dataField={nameOfGridHandler("fornecedorRazaoSocial")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Nome do fornecedor"
            width={300}
          />
          <Column
            key={nameOfGridHandler("fornecedorCpf")}
            dataField={nameOfGridHandler("fornecedorCpf")}
            {...obterConfiguracaoColuna("cpfFormatado")}
            caption="CPF do fornecedor"
            width={190}
          />
          <Column
            key={nameOfGridHandler("dataRecibo")}
            dataField={nameOfGridHandler("dataRecibo")}
            {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
            caption="Data do recibo"
            width={150}
          />
          <Column
            key={nameOfGridHandler("recibo")}
            dataField={nameOfGridHandler("recibo")}
            {...obterConfiguracaoColuna("stringG")}
            caption="Recibo"
            width={205}
          />
          <Column
            key={nameOfGridHandler("situacaoDecodificada")}
            dataField={nameOfGridHandler("situacaoDecodificada")}
            {...obterConfiguracaoColuna("stringP")}
            caption="Situação"
            width={100}
          />
          <Column
            key={nameOfGridHandler("codigoDoErro")}
            dataField={nameOfGridHandler("codigoDoErro")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Código do erro"
            width={130}
          />
          <Column
            key={nameOfGridHandler("motivoDoErro")}
            dataField={nameOfGridHandler("motivoDoErro")}
            {...obterConfiguracaoColuna("stringGG")}
            caption="Motivo do erro"
            width={250}
          />
          <Column
            key={nameOfGridHandler("minhaEmpresaApelido")}
            dataField={nameOfGridHandler("minhaEmpresaApelido")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Minha empresa"
            visible={false}
            width={200}
          />
          {GetColunasDeAuditoria()}
        </DataGrid>
      </ProvedorAjuda>

      <ModalAccordion
        modalAccordionVisivel={modalAccordionVisivel}
        handlerFecharModalAccordion={handleFecharModalAccordion}
        dataSource={dadosAccordion?.model}
        accordionId="accordion-detalhamento-envio-reinf-R4010"
        itemDoAccordionAltura="auto"
      />
    </>
  );
}
