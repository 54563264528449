import { ReactNode } from "react";
import { Erro } from "../../models/api/comum/erros";

export function formataMensagemDeErroComoElementoHtmlDivPadrao(
  erro: Erro
): ReactNode {
  {
    return (
      <div>
        <p>{erro.mensagem}</p>
      </div>
    );
  }
}
