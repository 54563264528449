import { PermissoesApontamentoDaOperacao } from "../../../../../models/permissoes/producao/apontamento-da-operacao/ApontamentoDaOperacaoPermissoes";
import {
  abrirModalMxpAntigo,
  DadosLink,
} from "../../../../../utils/common/menu-utils";
import { JanelasDeNotificacaoTitulos } from "../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import { renderToStringClient } from "../../../../../utils/react/react-utils";

const MensagemConfirmarApontamento = () => {
  return (
    <div>
      <p>
        A funcionalidade de apontamentos ainda não foi adaptada para a nova
        versão do MAXIPROD. <br />
        Ao clicar em &quot;Sim&quot;, você será direcionado à tela na versão
        antiga, onde poderá realizar o apontamento.
      </p>
      <p style={{ marginBottom: "0px" }}>Deseja prosseguir?</p>
    </div>
  );
};

export const abrirTelaDeApontamentoDaDuracaoDaSessaoNoMxp1 = async (
  ordemDeProducaoId: number
) => {
  if (
    verificaComNotificacaoSeUsuarioPossuiPermissoes([
      PermissoesApontamentoDaOperacao.InserirEditar,
    ])
  ) {
    const confirmacao = await exibirConfirmacao(
      JanelasDeNotificacaoTitulos.Aviso,
      renderToStringClient(<MensagemConfirmarApontamento />)
    );

    if (!confirmacao) {
      return;
    }

    const dadosLink: DadosLink = {
      rota: "_Apontamento_ApontarDuracaoDaSessao",
      tipo: "javaScriptMxpAntigo",
      paramFunction: () => `(${ordemDeProducaoId.toString()})`,
    };

    const abrirEmNovaAba = true;
    abrirModalMxpAntigo(dadosLink, abrirEmNovaAba);
  }
};
