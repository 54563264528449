import DataGrid, { Column, DataGridRef } from "devextreme-react/data-grid";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useParametroId } from "../../../../../hooks/route.hooks";
import { useSeletorGridMxp } from "../../../../../hooks/seletor.hooks";
import { PermissoesOperacaoDeOrdemDeProducao } from "../../../../../models/permissoes/producao/operacao-de-ordem-de-producao/permissoes-operacao-de-ordem-de-producao";
import { ResultadoAcaoFormulario } from "../../../../../models/shared/ui/formularios";
import { GridMxpProps } from "../../../../../models/shared/ui/grid";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesModais from "../../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import NormalizaTituloModal from "../../../../../utils/common/normaliza-titulo";
import { GeradorMensagensNotificacao } from "../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import { formatarPercentualComCasasDecimaisPersonalizadas } from "../../../../../utils/formatadores/formatador-de-numeros";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import { renderToStringClient } from "../../../../../utils/react/react-utils";
import UrlUtils from "../../../../../utils/url/url-utils";
import { OperacaoDaOrdemDeProducaoGridModel } from "../../models/operacao-de-ordem-de-producao";
import { OperacaoDeOrdemDeProducaoServico } from "../../servicos/operacao-de-ordem-de-producao-service";
import FormOperacaoDaOrdemDeProducao from "../formulario";

const service = new OperacaoDeOrdemDeProducaoServico();

const nameOfGridHandler = criarNameof<OperacaoDaOrdemDeProducaoGridModel>();

export default function GridOperacaoDaOrdemDeProducao(
  props: GridMxpProps<OperacaoDaOrdemDeProducaoGridModel>
) {
  const gridRef = useRef<DataGridRef>(null);
  const parametroId = useParametroId();
  const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);

  const dataSource =
    service.ObterDataSourceParaGrid<OperacaoDaOrdemDeProducaoGridModel>(
      props.filtrosNoServidor
    );

  const gridController = useMemo(
    () =>
      new GridController<OperacaoDaOrdemDeProducaoGridModel>(() =>
        gridRef.current?.instance()
      ),
    []
  );

  const handleAtualizarGrid = useCallback(() => {
    gridController.atualizar();
  }, [gridController]);

  useEffect(() => {
    if (parametroId) {
      setIdRegistroEdicao(parametroId);
    }
  }, [parametroId]);

  useSeletorGridMxp(() => gridRef.current, props.filtrosNoCliente);

  const handleNovoRegistro = useCallback(() => {
    if (
      verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesOperacaoDeOrdemDeProducao.InserirEditar,
      ])
    ) {
      setIdRegistroEdicao(0);
    }
  }, []);

  const handleEditarRegistro = useCallback(
    (registro: OperacaoDaOrdemDeProducaoGridModel) => {
      if (
        verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesOperacaoDeOrdemDeProducao.InserirEditar,
        ])
      ) {
        setIdRegistroEdicao(registro.id);
      }
    },
    []
  );

  const handleExcluirRegistro = useCallback(
    async (registro: OperacaoDaOrdemDeProducaoGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesOperacaoDeOrdemDeProducao.Excluir,
        ])
      ) {
        return;
      }

      const excluir = await exibirConfirmacao(
        `Confirmar exclusão`,
        obterMensagemExclusao(registro)
      );

      if (excluir) {
        try {
          const resposta = await service.Excluir(registro.id);

          if (resposta) {
            checarResponseExibeMensagemExclusaoDeSucesso(
              resposta,
              GeradorMensagensNotificacao.ExcluidoComSucessoFeminino("Operação")
            );
            handleAtualizarGrid();
          }
        } catch (erro) {
          tratarErroApi(erro);
        }
      }
    },
    [handleAtualizarGrid]
  );

  function obterMensagemExclusao(registro: OperacaoDaOrdemDeProducaoGridModel) {
    const descricao = registro.descricao ? `(${registro.descricao})` : "";
    const mensagem = renderToStringClient(
      <div>
        Tem certeza de que deseja excluir a operação {registro.numero}{" "}
        {descricao}?<br />
        Ao excluir uma operação, os insumos que forem vinculados a ela também
        serão excluídos.
      </div>
    );

    return mensagem;
  }

  const handleCallbackFormulario = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      setIdRegistroEdicao(NaN);

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }

      UrlUtils.RemoverParametroId(parametroId);
    },
    [parametroId, handleAtualizarGrid]
  );

  const handleFecharModal = useCallback(() => {
    setIdRegistroEdicao(NaN);
    UrlUtils.RemoverParametroId(parametroId);
  }, [parametroId]);

  useRegistrarAtalhosGrid<OperacaoDaOrdemDeProducaoGridModel>({
    controller: gridController,
    handleNovo: handleNovoRegistro,
    handleEditar: handleEditarRegistro,
    handleExcluir: handleExcluirRegistro,
  });

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "operacao-da-ordem-de-producao",
      () => gridRef.current?.instance(),
      false,
      props.filtrosNoCliente
    )
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.operacoesDeOrdensDeProducao)
      .definirBotaoNovo(handleNovoRegistro)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirDuploCliqueLinha(handleEditarRegistro)
      .definirSelecao()
      .definirOrdenacao()
      .build();
  }, [
    props.filtrosNoCliente,
    dataSource,
    handleAtualizarGrid,
    handleNovoRegistro,
    handleEditarRegistro,
  ]);

  return (
    <>
      <ProvedorAjuda id={"tooltips-grid-operacao-ordem-producao"}>
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
          {GridColunaAcoes<OperacaoDaOrdemDeProducaoGridModel>({
            handleEditar: handleEditarRegistro,
            handleExcluir: handleExcluirRegistro,
          })}
          <Column
            key={nameOfGridHandler("numeroOrdemDeProducao")}
            dataField={nameOfGridHandler("numeroOrdemDeProducao")}
            {...obterConfiguracaoColuna("stringMG")}
            caption="Ordem de produção"
          />
          <Column
            key={nameOfGridHandler("numero")}
            dataField={nameOfGridHandler("numero")}
            {...obterConfiguracaoColuna("stringP")}
            caption="Operação"
          />
          <Column
            key={nameOfGridHandler("descricao")}
            dataField={nameOfGridHandler("descricao")}
            {...obterConfiguracaoColuna("stringMP")}
            caption="Descrição"
          />
          <Column
            key={nameOfGridHandler("codigoItem")}
            dataField={nameOfGridHandler("codigoItem")}
            {...obterConfiguracaoColuna("stringMP")}
            caption="Item"
            width={200}
            visible={false}
          />
          <Column
            key={nameOfGridHandler("descricaoItem")}
            dataField={nameOfGridHandler("descricaoItem")}
            {...obterConfiguracaoColuna("stringM")}
            width={400}
            caption="Descrição do item"
            visible={false}
          />
          <Column
            key={nameOfGridHandler("codigoCentroDeTrabalho")}
            dataField={nameOfGridHandler("codigoCentroDeTrabalho")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Centro de trabalho"
          />
          <Column
            key={nameOfGridHandler("codigoEstacaoDeTrabalho")}
            dataField={nameOfGridHandler("codigoEstacaoDeTrabalho")}
            {...obterConfiguracaoColuna("stringMG")}
            caption="Estação de trabalho"
          />
          <Column
            key={nameOfGridHandler("codigoProximaOperacao")}
            dataField={nameOfGridHandler("codigoProximaOperacao")}
            {...obterConfiguracaoColuna("stringM")}
            width={150}
            caption="Próxima operação"
          />
          <Column
            key={nameOfGridHandler("leadTimeEmDias")}
            dataField={nameOfGridHandler("leadTimeEmDias")}
            dataType="number"
            width={150}
            caption="Lead time (dias)"
          />
          <Column
            key={nameOfGridHandler("folgaEmDias")}
            dataField={nameOfGridHandler("folgaEmDias")}
            dataType="number"
            width={130}
            caption="Folga (dias)"
            visible={false}
          />
          <Column
            key={nameOfGridHandler("tempoDePreparacaoEmHoras")}
            dataField={nameOfGridHandler("tempoDePreparacaoEmHoras")}
            dataType="number"
            width={200}
            format={{ type: "fixedPoint", precision: 2 }}
            caption="Tempo de preparação (h)"
            visible={false}
          />
          <Column
            key={nameOfGridHandler("quantidadeDaOperacao")}
            dataField={nameOfGridHandler("quantidadeDaOperacao")}
            dataType="number"
            width={150}
            format={{ type: "fixedPoint", precision: 5 }}
            caption="Qt da operação"
          />
          <Column
            key={nameOfGridHandler("quantidadeRealizada")}
            dataField={nameOfGridHandler("quantidadeRealizada")}
            dataType="number"
            width={150}
            format={{ type: "fixedPoint", precision: 5 }}
            caption="Qt realizada"
          />
          <Column
            key={nameOfGridHandler("percentualRealizado")}
            dataField={nameOfGridHandler("percentualRealizado")}
            {...obterConfiguracaoColuna("percentual")}
            width={145}
            caption="Qt realizada (%)"
            cellRender={({ data }) => {
              const dados = data as OperacaoDaOrdemDeProducaoGridModel;

              return `${formatarPercentualComCasasDecimaisPersonalizadas(
                dados.percentualRealizado,
                2
              )}`;
            }}
          />
          <Column
            key={nameOfGridHandler("tempoTotalEmHoras")}
            dataField={nameOfGridHandler("tempoTotalEmHoras")}
            dataType="number"
            width={145}
            format={{ type: "fixedPoint", precision: 5 }}
            caption="Tempo total (h)"
          />
          <Column
            key={nameOfGridHandler("tempoRealizadoEmHoras")}
            dataField={nameOfGridHandler("tempoRealizadoEmHoras")}
            dataType="number"
            width={170}
            format={{ type: "fixedPoint", precision: 5 }}
            caption="Tempo realizado (h)"
          />
          <Column
            key={nameOfGridHandler("percentualRealizadoEmHoras")}
            dataField={nameOfGridHandler("percentualRealizadoEmHoras")}
            {...obterConfiguracaoColuna("percentualComMinimoDuasCasasDecimais")}
            width={170}
            caption="Tempo realizado (%)"
          />
          <Column
            key={nameOfGridHandler("custoHora")}
            dataField={nameOfGridHandler("custoHora")}
            dataType="number"
            width={120}
            format={{ type: "fixedPoint", precision: 2 }}
            caption="Custo/hora"
          />
          <Column
            key={nameOfGridHandler("inicioPrevisto")}
            dataField={nameOfGridHandler("inicioPrevisto")}
            {...obterConfiguracaoColuna("dataAnoCurtoComHoraMinutoSegundos")}
            caption="Início previsto"
          />
          <Column
            key={nameOfGridHandler("fimPrevisto")}
            dataField={nameOfGridHandler("fimPrevisto")}
            {...obterConfiguracaoColuna("dataAnoCurtoComHoraMinutoSegundos")}
            caption="Fim previsto"
          />
          <Column
            key={nameOfGridHandler("inicioRealizado")}
            dataField={nameOfGridHandler("inicioRealizado")}
            {...obterConfiguracaoColuna("dataAnoCurtoComHoraMinutoSegundos")}
            caption="Início realizado"
          />
          <Column
            key={nameOfGridHandler("fimRealizado")}
            dataField={nameOfGridHandler("fimRealizado")}
            {...obterConfiguracaoColuna("dataAnoCurtoComHoraMinutoSegundos")}
            caption="Fim realizado"
          />
          <Column
            key={nameOfGridHandler("estado")}
            dataField={nameOfGridHandler("estado")}
            {...obterConfiguracaoColuna("stringP")}
            caption="Estado"
          />
          <Column
            key={nameOfGridHandler("baixaDosInsumos")}
            dataField={nameOfGridHandler("baixaDosInsumos")}
            {...obterConfiguracaoColuna("stringM")}
            width={160}
            caption="Baixa dos insumos"
          />
          <Column
            key={nameOfGridHandler("quantidadeDaOrdemDeProducao")}
            dataField={nameOfGridHandler("quantidadeDaOrdemDeProducao")}
            dataType="number"
            width={200}
            format={{ type: "fixedPoint", precision: 5 }}
            caption="Qt da ordem de produção"
          />
          <Column
            key={nameOfGridHandler("estadoDaOrdemDeProducao")}
            dataField={nameOfGridHandler("estadoDaOrdemDeProducao")}
            {...obterConfiguracaoColuna("stringM")}
            width={225}
            caption="Estado da ordem de produção"
          />
          <Column
            key={nameOfGridHandler("custoRealizado")}
            dataField={nameOfGridHandler("custoRealizado")}
            dataType="number"
            width={150}
            format={{ type: "fixedPoint", precision: 2 }}
            caption="Custo realizado"
          />
          <Column
            key={nameOfGridHandler("minhaEmpresaApelido")}
            dataField={nameOfGridHandler("minhaEmpresaApelido")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Minha empresa"
            visible={false}
          />
          {GetColunasDeAuditoria(true, false, [
            { ordenarPor: "criacaoData", ordem: "desc" },
          ])}
        </DataGrid>
      </ProvedorAjuda>

      <ModalMxp
        titulo={NormalizaTituloModal.Normalizar(
          idRegistroEdicao,
          NomesModais.operacaoDaOrdemDeProducao,
          true
        )}
        visivel={!Number.isNaN(idRegistroEdicao)}
        handleFechar={handleFecharModal}
        largura={"max(60vw, 800px)"}
        altura={"max(60vh, 800px)"}
      >
        <FormOperacaoDaOrdemDeProducao
          idRegistroEmEdicao={idRegistroEdicao}
          handleCallback={handleCallbackFormulario}
        />
      </ModalMxp>
    </>
  );
}
