export enum PermissoesOrdemDeProducao {
  Consultar = "OP000",
  InserirEditar = "OP001",
  ConcluirEDesconcluir = "OP002",
  Excluir = "OP003",
  RetirarMaterial = "OP007",
  ConsultarAnexos = "OP008",
  InserirAnexos = "OP009",
  ExcluirAnexos = "OP010",
  AtualizarRoteiro = "OP012",
  RetirarApara = "OP013",
}
