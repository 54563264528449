import TabContainer from "../../../../../../../components/layout/tab-container";
import GridEmbutidaTarefasEAnotacoes from "../../../../../../crm/tarefa-ou-anotacao/componentes/grid-embutida";

interface OrdemDeProducaoAbaTarefasEAnotacoesProps {
  ordemDeProducaoId: number;
}

export default function OrdemDeProducaoAbaTarefasEAnotacoes(
  props: OrdemDeProducaoAbaTarefasEAnotacoesProps
) {
  return (
    <TabContainer>
      <GridEmbutidaTarefasEAnotacoes {...props} />
    </TabContainer>
  );
}
