import { AccordionModel } from "../../../../components/dialogos/modal-accordion/modal-accordion";
import { ErroApi } from "../../../../models/api/comum/erros";

export interface IOperacaoEmMassaResponse {
  listaDeErros: ErroApi[];
  valido: boolean;
}

export interface IAcaoEmMassa {
  acaoPlural: string;
  acaoSingular: string;
}

export interface IAcaoComEntidadeEmMassa<T extends IOperacaoEmMassaResponse>
  extends IAcaoEmMassa {
  acaoPlural: string;
  acaoSingular: string;
  entidadeSingular: string;
  entidadePlural: string;
  formatarEntidade: (entidade: T) => string;
}

function capitalizarPrimeiraLetra(texto: string): string {
  if (!texto) return texto;
  return texto.charAt(0).toUpperCase() + texto.slice(1);
}

export function formatarMensagensAcaoAccordion<
  T extends IOperacaoEmMassaResponse
>(
  ordensDeProducaoAcaoSucesso: T[],
  ordensDeProducaoAcaoSemSucesso: T[],
  acoes: IAcaoComEntidadeEmMassa<T>
): AccordionModel[] {
  const accordionModel: AccordionModel[] = [];

  if (ordensDeProducaoAcaoSucesso.length > 0) {
    let mensagem = "";

    if (ordensDeProducaoAcaoSucesso.length == 1) {
      mensagem = `${ordensDeProducaoAcaoSucesso.length} ${acoes.entidadeSingular} ${acoes.acaoSingular} com sucesso. Clique para ver mais:`;
    } else {
      mensagem = `${ordensDeProducaoAcaoSucesso.length} ${acoes.entidadePlural} ${acoes.acaoPlural} com sucesso. Clique para ver mais:`;
    }

    accordionModel.push({
      grupoDeMensagensTitulo: mensagem,
      mensagens: ordensDeProducaoAcaoSucesso.map((x) => {
        return `${capitalizarPrimeiraLetra(
          acoes.entidadeSingular
        )} ${acoes.formatarEntidade(x)}`;
      }),
    });
  }

  if (ordensDeProducaoAcaoSemSucesso.length > 0) {
    let mensagem = "";

    if (ordensDeProducaoAcaoSemSucesso.length == 1) {
      mensagem = `${ordensDeProducaoAcaoSemSucesso.length} ${acoes.entidadeSingular} não foi ${acoes.acaoSingular}. Clique para ver mais:`;
    } else {
      mensagem = `${ordensDeProducaoAcaoSemSucesso.length} ${acoes.acaoPlural} não foram ${acoes.acaoPlural}. Clique para ver mais:`;
    }

    accordionModel.push({
      grupoDeMensagensTitulo: mensagem,
      mensagens: ordensDeProducaoAcaoSemSucesso.map((x) => {
        return `${capitalizarPrimeiraLetra(
          acoes.entidadeSingular
        )} ${acoes.formatarEntidade(x)} - Motivos do erro: ${x.listaDeErros
          .map((y) => y.mensagem)
          .join(" \n")}`;
      }),
    });
  }

  return accordionModel;
}
