import styled, { css } from "styled-components";

interface ContainerProps {
  clickavel?: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 4px;
  padding-right: 4px;
  cursor: ${(props) => (props.clickavel ? "pointer" : "default")};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    background-color: #adcff2;
  }

  &:active {
    ${(props) =>
      props.clickavel &&
      css`
        background-color: #cee1f4;
      `}
  }

  &:hover .botao-copiar {
    display: block;
  }
`;

export const IconeContainer = styled.div`
  padding: 2px;
  font-size: 16px;
  color: slategray;
  cursor: help;
`;

export const IconeSetaContainer = styled.div`
  padding: 2px;
  font-size: 16px;
  color: slategray;
`;

export const TituloContainer = styled.div`
  padding-top: 4px;
  padding-left: 4px;
  padding-right: 2px;
  width: 100%;
  color: black;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const TituloPropComplexa = styled.span``;

export const TituloPropSimples = styled.span``;

export const CopiarContainer = styled.div`
  cursor: pointer;
  display: none; //Será visível via hover do elemento pai
  padding: 2px;
  font-size: 16px;
  color: slategray;

  &:active {
    color: blue;
  }
`;
