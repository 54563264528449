import {
  ConfiguracoesErros,
  TipoErro,
} from "../../../../../models/api/comum/erros";
import { ErroModeloDeRelatorio } from "./modelo-de-relatorio.erros";

export const configuracoesErroModeloDeRelatorio = Object.freeze<
  ConfiguracoesErros<ErroModeloDeRelatorio>
>({
  "MODELORELATORIO-0002": {
    tipo: TipoErro.Popup,
  },
});
