import { useMemo } from "react";
import { FieldValues, UseControllerProps } from "react-hook-form";
import { FormSelectBoxLazyMxp } from "../../../../../../components/formularios";
import { ItemSelectionChangedType } from "../../../../../../components/formularios/selectbox-lazy-mxp";
import { DataSourceOpcoesBuilder } from "../../../../../../utils/grid/data-source-factory";
import { OrdemDeProducaoGridModel } from "../../../models/ordem-de-producao.api";
import { OrdemDeProducaoService } from "../../../servicos/ordem-de-producao.service";
import GridOrdemDeProducao from "../../grid-padrao";

export interface ComboOrdemDeProducaoMxpProps<T extends FieldValues>
  extends UseControllerProps<T> {
  titulo: string;
  tituloSeletor?: string;
  requerido?: boolean;
  filtrosNoCliente?: any[] | undefined;
  dataSourceOpcoes?: DataSourceOpcoesBuilder<OrdemDeProducaoGridModel>;
  somenteLeitura?: boolean;
  onSelectionChanged?:
    | ((e: ItemSelectionChangedType) => Promise<void>)
    | (() => void);
}

const ordemDeProducaoService = new OrdemDeProducaoService();

export default function ComboOrdemDeProducaoMxp<T extends FieldValues>(
  props: ComboOrdemDeProducaoMxpProps<T>
) {
  const [dataSourceItem, configuracoesExibicaoEBuscaItem] = useMemo(
    () =>
      ordemDeProducaoService.GetDadosSelectBoxOrdemDeProducao(
        props.dataSourceOpcoes
      ),
    [props.dataSourceOpcoes]
  );

  return (
    <FormSelectBoxLazyMxp
      name={props.name}
      titulo={props.titulo}
      control={props.control}
      requerido={props.requerido}
      configuracoesExibicaoEBusca={configuracoesExibicaoEBuscaItem}
      somenteLeitura={props.somenteLeitura}
      onSelectionChanged={props.onSelectionChanged}
      dataSource={dataSourceItem}
      seletorConfig={{
        modo: "selecaoUnica",
        titulo: props.tituloSeletor ?? "Selecionar ordem de produção",
        componenteGrid: (
          <GridOrdemDeProducao
            filtrosNoServidor={props.dataSourceOpcoes?.camposFiltro}
            filtrosNoCliente={props.filtrosNoCliente}
          />
        ),
      }}
      labelSemDados="Sem dados"
    />
  );
}
