import PaginaMxp from "../../../../../components/layout/pagina-mxp";
import ProvedorMenus from "../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../components/seguranca/requer-permissao";
import { PermissoesApuracaoDoCiap } from "../../../../../models/permissoes/fiscal/apuracao-ciap/permissoes-apuracao-ciap";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import GridApuracaoDoCiap from "../../componentes/grid";

export default function ApuracaoDoCiapPage() {
  return (
    <ProvedorMenus>
      <PaginaMxp
        id="apuracao-ciap-page"
        titulo={NomesTelas.apuracaoDoCiap}
        area={["Fiscal", "Apurações"]}
      >
        <RequerPermissao
          codigoPermissoes={[PermissoesApuracaoDoCiap.Consultar]}
        >
          <GridApuracaoDoCiap />
        </RequerPermissao>
      </PaginaMxp>
    </ProvedorMenus>
  );
}
