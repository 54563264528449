import { FinalidadeModeloRelatorio } from "../../../relatorio/relatorio-personalizado/models/relatorio-personalizado.enums";

export class FinalidadeModeloDeRelatorioTituloHelper {
  private static tipoDescricao: {
    [key in FinalidadeModeloRelatorio]: string;
  } = {
    [FinalidadeModeloRelatorio.OrdemDeProducao]: "ordens de produção",
  };

  public static obterTitulo(finalidade: FinalidadeModeloRelatorio) {
    return this.tipoDescricao[finalidade];
  }
}

export enum ModeloDeRelatorioPermissoes {
  Consultar = "REL000",
  BaixarRelatorioEmExcel = "REL005",
  BaixarRelatorioEmWord = "REL006",
  InserirEditar = "REL007",
  Excluir = "REL008",
}
