import styled from "styled-components";

export const Container = styled.div``;

export const Titulo = styled.span`
  font-weight: bold;
  word-break: break-all;
`;

export const Grupo = styled.div`
  padding-top: 6px;
  display: flex;
  flex-direction: column;
`;

export const Label = styled.span`
  color: blue;
  font-weight: 300;
`;

export const Monoespacado = styled.span`
  word-break: break-all;
`;

export const MonoespacadoComQuebra = styled.span`
  word-break: keep-all;
`;

export const Descricao = styled.span``;

export const Lista = styled.ul`
  padding-left: 20px;
  margin: 0;
`;

export const ItemLista = styled.li``;
