import TabContainer from "../../../../../../../components/layout/tab-container";
import ProvedorMenus from "../../../../../../../components/menus/provedor-menus";
import RequerPermissao from "../../../../../../../components/seguranca/requer-permissao";
import { PermissoesReserva } from "../../../../../../../models/permissoes/estoque/reserva/permissoes-reserva";
import { gridEmbutidaStyle } from "../../../../../../comum/utils/styles/grid";
import GridEmbutidaVinculosDaOrdemDeProducao from "../../../grid-embutida/vinculos-da-ordem-de-producao-grid";

interface OrdemDeProducaoAbaVinculosProps {
  idRegistro: number;
}

export default function OrdemDeProducaoAbaVinculos({
  idRegistro,
}: OrdemDeProducaoAbaVinculosProps) {
  return (
    <TabContainer>
      <ProvedorMenus>
        <RequerPermissao
          codigoPermissoes={[PermissoesReserva.ConsultarReservasEVinculos]}
        >
          <GridEmbutidaVinculosDaOrdemDeProducao
            ordemDeProducaoId={idRegistro}
            style={gridEmbutidaStyle}
          />
        </RequerPermissao>
      </ProvedorMenus>
    </TabContainer>
  );
}
