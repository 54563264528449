import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../components/botoes/botao-salvar-mxp";
import {
  FormNumberBox,
  FormTextBoxSimples,
} from "../../../../../components/formularios";
import SecaoGrupo from "../../../../../components/formularios/secaoGrupo";
import { ItemSelectionChangedType } from "../../../../../components/formularios/selectbox-lazy-mxp";
import FormMxp from "../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import {
  ResultadoAcaoFormulario,
  type IFormularioBase,
} from "../../../../../models/shared/ui/formularios";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  JanelasDeNotificacaoTitulos,
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import { formatarOrdemDeProducaoComItem } from "../../../../../utils/formatadores/formatador-de-identidades";
import { obterFormatStringNumero } from "../../../../../utils/formatadores/formatador-de-numeros";
import { DataSourceOpcoesBuilder } from "../../../../../utils/grid/data-source-factory";
import yupPt from "../../../../../utils/validacao/validacao";
import ComboItemPedidoDeVenda from "../../../../itens/item-pedido-de-venda/componentes/select-box-lazy";
import { type ItemPedidoDeVendaGridModel } from "../../../../itens/item-pedido-de-venda/models/item-pedido-de-venda.api";
import { FiltrosGridItemPedidoVenda } from "../../../../itens/item-pedido-de-venda/utils/item-pedido-de-venda.filtros";
import { type ItemResponse } from "../../../../itens/item/models/item.api";
import { ItemService } from "../../../../itens/item/servicos/item.service";
import ComboPedidoDeVenda from "../../../../vendas/pedidos-de-venda/componentes/select-box-lazy";
import { type PedidoDeVendaGridModel } from "../../../../vendas/pedidos-de-venda/models/pedidos-de-venda.api";
import { FiltrosGridPedidoVenda } from "../../../../vendas/pedidos-de-venda/utils/pedidos-de-venda.filtros";
import { type CadastrarReservaParaOrdemDeProducaoRequest } from "../../models/reserva.api";
import ReservaService from "../../service/reserva.service";
import { valorMaximoQuantidadeReserva } from "../../utils/reserva.constantes";

const serviceReserva = new ReservaService();
const serviceItem = new ItemService();

interface FormIncluirReservaProps extends IFormularioBase {
  idOrdemDeProducao: number;
  idItem: number;
  codigoOrdemDeProducao?: number;
  ordemDeProducaoEhSugerida: boolean;
}

const novoRegistro: Readonly<CadastrarReservaParaOrdemDeProducaoRequest> =
  Object.freeze({
    idPedidoDeVendaDestino: NaN,
    idItemPedidoDeVendaDestino: NaN,
    idOrdemDeProducaoOrigem: NaN,
    quantidade: NaN,
  });

const opcoesDataSourcePv: DataSourceOpcoesBuilder<PedidoDeVendaGridModel> = {
  camposFiltro: FiltrosGridPedidoVenda.pedidoNaoFinalizado,
};

const schema = yupPt.object({
  idPedidoDeVendaDestino: yupPt
    .number()
    .label("Pedido de venda")
    .transform((val, orig) =>
      typeof orig === "string" && orig.trim() === "" ? undefined : val
    )
    .required()
    .moreThan(0),
  idItemPedidoDeVendaDestino: yupPt
    .number()
    .label("Item do pedido de venda")
    .transform((val, orig) =>
      typeof orig === "string" && orig.trim() === "" ? undefined : val
    )
    .required()
    .moreThan(0),
  quantidade: yupPt
    .number()
    .label("Quantidade")
    .transform((val, orig) =>
      typeof orig === "string" && orig.trim() === "" ? undefined : val
    )
    .required()
    .moreThan(0)
    .max(valorMaximoQuantidadeReserva.toNumber()),
});

export default function FormIncluirReservaOrdemProducao({
  idOrdemDeProducao,
  codigoOrdemDeProducao,
  ordemDeProducaoEhSugerida,
  idItem,
  handleCallback,
}: FormIncluirReservaProps) {
  const [isCarregando, setCarregando] = useState(false);
  const [identidadeItem, setIdentidadeItem] = useState<{
    codigo: string;
    descricao?: string | null;
  }>({ codigo: "Carregando..." });
  const [pedidoDeVenda, setPedidoDeVenda] = useState<
    PedidoDeVendaGridModel | undefined
  >(undefined);
  const [itemPedidoDeVenda, setItemPedidoDeVenda] = useState<
    ItemPedidoDeVendaGridModel | undefined
  >(undefined);
  const [quantidadeDisponivel, setQuantidadeDisponivel] = useState(NaN);

  const { control, reset, handleSubmit, resetField, setValue, getValues } =
    useForm<CadastrarReservaParaOrdemDeProducaoRequest>({
      mode: "onSubmit",
      reValidateMode: "onSubmit",
      shouldUseNativeValidation: true,
      defaultValues: novoRegistro,
      resolver: yupResolver(schema),
    });

  const limparTela = useCallback(() => {
    // Não resetamos a descrição do item aqui pq o form pode ser aberto
    // de novo com o mesmo item, aí o useEffect que preenche ele iria não
    // ser chamado.
    setPedidoDeVenda(undefined);
    setItemPedidoDeVenda(undefined);
    setQuantidadeDisponivel(NaN);
    reset(novoRegistro);
  }, [reset]);

  // Limpar a tela quando muda a OP.
  useEffect(() => {
    limparTela();
    carregarDados();

    async function carregarDados() {
      setCarregando(true);
      try {
        if (typeof idItem === "number" && idItem > 0) {
          await carregarDadosItem();
        }

        if (typeof idOrdemDeProducao === "number" && idOrdemDeProducao > 0) {
          await carregarDadosOp();
        }
      } finally {
        setCarregando(false);
      }
    }

    async function carregarDadosItem() {
      try {
        setIdentidadeItem({ codigo: "Carregando..." });
        const response = await serviceItem.ObterPorId<ItemResponse>(idItem);

        if (checarResponse(response)) {
          setIdentidadeItem({
            codigo: response.model.codigo,
            descricao: response.model.descricao,
          });
        }
      } catch (error) {
        tratarErroApi(error);
        setIdentidadeItem({ codigo: "Erro ao carregar descrição" });
      }
    }

    async function carregarDadosOp() {
      try {
        const response =
          await serviceReserva.ObterQuantidadeDisponivelParaOrdemDeProducao(
            idOrdemDeProducao
          );

        if (checarResponse(response)) {
          setQuantidadeDisponivel(response.model);
        }
      } catch (error) {
        tratarErroApi(error);
        setQuantidadeDisponivel(NaN);
      }
    }
  }, [limparTela, idOrdemDeProducao, idItem]);

  const onPedidoDeVendaChanged = useCallback(
    (e: ItemSelectionChangedType<PedidoDeVendaGridModel>) => {
      const pv = e.selectedItem;
      setPedidoDeVenda(pv);

      // Limpar o campo de item se ele não for do pedido selecionado.
      if (pv && itemPedidoDeVenda && itemPedidoDeVenda.pedidoVendaId != pv.id) {
        setItemPedidoDeVenda(undefined);
        resetField("idItemPedidoDeVendaDestino");
      }
    },
    [itemPedidoDeVenda, resetField]
  );

  const onItemPedidoDeVendaChanged = useCallback(
    (e: ItemSelectionChangedType<ItemPedidoDeVendaGridModel>) => {
      const pvit = e.selectedItem;
      setItemPedidoDeVenda(pvit);

      if (pvit) {
        // Definir o pedido de venda se não tiver sido definido.
        if (!pedidoDeVenda || pedidoDeVenda.id != pvit.pedidoVendaId) {
          setPedidoDeVenda(undefined);
          setValue("idPedidoDeVendaDestino", pvit.pedidoVendaId);
        }

        // Definir a quantidade para a máxima que der.
        // Se a quantidade disponível for menor do que a que o item precisa, usamos ela
        // senão, usamos a quantidade que o item precisa mesmo.
        setValue(
          "quantidade",
          // Temos esse or pois o quantidadeDisponivel pode ser NaN e então o Math.min vai
          // retornar NaN, aí como fallback deixamos a quantidade do item.
          Math.min(quantidadeDisponivel, pvit.quantidade) || pvit.quantidade
        );
      }
    },
    [pedidoDeVenda, quantidadeDisponivel, setValue]
  );

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    handleCallback(resultado);
  }

  async function handleSalvar() {
    if (
      ordemDeProducaoEhSugerida &&
      !(await exibirConfirmacao(
        JanelasDeNotificacaoTitulos.Atencao,
        "Esta reserva poderá ser apagada pelo MRP, pois a ordem de produção está sugerida. Deseja continuar?"
      ))
    ) {
      return;
    }

    setCarregando(true);
    const model: CadastrarReservaParaOrdemDeProducaoRequest = {
      ...getValues(),
      idOrdemDeProducaoOrigem: idOrdemDeProducao,
    };
    try {
      const response = await serviceReserva.CadastrarReservaOrdemProducaoAsync(
        model
      );

      checarResponse(response);

      if (response.sucesso) {
        exibirNotificacaoToast({
          mensagem: "Reserva criada com sucesso.",
          tipo: TipoNotificacao.Sucesso,
        });
        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } catch (erro: any) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  const opcoesDataSourcePvit = useMemo(
    (): DataSourceOpcoesBuilder<ItemPedidoDeVendaGridModel> => ({
      camposRetorno: [
        "id",
        "pedidoVendaId",
        "numeroPedidoDeVenda",
        "numeroItemPedidoDeVenda",
        "codigoItem",
        "descricaoItem",
        "apelidoCliente",
        "quantidade",
      ],
      camposFiltro: pedidoDeVenda
        ? [
            ...FiltrosGridItemPedidoVenda.pedidoNaoFinalizado,
            {
              campo: "numeroPedidoDeVenda",
              operador: "=",
              valor: pedidoDeVenda.numero,
            },
          ]
        : FiltrosGridItemPedidoVenda.pedidoNaoFinalizado,
    }),
    [pedidoDeVenda]
  );

  if (!idOrdemDeProducao || idOrdemDeProducao < 1) return <></>;

  return (
    <>
      <ContainerFormMxp>
        <FormMxp carregando={isCarregando}>
          <SecaoGrupo titulo="O quê?">
            <Linha>
              <Coluna md={12}>
                <FormTextBoxSimples
                  titulo="Ordem de produção"
                  valor={formatarOrdemDeProducaoComItem(
                    codigoOrdemDeProducao,
                    identidadeItem
                  )}
                  somenteLeitura={true}
                />
              </Coluna>
            </Linha>
          </SecaoGrupo>
          <SecaoGrupo titulo="Para">
            <Linha>
              <Coluna md={4}>
                <ComboPedidoDeVenda
                  name="idPedidoDeVendaDestino"
                  titulo="Pedido de venda"
                  tituloSeletor="Selecionar pedido de venda"
                  control={control}
                  requerido
                  dataSourceOpcoes={opcoesDataSourcePv}
                  onSelectionChanged={onPedidoDeVendaChanged}
                />
              </Coluna>
              <Coluna md={8}>
                <ComboItemPedidoDeVenda
                  name="idItemPedidoDeVendaDestino"
                  titulo="Item do pedido de venda"
                  tituloSeletor="Selecionar item do pedido de venda"
                  control={control}
                  requerido
                  dataSourceOpcoes={opcoesDataSourcePvit}
                  onSelectionChanged={onItemPedidoDeVendaChanged}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={4}>
                <FormNumberBox
                  name="quantidade"
                  titulo="Quantidade"
                  toolTip="Quantidade do item a ser reservada"
                  control={control}
                  minimo={0}
                  maximo={valorMaximoQuantidadeReserva.toNumber()}
                  formato={obterFormatStringNumero(5)}
                  requerido
                />
              </Coluna>
            </Linha>
          </SecaoGrupo>
        </FormMxp>
        <ToolbarMxp>
          <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ContainerFormMxp>
    </>
  );
}
