import { useMemo } from "react";
import { FieldValues, UseControllerProps } from "react-hook-form";
import { FormSelectBoxLazyMxp } from "../../../../../components/formularios";
import { ItemSelectionChangedType } from "../../../../../components/formularios/selectbox-lazy-mxp";
import { DataSourceOpcoesBuilder } from "../../../../../utils/grid/data-source-factory";
import { EmpresaGridModel } from "../../models/empresa-grid";
import EmpresaServico from "../../servicos/empresa.service";
import EmpresaFiltrosHelpers from "../../utils/data-source/EmpresaFiltrosHelpers";
import GridEmpresa from "../grid";

interface ComboEmpresaMxpProps<T extends FieldValues>
  extends UseControllerProps<T> {
  titulo: string;
  tituloSeletor?: string;
  requerido?: boolean;
  somenteLeitura?: boolean;
  filtrosNoCliente?: any[] | undefined;
  dataSourceOpcoes?: DataSourceOpcoesBuilder<EmpresaGridModel>;
  onSelectionChanged?:
    | ((e: ItemSelectionChangedType) => Promise<void>)
    | (() => void);
}

const service = new EmpresaServico();

export function ComboClienteEmpresaMxp<T extends FieldValues>(
  props: ComboEmpresaMxpProps<T>
) {
  const dataSourceOpcoes = useMemo(() => {
    return {
      ...props.dataSourceOpcoes,
      camposFiltro: [
        ...(props.dataSourceOpcoes?.camposFiltro ??
          EmpresaFiltrosHelpers.FiltrarAtivos),
        ...EmpresaFiltrosHelpers.FiltrarClientes,
      ],
    } as DataSourceOpcoesBuilder<EmpresaGridModel>;
  }, [props.dataSourceOpcoes]);

  return <ComboEmpresaMxp {...props} dataSourceOpcoes={dataSourceOpcoes} />;
}

export function ComboPropriaEmpresaMxp<T extends FieldValues>(
  props: ComboEmpresaMxpProps<T>
) {
  const dataSourceOpcoes = useMemo(() => {
    return {
      ...props.dataSourceOpcoes,
      camposFiltro: [
        ...(props.dataSourceOpcoes?.camposFiltro ??
          EmpresaFiltrosHelpers.FiltrarAtivos),
        ...EmpresaFiltrosHelpers.FiltrarProprias,
      ],
    } as DataSourceOpcoesBuilder<EmpresaGridModel>;
  }, [props.dataSourceOpcoes]);

  return <ComboEmpresaMxp {...props} dataSourceOpcoes={dataSourceOpcoes} />;
}

export default function ComboEmpresaMxp<T extends FieldValues>(
  props: ComboEmpresaMxpProps<T>
) {
  const [dataSource, configuracoesExibicaoEBusca] = useMemo(
    () => service.GetDadosSelectBoxItem(props.dataSourceOpcoes),
    [props.dataSourceOpcoes]
  );

  return (
    <FormSelectBoxLazyMxp
      name={props.name}
      titulo={props.titulo}
      control={props.control}
      requerido={props.requerido}
      somenteLeitura={props.somenteLeitura}
      configuracoesExibicaoEBusca={configuracoesExibicaoEBusca}
      onSelectionChanged={props.onSelectionChanged}
      dataSource={dataSource}
      seletorConfig={{
        modo: "selecaoUnica",
        titulo: props.tituloSeletor ?? "Selecionar empresa",
        componenteGrid: (
          <GridEmpresa
            filtrosNoServidor={
              props.dataSourceOpcoes?.camposFiltro ||
              EmpresaFiltrosHelpers.FiltrarAtivos
            }
            filtrosNoCliente={props.filtrosNoCliente}
          />
        ),
      }}
      labelSemDados="Sem dados"
    />
  );
}
