import styled from "styled-components";

export const Container = styled.div`
  width: fit-content;
  margin: -5px;
`;

interface LinkButtonProps {
  isDisabled?: boolean;
}

export const LinkButton = styled.a<LinkButtonProps>`
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
  vertical-align: middle;
  border-radius: 4px;
  display: inline-flex;
  padding: 1px;

  &:not(:last-of-type) {
    margin-right: 2px;
  }

  &:hover {
    ${({ isDisabled }) =>
      !isDisabled &&
      `
        background: lightgray;
      `}
  }

  /* Estilo quando o botão estiver desabilitado */
  ${({ isDisabled }) =>
    isDisabled &&
    `
      opacity: 0.6;
      cursor: default;
    `}
`;
