import { PermissoesOrdemDeProducao } from "../../../../../models/permissoes/producao/ordem-de-producao/OrdemDeProducaoPermissoes";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import { renderToStringClient } from "../../../../../utils/react/react-utils";

const MensagemConfirmarRoteiro = () => {
  return (
    <div>
      Tem certeza de que deseja atualizar o roteiro da ordem de produção? <br />
      Ao atualizar o roteiro, os insumos e as operações serão excluídos e{" "}
      recriados. Caso você tenha inserido ou editado insumos e operações{" "}
      manualmente, essas informações serão perdidas.
    </div>
  );
};

export const confirmarRoteiro = async () => {
  if (
    verificaComNotificacaoSeUsuarioPossuiPermissoes([
      PermissoesOrdemDeProducao.AtualizarRoteiro,
    ])
  ) {
    const confirma = await exibirConfirmacao(
      "Confirmar atualização do roteiro",
      renderToStringClient(<MensagemConfirmarRoteiro />)
    );

    return confirma;
  }

  return false;
};
