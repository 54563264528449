import Decimal from "decimal.js";
import AuditavelDTO from "../../../../models/api/comum/auditavel-dto";
import AuditavelGridModel from "../../../../models/api/comum/auditavel-grid";
import { OperacaoEstado } from "./operacao-de-ordem-de-producao-enums";

export interface OperacaoDaOrdemDeProducaoGridModel extends AuditavelGridModel {
  ordemDeProducaoId: number;
  numeroOrdemDeProducao: number | null;
  numero: number | null;
  descricao: string | null;
  codigoItem: string | null;
  descricaoItem: string | null;
  codigoCentroDeTrabalho: string;
  codigoEstacaoDeTrabalho: string | null;
  codigoProximaOperacao: number;
  leadTimeEmDias: number;
  folgaEmDias: number;
  tempoDePreparacaoEmHoras: number | null;
  quantidadeDaOperacao: number;
  quantidadeRealizada: number | null;
  percentualRealizado: number | null;
  tempoTotalEmHoras: number;
  tempoRealizadoEmHoras: number | null;
  percentualRealizadoEmHoras: number | null;
  custoHora: number | null;
  inicioPrevisto: Date | null;
  fimPrevisto: Date | null;
  inicioRealizado: Date | null;
  fimRealizado: Date | null;
  estado: string | null;
  baixaDosInsumos: string | null;
  quantidadeDaOrdemDeProducao: number;
  estadoDaOrdemDeProducao: string | null;
  custoRealizado: number;
  minhaEmpresaApelido: string | null;
}

export interface OperacaoDaOrdemDeProducaoRequestDTO {
  id: number;
  estado: OperacaoEstado;
  numeroDaOperacao: number;
  idOperacaoDoItem: number | null;
  idOperacaoPosterior: number | null;
  idCentroDeTrabalho: number | null;
  idEstacaoDeTrabalho: number | null;
  estacaoDeTrabalhoEstaFixa: boolean;
  estacaoDeTrabalhoOrdemEmCapacidadeFinita: number | null;
  descricao: string;
  tempoDePreparacaoPrevistoEmHoras: number | null;
  preparacaoPrevistaNaCapacidadeFinitaData: Date | null;
  tempoPrevistoEmHoras: number;
  tempoPrevistoEstaFixo: boolean;
  folgaPrevistaEmDias: number;
  leadTimePrevistoEmDias: number;
  inicioPrevistoData: Date | null;
  fimPrevistoData: Date | null;
  quantidadePrevista: number;
  observacoes: string | null;
  idOrdemDeProducaoOuManutencao: number | null;
  idItemPaiDaOperacao: number | null;
  tempoRealizadoEmHoras: number | null;
  inicioRealizadoData: Date | null;
  fimRealizadoData: Date | null;
  quantidadeRealizada: number | null;
}

export interface OperacaoDaOrdemDeProducaoResponseDTO extends AuditavelDTO {
  id: number;
  estado: OperacaoEstado;
  numeroDaOperacao: number;
  idOperacaoDoItem: number | null;
  idOperacaoPosterior: number | null;
  idCentroDeTrabalho: number | null;
  idEstacaoDeTrabalho: number | null;
  estacaoDeTrabalhoEstaFixa: boolean;
  estacaoDeTrabalhoOrdemEmCapacidadeFinita: number | null;
  descricao: string;
  tempoDePreparacaoPrevistoEmHoras: number | null;
  preparacaoPrevistaNaCapacidadeFinitaData: Date | null;
  tempoPrevistoEmHoras: number;
  tempoPrevistoEstaFixo: boolean;
  folgaPrevistaEmDias: number;
  leadTimePrevistoEmDias: number;
  inicioPrevistoData: Date | null;
  fimPrevistoData: Date | null;
  quantidadePrevista: number;
  observacoes: string | null;
  idOrdemDeProducaoOuManutencao: number | null;
  idItemPaiDaOperacao: number | null;
  tempoRealizadoEmHoras: number | null;
  inicioRealizadoData: Date | null;
  fimRealizadoData: Date | null;
  quantidadeRealizada: number | null;
  quantidadeRealizadaEmPercentual: number | null;
  custoRealizado: number;
  folgaPrevistaInicioData: Date | null;
}

export const tamanhoMinimo: Decimal = new Decimal(0);
export const tamanhoMaximo: Decimal = new Decimal(9999999999);
export const tamanhoMaximoDescricao: Decimal = new Decimal(255);
export const tamanhoMaximoLeadTimeEFolgaPrevistaEmDias: Decimal = new Decimal(
  999
);
export const tamanhoMaximoTempoPrevistoEmHoras: Decimal = new Decimal(
  9_999_999_999.9999
);
