import Decimal from "decimal.js";
import AuditavelDTO from "../../../../models/api/comum/auditavel-dto";
import AuditavelGridModel from "../../../../models/api/comum/auditavel-grid";
import { CadastrarEmMassaExcelBase } from "../../../../models/api/comum/cadastrar-varios";
import { IOperacaoEmMassaResponse } from "../../../comum/utils/accordions/gerador-de-mensagens";
import { SelecionarEstoqueDestinoRequest } from "../../../estoque/estoque-movimentacao/componentes/partes/selecionar-estoque-destino/selecionar-estoque-destino";
import { EstoqueMovimentacaoTipo } from "../../../estoque/estoque-movimentacao/models/estoque-movimentacao.enums";
import {
  RastreabilidadePorLoteRequest,
  RastreabilidadePorNumeroDeSerieRequest,
} from "../../../estoque/lote/componentes/formulario-cadastro-de-rastreabilidade";
import { EstocagemTipo } from "../../../itens/item/models/item.enums";
import { ArquivoBaseModel } from "../../../sistema/arquivo/models/arquivo.api";
import {
  EstocagemDoProduto,
  OrdemDeProducaoEstado,
  OrdemDeProducaoTipoRelacionamento,
} from "../utils/enums/ordem-de-producao-enums";
import { RetiradaDeMaterialOuAparaValorTipo } from "../utils/enums/retirada-de-material-ou-apara.enums";

export interface OrdemDeProducaoGridModel extends AuditavelGridModel {
  id: number;
  numero?: number | null;
  quantidade: number;
  quantidadeConcluida: number;
  quantidadeAConcluir: number;
  necessidadeData: Date;
  itemId?: number;
  itemCodigo?: string | null;
  itemDescricao?: string | null;
  itemDescricaoComplementar?: string | null;
  itemUnidadePrincipalCodigo?: string | null;
  unidadePrincipalPossuiQuantidadeInteira: boolean;
  estado: OrdemDeProducaoEstado;
  estadoDecodificado: string;
  grupoCodigo?: string | null;
  estadoConfiguravel?: string | null;
  grupoDescricao?: string | null;
  itemDesenho: string;
  itemDesenhoRevisao?: string | null;
  quantidadeDePerdaPrevista: number;
  quantidadeBruta: number;
  quantidadeNaUnidadeDeVenda: number;
  itemUnidadeDeVendaCodigo?: string | null;
  itemPesoLiquidoUnitario?: number | null;
  pesoLiquidoTotal?: number | null;
  itemPesoBrutoUnitario?: number | null;
  pesoBrutoTotal?: number | null;
  emissaoData?: Date | null;
  emitidoPorApelido?: string | null;
  quantidadeSobra: number;
  origemDecodificada: string;
  inicioPrevistoOuRealizadoData?: Date | null;
  fimPrevistoOuRealizadoData?: Date | null;
  tempoRealizadoEmHoras?: number | null;
  observacoes?: string | null;
  tempoPrevistoEmHoras?: number | null;
  quantidadeOuTempoRealizadoEmPercentual: number;
  quantidadeConcluidaEmPercentual: number;
  maoDeObraCusto: number;
  insumosDisponibilidadeDecodificada: string;
  insumosBaixasEstadoDecodificado: string;
  itemPontoDeRessuprimento: number;
  estocagemDoProduto: EstocagemTipo;
  quantidadeDeItemEmEstoqueProprio: number;
  reservas: RelacionamentoOrdemDeProducaoGrid[];
  vinculos: RelacionamentoOrdemDeProducaoGrid[];
  reservasAtendidas: RelacionamentoOrdemDeProducaoGrid[];
}

export interface RelacionamentoOrdemDeProducaoGrid {
  id: number;
  tipo: OrdemDeProducaoTipoRelacionamento;

  idPedidoDeVenda: number;
  numeroPedidoDeVenda: number | null;

  idItemDoPedidoDeVenda: number;
  numeroItemDoPedidoDeVenda: number | null;

  idEmpresa: number;
  apelidoEmpresa: string;

  relacionamentoDecodificado: string;
  quantidade: number | null;
}

export interface DadosFiltroRelacionamentos {
  tipo: OrdemDeProducaoTipoRelacionamento;

  idEmpresa: number;
  apelidoEmpresa: string;

  idPedidoDeVenda: number;
  numeroPedidoDeVenda: number | null;

  idItemDoPedidoDeVenda: number;
  numeroItemDoPedidoDeVenda: number | null;

  descricao: string;
}

export interface OrdemDeProducaoRequest {
  id: number;
  numero: number;
  itemId: number | null;
  quantidade: number;
  fixarQuantidade: boolean;
  quantidadeDePerdaPrevista?: number | null;
  quantidadeBruta?: number | null;
  necessidadeData: Date;
  fixarNecessidadeData: boolean;
  estado: OrdemDeProducaoEstado;
  estocagemPor?: EstocagemDoProduto;
  estadoConfiguravelId?: number | null;
  observacoes: string;
  arquivos: Array<ArquivoBaseModel>;
}

export interface OrdemDeProducaoCadastrarVariosExcelRequest
  extends CadastrarEmMassaExcelBase {
  codigoItem: string;
  quantidade: number;
  necessidadeData: Date;
  quantidadeDePerdaPrevista: number | null;
  observacoes: string | null;
}

export interface OrdemDeProducaoBaixarVariasExcelRequest
  extends CadastrarEmMassaExcelBase {
  numeroOrdemDeProducao: number;
  codigoInsumo: string;
  numeroOperacaoDaOrdemDeProducao: number;
  loteOuNumeroDeSerie: string | null;
  apelidoContaDeEstoque: string | null;
  codigoEnderecoDeEstoque: string | null;
  codigoQualidade: string | null;
  apelidoDonoEstoque: string | null;
  quantidade: number | null;
  baixarQuantidadeMaiorQuePrevisto: boolean;
  numeroDeSerieDestino: string | null;
  manterEmEstoqueSeparado: boolean;
  forcarConclusaoSeQuantidadeMenorQuePrevista: boolean;
}

export interface OrdemDeProducaoBaixarVariasExcelRequest
  extends CadastrarEmMassaExcelBase {
  numeroOrdemDeProducao: number;
  codigoInsumo: string;
  numeroOperacaoDaOrdemDeProducao: number;
  loteOuNumeroDeSerie: string | null;
  apelidoContaDeEstoque: string | null;
  codigoEnderecoDeEstoque: string | null;
  codigoQualidade: string | null;
  apelidoDonoEstoque: string | null;
  quantidade: number | null;
  baixarQuantidadeMaiorQuePrevisto: boolean;
  numeroDeSerieDestino: string | null;
  manterEmEstoqueSeparado: boolean;
  forcarConclusaoSeQuantidadeMenorQuePrevista: boolean;
}

export interface OrdemDeProducaoResponse extends AuditavelDTO {
  id: number;
  numero: number;
  itemId: number | null;
  quantidade: number;
  fixarQuantidade: boolean;
  quantidadeDePerdaPrevista?: number | null;
  quantidadeBruta?: number | null;
  necessidadeData: Date;
  fixarNecessidadeData: boolean;
  estado: OrdemDeProducaoEstado;
  estocagemPor?: EstocagemDoProduto;
  estadoConfiguravelId?: number | null;
  observacoes: string;
  itemDescricao: string;
  arquivos: Array<ArquivoBaseModel>;
}

export const quantidadeDePerdaPrevistaMinima: Decimal = new Decimal(0);
export const quantidadeDePerdaPrevistaMaxima: Decimal = new Decimal(
  9_999_999_999.99999
);

export interface ConcluirOrdemDeProducaoRequest
  extends SelecionarEstoqueDestinoRequest,
    RastreabilidadePorLoteRequest,
    RastreabilidadePorNumeroDeSerieRequest {
  idOrdemDeProducao: number;
  itemId: number;
  quantidade: number;
  itemEstocagemTipo: EstocagemTipo;
  itemUsaQuantidadeInteira: boolean;
  fecharOp: boolean;
  destino: EstoqueMovimentacaoTipo;
  forcarConclusao: boolean;
  itemUtilizaPrefixo?: boolean;
  rastreabilidadeLoteId?: number;
  codigoOrdemDeProducao: string;
  codigoItem: string;
}

export interface RetiradaDeMaterialRequest
  extends SelecionarEstoqueDestinoRequest,
    RastreabilidadePorLoteRequest {
  id: number;
  idOrdemDeProducao: number | null;
  operacaoDeOrdemDeProducaoId: number | null;
  quantidade: number;
  valorUnitario: number;
  idInsumoOrigemValor?: number | null;
  valorUnitarioTipoOrigem: RetiradaDeMaterialOuAparaValorTipo;
  itemId: number | null;
}

export interface RetiradaDeAparaRequest
  extends SelecionarEstoqueDestinoRequest,
    RastreabilidadePorLoteRequest {
  id: number;
  idOrdemDeProducao: number | null;
  operacaoDeOrdemDeProducaoId: number | null;
  quantidade: number;
  valorUnitario: number;
  idInsumoOrigemValor?: number | null;
  valorUnitarioTipoOrigem: RetiradaDeMaterialOuAparaValorTipo;
  itemId: number | null;
}

export interface DadosItemDeRetiradaDeMaterial {
  operacaoDeOrdemDeProducaoId: number | null;
  idContaContabilDestino: number | null;
}

export interface DadosItemDeRetiradaDeApara {
  operacaoDeOrdemDeProducaoId: number | null;
  idContaContabilDestino: number | null;
}

export interface AcaoBaseOrdemDeProducaoResponse
  extends IOperacaoEmMassaResponse {
  ordemDeProducaoId: number;
  ordemDeProducaoNumero: string;
}

export type SuspenderOrdemDeProducaoResponse = AcaoBaseOrdemDeProducaoResponse;

export type ConfirmarOrdemDeProducaoResponse = AcaoBaseOrdemDeProducaoResponse;

export type CancelarOrdemDeProducaoResponse = AcaoBaseOrdemDeProducaoResponse;

export type ExcluirOrdensDeProducaoResponse = AcaoBaseOrdemDeProducaoResponse;
