import { NivelHierarquicoTagViewModel } from "../../models/relatorio-personalizado.viewmodel";
import {
  Container,
  Descricao,
  Grupo,
  ItemLista,
  Label,
  Lista,
  Monoespacado,
  MonoespacadoComQuebra,
  Titulo,
} from "./styles";

interface ToolTipTagProps {
  tag: string;
  propriedade: NivelHierarquicoTagViewModel;
}

export default function ToolTipTag({ tag, propriedade }: ToolTipTagProps) {
  const tipoDado = propriedade.objetoComplexo
    ? propriedade.nomeTipoAmigavel
    : propriedade.tipoDados;

  const descricaoTipo = propriedade.colecao ? `Lista de ${tipoDado}` : tipoDado;

  const haItensEnum = Object.keys(propriedade.itensEnum).length > 0;

  return (
    <Container>
      <Titulo>{propriedade.nomeAmigavel}</Titulo>
      <br />
      <Descricao>{propriedade.descricao}</Descricao>
      <br />
      <Grupo>
        <Label>Tipo de dado:</Label>
        <Monoespacado>{descricaoTipo}</Monoespacado>
      </Grupo>
      {haItensEnum && (
        <Grupo>
          <Label>Itens do enumerador:</Label>
          <Lista>
            {Object.keys(propriedade.itensEnum).map((k) => (
              <ItemLista key={k}>
                <MonoespacadoComQuebra>
                  <strong>{k.toUpperCase()}: </strong>
                  {propriedade.itensEnum[k]}
                </MonoespacadoComQuebra>
              </ItemLista>
            ))}
          </Lista>
        </Grupo>
      )}
      {propriedade.modificadores.length > 0 && (
        <Grupo>
          <Label>Modificadores:</Label>
          <Lista>
            {propriedade.modificadores.map((m) => (
              <ItemLista key={m}>
                <Monoespacado>{m}</Monoespacado>
              </ItemLista>
            ))}
          </Lista>
        </Grupo>
      )}
      {!propriedade.objetoComplexo && (
        <Grupo>
          <Label>Tag:</Label>
          <Monoespacado>{tag}</Monoespacado>
        </Grupo>
      )}
    </Container>
  );
}
