import DataGrid, { Column, DataGridRef } from "devextreme-react/cjs/data-grid";
import { ReactNode, useCallback, useContext, useMemo, useRef } from "react";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import BotaoEditarColunaAcaoGridMxp from "../../../../../components/grid-mxp/grid-mxp-botao-editar";
import BotaoExcluirColunaAcaoGridMxp from "../../../../../components/grid-mxp/grid-mxp-botao-excluir";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import MenuMxp from "../../../../../components/layout/menu-mxp";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import { LinkButton } from "../../../../../components/templates-celulas-grid/celula-controle-edicao-mxp/styles";
import { useControlarFormDeEdicao } from "../../../../../hooks/form.hooks";
import { useMenuDeContextosGrid } from "../../../../../hooks/menus.hooks";
import { useAppSelector } from "../../../../../hooks/store.hooks";
import { ResultadoAcaoFormulario } from "../../../../../models/shared/ui/formularios";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import {
  checarResponse,
  checarResponseBaixarArquivo,
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesModais from "../../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import NormalizaTituloModal from "../../../../../utils/common/normaliza-titulo";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { ItemContextMenuMxp } from "../../../../../utils/context-menu/context-menu-utils";
import {
  GestorEventoClickSimples,
  GestorEventoClickUnicaLinha,
} from "../../../../../utils/context-menu/gestor-evento-click";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import { StringsComum } from "../../../../comum/strings";
import RelatorioPersonalizadoContexto from "../../../../relatorio/relatorio-personalizado/contexts/relatorio-personalizado.context";
import { FinalidadeModeloRelatorio } from "../../../../relatorio/relatorio-personalizado/models/relatorio-personalizado.enums";
import { ModeloDeRelatorioGridModel } from "../../models/modelo-de-relatorio.api";
import { ModeloDeRelatorioPermissoes } from "../../models/modelo-de-relatorio.enums";
import ModeloDeRelatorioServico from "../../servicos/modelo-de-relatorio.servico";
import { StringsModeloDeRelatorio } from "../../strings";
import FormModeloDeRelatorio from "../formulario";

const service = new ModeloDeRelatorioServico();

const nameOfGridHandler = criarNameof<ModeloDeRelatorioGridModel>();

interface GridModeloDeRelatorioProps {
  finalidade: FinalidadeModeloRelatorio;
}

export default function GridModeloDeRelatorio(
  props: GridModeloDeRelatorioProps
) {
  const gridRef = useRef<DataGridRef>(null);
  const dataSource = service.ObterDataSourceParaGrid(props.finalidade);
  const {
    idRegistroEmEdicao,
    setIdRegistroEmEdicao,
    modalVisivel,
    encerrarEdicao,
  } = useControlarFormDeEdicao(NaN);

  const { carregarMenu } = useContext(RelatorioPersonalizadoContexto);

  const { definirModalDicionarioTagsVisivel } = useContext(
    RelatorioPersonalizadoContexto
  );

  const handleAbrirDicionarioTags = useCallback(() => {
    definirModalDicionarioTagsVisivel(true);
  }, []);

  const assinanteId = useAppSelector(
    (state) => state.sessao.dadosSessao!.assinanteId
  );

  const handleNovoRegistro = useCallback(() => {
    if (
      verificaComNotificacaoSeUsuarioPossuiPermissoes([
        ModeloDeRelatorioPermissoes.InserirEditar,
      ])
    ) {
      setIdRegistroEmEdicao(0);
    }
  }, []);

  const handleEditarRegistro = useCallback(
    (registro: ModeloDeRelatorioGridModel) => {
      if (
        verificaComNotificacaoSeUsuarioPossuiPermissoes([
          ModeloDeRelatorioPermissoes.InserirEditar,
        ])
      ) {
        setIdRegistroEmEdicao(registro.id);
      }
    },
    []
  );

  const handleFavoritar = useCallback(
    async (registro: ModeloDeRelatorioGridModel) => {
      try {
        const response = await service.Favoritar({ id: registro.id });

        const sucesso = await checarResponse(response);

        if (sucesso) {
          gridController.atualizar();
          exibirNotificacaoToast({
            mensagem: response.mensagem,
            tipo: TipoNotificacao.Sucesso,
          });
        }
      } catch (erro) {
        tratarErroApi(erro);
      }
    },
    []
  );

  const handleRestaurarFavorito = useCallback(async () => {
    try {
      const response = await service.RestaurarFavoritoPadrao({
        finalidade: props.finalidade,
      });

      const sucesso = await checarResponse(response);

      if (sucesso) {
        gridController.atualizar();
        exibirNotificacaoToast({
          mensagem: response.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
      }
    } catch (erro) {
      tratarErroApi(erro);
    }
  }, []);

  const handleDownload = useCallback(
    async (registro: ModeloDeRelatorioGridModel) => {
      try {
        const { response, nomeArquivo, extensao } =
          await service.RealizarDownload(registro.id);

        const sucesso = await checarResponseBaixarArquivo(
          response,
          nomeArquivo ?? NomesTelas.modeloDeRelatorio,
          extensao
        );

        if (sucesso) {
          exibirNotificacaoToast({
            mensagem:
              StringsModeloDeRelatorio.mensagemDownloadDeModeloComSucesso,
            tipo: TipoNotificacao.Sucesso,
          });
        }
      } catch (erro) {
        tratarErroApi(erro);
      }
    },
    []
  );

  const handleExcluirRegistro = useCallback(
    async (registro: ModeloDeRelatorioGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          ModeloDeRelatorioPermissoes.Excluir,
        ])
      ) {
        return;
      }

      const excluir = await exibirConfirmacao(
        StringsComum.exclusaoTitulo,
        `Tem certeza que deseja excluir o registro ${registro.nomeOriginalSemExtensao}?`
      );

      if (excluir) {
        try {
          const resposta = await service.Excluir(registro.id);

          if (resposta) {
            await carregarMenu();
            checarResponseExibeMensagemExclusaoDeSucesso(resposta);
            handleAtualizarGrid();
          }
        } catch (erro) {
          tratarErroApi(erro);
        }
      }
    },
    []
  );

  const gridController = new GridController<ModeloDeRelatorioGridModel>(() =>
    gridRef.current?.instance()
  );

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  function gerarBotoesAdicionais(
    registro: ModeloDeRelatorioGridModel
  ): ReactNode[] {
    const botoesAdicionais: ReactNode[] = [];

    const botaoDesabilitado = registro.assinanteId == 2 && assinanteId != 2;

    botoesAdicionais.push(
      <BotaoEditarColunaAcaoGridMxp
        key={`btn-editar-${registro.id}`}
        onClick={() => handleEditarRegistro(registro)}
        tooltipCustom={
          botaoDesabilitado
            ? StringsModeloDeRelatorio.tooltipBotaoEditarModeloPadrao
            : undefined
        }
        desabilitado={botaoDesabilitado}
      />
    );

    botoesAdicionais.push(
      <BotaoExcluirColunaAcaoGridMxp
        key={`btn-excluir-${registro.id}`}
        onClick={() => handleExcluirRegistro(registro)}
        tooltipCustom={
          botaoDesabilitado
            ? StringsModeloDeRelatorio.tooltipBotaoExcluirModeloPadrao
            : undefined
        }
        desabilitado={botaoDesabilitado}
      />
    );

    botoesAdicionais.push(
      <LinkButton
        key={`btn-download-${registro.id}`}
        onClick={() => handleDownload(registro)}
        title={StringsModeloDeRelatorio.tooltipBotaoBaixarModelo}
      >
        <i className="ic-material-symbols-outlined ic-download icone-linha-grid"></i>
      </LinkButton>
    );

    return botoesAdicionais;
  }

  const menusDeContexto: ItemContextMenuMxp[] = [
    {
      text: "Ações",
      icon: "ic-material-symbols-outlined ic-vertical",
      hint: "Menu com opções de ação",
      exibirNoMenuPrincipal: true,
      items: [
        {
          text: "Definir como favorito",
          hint: 'O modelo de relatório favorito será utilizado ao clicar no ícone de impressão ou no botão "Imprimir". Somente um modelo pode ser selecionado como favorito.',
          icon: "ic-material-symbols-outlined ic-star",
          exibirNaLinhaDaGrid: "menuDeContexto",
          exibirNoMenuPrincipal: true,
          gestorEventoClick: new GestorEventoClickUnicaLinha(
            handleFavoritar,
            () => gridController
          ),
        },
        {
          text: "Restaurar o favorito",
          hint: 'Restaura o modelo favorito, utilizado no ícone de impressão e no botão "Imprimir" para o padrão do sistema.',
          icon: "ic-material-symbols-outlined ic-undo",
          exibirNaLinhaDaGrid: "menuDeContexto",
          exibirNoMenuPrincipal: true,
          gestorEventoClick: new GestorEventoClickSimples(
            handleRestaurarFavorito
          ),
        },
      ],
    },
    {
      text: "Consultar",
      icon: "ic-material-symbols-outlined ic-manage-search",
      hint: "Menu com opções de consultar",
      exibirNoMenuPrincipal: true,
      items: [
        {
          text: "Dicionário de tags",
          icon: "ic-material-symbols-outlined ic-data-array",
          gestorEventoClick: new GestorEventoClickSimples(
            handleAbrirDicionarioTags
          ),
        },
      ],
    },
    {
      text: "Definir como favorito",
      hint: 'O modelo de relatório favorito será utilizado ao clicar no ícone de impressão ou no botão "Imprimir". Somente um modelo pode ser selecionado como favorito.',
      icon: "ic-material-symbols-outlined ic-star",
      exibirNaLinhaDaGrid: "menuDeContexto",
      gestorEventoClick: new GestorEventoClickUnicaLinha(
        handleFavoritar,
        () => gridController
      ),
    },
    {
      text: "Restaurar o favorito",
      hint: 'Restaura o modelo favorito, utilizado no ícone de impressão e no botão "Imprimir" para o padrão do sistema.',
      icon: "ic-material-symbols-outlined ic-undo",
      exibirNaLinhaDaGrid: "menuDeContexto",
      gestorEventoClick: new GestorEventoClickSimples(handleRestaurarFavorito),
    },
  ];

  useMenuDeContextosGrid(menusDeContexto);

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "modelo-de-relatorio",
      () => gridRef.current?.instance(),
      false
    )
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.modeloDeRelatorio)
      .definirBotaoNovo(handleNovoRegistro)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .definirDuploCliqueLinha(handleEditarRegistro)
      .definirStyles({ height: "70vh" })
      .definirMenuDeContexto(menusDeContexto)
      .build();
  }, []);

  const handleCallbackFormulario = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      encerrarEdicao();

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }
    },
    []
  );

  const handleFecharModal = useCallback(() => {
    encerrarEdicao();
  }, []);

  return (
    <>
      <MenuMxp />
      <ProvedorAjuda id="tooltips-grid-modelo-de-relatorio">
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
          {GridColunaAcoes<ModeloDeRelatorioGridModel>({
            gerarBotoesAdicionais: gerarBotoesAdicionais,
          })}
          <Column
            key={nameOfGridHandler("nomeOriginalSemExtensao")}
            dataField={nameOfGridHandler("nomeOriginalSemExtensao")}
            {...obterConfiguracaoColuna("stringXG")}
            caption={StringsComum.nome}
            cellRender={({ data }) => {
              const dados = data as ModeloDeRelatorioGridModel;
              return (
                <>
                  {dados.nomeOriginalSemExtensao}
                  {dados.favorito && (
                    <i
                      className="ic-material-symbols-outlined ic-star"
                      style={{
                        fontSize: "9pt",
                        paddingLeft: "2px",
                        paddingTop: "2px",
                      }}
                    ></i>
                  )}
                </>
              );
            }}
          />
          <Column
            key={nameOfGridHandler("descricao")}
            dataField={nameOfGridHandler("descricao")}
            {...obterConfiguracaoColuna("stringXG")}
            caption={StringsComum.descricao}
          />
          <Column
            key={nameOfGridHandler("extensao")}
            dataField={nameOfGridHandler("extensao")}
            {...obterConfiguracaoColuna("stringP")}
            caption="Extensão"
          />
          <Column
            key={nameOfGridHandler("finalidadeDecodificada")}
            dataField={nameOfGridHandler("finalidadeDecodificada")}
            {...obterConfiguracaoColuna("stringMG")}
            caption="Finalidade"
            visible={false}
          />
          {GetColunasDeAuditoria()}
        </DataGrid>

        <ModalMxp
          titulo={NormalizaTituloModal.Normalizar(
            idRegistroEmEdicao,
            NomesModais.modeloDeRelatorio
          )}
          visivel={modalVisivel}
          handleFechar={handleFecharModal}
          largura={"max(50vw, 800px)"}
        >
          <FormModeloDeRelatorio
            finalidade={props.finalidade}
            idRegistroEmEdicao={idRegistroEmEdicao}
            handleCallback={handleCallbackFormulario}
          />
        </ModalMxp>
      </ProvedorAjuda>
    </>
  );
}
