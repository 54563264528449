import { PermissoesOrdemDeProducao } from "../../../../../models/permissoes/producao/ordem-de-producao/OrdemDeProducaoPermissoes";
import { tratarErroApi } from "../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  JanelasDeNotificacaoTitulos,
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { exibirAlerta } from "../../../../../utils/dialogos";
import { OrdemDeProducaoService } from "../../servicos/ordem-de-producao.service";
import { OrdemDeProducaoEstado } from "../enums/ordem-de-producao-enums";

export const verificacoesParaRetiradaDeMaterial = (
  ordemDeProducaoEstado: OrdemDeProducaoEstado | undefined
): boolean => {
  if (
    !verificaComNotificacaoSeUsuarioPossuiPermissoes([
      PermissoesOrdemDeProducao.RetirarMaterial,
    ])
  ) {
    return false;
  }

  if (ordemDeProducaoEstado !== OrdemDeProducaoEstado.AProduzir) {
    exibirNotificacaoToast({
      mensagem: `Somente ordens de produção no estado "A produzir" podem ter retirada de materiais.`,
      tipo: TipoNotificacao.Erro,
    });
    return false;
  }

  return true;
};

export const verificacoesParaRetiradaDeApara = async (
  ordemDeProducaoEstado: OrdemDeProducaoEstado | undefined,
  ordemDeProducaoId: number
): Promise<boolean> => {
  const ordemDeProducaoService = new OrdemDeProducaoService();

  if (
    !verificaComNotificacaoSeUsuarioPossuiPermissoes([
      PermissoesOrdemDeProducao.RetirarApara,
    ])
  ) {
    return false;
  }

  if (ordemDeProducaoEstado !== OrdemDeProducaoEstado.AProduzir) {
    exibirNotificacaoToast({
      mensagem: `Somente ordens de produção no estado "A produzir" podem ter retirada de aparas.`,
      tipo: TipoNotificacao.Erro,
    });
    return false;
  }

  try {
    const resposta =
      await ordemDeProducaoService.VerificarSeOrdemDeProducaoPossuiAparas(
        ordemDeProducaoId
      );

    if (!resposta.model) {
      await exibirAlerta(
        JanelasDeNotificacaoTitulos.Atencao,
        `Para realizar o apontamento de aparas, é necessário cadastrar um item como 
        'apara' na estrutura do item da ordem de produção. Para saber mais sobre aparas acesse <a target="_blank" href="https://maxiprod.com.br/ajuda/producao/aparas/">clique aqui</a>.`
      );

      return false;
    }

    return true;
  } catch (erro) {
    tratarErroApi(erro);
    return false;
  }
};
