import DataGrid, { Column, DataGridRef } from "devextreme-react/cjs/data-grid";
import { useMemo, useRef } from "react";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import { useSeletorGridMxp } from "../../../../../hooks/seletor.hooks";
import { GridMxpProps } from "../../../../../models/shared/ui/grid";
import criarNameof from "../../../../../utils/common/cria-name-of";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import { ItemNotaFiscalRecebidaGridComImpostosGridModel } from "../../models/item-nota-fiscal-recebida";
import ItemNotaFiscalRecebidaService from "../../servicos/item-nota-fiscal-recebida.service";

const service = new ItemNotaFiscalRecebidaService();
const nameOfGridHandler =
  criarNameof<ItemNotaFiscalRecebidaGridComImpostosGridModel>();

export default function GridItemNotaFiscalRecebidaComImpostos(
  props: GridMxpProps<ItemNotaFiscalRecebidaGridComImpostosGridModel>
) {
  const gridRef = useRef<DataGridRef>(null);

  const dataSource =
    service.ObterDataSourceParaGrid<ItemNotaFiscalRecebidaGridComImpostosGridModel>(
      props.filtrosNoServidor
    );

  useSeletorGridMxp(() => gridRef.current);

  if (props.filtrosNoServidor) {
    dataSource.filter(
      props.filtrosNoServidor.map((x) => [x.campo, x.operador, x.valor])
    );
  }

  const gridController =
    new GridController<ItemNotaFiscalRecebidaGridComImpostosGridModel>(() =>
      gridRef.current?.instance()
    );

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar("item-nota-fiscal-recebida", () =>
      gridRef.current?.instance()
    )
      .definirStyles({ height: "100%" })
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .definirSelecao()
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirOrdenacao()
      .build();
  }, [props.filtrosNoServidor]);

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  return (
    <>
      <ProvedorAjuda id={"tooltips-grid-item-nota-fiscal-recebida"}>
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          <Column
            key={nameOfGridHandler("dataEmissaoNota")}
            dataField={nameOfGridHandler("dataEmissaoNota")}
            {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
            caption="Emissão"
            sortOrder="desc"
          />
          <Column
            key={nameOfGridHandler("numeroNota")}
            dataField={nameOfGridHandler("numeroNota")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Nota fiscal"
          />
          <Column
            key={nameOfGridHandler("serieNota")}
            dataField={nameOfGridHandler("serieNota")}
            {...obterConfiguracaoColuna("serie")}
          />
          <Column
            key={nameOfGridHandler("apelidoFornecedorNota")}
            dataField={nameOfGridHandler("apelidoFornecedorNota")}
            {...obterConfiguracaoColuna("stringG")}
            caption="Remetente"
          />
          <Column
            key={nameOfGridHandler("situacaoNotaDecodificada")}
            dataField={nameOfGridHandler("situacaoNotaDecodificada")}
            {...obterConfiguracaoColuna("stringG")}
            caption="Estado"
          />
          <Column
            key={nameOfGridHandler("sequenciaDoItemNaNota")}
            dataField={nameOfGridHandler("sequenciaDoItemNaNota")}
            {...obterConfiguracaoColuna("stringPP")}
            caption="#"
          />
          <Column
            key={nameOfGridHandler("codigoItem")}
            dataField={nameOfGridHandler("codigoItem")}
            {...obterConfiguracaoColuna("stringP")}
            caption="Item"
          />
          <Column
            key={nameOfGridHandler("descricao")}
            dataField={nameOfGridHandler("descricao")}
            {...obterConfiguracaoColuna("stringG")}
            caption="Descrição"
          />
          <Column
            key={nameOfGridHandler("quantidade")}
            dataField={nameOfGridHandler("quantidade")}
            {...obterConfiguracaoColuna("quantidade")}
            caption="Quantidade"
          />
          <Column
            key={nameOfGridHandler("unidade")}
            dataField={nameOfGridHandler("unidade")}
            {...obterConfiguracaoColuna("unidade")}
            caption="Unid"
          />
          <Column
            key={nameOfGridHandler("valorUnitario")}
            dataField={nameOfGridHandler("valorUnitario")}
            {...obterConfiguracaoColuna("monetario")}
            caption="Valor un"
          />
          <Column
            key={nameOfGridHandler("valorTotal")}
            dataField={nameOfGridHandler("valorTotal")}
            {...obterConfiguracaoColuna("monetario")}
            caption="Valor total"
          />
          <Column
            key={nameOfGridHandler("valorIcms")}
            dataField={nameOfGridHandler("valorIcms")}
            {...obterConfiguracaoColuna("monetario")}
            caption="Valor do ICMS"
          />
          <Column
            key={nameOfGridHandler("valorIcmsSt")}
            dataField={nameOfGridHandler("valorIcmsSt")}
            {...obterConfiguracaoColuna("monetario")}
            caption="Valor do ICMS ST"
          />
          <Column
            key={nameOfGridHandler("valorIcmsDifal")}
            dataField={nameOfGridHandler("valorIcmsDifal")}
            {...obterConfiguracaoColuna("monetario")}
            caption="Valor do ICMS DIFAL comprador"
          />
        </DataGrid>
      </ProvedorAjuda>
    </>
  );
}
