import { ReactNode } from "react";
import { ErroGenerico } from "../../../../features/comum/utils/erros/comum.erros";
import { ErroEstoqueMovimentacao } from "../../../../features/estoque/estoque-movimentacao/utils/erros/estoque-movimentacao.erros";
import { ErroLote } from "../../../../features/estoque/lote/utils/erros/lote.erros";
import { ErroReserva } from "../../../../features/estoque/reserva/utils/erros/reserva.erros";
import { ErroCentroDeTrabalho } from "../../../../features/producao/centro-de-trabalho/utils/erros/centro-de-trabalho.erros";
import { ErroInsumoDaOrdemDeProducao } from "../../../../features/producao/insumo-da-ordem-de-producao/utils/erros/insumo-da-ordem-de-producao.erros";
import { ErroOrdemDeProducao } from "../../../../features/producao/ordem-de-producao/utils/erros/ordem-de-producao.erros";
import { ErroModeloDeRelatorio } from "../../../../features/sistema/modelo-de-relatorio/utils/erros/modelo-de-relatorio.erros";
import { TipoNotificacao } from "../../../../utils/common/notificacoes-utils";

export * from "./erros.base";

export type ErroApi =
  | ErroGenerico
  | ErroCentroDeTrabalho
  | ErroOrdemDeProducao
  | ErroInsumoDaOrdemDeProducao
  | ErroReserva
  | ErroLote
  | ErroEstoqueMovimentacao
  | ErroModeloDeRelatorio;

export enum TipoErro {
  Toast,
  Popup,
  Confirmacao,
  PopUpAgrupado,
  // TODO: Accordion
}

export interface ConfiguracaoErroBase<T extends TipoErro = TipoErro> {
  tipo: T;
}

export interface ConfiguracaoErroToast<Erro extends ErroApi = ErroApi>
  extends ConfiguracaoErroBase<TipoErro.Toast> {
  tipoToast?: TipoNotificacao;
  formatador?: ((erro: Erro) => string) | (() => string);
}

export interface ConfiguracaoErroPopup<Erro extends ErroApi = ErroApi>
  extends ConfiguracaoErroBase<TipoErro.Popup> {
  titulo?: string;
  formatador?: ((erro: Erro) => ReactNode) | (() => ReactNode);
}

export interface ConfiguracaoErroPopupAgrupado<Erro extends ErroApi = ErroApi>
  extends ConfiguracaoErroBase<TipoErro.PopUpAgrupado> {
  titulo?: string;
  formatador?: ((erro: Erro) => ReactNode) | (() => ReactNode);
}

export interface ConfiguracaoErroConfimacao<Erro extends ErroApi = ErroApi>
  extends ConfiguracaoErroBase<TipoErro.Confirmacao> {
  titulo?: string;
  formatador?: ((erro: Erro) => ReactNode) | (() => ReactNode);
}

export type ConfiguracaoErro<Erro extends ErroApi = ErroApi> =
  | ConfiguracaoErroToast<Erro>
  | ConfiguracaoErroPopup<Erro>
  | ConfiguracaoErroConfimacao<Erro>
  | ConfiguracaoErroPopupAgrupado<Erro>;

export type ConfiguracoesErros<Erros extends ErroApi = ErroApi> = {
  [Erro in Erros as Erro["codigoErro"]]?: ConfiguracaoErro<Erro>;
};
