import { DataGrid } from "devextreme-react";
import { Column, DataGridRef } from "devextreme-react/cjs/data-grid";
import { useCallback, useEffect, useMemo, useRef } from "react";
import GridColunaAcoes from "../../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { ModalMxp } from "../../../../../../components/layout/modal-mxp";
import { useRegistrarAtalhosGrid } from "../../../../../../hooks/atalhos.hooks";
import { useControlarFormDeEdicao } from "../../../../../../hooks/form.hooks";
import { useParametroId } from "../../../../../../hooks/route.hooks";
import { PermissoesEstoqueMovimentacao } from "../../../../../../models/direitos/estoque/estoque-movimentacao/permissoes-estoque-movimentacao";
import { ResultadoAcaoFormulario } from "../../../../../../models/shared/ui/formularios";
import { GridMxpProps } from "../../../../../../models/shared/ui/grid";
import GetColunasDeAuditoria from "../../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import { EstoqueMovimentacaoService } from "../../../../../../services/estoque-movimentacoes/estoque-movimentacoes-service";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../../../utils/api/api-utils";
import NomesModais from "../../../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../../../utils/common/nomes-telas";
import { GeradorMensagensNotificacao } from "../../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../../../utils/dialogos";
import GridBuilder from "../../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../../utils/grid/padroes-colunas";
import UrlUtils from "../../../../../../utils/url/url-utils";
import FormRetirarApara from "../../../../../producao/ordem-de-producao/componentes/formularios/retiradas/retirar-apara";
import { OrdemDeProducaoEstado } from "../../../../../producao/ordem-de-producao/utils/enums/ordem-de-producao-enums";
import { verificacoesParaRetiradaDeApara } from "../../../../../producao/ordem-de-producao/utils/handlers/retirada-de-material-ou-apara";
import { MovimentacaoBaseGridModel } from "../../../models/estoque-movimentacao.api";
import GetColunasMovimentacao from "../colunas/colunas-movimentacao";

const service = new EstoqueMovimentacaoService();

interface GridEmbutidaAparasProps
  extends GridMxpProps<MovimentacaoBaseGridModel> {
  ordemDeProducaoId: number;
  ordemDeProducaoEstado?: OrdemDeProducaoEstado;
  ordemDeProducaoNumero?: number;
}

export default function GridEmbutidaRetiradaDeApara(
  props: GridEmbutidaAparasProps
) {
  const gridRef = useRef<DataGridRef>(null);
  const parametroId = useParametroId();

  const {
    idRegistroEmEdicao,
    setIdRegistroEmEdicao,
    modalVisivel,
    encerrarEdicao,
  } = useControlarFormDeEdicao(NaN);

  const dataSource = service.ObterDataSourceParaGridRetiradaDeApara(
    props.ordemDeProducaoId,
    props.filtrosNoServidor
  );

  useEffect(() => {
    if (parametroId) {
      setIdRegistroEmEdicao(parametroId);
    }
  }, [parametroId, setIdRegistroEmEdicao]);

  const handleNovoRegistro = useCallback(async () => {
    if (
      await verificacoesParaRetiradaDeApara(
        props.ordemDeProducaoEstado,
        props.ordemDeProducaoId
      )
    ) {
      setIdRegistroEmEdicao(0);
    }
  }, [
    props.ordemDeProducaoEstado,
    props.ordemDeProducaoId,
    setIdRegistroEmEdicao,
  ]);

  const gridController = useMemo(
    () =>
      new GridController<MovimentacaoBaseGridModel>(() =>
        gridRef.current?.instance()
      ),
    []
  );

  const handleAtualizarGrid = useCallback(() => {
    gridController.atualizar();
  }, [gridController]);

  const handleExcluirRegistro = useCallback(
    async (registro: MovimentacaoBaseGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesEstoqueMovimentacao.MovimentarEEstornar,
        ])
      ) {
        return;
      }

      const excluir = await exibirConfirmacao(
        "Confirmar exclusão",
        `Tem certeza de que deseja excluir a retirada de apara?`
      );

      if (excluir) {
        try {
          const resposta = await service.Excluir(registro.id);

          if (resposta) {
            checarResponseExibeMensagemExclusaoDeSucesso(
              resposta,
              GeradorMensagensNotificacao.ExcluidoComSucessoFeminino(
                "Retirada de apara"
              )
            );
            handleAtualizarGrid();
          }
        } catch (erro) {
          tratarErroApi(erro);
        }
      }
    },
    [handleAtualizarGrid]
  );

  const handleCallbackFormulario = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      encerrarEdicao();

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }

      UrlUtils.RemoverParametroId(parametroId);
    },
    [encerrarEdicao, handleAtualizarGrid, parametroId]
  );

  const handleFecharModal = useCallback(() => {
    encerrarEdicao();
    UrlUtils.RemoverParametroId(parametroId);
  }, [encerrarEdicao, parametroId]);

  useRegistrarAtalhosGrid<MovimentacaoBaseGridModel>({
    controller: gridController,
    handleNovo: handleNovoRegistro,
    handleExcluir: handleExcluirRegistro,
  });

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "retirada-de-apara",
      () => gridRef.current?.instance(),
      false,
      props.filtrosNoCliente
    )
      .definirStyles(props.style)
      .definirDataSource(dataSource)
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirFiltros()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.retiradaDeMaterial)
      .definirBotaoNovo(handleNovoRegistro)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .build();
  }, [
    dataSource,
    handleAtualizarGrid,
    handleNovoRegistro,
    props.filtrosNoCliente,
    props.style,
  ]);

  return (
    <>
      <DataGrid ref={gridRef} {...configuracoesGrid}>
        <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
        {GridColunaAcoes<MovimentacaoBaseGridModel>({
          handleExcluir: handleExcluirRegistro,
        })}

        {GetColunasMovimentacao([
          { ordenarPor: "dataDaMovimentacao", ordem: "desc" },
        ])}

        {GetColunasDeAuditoria()}
      </DataGrid>

      <ModalMxp
        titulo={`${idRegistroEmEdicao ? "Visualizar" : "Nova"} ${
          NomesModais.retiradaDeApara
        }`}
        visivel={modalVisivel}
        handleFechar={handleFecharModal}
        largura={"max(65vw, 900px)"}
        altura={"auto"}
        alturaMaxima={"95vh"}
      >
        <FormRetirarApara
          {...props}
          idRegistroEmEdicao={idRegistroEmEdicao}
          handleCallback={handleCallbackFormulario}
        />
      </ModalMxp>
    </>
  );
}
