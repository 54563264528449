import styled from "styled-components";
import { CoresChip } from "./cores";

interface MxpChipProps {
  color: CoresChip;
  clicavel?: boolean;
}

export const MxpChip = styled.div<MxpChipProps>`
  background-color: color-mix(in srgb, currentColor 15%, transparent);
  color: ${(props) => props.color};
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: 4px;
  padding-inline: 6px;
  margin-block: -1px;
  display: inline;
  font-weight: 500;
  &:hover {
    border: 1px solid;
    cursor: ${(props) => (props.clicavel ? "pointer" : "default")};
  }
`;
