import { useMemo } from "react";
import { FieldValues, UseControllerProps } from "react-hook-form";
import { FormSelectBoxLazyMxp } from "../../../../../components/formularios";
import { assertConfiguracaoExibicaoEBuscaType } from "../../../../../components/formularios/selectbox-lazy-mxp";
import {
  FormataDescricao,
  FormatadoresSelectBox,
} from "../../../../../utils/formatadores/formatador-de-selectbox";
import GridValorOpcao from "../../componentes/grid-padrao";
import {
  TipoValorOpcao,
  ValorOpcaoGridModel,
} from "../../models/valor-opcao.api";
import ValorOpcaoService from "../../servicos/valor-opcao.service";

interface ComboContatoMxpProps<T extends FieldValues>
  extends UseControllerProps<T> {
  titulo: string;
  tipoValorOpcao: TipoValorOpcao;
  tituloSeletor?: string;
  requerido?: boolean;
  somenteLeitura?: boolean;
  filtrosNoCliente?: any[];
}

const apiValorOpcao = new ValorOpcaoService();

const valorOpcaoGridExibicaoEBusca =
  assertConfiguracaoExibicaoEBuscaType<ValorOpcaoGridModel>({
    expressaoDeBusca: ["codigoValorOpcao", "descricaoValorOpcao"],
    nomeCampoChave: "id",
    nomeCampoExibicao: (c) => {
      if (c) {
        return FormataDescricao(
          FormatadoresSelectBox.CodigoDescricaoTraco,
          c.codigoValorOpcao,
          c.descricaoValorOpcao
        );
      }

      return "";
    },
  });

export default function ComboValorOpcaoMxp<T extends FieldValues>(
  props: ComboContatoMxpProps<T>
) {
  const dataSourceValorOpcaoGrid = useMemo(
    () =>
      apiValorOpcao.GetDataSourceSelectBoxLazyMxp<ValorOpcaoGridModel>(
        props.tipoValorOpcao,
        {
          camposRetorno: ["id", "codigoValorOpcao", "descricaoValorOpcao"],
          camposOrdenacao: [
            {
              nomeCampo: "codigoValorOpcao",
              desc: true,
            },
          ],
        }
      ),
    [props.tipoValorOpcao]
  );

  return (
    <FormSelectBoxLazyMxp
      name={props.name}
      titulo={props.titulo}
      control={props.control}
      dataSource={dataSourceValorOpcaoGrid}
      configuracoesExibicaoEBusca={valorOpcaoGridExibicaoEBusca}
      seletorConfig={{
        modo: "selecaoUnica",
        titulo: props.tituloSeletor || "Selecionar valor",
        componenteGrid: (
          <GridValorOpcao
            filtrosNoCliente={props.filtrosNoCliente}
            tipoValorOpcao={props.tipoValorOpcao}
          />
        ),
      }}
      labelSemDados="Sem dados"
      requerido={props.requerido}
      somenteLeitura={props.somenteLeitura}
    />
  );
}
