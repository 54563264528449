interface SesaoGrupoFormProps {
  titulo?: string;
  children: React.ReactNode;
}

export default function ({ titulo, children }: SesaoGrupoFormProps) {
  return (
    <div className="dx-fieldset" style={{ margin: "0", marginTop: "1em" }}>
      <div className="dx-fieldset-header" style={{ margin: "0" }}>
        {titulo}
      </div>
      <hr />
      {children}
    </div>
  );
}
