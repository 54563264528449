import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { FormTextBox } from "../../../../../components/formularios";
import CardRedefinirSenhaViaEmail from "../../../../../components/layout/card-recuperar-senha-via-email";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import LoginBg from "../../../../../components/layout/login-bg";
import useEffectOnLoad from "../../../../../hooks/effect.hooks";
import { useAppDispatch } from "../../../../../hooks/store.hooks";
import { bloquearUI, desbloquearUI } from "../../../../../store/ui/ui.slice";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { perderFocoElementoAtual } from "../../../../../utils/html-dom/html-dom-utils";
import { MensagensPadraoYup } from "../../../../comum/utils/yup/mensagens";
import { RecuperarSenhaViaEmailRequest } from "../../models/login.api";
import ControleAcessoService from "../../servicos/controle-acesso.servico";

const controleAcessoService = new ControleAcessoService();

export default function RecuperacaoDeSenhaViaEmailPage() {
  const [searchParams] = useSearchParams();
  const hashLink = searchParams.get("hash");
  const emailLink = searchParams.get("email");

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  useEffectOnLoad(() => {
    verificarParametrosUrl();
  });

  async function verificarParametrosUrl() {
    if (!hashLink || !emailLink) {
      navigate("/");
      exibirNotificacaoToast({
        mensagem: "Link sem informação de e-mail e/ou hash.",
        tipo: TipoNotificacao.Erro,
      });
      return;
    }

    try {
      dispatch(bloquearUI());
      const resposta =
        await controleAcessoService.verificarSeHashDeRecuperacaoDeSenhaEstaValido(
          {
            email: emailLink,
            hash: hashLink,
          }
        );

      checarResponse(resposta);
    } catch (erro) {
      tratarErroApi(erro);

      navigate("/");
    } finally {
      dispatch(desbloquearUI());
    }
  }

  const schema = yup.object().shape({
    senha: yup
      .string()
      .required(MensagensPadraoYup.campoObrigatorio)
      .matches(
        /(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})/,
        "Deve ter letras maiúsculas e minúsculas."
      )
      .matches(/(.*[0-9]){1,}/, "Deve ter pelo menos um número.")
      .matches(
        /(.*[!@#$%^&*()\-__+.]){1,}/,
        "Deve ter pelo menos um  caractere especial."
      )
      .matches(/^(?=.{10,})/, "Deve ter 10 caracteres ou mais."),
    confirmacaoSenha: yup
      .string()
      .required(MensagensPadraoYup.campoObrigatorio)
      .test("senhaIguaisTest", "Senhas devem ser iguais", function (value) {
        return this.parent.senha == value;
      }),
  });

  function handleEnter() {
    perderFocoElementoAtual();
    form?.requestSubmit();
  }

  const { register, getValues, control, handleSubmit } =
    useForm<RecuperarSenhaViaEmailRequest>({
      criteriaMode: "all",
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: yupResolver(schema),
    });

  async function recuperarSenha() {
    try {
      dispatch(bloquearUI());
      const model = getValues();

      const resposta = await controleAcessoService.recuperarSenha(model);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: "Sua senha foi alterada.",
          tipo: TipoNotificacao.Sucesso,
        });
        navigate("/");
      } else {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Erro,
        });
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      dispatch(desbloquearUI());
    }
  }

  let form: HTMLFormElement | null;

  return (
    <>
      <LoginBg>
        <CardRedefinirSenhaViaEmail
          titulo="Redefinir senha"
          onClickRecuperar={() => form?.requestSubmit()}
        >
          <form
            ref={(ref) => (form = ref)}
            onSubmit={handleSubmit(recuperarSenha)}
          >
            <input
              type="hidden"
              {...register("email")}
              defaultValue={emailLink ?? ""}
            />
            <input
              type="hidden"
              {...register("hash")}
              defaultValue={hashLink ?? ""}
            />
            <Linha>
              <Coluna md={12}>
                <FormTextBox
                  name="senha"
                  titulo="Senha"
                  mostarMultiplosErros
                  control={control}
                  requerido
                  tipo="password"
                  onEnter={handleEnter}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={12}>
                <FormTextBox
                  name="confirmacaoSenha"
                  titulo="Confirmar senha"
                  control={control}
                  requerido
                  tipo="password"
                  onEnter={handleEnter}
                />
              </Coluna>
            </Linha>
          </form>
        </CardRedefinirSenhaViaEmail>
      </LoginBg>
    </>
  );
}
