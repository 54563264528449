import LinkDeAjudaService from "../../features/ajuda/ajuda-paginas/servicos/link-ajuda.service";
import { checarResponse } from "../api/api-utils";

const CacheKey = "LinksAjuda_Cache";

const MinutosExpiracaoCache = parseInt(
  process.env.REACT_APP_HELP_CACHE_TIME_MINUTES as string
);

interface LinkItem {
  id: number;
  textoExibicao: string;
  slug: string;
  ordemExibicao: number;
}

interface PaginasItem {
  codigo: string;
  itens: LinkItem[];
}

interface CacheLinks {
  dataHora: number;
  paginas: PaginasItem[];
}

const linkAjudaService = new LinkDeAjudaService();

export class ProvedorLinkAjuda {
  static async criarProvedor() {
    const provedor = new ProvedorLinkAjuda();
    await provedor.carregar();
    return provedor;
  }

  static limparCache() {
    for (const key in localStorage) {
      if (key.startsWith(CacheKey)) {
        localStorage.removeItem(key);
      }
    }
  }

  private constructor() {
    this.cache = {
      dataHora: 0,
      paginas: [],
    };
  }

  private cache: CacheLinks;

  get expirado() {
    return Date.now() - this.cache.dataHora > MinutosExpiracaoCache * 60 * 1000;
  }

  public obterLinks(codigo: string) {
    const links = this.cache.paginas.find((pagina) => pagina.codigo === codigo);

    return links?.itens ?? [];
  }

  public async carregar() {
    const json = localStorage.getItem(CacheKey);

    if (json) {
      this.cache = JSON.parse(json) as CacheLinks;

      if (this.expirado) {
        await this.carregarOnLine();
      }
    } else {
      await this.carregarOnLine();
    }
  }

  public async carregarOnLine() {
    try {
      const links = await linkAjudaService.ObterListaLinksComCache(false);

      if (checarResponse(links)) {
        const cacheObj: CacheLinks = {
          dataHora: Date.now(),
          paginas: [],
        };

        for (const link of links.model) {
          if (
            !cacheObj.paginas.some((pagina) => pagina.codigo === link.codigo)
          ) {
            cacheObj.paginas.push({ codigo: link.codigo, itens: [] });
          }

          const index = cacheObj.paginas.findIndex(
            (pagina) => pagina.codigo === link.codigo
          );

          cacheObj.paginas[index].itens.push({
            id: link.id,
            textoExibicao: link.textoExibicao ?? "",
            ordemExibicao: link.ordemExibicao ?? 0,
            slug: link.slug,
          });
        }

        const json = JSON.stringify(cacheObj);

        if (cacheObj.paginas.length > 0) {
          localStorage.setItem(CacheKey, json);
        }

        this.cache = cacheObj;
      }
    } catch (erro) {
      console.error(erro);
    }
  }
}
