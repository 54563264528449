import { AxiosRequestConfig } from "axios";
import getApi from "../../../../configs/api";
import { ResponseBaseArquivo } from "../../../../models/api/comum/response-base";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import criarDatasourceGrid from "../../../../utils/grid/back-end-grid-utils";

export default class UsuarioService {
  private _nomeEndpoint: string;

  constructor() {
    this._nomeEndpoint = NomesEndpoints.Usuario;
  }

  public ObterGridSelecaoCondutorSource() {
    return criarDatasourceGrid(`${this._nomeEndpoint}/condutor-selecao-grid`);
  }

  public async Imprimir(loadOptions: string) {
    const api = getApi();

    const resquestConfig: AxiosRequestConfig = {
      responseType: "blob",
    };

    const response = await api.get<ResponseBaseArquivo>(
      `${this._nomeEndpoint}/Imprimir?${loadOptions}`,
      resquestConfig
    );

    return response;
  }
}
