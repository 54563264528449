import { CSSProperties } from "styled-components";
import { RelacionamentoOrdemDeProducaoGrid } from "../../../features/producao/ordem-de-producao/models/ordem-de-producao.api";
import {
  OrdemDeProducaoTipoRelacionamento,
  OrdemDeProducaoTipoRelacionamentoDescricao,
} from "../../../features/producao/ordem-de-producao/utils/enums/ordem-de-producao-enums";
import { abrirModalMxpAntigo } from "../../../utils/common/menu-utils";
import { formatarNumero } from "../../../utils/formatadores/formatador-de-numeros";
import { MxpChip } from "../../mxp/chip";
import { CoresChip } from "../../mxp/chip/cores";
import styles from "./index.module.scss";

const coresChips = {
  [OrdemDeProducaoTipoRelacionamento.Vinculo]: CoresChip.Cinza,
  [OrdemDeProducaoTipoRelacionamento.Reserva]: CoresChip.Azul,
  [OrdemDeProducaoTipoRelacionamento.ReservaAtendida]: CoresChip.Verde,
};

const onClickChip = (
  chip: RelacionamentoOrdemDeProducaoGrid,
  ctrlKey: boolean
) => {
  const abrirEmNovaAba = true;
  abrirModalMxpAntigo(
    {
      rota: "_ZoomChips_AbrirModalPVITePV",
      tipo: "javaScriptMxpAntigo",
      paramFunction: () =>
        `(${chip.idItemDoPedidoDeVenda}, ${chip.idPedidoDeVenda}, ${ctrlKey})`,
    },
    abrirEmNovaAba
  );
};

export const ChipCellClass = styles.chipCell;

export function renderChips(chips: RelacionamentoOrdemDeProducaoGrid[]) {
  const estilosExtras: CSSProperties =
    chips.length === 1 ? { maxWidth: "600px", width: "fit-content" } : {};

  if (chips.length === 0) {
    return <></>;
  }

  return (
    <div className={styles.chipContainer} style={{ display: "flex" }}>
      {chips.map((chip: RelacionamentoOrdemDeProducaoGrid, idx: number) => (
        <MxpChip
          color={coresChips[chip.tipo]}
          clicavel={true}
          className={styles.chip}
          style={{ ...estilosExtras }}
          onClick={(e) => onClickChip(chip, e.ctrlKey)}
          key={idx}
          title={OrdemDeProducaoTipoRelacionamentoDescricao[chip.tipo]}
        >
          {chip.numeroPedidoDeVenda} #{chip.numeroItemDoPedidoDeVenda} (
          {formatarNumero(chip.quantidade || 0, 0, 5)}) - {chip.apelidoEmpresa}
        </MxpChip>
      ))}
    </div>
  );
}
