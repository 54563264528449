import { useContext } from "react";
import BreadCrumbCaminhoTags from "../bread-crumb-caminho-tags";
import { DicionarioTagsContext } from "../dicionario-tags-schema-provider";
import { ZonaClicavel } from "./styles";

export default function CaminhoTags() {
  const { navegacao, irParaRaiz } = useContext(DicionarioTagsContext);

  return (
    <div>
      <span>
        <ZonaClicavel onClick={irParaRaiz} title="Ir para">
          <span className="fa-solid fa-house" />
        </ZonaClicavel>
      </span>
      {navegacao
        .filter((x) => x.nome)
        .map((n) => (
          <BreadCrumbCaminhoTags key={`${n.nivel}-${n.nome}`} navegacao={n} />
        ))}
    </div>
  );
}
