import DataGrid, { Column, DataGridRef } from "devextreme-react/cjs/data-grid";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import GridColunaAcoes from "../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { ModalMxp } from "../../../../../components/layout/modal-mxp";
import { useRegistrarAtalhosGrid } from "../../../../../hooks/atalhos.hooks";
import { useControlarFormDeEdicao } from "../../../../../hooks/form.hooks";
import { useMenuDeContextosGrid } from "../../../../../hooks/menus.hooks";
import { useParametroId } from "../../../../../hooks/route.hooks";
import { PermissoesInformativoComposicaoCustosLucroReal } from "../../../../../models/permissoes/fiscal/informativo-composicao-custos-lucro-real/permissoes-informativo-composicao-lucro-real";
import { ResultadoAcaoFormulario } from "../../../../../models/shared/ui/formularios";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import { bloquearUI, desbloquearUI } from "../../../../../store/ui/ui.slice";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  checarResponseExibeMensagemExecutadoComSucesso,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesModais from "../../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import NormalizaTituloModal from "../../../../../utils/common/normaliza-titulo";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { ItemContextMenuMxp } from "../../../../../utils/context-menu/context-menu-utils";
import { GestorEventoClickUnicaLinha } from "../../../../../utils/context-menu/gestor-evento-click";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import { formatarDataAno4digitos } from "../../../../../utils/formatadores/formatador-de-datas";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import UrlUtils from "../../../../../utils/url/url-utils";
import { ApuracaoDoInformativoDaComposicaoDeCustosDaEcfGridModel } from "../../models/apuracao-informativo-composicao-custos-ecf";
import { ApuracaoDoInformativoDaComposicaoDeCustosDaEcfService } from "../../servicos/apuracao-informativo-composicao-custos-ecf";
import EditFormInsercaoApuracaoDoInformativoDaComposicaoDeCustosDaEcf from "../formulario/criacao";
import EditFormEdicaoApuracaoDoInformativoDaComposicaoDeCustosDaEcf from "../formulario/edicao";

const service = new ApuracaoDoInformativoDaComposicaoDeCustosDaEcfService();
const dataSource = service.GetGridSource();

const nameOfGridHandler =
  criarNameof<ApuracaoDoInformativoDaComposicaoDeCustosDaEcfGridModel>();

export function GridInformativoComposicaoCustosLucroReal() {
  const gridRef = useRef<DataGridRef>(null);
  const parametroId = useParametroId();

  const {
    idRegistroEmEdicao,
    setIdRegistroEmEdicao,
    modalVisivel,
    encerrarEdicao,
  } = useControlarFormDeEdicao(NaN);

  const dispatch = useDispatch();

  const handleNovoRegistro = useCallback(() => {
    if (
      !verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesInformativoComposicaoCustosLucroReal.InserirEditar,
      ])
    ) {
      return;
    }
    setIdRegistroEmEdicao(0);
  }, []);

  const handleEditarRegistro = useCallback(
    (registro: ApuracaoDoInformativoDaComposicaoDeCustosDaEcfGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesInformativoComposicaoCustosLucroReal.InserirEditar,
        ])
      ) {
        return;
      }
      setIdRegistroEmEdicao(registro.id);
    },
    []
  );

  const handleExcluirRegistro = useCallback(
    async (
      registro: ApuracaoDoInformativoDaComposicaoDeCustosDaEcfGridModel
    ) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesInformativoComposicaoCustosLucroReal.Excluir,
        ])
      ) {
        return;
      }
      const excluir = await exibirConfirmacao(
        "Confirmar exclusão",
        obterMensagemExclusao(registro)
      );

      if (excluir) {
        const resposta = await service.Excluir(registro.id);

        if (resposta) {
          checarResponseExibeMensagemExclusaoDeSucesso(resposta);
          handleAtualizarGrid();
        }
      }
    },
    []
  );

  function obterMensagemExclusao(
    registro: ApuracaoDoInformativoDaComposicaoDeCustosDaEcfGridModel
  ) {
    return `Tem certeza que deseja excluir o informativo da composição de custos do Lucro Real de ${formatarDataAno4digitos(
      registro.dataInicial
    )} a ${formatarDataAno4digitos(registro.dataFinal)}?`;
  }

  const handleRecalcular = useCallback(
    async (
      registro: ApuracaoDoInformativoDaComposicaoDeCustosDaEcfGridModel
    ) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesInformativoComposicaoCustosLucroReal.InserirEditar,
        ])
      ) {
        return;
      }

      const recalcular = await exibirConfirmacao(
        "Confirmar recálculo",
        "Tem certeza de que deseja recalcular a apuração?"
      );

      if (recalcular) {
        try {
          dispatch(bloquearUI());
          const resultado = await service.Recalcular(registro?.id);
          checarResponseExibeMensagemExecutadoComSucesso(resultado);
          if (resultado.sucesso) {
            handleAtualizarGrid();
          }
        } finally {
          dispatch(desbloquearUI());
        }
      }
    },
    []
  );

  const gridController =
    new GridController<ApuracaoDoInformativoDaComposicaoDeCustosDaEcfGridModel>(
      () => gridRef.current?.instance()
    );

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  useRegistrarAtalhosGrid<ApuracaoDoInformativoDaComposicaoDeCustosDaEcfGridModel>(
    {
      controller: gridController,
      handleNovo: handleNovoRegistro,
      handleEditar: handleEditarRegistro,
      handleExcluir: handleExcluirRegistro,
    }
  );

  const acoesDeContexto: ItemContextMenuMxp[] = [
    {
      text: "Ações",
      icon: "ic-material-symbols-outlined ic-vertical",
      hint: "Menu com opções de ação",
      exibirNaLinhaDaGrid: "menuDeContexto",
      exibirNoMenuPrincipal: true,
      items: [
        {
          text: "Recalcular apuração",
          gestorEventoClick: new GestorEventoClickUnicaLinha(
            handleRecalcular,
            () => gridController
          ),
          exibirNaLinhaDaGrid: "sempre",
        },
      ],
    },
  ];

  useMenuDeContextosGrid(acoesDeContexto);

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar("apuracao-informativo-composicao-custos-ecf", () =>
      gridRef.current?.instance()
    )
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(
        NomesTelas.informativoDaComposicaoDeCustosDoLucroReal
      )
      .definirBotaoNovo(handleNovoRegistro)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .definirDuploCliqueLinha(handleEditarRegistro)
      .definirMenuDeContexto(acoesDeContexto)
      .build();
  }, []);

  useEffect(() => {
    if (parametroId) {
      setIdRegistroEmEdicao(parametroId);
    }
  }, []);

  const handleCallbackFormulario = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      setIdRegistroEmEdicao(NaN);

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }

      UrlUtils.RemoverParametroId(parametroId);
    },
    [parametroId]
  );

  const handleFecharModal = useCallback(() => {
    setIdRegistroEmEdicao(NaN);
    UrlUtils.RemoverParametroId(parametroId);
  }, [parametroId]);

  return (
    <>
      <DataGrid ref={gridRef} {...configuracoesGrid}>
        {GridColunaAcoes<ApuracaoDoInformativoDaComposicaoDeCustosDaEcfGridModel>(
          {
            handleEditar: handleEditarRegistro,
            handleExcluir: handleExcluirRegistro,
          }
        )}
        <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
        <Column
          key={nameOfGridHandler("dataInicial")}
          dataField={nameOfGridHandler("dataInicial")}
          {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
          caption="Data inicial"
          width={110}
        />
        <Column
          key={nameOfGridHandler("dataFinal")}
          dataField={nameOfGridHandler("dataFinal")}
          {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
          caption="Data final"
          width={110}
        />
        {GetColunasDeAuditoria()}
      </DataGrid>

      <ModalMxp
        titulo={NormalizaTituloModal.Normalizar(
          idRegistroEmEdicao,
          NomesModais.informativoComposicaoCustosLucroReal
        )}
        visivel={modalVisivel && idRegistroEmEdicao === 0}
        handleFechar={handleFecharModal}
        largura={"max(50vw, 800px)"}
      >
        <EditFormInsercaoApuracaoDoInformativoDaComposicaoDeCustosDaEcf
          idRegistroEmEdicao={idRegistroEmEdicao}
          handleCallback={handleCallbackFormulario}
        />
      </ModalMxp>

      <ModalMxp
        titulo={NormalizaTituloModal.Normalizar(
          idRegistroEmEdicao,
          NomesModais.informativoComposicaoCustosLucroReal
        )}
        visivel={modalVisivel && idRegistroEmEdicao > 0}
        handleFechar={encerrarEdicao}
        largura={"max(50vw, 800px)"}
      >
        <EditFormEdicaoApuracaoDoInformativoDaComposicaoDeCustosDaEcf
          idRegistroEmEdicao={idRegistroEmEdicao}
          handleCallback={handleCallbackFormulario}
        />
      </ModalMxp>
    </>
  );
}
