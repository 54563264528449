import {
  FormataDescricao,
  FormatadoresSelectBox,
} from "./formatador-de-selectbox";

interface DadosItem {
  readonly codigo: string;
  readonly descricao?: string | null;
}

export function formatarItem(item: DadosItem) {
  return typeof item.descricao === "string" && item.descricao.trim() !== ""
    ? FormataDescricao(
        FormatadoresSelectBox.CodigoDescricaoParenteses,
        item.codigo,
        item.descricao
      )
    : `${item.codigo}`;
}

export function formatarNumeroOrdemDeProducao(
  numeroOrdemDeProducao: number | null | undefined
) {
  return `${
    typeof numeroOrdemDeProducao === "number" ? numeroOrdemDeProducao : "sem nº"
  }`;
}

export function formatarOrdemDeProducaoComItem(
  numeroOrdemDeProducao: number | null | undefined,
  item: DadosItem
) {
  return typeof item.descricao === "string" && item.descricao.trim() !== ""
    ? FormataDescricao(
        FormatadoresSelectBox.NumeroTracoCodigoEspacoDescricaoEntreParenteses,
        formatarNumeroOrdemDeProducao(numeroOrdemDeProducao),
        item.codigo,
        item.descricao
      )
    : FormataDescricao(
        FormatadoresSelectBox.CodigoDescricaoTraco,
        formatarNumeroOrdemDeProducao(numeroOrdemDeProducao),
        item.codigo
      );
}
