import { AnchorHTMLAttributes } from "react";
import iconCompras from "../../assets/img/Menu/iconCompras.svg";
import iconContabilidade from "../../assets/img/Menu/iconContabilidade.svg";
import iconCrm from "../../assets/img/Menu/iconCrm.svg";
import iconEstoque from "../../assets/img/Menu/iconEstoque.svg";
import iconFinanceiro from "../../assets/img/Menu/iconFinanceiro.svg";
import iconFiscal from "../../assets/img/Menu/iconFiscal.svg";
import iconItens from "../../assets/img/Menu/iconItens.svg";
import iconProducao from "../../assets/img/Menu/iconProducao.svg";
import iconQualidade from "../../assets/img/Menu/iconQualidade.svg";
import iconVendas from "../../assets/img/Menu/iconVendas.svg";
import store from "../../store";
import { exibirModalSobre } from "../../store/ui/ui.slice";
import { TeclasOpcionais } from "../atalhos/colecao-atalhos";
import NomesTelas from "./nomes-telas";

export interface MenuItem {
  text?: string;
  icon?: string;
  onClick?: () => void;
  raiz?: boolean;
  link?: DadosLink;
  items?: MenuItem[];
  beginGroup?: boolean;
  visible?: boolean;
  url?: string;
  linkAttr?: AnchorHTMLAttributes<any>;
  atalho?: TeclasOpcionais;
  criadoEm?: Date;
}

export type TipoLinkMenu =
  | "linkLocal"
  | "linkMxpAntigo"
  | "javaScriptMxpAntigo"
  | "linkPaginaExterna";

export type DadosLink = {
  rota: string;
  tipo: TipoLinkMenu;
  paramFunction?: () => string;
};

export function navegarParaMxpAntigo(rota: string) {
  const endpointAntigo = process.env
    .REACT_APP_BACKEND_ANTIGO_ENDPOINT as string;
  window.open(endpointAntigo + rota, "_self", "noreferrer");
}

export function navegarParaMxpAntigoEmNovaAba(rota: string) {
  const endpointAntigo = process.env
    .REACT_APP_BACKEND_ANTIGO_ENDPOINT as string;
  window.open(endpointAntigo + rota, "_blank", "noreferrer");
}

export function navegarParaPaginaExterna(pagina: string) {
  window.open(pagina, "_blank", "noreferrer");
}

export function abrirModalMxpAntigo(
  item: DadosLink,
  abrirEmNovaAba?: boolean,
  telaAAbrir?: string
) {
  let funcao;
  const rota = telaAAbrir ?? "Home/Index";
  if (item.paramFunction) {
    funcao = `${item.rota + item.paramFunction()}`;
  } else {
    funcao = `${item.rota}($(this))`;
  }

  abrirEmNovaAba
    ? navegarParaMxpAntigoEmNovaAba(`/${rota}?func=` + funcao)
    : navegarParaMxpAntigo(`/${rota}?func=` + funcao);
}

function gerarLinksMenu(
  itens: MenuItem[],
  navegarPara: (rota: string) => void,
  mobile: boolean
) {
  for (const item of itens) {
    if (item.items) {
      item.items = gerarLinksMenu(item.items, navegarPara, mobile);
      continue;
    }

    if (item.link) {
      const [url, linkAttr] = gerarDadosLink(item.link);
      item.url = url;
      item.linkAttr = linkAttr;
    }
  }

  return itens;
}

function gerarDadosLink(item: DadosLink): [string, AnchorHTMLAttributes<any>] {
  let url = "";
  const endpointAntigo = process.env
    .REACT_APP_BACKEND_ANTIGO_ENDPOINT as string;

  switch (item.tipo) {
    case "linkPaginaExterna":
      url = item.rota;
      break;
    case "linkMxpAntigo":
      url = endpointAntigo + item.rota;
      break;
    case "javaScriptMxpAntigo":
      if (item.paramFunction) {
        url = `${endpointAntigo}/Home/Index?func=${
          item.rota + item.paramFunction()
        }`;
      } else {
        url = `${endpointAntigo}/Home/Index?func=${item.rota}($(this))`;
      }

      break;
    default:
      url = item.rota;
      break;
  }

  const linkAttr: AnchorHTMLAttributes<any> = {
    target: item.tipo === "linkPaginaExterna" ? "_blank" : "_self",
    rel: item.tipo !== "linkLocal" ? "noreferrer" : undefined,
  };

  return [url, linkAttr];
}

export default function gerarItensMenu(
  navegarPara: (rota: string) => void,
  mobile: boolean,
  isUsuarioMaster: boolean | undefined
): MenuItem[] {
  const dados: MenuItem[] = [
    {
      text: "CRM",
      icon: iconCrm,
      items: [
        {
          text: "Clientes e clientes potenciais",
          link: {
            rota: "/Empresa/Crm",
            tipo: "linkMxpAntigo",
          },
          atalho: {
            tecla: "K",
            ctrl: true,
          },
        },
        {
          text: "Representantes/vendedores",
          link: {
            rota: "/Empresa/Representantes",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Parceiros",
          link: {
            rota: "/Empresa/Parceiros",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Concorrentes",
          link: {
            rota: "/Empresa/Concorrentes",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Contatos",
          link: {
            rota: "/Contato",
            tipo: "linkMxpAntigo",
          },
          atalho: {
            tecla: "H",
            alt: true,
          },
          beginGroup: true,
        },
        {
          text: "Tarefas e anotações",
          link: {
            rota: "/Anotacao",
            tipo: "linkMxpAntigo",
          },
          atalho: {
            tecla: "T",
            alt: true,
            shift: true,
          },
          beginGroup: true,
        },
        {
          text: "Tarefas pendentes",
          link: {
            rota: "/Anotacao/TarefasPendentes",
            tipo: "linkMxpAntigo",
          },
          atalho: {
            tecla: "T",
            alt: true,
          },
        },
        {
          text: "Cadastros",
          items: [
            {
              text: "Segmentos de clientes",
              link: {
                rota: "/ValorOpcao/SegmentoEmpresa",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Regiões de clientes",
              link: {
                rota: "/ValorOpcao/RegiaoEmpresa",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Perfis de clientes",
              link: {
                rota: "/ValorOpcao/PerfilEmpresa",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Estados de CRM dos clientes",
              link: {
                rota: "/ValorOpcao/EstadoCRM",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Grupos de campos adicionais das empresas",
              link: {
                rota: "/GrupoCampoAdicionalEmpresa",
                tipo: "linkMxpAntigo",
              },
              beginGroup: true,
            },
            {
              text: "Campos adicionais das empresas",
              link: {
                rota: "/CampoAdicionalPadraoEmpresa",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Assuntos de tarefas e anotações",
              link: {
                rota: "/ValorOpcao/AssuntoAnotacao",
                tipo: "linkMxpAntigo",
              },
              beginGroup: true,
            },
            {
              text: "Classes de tarefas e anotações",
              link: {
                rota: "/ValorOpcao/AcaoAnotacao",
                tipo: "linkMxpAntigo",
              },
            },
          ],
          beginGroup: true,
        },
      ],
    },
    {
      text: "Vendas",
      icon: iconVendas,
      items: [
        {
          text: "Notas fiscais",
          link: {
            rota: "/NotaFiscal",
            tipo: "linkMxpAntigo",
          },
          atalho: {
            tecla: "N",
            alt: true,
          },
        },
        {
          text: "Itens das notas fiscais",
          link: {
            rota: "/ItemNotaFiscal",
            tipo: "linkMxpAntigo",
          },
          atalho: {
            tecla: "N",
            alt: true,
            shift: true,
          },
        },
        {
          text: "Pedidos de venda",
          link: {
            rota: "/NotaFiscal/PedidoVenda",
            tipo: "linkMxpAntigo",
          },
          atalho: {
            tecla: "V",
            alt: true,
          },
          beginGroup: true,
        },
        {
          text: "Itens dos pedidos de venda",
          link: {
            rota: "/ItemNotaFiscal/ItemPedidoVenda",
            tipo: "linkMxpAntigo",
          },
          atalho: {
            tecla: "V",
            alt: true,
            shift: true,
          },
        },
        {
          text: "Painel de controle do pedido de venda",
          link: {
            rota: "/PainelControlePedidoVenda",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Propostas de venda",
          link: {
            rota: "/NotaFiscal/Proposta",
            tipo: "linkMxpAntigo",
          },
          atalho: {
            tecla: "W",
            alt: true,
          },
          beginGroup: true,
        },
        {
          text: "Itens das propostas de venda",
          link: {
            rota: "/ItemNotaFiscal/ItemProposta",
            tipo: "linkMxpAntigo",
          },
          atalho: {
            tecla: "W",
            alt: true,
            shift: true,
          },
        },
        {
          text: "Tabelas de preços",
          link: {
            rota: "/TabelaPreco",
            tipo: "linkMxpAntigo",
          },
          beginGroup: true,
        },
        {
          text: "Itens das tabelas de preços",
          link: {
            rota: "/ItemTabelaPreco",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Clientes",
          link: {
            rota: "/Empresa/Clientes",
            tipo: "linkMxpAntigo",
          },
          atalho: {
            tecla: "K",
            alt: true,
          },
          beginGroup: true,
        },
        {
          text: "Representantes/vendedores",
          link: {
            rota: "/Empresa/Representantes",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Transportadoras",
          link: {
            rota: "/Empresa/Transportadoras",
            tipo: "linkMxpAntigo",
          },
          atalho: {
            tecla: "Y",
            alt: true,
          },
        },
        {
          text: "Análise de crédito",
          link: {
            rota: "/Empresa/AnaliseCredito",
            tipo: "linkMxpAntigo",
          },
          beginGroup: true,
        },
        {
          text: "Painel de controle comercial",
          link: {
            rota: "/PainelControleComercial",
            tipo: "linkMxpAntigo",
          },
          beginGroup: true,
        },
        {
          text: "Comissões por recebimento",
          link: {
            rota: "/Comissao/PorRecebimento",
            tipo: "linkMxpAntigo",
          },
          beginGroup: true,
        },
        {
          text: "Cadastros",
          items: [
            {
              text: "Códigos externos dos itens",
              link: {
                rota: "/ItemCodigoExterno",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Empresas X minhas empresas",
              link: {
                rota: "/EmpresaPropriaEmpresa",
                tipo: "linkMxpAntigo",
              },
              beginGroup: true,
            },
            {
              text: "Tabelas de preços X minhas empresas",
              link: {
                rota: "/TabelaPrecoPropriaEmpresa",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Itens das tabelas de preços X minhas empresas",
              link: {
                rota: "/ItemTabelaPrecoPropriaEmpresa",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Estados de proposta de venda",
              link: {
                rota: "/ValorOpcao/EstadosProposta",
                tipo: "linkMxpAntigo",
              },
              beginGroup: true,
            },
            {
              text: "Estados de pedido de venda",
              link: {
                rota: "/ValorOpcao/EstadosPedidoVenda",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Estados de nota fiscal",
              link: {
                rota: "/ValorOpcao/EstadosNotaFiscal",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Markup",
              link: {
                rota: "/MarkupMestre",
                tipo: "linkMxpAntigo",
              },
              beginGroup: true,
            },
            {
              text: "Grupos de campos adicionais dos pedidos de venda",
              link: {
                rota: "/GrupoCampoAdicionalPadraoPedidoVenda",
                tipo: "linkMxpAntigo",
              },
              beginGroup: true,
            },
            {
              text: "Campos adicionais dos pedidos de venda",
              link: {
                rota: "/CampoAdicionalPadraoPedidoVenda",
                tipo: "linkMxpAntigo",
              },
            },
          ],
          beginGroup: true,
        },
      ],
    },
    {
      text: "Compras",
      icon: iconCompras,
      items: [
        {
          text: "Notas fiscais recebidas",
          link: {
            rota: "/NotaFiscal/Recebida",
            tipo: "linkMxpAntigo",
          },
          atalho: {
            tecla: "E",
            alt: true,
          },
        },
        {
          text: "Itens das notas fiscais recebidas",
          link: {
            rota: "/ItemNotaFiscal/ItemNotaRecebida",
            tipo: "linkMxpAntigo",
          },
          atalho: {
            tecla: "E",
            alt: true,
            shift: true,
          },
        },
        {
          text: "Pedidos de compra",
          link: {
            rota: "/NotaFiscal/PedidoCompra",
            tipo: "linkMxpAntigo",
          },
          atalho: {
            tecla: "C",
            alt: true,
          },
          beginGroup: true,
        },
        {
          text: "Itens dos pedidos de compra",
          link: {
            rota: "/ItemNotaFiscal/ItemPedidoCompra",
            tipo: "linkMxpAntigo",
          },
          atalho: {
            tecla: "C",
            alt: true,
            shift: true,
          },
        },
        {
          text: "Itens dos pedidos de compra detalhados por reserva",
          link: {
            rota: "/ItemNotaFiscal/ItemPedidoCompraDetalhadoPorReserva",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Cotações de compra",
          link: {
            rota: "/NotaFiscal/Cotacao",
            tipo: "linkMxpAntigo",
          },
          beginGroup: true,
        },
        {
          text: "Itens das cotações de compra",
          link: {
            rota: "/ItemNotaFiscal/ItemCotacao",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Solicitações de compra",
          link: {
            rota: "/SolicitacaoCompra",
            tipo: "linkMxpAntigo",
          },
          atalho: {
            tecla: "S",
            alt: true,
          },
          beginGroup: true,
        },
        {
          text: "Solicitações de envio para terceirização",
          link: {
            rota: "/SolicitacaoCompra/EnvioTerceirizacao",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Solicitações de recebimento para terceirização",
          link: {
            rota: "/SolicitacaoCompra/RecebimentoTerceirizacao",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Fornecedores",
          link: {
            rota: "/Empresa/Fornecedores",
            tipo: "linkMxpAntigo",
          },
          atalho: {
            tecla: "F",
            alt: true,
          },
          beginGroup: true,
        },
        {
          text: "Transportadoras",
          link: {
            rota: "/Empresa/Transportadoras",
            tipo: "linkMxpAntigo",
          },
          atalho: {
            tecla: "Y",
            alt: true,
          },
        },
        {
          text: "Cadastros",
          items: [
            {
              text: "Códigos externos dos itens",
              link: {
                rota: "/ItemCodigoExterno",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Fornecedores dos itens",
              link: {
                rota: "/ItemEmpresa",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Estados de cotação de compra",
              link: {
                rota: "/ValorOpcao/EstadosCotacao",
                tipo: "linkMxpAntigo",
              },
              beginGroup: true,
            },
            {
              text: "Estados de pedido de compra",
              link: {
                rota: "/ValorOpcao/EstadosPedidoCompra",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Estados de nota recebida",
              link: {
                rota: "/ValorOpcao/EstadosNotaFiscalRecebida",
                tipo: "linkMxpAntigo",
              },
            },
          ],
          beginGroup: true,
        },
      ],
    },
    {
      text: "Itens",
      icon: iconItens,
      items: [
        {
          text: "Itens",
          link: {
            rota: "/Item",
            tipo: "linkMxpAntigo",
          },
          atalho: {
            tecla: "I",
            alt: true,
          },
        },
        {
          text: "Códigos externos",
          link: {
            rota: "/ItemCodigoExterno",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Fornecedores dos itens",
          link: {
            rota: "/ItemEmpresa",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Grupos",
          link: {
            rota: "/Grupo",
            tipo: "linkMxpAntigo",
          },
          atalho: {
            tecla: "G",
            alt: true,
          },
          beginGroup: true,
        },
        {
          text: "Cadastros",
          items: [
            {
              text: "Propriedades dos itens nas minhas empresas",
              link: {
                rota: "/ItemPropriaEmpresa",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Unidades de medida",
              link: {
                rota: "/ValorOpcao/UnidadeMedida",
                tipo: "linkMxpAntigo",
              },
              beginGroup: true,
            },
            {
              text: "Prefixos de código de item",
              link: {
                rota: "/PrefixoItem",
                tipo: "linkMxpAntigo",
              },
              beginGroup: true,
            },
            {
              text: "Prefixo e sufixo de lotes e números de série",
              link: {
                rota: "/itens/prefixo-e-sufixo-de-lote",
                tipo: "linkLocal",
              },
            },
            {
              text: "Itens no e-commerce/Mercos",
              link: {
                rota: "/ItemAplicativoExterno",
                tipo: "linkMxpAntigo",
              },
              beginGroup: true,
            },
            {
              text: "Códigos de produto da ANVISA",
              link: {
                rota: "/ValorOpcao/CodigosANVISA",
                tipo: "linkMxpAntigo",
              },
              beginGroup: true,
            },
            {
              text: "Códigos de produto da ANP",
              link: {
                rota: "/ValorOpcao/CodigosDeProdutoDaANP",
                tipo: "linkMxpAntigo",
              },
            },
          ],
          beginGroup: true,
        },
      ],
    },
    {
      text: "Estoque",
      icon: iconEstoque,
      items: [
        {
          text: "Estoque",
          link: {
            rota: "/ItemEstoque?visualizarEstoqueOutrasEmpresas=True",
            tipo: "linkMxpAntigo",
          },
          atalho: {
            tecla: "Q",
            alt: true,
          },
        },
        {
          text: "Estoque agrupado",
          link: {
            rota: "/ItemEstoque?Agrupado=True",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Volumes master",
          link: {
            rota: "/VolumeMaster",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Movimentações de estoque",
          link: {
            rota: "/Movimentacao?exibicao=Fisicos",
            tipo: "linkMxpAntigo",
          },
          atalho: {
            tecla: "M",
            alt: true,
          },
          beginGroup: true,
        },
        {
          text: "Ficha de estoque",
          link: {
            rota: "/FichaEstoque",
            tipo: "linkMxpAntigo",
          },
          atalho: {
            tecla: "Q",
            ctrl: true,
          },
        },
        {
          text: "Ficha futura",
          link: {
            rota: "/PerfilEstoque",
            tipo: "linkMxpAntigo",
          },
          atalho: {
            tecla: "U",
            ctrl: true,
          },
        },
        {
          text: "Lotes",
          link: {
            rota: "/estoque/lote",
            tipo: "linkLocal",
          },
          criadoEm: new Date(2024, 4, 8),
          beginGroup: true,
        },
        {
          text: "Reservas",
          link: {
            rota: "/Reserva",
            tipo: "linkMxpAntigo",
          },
          atalho: {
            tecla: "J",
            alt: true,
          },
          beginGroup: true,
        },
        {
          text: "Vínculos",
          link: {
            rota: "/Vinculo",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Vínculos não reservados",
          link: {
            rota: "/Vinculo/VinculosNaoReservados",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Itens de pedido de venda a separar",
          link: {
            rota: "/SeparacaoItensPedidoVenda",
            tipo: "linkMxpAntigo",
          },
          beginGroup: true,
        },
        {
          text: "Separações para expedição",
          link: {
            rota: "/SeparacaoExpedicao",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Fechamentos de estoque",
          link: {
            rota: "/FechamentoEstoque",
            tipo: "linkMxpAntigo",
          },
          beginGroup: true,
        },
        {
          text: "Inventário",
          link: {
            rota: "/Inventario",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Cadastros",
          items: [
            {
              text: "Endereços de estoque",
              link: {
                rota: "/EnderecoEstoque",
                tipo: "linkMxpAntigo",
              },
            },
          ],
          beginGroup: true,
        },
      ],
    },
    {
      text: "Produção",
      icon: iconProducao,
      items: [
        {
          text: "Ordens de produção",
          link: {
            rota: "/OrdemProducao",
            tipo: "linkMxpAntigo",
          },
          atalho: {
            tecla: "O",
            alt: true,
          },
        },
        {
          text: "Ordens de produção [master]",
          link: {
            rota: "/producao/ordem-de-producao",
            tipo: "linkLocal",
          },
          visible: isUsuarioMaster,
        },
        {
          text: "Operações de ordens de produção [master]",
          link: {
            rota: "/producao/operacoes-de-ordem-de-producao",
            tipo: "linkLocal",
          },
          criadoEm: new Date(2024, 8, 12),
          visible: isUsuarioMaster,
        },
        {
          text: "Insumos das ordens de produção [master]",
          link: {
            rota: "/producao/insumos-das-ordens-de-producao",
            tipo: "linkLocal",
          },
          visible: isUsuarioMaster,
          criadoEm: new Date(2024, 7, 4),
        },
        {
          text: "Operações de ordens de produção e manutenção",
          link: {
            rota: "/OperacaoOrdemProducao",
            tipo: "linkMxpAntigo",
          },
          atalho: {
            tecla: "O",
            ctrl: true,
          },
        },
        {
          text: "Novo apontamento de operação",
          link: {
            rota: "_Menu_NovoApontamento",
            tipo: "javaScriptMxpAntigo",
          },
          atalho: {
            tecla: "A",
            alt: true,
          },
        },
        {
          text: "Ordens de manutenção",
          link: {
            rota: "/OrdemProducao/OrdemManutencao",
            tipo: "linkMxpAntigo",
          },
          beginGroup: true,
        },
        {
          text: "Estações de trabalho",
          link: {
            rota: "/Equipamento",
            tipo: "linkMxpAntigo",
          },
          beginGroup: true,
        },
        {
          text: "Centros de trabalho",
          link: {
            rota: "/producao/centro-de-trabalho",
            tipo: "linkLocal",
          },
        },
        {
          text: "Carga fábrica",
          link: {
            rota: "/CargaFabrica",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Grupos de centros de trabalho",
          link: {
            rota: "/producao/grupo-centro-de-trabalho",
            tipo: "linkLocal",
          },
        },
        {
          text: "Pessoas",
          link: {
            rota: "/Pessoa",
            tipo: "linkMxpAntigo",
          },
          beginGroup: true,
        },
        {
          text: "Apontamento de operações",
          link: {
            rota: "/ApontamentoHora?inicializaData=True",
            tipo: "linkMxpAntigo",
          },
          atalho: {
            tecla: "A",
            alt: true,
            shift: true,
          },
        },
        {
          text: "Rodar MRP",
          link: {
            rota: "_Menu_MRP",
            tipo: "javaScriptMxpAntigo",
          },
          beginGroup: true,
        },
        {
          text: "Rodar MRP [Master]",
          link: {
            rota: "/producao/mrp",
            tipo: "linkLocal",
          },
          visible: isUsuarioMaster,
        },
        {
          text: "Logs de execução do MRP",
          link: {
            rota: "/MRPLog",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Cadastros",
          items: [
            {
              text: "Calendários de grupos de CTs",
              link: {
                rota: "/CalendarioGrupoCT",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Eventos do calendário fabril",
              link: {
                rota: "/CalendarioEvento",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Motivos de parada",
              link: {
                rota: "/Parada",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Estados de ordem de produção",
              link: {
                rota: "/ValorOpcao/EstadosOrdemProducao",
                tipo: "linkMxpAntigo",
              },
              beginGroup: true,
            },
            {
              text: `${NomesTelas.estadoConfiguravelDaOrdemDeProducao} [Master]`,
              link: {
                rota: "/producao/estado-configuravel-da-ordem-de-producao",
                tipo: "linkLocal",
              },
              beginGroup: true,
              criadoEm: new Date(2024, 9, 5),
              visible: isUsuarioMaster,
            },
            {
              text: "Estados de ordem de manutenção",
              link: {
                rota: "/ValorOpcao/EstadosOrdemManutencao",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: NomesTelas.vinculosDoCentroDeTrabalhoComEnderecosDeEstoque,
              link: {
                rota: "/producao/vinculo-endereco-estoque-centro-de-trabalho",
                tipo: "linkLocal",
              },
              beginGroup: true,
            },
          ],
          beginGroup: true,
        },
      ],
    },
    {
      text: "Qualidade",
      icon: iconQualidade,
      items: [
        {
          text: "Processos de qualidade",
          link: {
            rota: "/OrdemProducao/ProcessosDeQualidade",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Etapas dos processos de qualidade",
          link: {
            rota: "/OperacaoOrdemProducao/IndexNC",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Boletins de inspeção",
          link: {
            rota: "/BoletimInspecao",
            tipo: "linkMxpAntigo",
          },
          beginGroup: true,
        },
        {
          text: "Cadastros",
          items: [
            {
              text: "Classes de processo de qualidade",
              link: {
                rota: "/Grupo/ClassesProcessosDeQualidade",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Níveis de qualidade de estoque",
              link: {
                rota: "/QualidadeInspecao",
                tipo: "linkMxpAntigo",
              },
            },
          ],
          beginGroup: true,
        },
      ],
    },
    {
      text: "Financeiro",
      icon: iconFinanceiro,
      items: [
        {
          text: "Contas a receber",
          link: {
            rota: "/Titulo/ContaReceber",
            tipo: "linkMxpAntigo",
          },
          atalho: {
            tecla: "R",
            alt: true,
          },
        },
        {
          text: "Contas a pagar",
          link: {
            rota: "/Titulo/ContaPagar",
            tipo: "linkMxpAntigo",
          },
          atalho: {
            tecla: "P",
            alt: true,
          },
        },
        {
          text: "Extrato",
          link: {
            rota: "/Extrato",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Extrato detalhado por contrapartida",
          link: {
            rota: "/ExtratoDetalhadoPorContrapartida?initializeWithFirstDayOfMonth=True",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Extrato detalhado por receita/despesa",
          link: {
            rota: "/DemonstrativoCaixaBancoPorReceitaDespesa?exibicao=A&isExtratoDetalhado=True",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Importar OFX",
          link: {
            rota: "/ItemOFX",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Fluxo de caixa",
          link: {
            rota: "/Titulo/FluxoCaixa",
            tipo: "linkMxpAntigo",
          },
          atalho: {
            tecla: "X",
            alt: true,
          },
        },
        {
          text: "Painel de controle financeiro",
          link: {
            rota: "/PainelControleFinanceiro",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Contas a receber detalhadas por receita",
          link: {
            rota: "/TituloDetalhado/ContasAReceber",
            tipo: "linkMxpAntigo",
          },
          beginGroup: true,
        },
        {
          text: "Contas a pagar detalhadas por despesa",
          link: {
            rota: "/TituloDetalhado/ContasAPagar",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Fluxo de caixa detalhado por receita/despesa",
          link: {
            rota: "/TituloDetalhado/FluxoCaixa",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Retenções de contas a receber",
          link: {
            rota: "/Titulo/RetencaoReceber",
            tipo: "linkMxpAntigo",
          },
          beginGroup: true,
        },
        {
          text: "Retenções de contas a pagar",
          link: {
            rota: "/Titulo/RetencaoPagar",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Cadastros",
          items: [
            {
              text: "Formas de cobrança e contas bancárias",
              link: {
                rota: "/ContaBancaria",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Moedas e índices",
              link: {
                rota: "/Moeda",
                tipo: "linkMxpAntigo",
              },
              beginGroup: true,
            },
            {
              text: "Taxas de câmbio e valores de índices",
              link: {
                rota: "/Cambio",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Grupos de campos adicionais de contas a receber",
              link: {
                rota: "/GrupoCampoAdicionalContaReceber",
                tipo: "linkMxpAntigo",
              },
              beginGroup: true,
              criadoEm: new Date(2024, 4, 24),
            },
            {
              text: "Campos adicionais de contas a receber",
              link: {
                rota: "/CampoAdicionalPadraoContaReceber",
                tipo: "linkMxpAntigo",
              },
              criadoEm: new Date(2024, 4, 24),
            },
            {
              text: "Grupos de campos adicionais de contas a pagar",
              link: {
                rota: "/GrupoCampoAdicionalContaPagar",
                tipo: "linkMxpAntigo",
              },
              criadoEm: new Date(2024, 4, 24),
            },
            {
              text: "Campos adicionais de contas a pagar",
              link: {
                rota: "/CampoAdicionalPadraoContaPagar",
                tipo: "linkMxpAntigo",
              },
              criadoEm: new Date(2024, 4, 24),
            },
          ],
          beginGroup: true,
        },
      ],
    },
    {
      text: "Fiscal",
      icon: iconFiscal,
      items: [
        {
          text: "Notas fiscais",
          items: [
            {
              text: "Notas fiscais e notas fiscais recebidas",
              link: {
                rota: "/NotaFiscal/Notas",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Itens das NFs e NFs recebidas agrupados por NF e operação fiscal",
              link: {
                rota: "/ItemNotaFiscal/AgrupadoPorNotaFiscalENotaFiscalRecebida",
                tipo: "linkMxpAntigo",
              },
              beginGroup: true,
            },
            {
              text: "Itens das NFs agrupados por NF e operação fiscal",
              link: {
                rota: "/ItemNotaFiscal/AgrupadoPorNotaFiscal",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Itens das NFs recebidas agrupados por NF e operação fiscal",
              link: {
                rota: "/ItemNotaFiscal/AgrupadoPorNotaFiscalRecebida",
                tipo: "linkMxpAntigo",
              },
            },
          ],
        },
        {
          text: "Apurações",
          items: [
            {
              text: "ICMS próprio",
              link: {
                rota: "/ApuracaoImposto/ICMS",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "ICMS por substituição tributária",
              link: {
                rota: "/ApuracaoImposto/ICMS_ST",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "ICMS por diferencial de alíquota origem/destino",
              link: {
                rota: "/ApuracaoImposto/ICMSDiferencialDeAliquotaOrigemDestino",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "ICMS por diferencial de alíquota FCP",
              link: {
                rota: "/ApuracaoImposto/ICMS_DEST_FCP",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "IPI",
              link: {
                rota: "/ApuracaoImposto/IPI",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "CIAP",
              link: {
                rota: "/fiscal/apuracao-ciap",
                tipo: "linkLocal",
              },
              criadoEm: new Date(2024, 10, 8),
            },
            {
              text: "PIS",
              link: {
                rota: "/ApuracaoPisCofins/Pis",
                tipo: "linkMxpAntigo",
              },
              beginGroup: true,
            },
            {
              text: "COFINS",
              link: {
                rota: "/ApuracaoPisCofins/Cofins",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "IRPJ",
              link: {
                rota: "/ApuracaoIRPJ",
                tipo: "linkMxpAntigo",
              },
              beginGroup: true,
            },
            {
              text: "CSLL",
              link: {
                rota: "/ApuracaoCSLL",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "LALUR e IRPJ do Lucro real",
              link: {
                rota: "/fiscal/apuracao-lalur-irpj",
                tipo: "linkLocal",
              },
              beginGroup: true,
              criadoEm: new Date(2024, 6, 9),
            },
            {
              text: "LACS e CSLL do Lucro real",
              link: {
                rota: "/fiscal/apuracao-lacs-csll",
                tipo: "linkLocal",
              },
              criadoEm: new Date(2024, 6, 9),
            },
            {
              text: "Informativo da composição de custos do Lucro Real",
              link: {
                rota: "/fiscal/informativo-composicao-custos-lucro-real",
                tipo: "linkLocal",
              },
              beginGroup: true,
              criadoEm: new Date(2024, 6, 8),
            },
          ],
          beginGroup: true,
        },
        {
          text: "EFD ICMS IPI (Sped fiscal)",
          link: {
            rota: "_Menu_SpedFiscal_Create",
            tipo: "javaScriptMxpAntigo",
          },
          beginGroup: true,
        },
        {
          text: "Correções de apontamentos de estoque de apurações anteriores",
          link: {
            rota: "/SpedRegistroK280",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Declarações de exportação",
          link: {
            rota: "/DeclaracaoExportacao",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "EFD Contribuições (Sped PIS/COFINS)",
          link: {
            rota: "_Menu_SpedContribuicoes_Create",
            tipo: "javaScriptMxpAntigo",
          },
          beginGroup: true,
        },
        {
          text: "Demonstrativo do PIS/COFINS e da base do IRPJ (lucro presumido)",
          link: {
            rota: "/DemonstrativoPisCofins",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Sintegra",
          link: {
            rota: "_Menu_Sintegra_Create",
            tipo: "javaScriptMxpAntigo",
          },
          beginGroup: true,
        },
        {
          text: "GIA",
          link: {
            rota: "_Menu_GIA_Create",
            tipo: "javaScriptMxpAntigo",
          },
        },
        {
          text: "MDF-e",
          link: {
            rota: "/fiscal/mdfe",
            tipo: "linkLocal",
          },
          beginGroup: true,
        },
        {
          text: "EFD REINF",
          items: [
            {
              text: "Apuração",
              link: {
                rota: "/fiscal/efd-reinf/apuracao",
                tipo: "linkLocal",
              },
              beginGroup: true,
            },
            {
              text: "R-2010 - Retenção contribuição previdenciária (INSS) - Tomadores de serviços",
              link: {
                rota: "/fiscal/efd-reinf/2010",
                tipo: "linkLocal",
              },
              criadoEm: new Date(2024, 2, 10),
              beginGroup: true,
            },
            {
              text: "R-2020 - Retenção contribuição previdenciária (INSS) - Prestadores de serviços",
              link: {
                rota: "/fiscal/efd-reinf/2020",
                tipo: "linkLocal",
              },
              criadoEm: new Date(2024, 2, 10),
            },
            {
              text: "R-4010 - Pagamentos/créditos a beneficiário pessoa física",
              criadoEm: new Date(2024, 2, 13),
              link: {
                rota: "/fiscal/efd-reinf/4010",
                tipo: "linkLocal",
              },
            },
            {
              text: NomesTelas.efdReinf4020,
              criadoEm: new Date(2024, 3, 14),
              link: {
                rota: "/fiscal/efd-reinf/4020",
                tipo: "linkLocal",
              },
            },
            {
              text: "R-4040 - Pagamentos/créditos a beneficiários não identificados",
              link: {
                rota: "/fiscal/efd-reinf/4040",
                tipo: "linkLocal",
              },
              criadoEm: new Date(2024, 2, 15),
            },
            {
              text: "R-4080 - Retenção no recebimento",
              criadoEm: new Date(2024, 3, 22),
              link: {
                rota: "/fiscal/efd-reinf/4080",
                tipo: "linkLocal",
              },
            },
            {
              text: "Configuração do certificado digital",
              link: {
                rota: "/sistema/certificado-digital",
                tipo: "linkLocal",
              },
              beginGroup: true,
            },
            {
              text: "Tipos de serviço SPED Reinf",
              link: {
                rota: "/TipoServico",
                tipo: "linkMxpAntigo",
              },
              beginGroup: true,
            },
            {
              text: "Naturezas do rendimento",
              link: {
                rota: "/fiscal/efd-reinf/natureza-do-rendimento",
                tipo: "linkLocal",
              },
            },
          ],
          beginGroup: true,
        },
        {
          text: "Cadastros",
          items: [
            {
              text: "Operações fiscais",
              link: {
                rota: "/OperacaoFiscal",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Categorias especiais das operações fiscais",
              link: {
                rota: "/ValorOpcao/CategoriasEspeciaisOperacaoFiscal",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Exceções para as operações fiscais dos itens",
              link: {
                rota: "/OperacaoFiscalExcecao",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "NCMs",
              link: {
                rota: "/ClassificacaoFiscal",
                tipo: "linkMxpAntigo",
              },
              beginGroup: true,
            },
            {
              text: "Valores aproximados dos tributos",
              link: {
                rota: "/NCMPropriaEmpresa",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Alíquotas internas de ICMS e FCP",
              link: {
                rota: "/NCMUF",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Reduções da base de cálculo do ICMS",
              link: {
                rota: "/NCMUForigemUFDestino",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Informações adicionais",
              link: {
                rota: "/InformacaoAdicional",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "CESTs",
              link: {
                rota: "/CodigoSubstituicaoTributaria",
                tipo: "linkMxpAntigo",
              },
              beginGroup: true,
            },
            {
              text: "ICMS ST e MVAs",
              link: {
                rota: "/MargemValorAgregado",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Cargas médias de ICMS ST",
              link: {
                rota: "/CargaMediaDeICMS_ST",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Situações fiscais especiais",
              link: {
                rota: "/ValorOpcao/SituacaoFiscalEspecial",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Inscrições estaduais de substituto tributário",
              link: {
                rota: "/UFPropriaEmpresa",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Configurações do cálculo do ICMS DIFAL por UF",
              link: {
                rota: "/UFConfiguracao",
                tipo: "linkMxpAntigo",
              },
              beginGroup: true,
            },
            {
              text: "Códigos de tributação de serviços",
              link: {
                rota: "/tributacao/codigo-tributacao-servico",
                tipo: "linkLocal",
              },
              beginGroup: true,
            },
            {
              text: "Códigos de benefícios fiscais na UF",
              link: {
                rota: "/ValorOpcao/BeneficiosFiscaisNaUF",
                tipo: "linkMxpAntigo",
              },
              beginGroup: true,
            },
            {
              text: "Séries de documentos fiscais",
              link: {
                rota: "/fiscal/serie-fiscal",
                tipo: "linkLocal",
              },
              beginGroup: true,
            },
            {
              text: "Condutores",
              link: {
                rota: "/Pessoa/Condutor",
                tipo: "linkMxpAntigo",
              },
              beginGroup: true,
            },
            {
              text: "Veículos",
              link: {
                rota: "/vendas/veiculo",
                tipo: "linkLocal",
              },
            },
            {
              text: "Conjunto de veículos",
              link: {
                rota: "/vendas/conjunto-veiculo",
                tipo: "linkLocal",
              },
            },
            {
              text: NomesTelas.cfop,
              onClick: () => {
                navegarPara("/fiscal/cfop");
              },
              beginGroup: true,
            },
          ],
          beginGroup: true,
        },
      ],
    },
    {
      text: "Contabilidade",
      icon: iconContabilidade,
      items: [
        {
          text: "Lançamentos contábeis",
          link: {
            rota: "/LancamentoContabil",
            tipo: "linkMxpAntigo",
          },
          atalho: {
            tecla: "L",
            alt: true,
          },
        },
        {
          text: "Razão",
          link: {
            rota: "/Razao",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Balancete contábil",
          link: {
            rota: "/Balancete?tipoFluxo=C",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Balancete comparativo",
          link: {
            rota: "/contabilidade/balancete-comparativo",
            tipo: "linkLocal",
          },
          criadoEm: new Date(2024, 2, 5),
        },
        {
          text: "Balancete auxiliar por cliente/fornecedor",
          link: {
            rota: "/BalanceteAuxiliarClienteFornecedor",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Balancete por centro de custos",
          link: {
            rota: "/Balancete?tipoFluxo=E",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Demonstrativo do caixa/banco detalhado por receita/despesa (sintético)",
          link: {
            rota: "/DemonstrativoCaixaBancoPorReceitaDespesa",
            tipo: "linkMxpAntigo",
          },
          beginGroup: true,
        },
        {
          text: "Demonstrativo do caixa/banco detalhado por receita/despesa (analítico)",
          link: {
            rota: "/DemonstrativoCaixaBancoPorReceitaDespesa?exibicao=A",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "DRE",
          link: {
            rota: "/DemonstracaoContabilCalculo/DRE",
            tipo: "linkMxpAntigo",
          },
          beginGroup: true,
        },
        {
          text: "Balanço patrimonial",
          link: {
            rota: "/DemonstracaoContabilCalculo/BalancoPatrimonial",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Fechamentos contábeis",
          link: {
            rota: "/FechamentoContabil",
            tipo: "linkMxpAntigo",
          },
          beginGroup: true,
        },
        {
          text: "ECD (Sped Contábil)",
          link: {
            rota: "_Menu_SpedContabil_Create",
            tipo: "javaScriptMxpAntigo",
          },
          beginGroup: true,
        },
        {
          text: "ECF",
          link: {
            rota: "/contabilidade/ecf",
            tipo: "linkLocal",
          },
        },
        {
          text: "Imobilizado",
          link: {
            rota: "/contabilidade/imobilizado",
            tipo: "linkLocal",
          },
          criadoEm: new Date(2024, 6, 23),
          beginGroup: true,
        },
        {
          text: "Cadastros",
          items: [
            {
              text: "Contas contábeis",
              link: {
                rota: "/Conta?tipoFluxo=C",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Centros de custos",
              link: {
                rota: "/Conta?tipoFluxo=E",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Vínculos entre contas contábeis e centro de custos",
              link: {
                rota: "/VinculoContaContabilCentroCusto",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Códigos de aglutinação",
              link: {
                rota: "/ValorOpcao/CodigosDeAglutinacao",
                tipo: "linkMxpAntigo",
              },
              beginGroup: true,
            },
            {
              text: "Balanços patrimoniais",
              link: {
                rota: "/DemonstracaoContabil/BalancoPatrimonial",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "DREs",
              link: {
                rota: "/DemonstracaoContabil/DRE",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "DMPLs",
              link: {
                rota: "/DemonstracaoContabil/DMPL",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Fatos contábeis",
              link: {
                rota: "/ValorOpcao/FatosContabeis",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Contas da parte B do Lucro Real",
              link: {
                rota: "/contabilidade/contas-da-parte-b",
                tipo: "linkLocal",
              },
              criadoEm: new Date(2024, 4, 28),
              beginGroup: true,
            },
          ],
          beginGroup: true,
        },
      ],
    },
    {
      icon: "fa-solid fa-gear",
      items: [
        {
          text: "Configurações",
          link: {
            rota: "/Opcoes/Edit",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Editar minha empresa",
          link: {
            rota: "_Menu_Opcoes_Empresa_Edit",
            tipo: "javaScriptMxpAntigo",
            paramFunction: () => {
              const empresaId = store.getState().sessao.dadosSessao?.empresa.id;
              return `($(this),${empresaId})`;
            },
          },
          beginGroup: true,
        },
        {
          text: "Minhas empresas",
          link: {
            rota: "/Empresa/MinhasEmpresas",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Editar meu usuário",
          link: {
            rota: "_Menu_Opcoes_Usuario_Edit",
            tipo: "javaScriptMxpAntigo",
            paramFunction: () => {
              const usuarioId = store.getState().sessao.dadosSessao?.usuario.id;
              return `($(this),${usuarioId})`;
            },
          },
          beginGroup: true,
        },
        {
          text: "Usuários",
          link: {
            rota: "/Usuario",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Perfis de acesso",
          link: {
            rota: "/Perfil",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Perfis de acesso x relatórios",
          link: {
            rota: "/PerfilRelatorio",
            tipo: "linkMxpAntigo",
          },
        },
        {
          text: "Usuários logados",
          link: {
            rota: "/controle-de-acesso/usuarioslogados",
            tipo: "linkLocal",
          },
        },
        {
          text: "API",
          link: {
            rota: "/APIDescricao",
            tipo: "linkMxpAntigo",
          },
          beginGroup: true,
        },
        {
          text: "Gerar token de acesso à API GraphQL",
          link: {
            rota: "/usuario",
            tipo: "linkLocal",
          },
        },
        {
          text: "Modelos de e-mail",
          link: {
            rota: "/ModeloEmail",
            tipo: "linkMxpAntigo",
          },
          beginGroup: true,
        },
        {
          text: "E-mails bloqueados",
          link: {
            rota: "/EmailBloqueado",
            tipo: "linkMxpAntigo",
          },
          beginGroup: true,
        },
        {
          text: "Cadastros",
          items: [
            {
              text: "Documentos",
              link: {
                rota: "/Documento",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Conjuntos de opções",
              link: {
                rota: "/ConjuntoOpcoes",
                tipo: "linkMxpAntigo",
              },
              beginGroup: true,
            },
            {
              text: "Feriados",
              link: {
                rota: "/Feriado",
                tipo: "linkMxpAntigo",
              },
              beginGroup: true,
            },
            {
              text: "Usuários no e-commerce/Mercos",
              link: {
                rota: "/UsuarioPropriaEmpresa",
                tipo: "linkMxpAntigo",
              },
              beginGroup: true,
            },
            {
              text: "Pessoas",
              link: {
                rota: "/Pessoa",
                tipo: "linkMxpAntigo",
              },
              beginGroup: true,
            },
            {
              text: "Municípios",
              link: {
                rota: "/Municipio",
                tipo: "linkMxpAntigo",
              },
              beginGroup: true,
            },
          ],
        },
        {
          text: "Instalar",
          items: [
            {
              text: "Integração com SolidWorks",
              link: {
                rota: "/Plugin/SolidWorks",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Integração com Inventor",
              link: {
                rota: "/Plugin/Inventor",
                tipo: "linkMxpAntigo",
              },
            },
            {
              text: "Integração com Balança",
              link: {
                rota: "/Plugin/MxpBalanca",
                tipo: "linkMxpAntigo",
              },
              beginGroup: true,
            },
            {
              text: "Gerenciador de certificados digitais",
              link: {
                rota: "/Plugin/GerCert",
                tipo: "linkMxpAntigo",
              },
              beginGroup: true,
            },
            {
              text: "TeamViewer versão 11",
              link: {
                rota: "_Menu_Opcoes_InstalarTeamViewerV11",
                tipo: "javaScriptMxpAntigo",
              },
              beginGroup: true,
            },
            {
              text: "AnyDesk",
              link: {
                rota: "_Menu_Opcoes_InstalarAnyDesk",
                tipo: "javaScriptMxpAntigo",
              },
            },
          ],
        },
        {
          text: "Minha assinatura",
          items: [
            {
              text: "Termos de uso",
              link: {
                rota: "_Assinante_TermosDeUso",
                tipo: "javaScriptMxpAntigo",
              },
            },
            {
              text: "Perguntas frequentes",
              link: {
                rota: "https://maxiprod.com.br/perguntas-frequentes/",
                tipo: "linkPaginaExterna",
              },
            },
          ],
        },
        {
          text: "Sobre",
          onClick: () => store.dispatch(exibirModalSobre()),
          beginGroup: true,
        },
      ],
    },
    {
      icon: "fa-regular fa-circle-question",
      items: [
        {
          text: "Central de ajuda",
          link: {
            rota: "https://maxiprod.com.br/central-de-ajuda/",
            tipo: "linkPaginaExterna",
          },
        },
        {
          text: "Implementação passo a passo",
          link: {
            rota: "https://maxiprod.com.br/ajuda/primeiros-passos/implantacao-do-erp-maxiprod/",
            tipo: "linkPaginaExterna",
          },
          beginGroup: true,
        },
        {
          text: "Perguntas frequentes",
          link: {
            rota: "https://maxiprod.com.br/perguntas-frequentes/",
            tipo: "linkPaginaExterna",
          },
        },
      ],
    },
    {
      icon: "fa-regular fa-comments",
      items: [
        {
          text: "Chat",
          link: {
            rota: "_FaleConosco_ExibeMovidesk",
            tipo: "javaScriptMxpAntigo",
          },
        },
        {
          text: "Ticket/telefone",
          link: {
            rota: "_FaleConosco_ExibeMovidesk",
            tipo: "javaScriptMxpAntigo",
          },
        },
        {
          text: "Whatsapp",
          link: {
            rota: "https://wa.me/555130192936",
            tipo: "linkPaginaExterna",
          },
        },
      ],
    },
  ];

  return gerarLinksMenu(dados, navegarPara, mobile);
}
