import ModalGerenciarModelosDeRelatoriosPersonalizados from "../../../../sistema/modelo-de-relatorio/componentes/modal-gerenciamento";
import ModalDicionarioTags from "../modal-dicionario-tags";
import ModalGerarRelatorioPersonalizado from "../modal-impressao";

export default function ControleImpressaoRelatoriosPersonalizados() {
  return (
    <>
      <ModalGerenciarModelosDeRelatoriosPersonalizados />
      <ModalGerarRelatorioPersonalizado />
      <ModalDicionarioTags />
    </>
  );
}
