import { ValueChangedEvent } from "devextreme/ui/check_box";
import { useCallback, useEffect, useState } from "react";
import {
  FormCheckBox,
  FormDateBox,
  FormTextBox,
} from "../../../../../../components/formularios";
import { ItemSelectionChangedType } from "../../../../../../components/formularios/selectbox-lazy-mxp";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import TabContainer from "../../../../../../components/layout/tab-container";
import { DadosConfiguracoesEstoque } from "../../../../../../models/api/tokens/configuracoes";
import ConfiguracoesServico from "../../../../../../services/configuracoes/configuracoes.service";
import { useSubFormContext } from "../../../../../../utils/form/subformContext";
import { DataSourceOpcoesBuilder } from "../../../../../../utils/grid/data-source-factory";
import ComboItemMxp from "../../../../../itens/item/componentes/select-box-lazy";
import { ItemGridModel } from "../../../../../itens/item/models/item.api";
import ItensFiltrosHelpers from "../../../../../itens/item/utils/filtros/itens-filtros-helpers";
import PrefixoESufixoDeLoteService from "../../../../../itens/prefixo-e-sufixo-de-lote/servicos/prefixo-e-sufixo-de-lote.servico";
import ComboEmpresaMxp from "../../../../../sistema/empresa/componentes/select-box-lazy";
import {
  DadosRastreabilidadeLoteRequest,
  LoteRequestBase,
} from "../../../models/lote.api";
import LoteContantes from "../../../models/lote.constantes";

interface LoteAbaDadosGeraisProps {
  isRegistroEmEdicao: boolean;
}

const dataSourceOpcoesItem: DataSourceOpcoesBuilder<ItemGridModel> = {
  camposRetorno: [
    "id",
    "codigo",
    "descricao",
    "custo",
    "estocagemPor",
    "utilizaPrefixoESufixo",
  ],
  camposFiltro:
    ItensFiltrosHelpers.FiltrarAtivosSemProcedenciaAgregadaOuManutencao
      .camposFiltro,
};

const ApiConfiguracoes = new ConfiguracoesServico();
const prefixoESufixoDeLoteService = new PrefixoESufixoDeLoteService();

export default function LoteAbaDadosGerais(props: LoteAbaDadosGeraisProps) {
  const { hookForms, isSubform, obterNomeDaPropriedade } = useSubFormContext<
    LoteRequestBase,
    DadosRastreabilidadeLoteRequest
  >();
  const { getValues, watch, setValue, trigger, control } = hookForms;

  const [loteDoFabricanteOuNumeroDeSerie, setLoteDoFabricanteOuNumeroDeSerie] =
    useState("");

  const [configuracoesEstoque, setConfiguracoesEstoque] = useState<
    DadosConfiguracoesEstoque | undefined
  >(undefined);

  const [utilizaPrefixoDesabilitado, setUtilizaPrefixoDesabilitado] =
    useState(false);

  useEffect(() => {
    if (configuracoesEstoque) {
      return;
    }

    carregarConfiguracoesEstoque();
  }, []);

  const carregarConfiguracoesEstoque = async () => {
    setConfiguracoesEstoque(
      await ApiConfiguracoes.ObterConfiguracoesDeEstoque()
    );
  };

  useEffect(() => {
    if (!configuracoesEstoque) {
      return;
    }
    setValue(
      "loteDataRequerido",
      configuracoesEstoque.loteDatasRequerido ?? false
    );
    setValue(
      "controleLoteFabricacao",
      configuracoesEstoque.controleLoteFabricacao ?? false
    );
  }, [
    configuracoesEstoque,
    watch("loteDataRequerido"),
    watch("controleLoteFabricacao"),
  ]);

  const itemIdInicialDoLoteEmEdicao = props.isRegistroEmEdicao
    ? (getValues("itemId") as number)
    : undefined;

  async function utilizaPrefixoCheckBoxHandler(e: ValueChangedEvent) {
    if (!e.event) {
      return;
    }

    const itemId = getValues("itemId");

    if (itemId && e.value == true) {
      const forcarObterLoteDoFabricanteOuNumeroSerie = true;
      ajustaLoteDoFabricanteOuNumeroDeSerieComPrefixoESufixo(
        itemId,
        forcarObterLoteDoFabricanteOuNumeroSerie
      );
    }

    const loteDoFabricanteOuNumeroDeSerieTexto =
      loteDoFabricanteOuNumeroDeSerie ||
      (getValues("loteDoFabricanteOuNumeroDeSerie") as string);

    setValue(
      "loteDoFabricanteOuNumeroDeSerie",
      e.value ? loteDoFabricanteOuNumeroDeSerieTexto : ""
    );
  }

  const onItemChanged = useCallback(
    async (e: ItemSelectionChangedType<ItemGridModel>) => {
      const itemSelecionado = e.selectedItem as ItemGridModel;

      if (props.isRegistroEmEdicao) {
        habilitaDesabilitaUtilizaPrefixo(itemSelecionado);
        return;
      }

      if (itemSelecionado.utilizaPrefixoESufixo) {
        ajustaLoteDoFabricanteOuNumeroDeSerieComPrefixoESufixo(
          itemSelecionado.id
        );
      } else {
        setValue("loteDoFabricanteOuNumeroDeSerie", "");
      }

      inicializarCheckboxUtilizaPrefixoConformeItemSelecionado(itemSelecionado);
    },
    []
  );

  function inicializarCheckboxUtilizaPrefixoConformeItemSelecionado(
    itemSelecionado: ItemGridModel
  ) {
    setValue("utilizaPrefixo", itemSelecionado.utilizaPrefixoESufixo);

    habilitaDesabilitaUtilizaPrefixo(itemSelecionado);

    trigger("utilizaPrefixo");
  }

  function habilitaDesabilitaUtilizaPrefixo(itemSelecionado: ItemGridModel) {
    if (!itemSelecionado.utilizaPrefixoESufixo) {
      setUtilizaPrefixoDesabilitado(true);
    } else {
      setUtilizaPrefixoDesabilitado(false);
    }
  }

  async function ajustaLoteDoFabricanteOuNumeroDeSerieComPrefixoESufixo(
    itemId: number,
    forcar: boolean = false
  ) {
    if (itemId == itemIdInicialDoLoteEmEdicao && !forcar) {
      setLoteDoFabricanteOuNumeroDeSerie(
        getValues("loteDoFabricanteOuNumeroDeSerie") as string
      );

      return;
    }

    if (itemId > 0) {
      const prefixoESufixoDeLoteDoItem =
        await prefixoESufixoDeLoteService.ObterPrefixoESufixoDeLoteDoItem(
          itemId
        );

      setLoteDoFabricanteOuNumeroDeSerie(prefixoESufixoDeLoteDoItem);

      if (watch("utilizaPrefixo")) {
        setValue("loteDoFabricanteOuNumeroDeSerie", prefixoESufixoDeLoteDoItem);
        trigger("loteDoFabricanteOuNumeroDeSerie");
      }
    }
  }

  return (
    <TabContainer>
      <Linha>
        <div style={{ display: isSubform ? "none" : "contents" }}>
          <Coluna md={6}>
            <ComboItemMxp
              name={obterNomeDaPropriedade("itemId")}
              titulo="Item"
              control={control}
              requerido
              somenteLeitura={props.isRegistroEmEdicao || isSubform}
              dataSourceOpcoes={dataSourceOpcoesItem}
              onSelectionChanged={onItemChanged}
            />
          </Coluna>
        </div>

        <Coluna md={6}>
          <ComboEmpresaMxp
            name={obterNomeDaPropriedade("fabricanteId")}
            titulo="Fabricante"
            control={control}
            requerido
            tituloSeletor="Selecionar fabricante"
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={6}>
          <FormTextBox
            name={obterNomeDaPropriedade("loteDoFabricanteOuNumeroDeSerie")}
            titulo="Lote do fabricante / número de série"
            control={control}
            requerido
            tamanhoMaximo={
              LoteContantes.LoteDoFabricanteOuNumeroDeSerieMaxLength
            }
            somenteLeitura={watch("utilizaPrefixo")}
          />
        </Coluna>
        <Coluna md={6} centralizar>
          <FormCheckBox
            name={obterNomeDaPropriedade("utilizaPrefixo")}
            titulo="Utilizar prefixo e sufixo do item"
            control={control}
            requerido
            onValueChanged={utilizaPrefixoCheckBoxHandler}
            desabilitado={utilizaPrefixoDesabilitado}
          />
        </Coluna>
      </Linha>
      {watch("controleLoteFabricacao") && (
        <Linha>
          <Coluna md={3}>
            <FormDateBox
              name={obterNomeDaPropriedade("fabricacaoData")}
              titulo="Data de fabricação"
              control={control}
              aceitaValorCustomizado={true}
              aceitaDigitacaoComMascara={true}
              requerido={watch("loteDataRequerido")}
            />
          </Coluna>
          <Coluna md={3}>
            <FormDateBox
              name={obterNomeDaPropriedade("vencimentoData")}
              titulo="Data de vencimento"
              control={control}
              aceitaValorCustomizado={true}
              aceitaDigitacaoComMascara={true}
              requerido={watch("loteDataRequerido")}
            />
          </Coluna>
          <Coluna md={3}>
            <FormDateBox
              name={obterNomeDaPropriedade("reanaliseData")}
              titulo="Data de reanálise"
              control={control}
              aceitaValorCustomizado={true}
              aceitaDigitacaoComMascara={true}
            />
          </Coluna>
        </Linha>
      )}
    </TabContainer>
  );
}
