import { useEffect } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import BalanceteComparativoPage from "../../features/contabilidade/balancete-comparativo/paginas/listagem";
import ContaDaParteBPage from "../../features/contabilidade/conta-parte-b/paginas/listagem";
import { ModalEcf } from "../../features/contabilidade/ecf/componentes/modal";
import ImobilizadoPage from "../../features/contabilidade/imobilizado/paginas/listagem";
import LotePage from "../../features/estoque/lote/paginas/listagem";
import CfopPage from "../../features/fiscal/cfop/paginas/listagem";
import CodigoTributacaoServicoPage from "../../features/fiscal/codigo-tributacao-servico/paginas/listagem";
import EfdReinfApuracaoPage from "../../features/fiscal/efd-reinf/paginas/listagem/apuracao";
import EfdReinf2010Page from "../../features/fiscal/efd-reinf/paginas/listagem/efd-reinf-2010";
import EfdReinf2020Page from "../../features/fiscal/efd-reinf/paginas/listagem/efd-reinf-2020";
import EfdReinf4010Page from "../../features/fiscal/efd-reinf/paginas/listagem/efd-reinf-4010";
import EfdReinf4020Page from "../../features/fiscal/efd-reinf/paginas/listagem/efd-reinf-4020";
import EfdReinf4040Page from "../../features/fiscal/efd-reinf/paginas/listagem/efd-reinf-4040";
import EfdReinf4080Page from "../../features/fiscal/efd-reinf/paginas/listagem/efd-reinf-4080";
import NaturezaDoRendimentoPage from "../../features/fiscal/efd-reinf/paginas/listagem/natureza-do-rendimento";
import InformativoComposicaoCustosLucroRealPage from "../../features/fiscal/informativo-composicao-custos-lucro-real/paginas/listagem";
import SerieFiscalPage from "../../features/fiscal/serie-fiscal/paginas/listagem";
import PagePrefixoESufixoDeLote from "../../features/itens/prefixo-e-sufixo-de-lote/paginas/listagem";
import CentroDeTrabalhoPage from "../../features/producao/centro-de-trabalho/paginas/listagem";
import EstadoConfiguravelDaOrdemDeProducaoPage from "../../features/producao/estado-configuravel-da-ordem-de-producao/paginas/listagem";
import CentroDeTrabalhoGrupoPage from "../../features/producao/grupo-de-centro-de-trabalho/paginas/listagem";
import InsumosDasOrdensDeProducaoPage from "../../features/producao/insumo-da-ordem-de-producao/paginas/listagem";
import OperacaoDeOrdemDeProducaoPage from "../../features/producao/operacao-da-ordem-de-producao/paginas/listagem";
import OrdemDeProducaoPage from "../../features/producao/ordem-de-producao/paginas/listagem";
import VinculoEnderecoEstoqueCentroDeTrabalhoPage from "../../features/producao/vinculo-endereco-estoque-centro-de-trabalho/paginas/listagem";
import LogoutRedirect from "../../features/seguranca/login/componentes/logout";
import OauthAutenticadoRedirect from "../../features/seguranca/login/componentes/oauth-autenticado-redirect";
import RecuperacaoDeSenhaViaEmailPage from "../../features/seguranca/login/componentes/recuperacao-senha-via-email";
import { SingleSignOnAutenticado } from "../../features/seguranca/login/componentes/SingleSignOn";
import TrocaEmpresaRedirect from "../../features/seguranca/login/componentes/troca-empresa-redirect";
import UsuarioPage from "../../features/seguranca/usuario/paginas/listagem";
import UsuariosLogadosPage from "../../features/seguranca/usuarios-logados/paginas/listagem";
import CertificadoDigitalPage from "../../features/sistema/certificado-digital/paginas/listagem";
import ConjuntoVeiculoPage from "../../features/vendas/conjunto-veiculo/paginas/listagem";
import VeiculoPage from "../../features/vendas/veiculo/paginas/listagem";
import { useAppDispatch, useAppSelector } from "../../hooks/store.hooks";

import LinkDeAjudaService from "../../features/ajuda/ajuda-paginas/servicos/link-ajuda.service";
import ApuracaoDoCiapPage from "../../features/fiscal/apuracao-ciap/paginas/listagem";
import ApuracaoLacsCsllPage from "../../features/fiscal/apuracao-lacs-csll/paginas/listagem";
import ApuracaoLalurIrpjPage from "../../features/fiscal/apuracao-lalur-irpj/paginas/listagem";
import DicionarioDeTagsPage from "../../features/relatorio/relatorio-personalizado/paginas/dicionario-tags";
import MdfePage from "../../pages/fiscal/mdfe";
import HomePage from "../../pages/home";
import NotFoundPage from "../../pages/outras/not-found";
import { ModalMrp } from "../../pages/producao/mrp";
import AgregacaoDeModais from "../../parts/layout/agregacao-de-modais";
import MenuNav from "../../parts/layout/menu-nav/menu-nav";
import { definirLinksAjuda } from "../../store/ui/ui.slice";
import { checarResponse, tratarErroApi } from "../../utils/api/api-utils";

const linkAjudaService = new LinkDeAjudaService();

export default function RotasAutenticado() {
  const appName = process.env.REACT_APP_APP_NAME as string;
  const simplificado = useAppSelector(
    (state) => !!state.sessao.dadosSessao?.simplificado
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    async function carregarLinksAjuda() {
      try {
        const resposta = await linkAjudaService.ObterListaSimples();
        if (checarResponse(resposta)) {
          dispatch(definirLinksAjuda(resposta.model));
        }
      } catch (e) {
        tratarErroApi(e);
      }
    }

    carregarLinksAjuda();
  }, []);

  return (
    <Router>
      <MenuNav title={appName}>
        <AgregacaoDeModais />
        <Routes>
          <Route path="/oauth" element={<OauthAutenticadoRedirect />} />
          <Route path="/logout" element={<LogoutRedirect />} />
          <Route path="/singlesignon" element={<SingleSignOnAutenticado />} />
          <Route path="/autologin" element={<Navigate to="/" />} />
          <Route
            path="/RecuperarSenha"
            element={<RecuperacaoDeSenhaViaEmailPage />}
          />
          <Route
            path="/controle-de-acesso/usuarioslogados"
            element={<UsuariosLogadosPage />}
          />
          {simplificado && (
            <Route
              path="*"
              element={<Navigate to="/controle-de-acesso/usuarioslogados" />}
            />
          )}
          {!simplificado && (
            <>
              <Route path="/" element={<HomePage />} />
              <Route path="*" element={<NotFoundPage />} />
              <Route path="troca-empresa" element={<TrocaEmpresaRedirect />} />
              <Route
                path="/tributacao/codigo-tributacao-servico/:id?"
                element={<CodigoTributacaoServicoPage />}
              />
              <Route path="/vendas/veiculo" element={<VeiculoPage />} />
              <Route
                path="/vendas/conjunto-veiculo"
                element={<ConjuntoVeiculoPage />}
              />
              <Route path="/fiscal/mdfe" element={<MdfePage />} />
              <Route path="/usuario" element={<UsuarioPage />} />
              <Route path="/estoque/lote/:id?" element={<LotePage />} />

              <Route
                path="/producao/ordem-de-producao/:id?"
                element={<OrdemDeProducaoPage />}
              />
              <Route
                path="/producao/operacoes-de-ordem-de-producao/:id?"
                element={<OperacaoDeOrdemDeProducaoPage />}
              />
              <Route
                path="/producao/insumos-das-ordens-de-producao"
                element={<InsumosDasOrdensDeProducaoPage />}
              />
              <Route
                path="/itens/prefixo-e-sufixo-de-lote"
                element={<PagePrefixoESufixoDeLote />}
              />
              <Route path="/producao/centro-de-trabalho/:id?">
                <Route
                  path="novo"
                  element={<CentroDeTrabalhoPage abrirModalNovoRegistro />}
                />
                <Route path="" element={<CentroDeTrabalhoPage />} />
              </Route>
              <Route
                path="/producao/grupo-centro-de-trabalho/:id?"
                element={<CentroDeTrabalhoGrupoPage />}
              />
              <Route path="/fiscal/cfop" element={<CfopPage />} />
              <Route
                path="/fiscal/serie-fiscal"
                element={<SerieFiscalPage />}
              />
              <Route
                path="/producao/vinculo-endereco-estoque-centro-de-trabalho/:id?"
                element={<VinculoEnderecoEstoqueCentroDeTrabalhoPage />}
              />
              <Route
                path="/sistema/certificado-digital"
                element={<CertificadoDigitalPage />}
              />
              <Route
                path="/fiscal/efd-reinf/natureza-do-rendimento"
                element={<NaturezaDoRendimentoPage />}
              />
              <Route
                path="/fiscal/efd-reinf/apuracao"
                element={<EfdReinfApuracaoPage />}
              />
              <Route
                path="/contabilidade/balancete-comparativo"
                element={<BalanceteComparativoPage />}
              />
              <Route
                path="/fiscal/efd-reinf/2010"
                element={<EfdReinf2010Page />}
              />
              <Route
                path="/fiscal/efd-reinf/2020"
                element={<EfdReinf2020Page />}
              />
              <Route
                path="/fiscal/efd-reinf/4080"
                element={<EfdReinf4080Page />}
              />
              <Route
                path="/fiscal/efd-reinf/4020"
                element={<EfdReinf4020Page />}
              />
              <Route
                path="/fiscal/efd-reinf/4010"
                element={<EfdReinf4010Page />}
              />
              <Route
                path="/fiscal/efd-reinf/4040"
                element={<EfdReinf4040Page />}
              />
              <Route path="/producao/mrp" element={<ModalMrp />} />
              <Route path="/contabilidade/ecf" element={<ModalEcf />} />
              <Route
                path="/contabilidade/contas-da-parte-b"
                element={<ContaDaParteBPage />}
              />
              <Route
                path="/fiscal/apuracao-lacs-csll"
                element={<ApuracaoLacsCsllPage />}
              />
              <Route
                path="/fiscal/apuracao-lalur-irpj"
                element={<ApuracaoLalurIrpjPage />}
              />
              <Route
                path="/fiscal/informativo-composicao-custos-lucro-real"
                element={<InformativoComposicaoCustosLucroRealPage />}
              />
              <Route
                path="/contabilidade/imobilizado"
                element={<ImobilizadoPage />}
              />
              <Route
                path="/fiscal/apuracao-ciap"
                element={<ApuracaoDoCiapPage />}
              />
              <Route
                path="/producao/estado-configuravel-da-ordem-de-producao"
                element={<EstadoConfiguravelDaOrdemDeProducaoPage />}
              />
              <Route
                path="/relatorio/dicionario-tags/:tipo"
                element={<DicionarioDeTagsPage />}
              />
            </>
          )}
        </Routes>
      </MenuNav>
    </Router>
  );
}
