import { yupResolver } from "@hookform/resolvers/yup";
import { FileUploaderRef } from "devextreme-react/cjs/file-uploader";
import { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import BotaoSalvarMxp from "../../../../../components/botoes/botao-salvar-mxp";
import {
  FormFile,
  FormGrupo,
  FormTextBox,
} from "../../../../../components/formularios";
import FormMxp from "../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import AuditavelDTO from "../../../../../models/api/comum/auditavel-dto";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../models/shared/ui/formularios";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { StringsComum } from "../../../../comum/strings";
import RelatorioPersonalizadoContexto from "../../../../relatorio/relatorio-personalizado/contexts/relatorio-personalizado.context";
import { FinalidadeModeloRelatorio } from "../../../../relatorio/relatorio-personalizado/models/relatorio-personalizado.enums";
import { ModeloDeRelatorioRequest } from "../../models/modelo-de-relatorio.api";
import ModeloDeRelatorioConstantes from "../../models/modelo-de-relatorio.constantes";
import { ModeloDeRelatorioPermissoes } from "../../models/modelo-de-relatorio.enums";
import ModeloDeRelatorioServico from "../../servicos/modelo-de-relatorio.servico";

const novoRegistro: ModeloDeRelatorioRequest = {
  id: 0,
  descricao: "",
  finalidade: undefined,
  arquivo: undefined,
};

const servico = new ModeloDeRelatorioServico();

let dadosAuditoria: AuditavelDTO | undefined = undefined;

interface FormModeloDeRelatorioProps extends IFormularioEditavelBase {
  finalidade: FinalidadeModeloRelatorio;
}

export default function FormModeloDeRelatorio(
  props: FormModeloDeRelatorioProps
) {
  const { carregarMenu } = useContext(RelatorioPersonalizadoContexto);

  const [carregando, setCarregando] = useState(false);
  const refUploader = useRef<FileUploaderRef>(null);

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    descricao: yup
      .string()
      .nullable()
      .max(ModeloDeRelatorioConstantes.DescricaoTamanhoMaximo),
    finalidade: yup
      .mixed<FinalidadeModeloRelatorio>()
      .oneOf(
        Object.values(FinalidadeModeloRelatorio).map((x) => x as number),
        StringsComum.valorInvalido
      )
      .required(),
    arquivo: yup
      .mixed<File>()
      .when(["id"], (valores, schema) => {
        if (valores[0] === 0) {
          // Obrigatório na inserção
          return schema.required();
        } else {
          return schema.notRequired();
        }
      })
      .test(
        "fileSize",
        "Tamanho máximo aceito do arquivo é de 10MB.",
        (value) => {
          if (!value) {
            return true;
          }

          return (
            value.size <=
            ModeloDeRelatorioConstantes.TamanhoMaximoDoArquivoEmBytes
          );
        }
      )
      .test("fileType", "Extensões aceitas: .docx e .xlsx.", (value) => {
        if (!value) {
          return true;
        }

        return (
          value.type == ModeloDeRelatorioConstantes.ContentTypeWord ||
          value.type == ModeloDeRelatorioConstantes.ContentTypeExcel
        );
      }),
  });

  const { register, control, handleSubmit, getValues, reset, setValue } =
    useForm<ModeloDeRelatorioRequest>({
      resolver: yupResolver(schema),
    });

  function limparTela() {
    dadosAuditoria = undefined;
    reset(novoRegistro);
    refUploader?.current?.instance().reset();
  }

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    preencherTela();
  }, [props.idRegistroEmEdicao]);

  async function preencherTela() {
    if (props.idRegistroEmEdicao == 0) {
      limparTela();
      setValue("finalidade", props.finalidade);
    } else if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    }
  }

  async function carregarRegistroDoId() {
    try {
      setCarregando(true);
      const resposta = await servico.ObterPorIdComDadosAuditoria(
        props.idRegistroEmEdicao
      );
      checarResponse(resposta);
      dadosAuditoria = resposta.model;
      reset(resposta.model);
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();
    try {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          ModeloDeRelatorioPermissoes.InserirEditar,
        ])
      ) {
        return;
      }

      const resposta =
        props.idRegistroEmEdicao > 0
          ? await servico.Atualizar(model)
          : await servico.Cadastrar(model);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        await carregarMenu();
        fechar(ResultadoAcaoFormulario.AcaoConcluida);
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  return (
    <>
      <ContainerFormMxp>
        <FormMxp carregando={carregando}>
          <ProvedorAjuda id="edit-form-modelo-de-relatorio">
            <input type="hidden" {...register("id")} defaultValue={0} />
            <input type="hidden" {...register("finalidade")} />

            <FormGrupo>
              <Linha>
                <Coluna md={12}>
                  <FormTextBox
                    name="descricao"
                    titulo="Descrição"
                    control={control}
                    tamanhoMaximo={
                      ModeloDeRelatorioConstantes.DescricaoTamanhoMaximo
                    }
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={5}>
                  <FormFile
                    refExterna={refUploader}
                    name="arquivo"
                    control={control}
                    extensoesAceitas={
                      ModeloDeRelatorioConstantes.ExtensoesAceitas
                    }
                  />
                </Coluna>
              </Linha>
            </FormGrupo>
          </ProvedorAjuda>
        </FormMxp>
        <ToolbarMxp dadosAuditoria={dadosAuditoria}>
          <BotaoSalvarMxp handleClick={handleSubmit(handleSalvar)} />
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ContainerFormMxp>
    </>
  );
}
