import { DataGridTypes, IColumnProps } from "devextreme-react/data-grid";
import IconeAjuda from "../../components/ajuda/icone-ajuda";
import LinkAjuda from "../../components/ajuda/link-ajuda";
import {
  ContainerHeaderGrid,
  IconeHeaderGrid,
  TextoHeaderGrid,
} from "../../components/grid-mxp/grid-mxp-header-template";
import { StringsComum } from "../../features/comum/strings";
import { StringsCentroDeTrabalho } from "../../features/producao/centro-de-trabalho/strings";
import {
  ativoPorExtenso,
  inativoPorExtenso,
  naoPorExtenso,
  simPorExtenso,
} from "../common/constantes";
import {
  formatarDataAno2digitos,
  formatarDataHoraAno2digitos,
} from "../formatadores/formatador-de-datas";
import {
  formatarNumero,
  formatarValorContabilComSufixo,
} from "../formatadores/formatador-de-numeros";
import {
  formatarCNPJ,
  formatarCPF,
} from "../formatadores/formatador-de-strings";

const DataAnoCompletoComHoraMinutoSegundos = "dd/MM/yyyy HH:mm:ss";
const DataAnoCompletoSemHora = "dd/MM/yyyy";

/* Se faz necessário para que os filtros exibam o ano com 4 digitos, mas a
 * célular exiba com 2 digitos. */
const CellRenderData2Digitos = (e: DataGridTypes.ColumnCellTemplateData) => {
  const dado = e.column.name && e.data ? e.data[e.column.name] : undefined;
  return formatarDataAno2digitos(dado);
};

const CellRenderDataHora2Digitos = (
  e: DataGridTypes.ColumnCellTemplateData
) => {
  const dado = e.column.name && e.data ? e.data[e.column.name] : undefined;
  return formatarDataHoraAno2digitos(dado);
};

const Padroes = {
  id: ensureType({
    caption: StringsComum.id,
    width: 50,
    allowResizing: true,
    dataType: "number",
    visible: false,
  }),
  idPropridadeDeNavegacaoNaoVisivel: ensureType({
    caption: "",
    width: 0,
    allowResizing: false,
    visible: false,
    dataType: "number",
    showInColumnChooser: false,
  }),
  apenasParaFiltro: ensureType({
    caption: "",
    width: 0,
    allowResizing: false,
    visible: false,
    dataType: "number",
    showInColumnChooser: false,
  }),
  acoes: ensureType({
    caption: StringsComum.acoes,
    allowResizing: false,
    allowFiltering: false,
    allowExporting: false,
    allowReordering: false,
    allowSorting: false,
    allowSearch: false,
    allowHiding: false,
    allowEditing: false,
    fixed: true,
    width: "auto",
    minWidth: 65,
    dataType: "object",
  }),
  colunaDeEspaco: ensureType({
    width: "100%",
    name: "colunaDeEspaco",
    cssClass: "coluna-de-espaco",
    visibleIndex: 1000,
    allowReordering: false,
    showInColumnChooser: false,
    allowHiding: false,
    allowResizing: false,
    allowSorting: false,
    allowSearch: false,
    allowFixing: false,
    fixedPosition: "right",
    dataType: "object",
  }),
  cpfCnpj: ensureType({
    caption: StringsComum.cpfCnpj,
    width: 190,
    dataType: "string",
    allowResizing: true,
  }),
  cnpjFormatado: ensureType({
    caption: StringsComum.cnpj,
    width: 200,
    dataType: "string",
    allowResizing: true,
    customizeText: (value) => formatarCNPJ(value.valueText),
  }),
  cpfFormatado: ensureType({
    caption: StringsComum.cpf,
    width: 200,
    dataType: "string",
    allowResizing: true,
    customizeText: (value) => formatarCPF(value.valueText),
  }),
  nomeFantasia: ensureType({
    caption: StringsComum.nomeFantasia,
    allowResizing: true,
    dataType: "string",
    width: 200,
  }),
  razaoSocial: ensureType({
    caption: StringsComum.razaoSocial,
    allowResizing: true,
    dataType: "string",
    width: 300,
  }),
  apelido: ensureType({
    caption: StringsComum.apelido,
    allowResizing: true,
    dataType: "string",
    width: 120,
  }),
  chaveAcesso: ensureType({
    caption: StringsComum.chaveDeAcesso,
    allowResizing: true,
    dataType: "string",
    width: 345,
  }),
  serie: ensureType({
    caption: StringsComum.serie,
    allowResizing: true,
    dataType: "string",
    width: 80,
  }),
  uf: ensureType({
    caption: StringsComum.uf,
    allowResizing: true,
    dataType: "string",
    width: 120,
  }),
  stringPP: ensureType({
    allowResizing: true,
    dataType: "string",
    width: 50,
  }),
  stringP: ensureType({
    allowResizing: true,
    dataType: "string",
    width: 100,
  }),
  stringMP: ensureType({
    allowResizing: true,
    dataType: "string",
    width: 125,
  }),
  stringM: ensureType({
    allowResizing: true,
    dataType: "string",
    width: 150,
  }),
  stringMG: ensureType({
    allowResizing: true,
    dataType: "string",
    width: 175,
  }),
  stringG: ensureType({
    allowResizing: true,
    dataType: "string",
    width: 200,
  }),
  stringGG: ensureType({
    allowResizing: true,
    dataType: "string",
    width: 300,
  }),
  stringXG: ensureType({
    allowResizing: true,
    dataType: "string",
    width: 400,
  }),
  stringXXG: ensureType({
    allowResizing: true,
    dataType: "string",
    width: 500,
  }),
  codigo: ensureType({
    caption: StringsComum.codigo,
    allowResizing: true,
    dataType: "string",
    width: 70,
  }),
  codigoNumerico: ensureType({
    allowResizing: true,
    width: 100,
    dataType: "number",
  }),
  ordem: ensureType({
    allowResizing: true,
    width: 90,
    dataType: "number",
  }),
  dataAnoCurtoComHoraMinutoSegundos: ensureType({
    allowResizing: true,
    width: 150,
    dataType: "datetime",
    selectedFilterOperation: "between",
    format: DataAnoCompletoComHoraMinutoSegundos,
    alignment: "right",
    cellRender: CellRenderDataHora2Digitos,
  }),
  dataAnoCurtoSemHora: ensureType({
    allowResizing: true,
    width: 80,
    dataType: "date",
    selectedFilterOperation: "between",
    format: DataAnoCompletoSemHora,
    alignment: "right",
    cellRender: CellRenderData2Digitos,
  }),
  criadoEm: ensureType({
    caption: StringsComum.criadoEm,
    allowResizing: true,
    width: 150,
    dataType: "date",
    selectedFilterOperation: "between",
    visible: false,
    format: DataAnoCompletoComHoraMinutoSegundos,
    alignment: "right",
    cellRender: CellRenderDataHora2Digitos,
  }),
  alteradoEm: ensureType({
    caption: StringsComum.alteradoEm,
    allowResizing: true,
    width: 150,
    dataType: "date",
    selectedFilterOperation: "between",
    visible: false,
    format: DataAnoCompletoComHoraMinutoSegundos,
    alignment: "right",
    cellRender: CellRenderDataHora2Digitos,
  }),
  criadoPor: ensureType({
    caption: StringsComum.criadoPor,
    allowResizing: true,
    dataType: "string",
    width: 180,
    visible: false,
  }),
  alteradoPor: ensureType({
    caption: StringsComum.alteradoPor,
    dataType: "string",
    allowResizing: true,
    width: 180,
    visible: false,
  }),
  dataHoraSemFormatacao: ensureType({
    allowResizing: true,
    width: 150,
    dataType: "datetime",
    selectedFilterOperation: "between",
    alignment: "right",
  }),
  percentual: ensureType({
    allowResizing: true,
    width: 80,
    dataType: "number",
    format: {
      type: "fixedPoint",
      precision: 2,
    },
  }),
  percentualComMinimoDuasCasasDecimais: ensureType({
    allowResizing: true,
    width: 80,
    alignment: "right",
    dataType: "number",
    format: {
      style: "percent",
      minimumFractionDigits: 2,
    },
  }),
  decimalComMaximoDuasCasasDecimais: ensureType({
    allowResizing: true,
    width: 150,
    dataType: "number",
    format: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  }),
  decimalComMaximoQuatroCasasDecimais: ensureType({
    allowResizing: true,
    width: 150,
    dataType: "number",
    format: {
      minimumFractionDigits: 2,
      maximumFractionDigits: 4,
    },
  }),
  decimalComMaximoCincoCasasDecimais: ensureType({
    allowResizing: true,
    width: 150,
    dataType: "number",
    format: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 5,
    },
  }),
  monetario: ensureType({
    allowResizing: true,
    width: 100,
    dataType: "number",
    customizeText: (value) => formatarNumero(Number(value.valueText), 2),
  }),
  monetarioBalanceteCD: ensureType({
    allowResizing: true,
    width: 140,
    dataType: "number",
    format: {
      formatter: (v) => formatarValorContabilComSufixo(v as number),
    },
  }),
  monetarioBalancete: ensureType({
    allowResizing: true,
    width: 140,
    dataType: "number",
    customizeText: (value) => formatarNumero(Number(value.valueText), 2),
  }),
  peso: ensureType({
    allowResizing: true,
    width: 80,
    dataType: "number",
    format: {
      type: "fixedPoint",
      precision: 3,
    },
  }),
  custoPorHora: ensureType({
    caption: StringsCentroDeTrabalho.custoHoraReaisAbreviado,
    allowResizing: true,
    width: 115,
    dataType: "number",
  }),
  horasPorDia: ensureType({
    caption: StringsCentroDeTrabalho.horaDiaAbreviado,
    allowResizing: true,
    width: 80,
    dataType: "number",
  }),
  unidade: ensureType({
    caption: StringsComum.unidadeAbreviado,
    allowResizing: true,
    dataType: "string",
    width: 80,
  }),
  centroDeTrabalhoGrupo: ensureType({
    caption: StringsCentroDeTrabalho.grupoCentroDeTrabalho,
    dataType: "string",
    allowResizing: true,
    width: 220,
    visible: false,
  }),
  grupoDeCentroDeTrabalhoCalendario: ensureType({
    caption: StringsCentroDeTrabalho.calendarioGrupoCentroDeTrabalho,
    dataType: "string",
    allowResizing: true,
    width: 300,
    visible: false,
  }),
  boolSimNao: ensureType({
    allowResizing: true,
    dataType: "boolean",
    width: 90,
    alignment: "left",
    trueText: simPorExtenso,
    falseText: naoPorExtenso,
  }),
  boolAtivoInativo: ensureType({
    allowResizing: true,
    dataType: "boolean",
    width: 90,
    alignment: "left",
    trueText: ativoPorExtenso,
    falseText: inativoPorExtenso,
  }),
  quantidade: ensureType({
    caption: StringsComum.quantidadeAbreviado,
    allowResizing: true,
    width: 150,
    alignment: "right",
    dataType: "number",
    format: {
      type: "fixedPoint",
      precision: 2,
    },
  }),
  quantidadeComNoMaximoCincoCasasDecimais: ensureType({
    caption: StringsComum.quantidadeAbreviado,
    allowResizing: true,
    width: 150,
    alignment: "right",
    dataType: "number",
    format: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 5,
    },
  }),
  chips: ensureType({
    allowResizing: true,
    // Não remove a caixa de busca, só faz ela não fazer nada: https://supportcenter.devexpress.com/ticket/details/t739865/datagrid-how-to-hide-search-row-for-column-where-filtering-is-enabled
    allowSearch: false,
    // Desabilitamos o filtro por padrão para que seja implementado de forma manual na coluna.
    allowFiltering: false,
    // Ordenação não é possível para colunas onde o valor é um array.
    allowSorting: false,
    width: 200,
    filterOperations: ["contains", "notcontains", "startswith", "endswith"],
    dataType: "object",
  }),
};

// Interface necessária para deixar o campo "dataType" como obrigatório
// para evitar erros quando a grid não retornar dados e renderizações desnecessárias.
interface IColumnMxpProps extends Omit<IColumnProps, "dataType"> {
  dataType: "string" | "number" | "date" | "boolean" | "object" | "datetime";
}

function ensureType(obj: IColumnMxpProps) {
  return obj;
}

const tiposComAlinhamentoADiretira: (string | undefined)[] = [
  "number",
  "date",
  "datetime",
];

function obterConfiguracaoColuna(tipo: keyof typeof Padroes): IColumnProps {
  const padroes = Padroes[tipo];
  return {
    ...padroes,
    // Ativa filtro do header da coluna caso seja do tipo string.
    headerFilter: {
      search: {
        enable: padroes.dataType === "string",
      },
    },
    // Define o alinhamento da coluna dependendo do tipo.
    alignment: tiposComAlinhamentoADiretira.includes(padroes.dataType)
      ? "right"
      : padroes.alignment,
    // Adiciona o link de ajuda nos headers da coluna
    headerCellRender: (e) => {
      return (
        <>
          <ContainerHeaderGrid>
            <TextoHeaderGrid className="texto">
              <span>{e.column.caption}</span>
            </TextoHeaderGrid>

            {e.column.dataField && (
              <IconeHeaderGrid className="container-icone">
                <LinkAjuda keyAjuda={e.column.dataField}>
                  <IconeAjuda />
                </LinkAjuda>
              </IconeHeaderGrid>
            )}
          </ContainerHeaderGrid>
        </>
      );
    },
  };
}

export default obterConfiguracaoColuna;
