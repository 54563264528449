import TabPanel, { Item } from "devextreme-react/cjs/tab-panel";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import BotaoCancelarMxp from "../../../../../components/botoes/botao-cancelar-mxp";
import {
  FormDateBox,
  FormNumberBox,
  FormTextBoxSimples,
} from "../../../../../components/formularios";
import FormMxp from "../../../../../components/layout/form";
import { ContainerFormMxp } from "../../../../../components/layout/form/styles";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import TabContainer from "../../../../../components/layout/tab-container";
import ToolbarMxp from "../../../../../components/layout/toolbar-mxp";
import { useAppSelector } from "../../../../../hooks/store.hooks";
import AuditavelDTO from "../../../../../models/api/comum/auditavel-dto";
import {
  IFormularioEditavelBase,
  ResultadoAcaoFormulario,
} from "../../../../../models/shared/ui/formularios";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import { obterFormatStringNumero } from "../../../../../utils/formatadores/formatador-de-numeros";
import { formatarCpfCnpj } from "../../../../../utils/formatadores/formatador-de-strings";
import {
  ApuracaoDoCiapDTO,
  ApuracaoDoCiapResponse,
} from "../../models/apuracao-ciap";
import { ApuracaoDoCiapService } from "../../servicos/apuracao-ciap.service";
import GridInternalMovimentacaoDoImobilizado from "./grid-movimentacao-do-imobilizado";

const novoRegistro: ApuracaoDoCiapDTO = {
  id: 0,
  dataInicial: "",
  dataFinal: "",
  saldoInicialDoIcms: 0,
  totalDasParcelasDeIcms: 0,
  valorDasSaidasTributadasOuExportacao: 0,
  totalDasSaidas: 0,
  indiceDeParticipacao: 0,
  parcelaDeIcmsASerApropriada: 0,
};

const service = new ApuracaoDoCiapService();

const rederizarTitulo = (item: any) => item.text;

let dadosAuditoria: AuditavelDTO | undefined = undefined;

export default function EditFormAlteracaoApuracaoDoCiap(
  props: IFormularioEditavelBase
) {
  const [carregando, setCarregando] = useState(false);
  const empresaLogada = useAppSelector((state) => {
    return {
      Apelido: state.sessao.dadosSessao?.empresa.apelido,
      CpfCpj: state.sessao.dadosSessao?.empresa.cnpjCpfCodigo,
    };
  });

  const { register, control, reset } = useForm<ApuracaoDoCiapDTO>({});

  //Hook usado para carregar os dados da tela
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEmEdicao)) {
      return;
    }

    preencherTela();
  }, [props.idRegistroEmEdicao]);

  async function preencherTela() {
    if (props.idRegistroEmEdicao == 0) {
      limparTela();
    } else if (props.idRegistroEmEdicao > 0) {
      await carregarRegistroDoId();
    }
  }

  async function carregarRegistroDoId() {
    try {
      setCarregando(true);
      const resposta =
        await service.ObterPorIdComDadosAuditoria<ApuracaoDoCiapResponse>(
          props.idRegistroEmEdicao
        );
      checarResponse(resposta);
      dadosAuditoria = resposta.model;
      reset(resposta.model);
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  function limparTela() {
    dadosAuditoria = undefined;
    reset(novoRegistro);
  }

  function handleCancelar() {
    fechar(ResultadoAcaoFormulario.AcaoCancelada);
  }

  function fechar(resultado: ResultadoAcaoFormulario) {
    limparTela();
    props.handleCallback(resultado);
  }

  return (
    <>
      <ContainerFormMxp>
        <FormMxp carregando={carregando}>
          <ProvedorAjuda id="edit-form-apuracao-lacs-csll">
            <input type="hidden" {...register("id")} defaultValue={0} />
            <Linha>
              <Coluna md={8}>
                <FormTextBoxSimples
                  titulo={"Minha empresa"}
                  valor={`${empresaLogada.Apelido} - ${formatarCpfCnpj(
                    empresaLogada.CpfCpj!
                  )}`}
                  somenteLeitura
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={3}>
                <FormDateBox
                  name="dataInicial"
                  titulo="Período de"
                  control={control}
                  requerido
                  aceitaValorCustomizado={true}
                  aceitaDigitacaoComMascara={true}
                  somenteLeitura
                />
              </Coluna>
              <Coluna md={3}>
                <FormDateBox
                  name="dataFinal"
                  titulo="a"
                  control={control}
                  requerido
                  aceitaValorCustomizado={true}
                  aceitaDigitacaoComMascara={true}
                  somenteLeitura
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={6}>
                <FormNumberBox
                  name="saldoInicialDoIcms"
                  titulo="Saldo inicial do ICMS do CIAP"
                  control={control}
                  requerido
                  formato={obterFormatStringNumero(2)}
                  somenteLeitura
                />
              </Coluna>
              <Coluna md={6}>
                <FormNumberBox
                  name="totalDasParcelasDeIcms"
                  titulo="Somatório das parcelas do ICMS"
                  control={control}
                  requerido
                  formato={obterFormatStringNumero(2)}
                  somenteLeitura
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={6}>
                <FormNumberBox
                  name="valorDasSaidasTributadasOuExportacao"
                  titulo="Valor das saídas tributadas ou de exportacão"
                  control={control}
                  requerido
                  formato={obterFormatStringNumero(2)}
                  somenteLeitura
                />
              </Coluna>
              <Coluna md={6}>
                <FormNumberBox
                  name="totalDasSaidas"
                  titulo="Valor das saídas"
                  control={control}
                  requerido
                  formato={obterFormatStringNumero(2)}
                  somenteLeitura
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={6}>
                <FormNumberBox
                  name="indiceDeParticipacao"
                  titulo="Índice de participação"
                  control={control}
                  requerido
                  formato={obterFormatStringNumero(2)}
                  somenteLeitura
                />
              </Coluna>
              <Coluna md={6}>
                <FormNumberBox
                  name="parcelaDeIcmsASerApropriada"
                  titulo="Parcela de ICMS a ser apropriada"
                  control={control}
                  requerido
                  formato={obterFormatStringNumero(2)}
                  somenteLeitura
                />
              </Coluna>
            </Linha>
            <TabPanel
              showNavButtons
              swipeEnabled={false}
              itemTitleRender={rederizarTitulo}
              height={"max(40vh, 415px)"}
            >
              <Item text="Movimentações dos imobilizados">
                <TabContainer>
                  <GridInternalMovimentacaoDoImobilizado
                    apuracaoId={props.idRegistroEmEdicao}
                  />
                </TabContainer>
              </Item>
            </TabPanel>
          </ProvedorAjuda>
        </FormMxp>
        <ToolbarMxp dadosAuditoria={dadosAuditoria}>
          <BotaoCancelarMxp handleClick={handleCancelar} />
        </ToolbarMxp>
      </ContainerFormMxp>
    </>
  );
}
