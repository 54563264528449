import { SortDescriptor } from "devextreme/data";
import DataSource from "devextreme/data/data_source";
import DataStoreFactory from "./data-store-factory";

export interface DataSourceOpcoes<T> {
  quantidadeRegistros?: number;
  camposRetorno: Extract<keyof T, string>[];
  camposOrdenacao: DataSourceOrdenacao<T>[];
  camposFiltro?: DataSourceFiltragem<T>[];
  filtroExato?: DataSourceRawFilter<T>;
}

export type DataSourceOpcoesBuilder<T> = Partial<DataSourceOpcoes<T>>;

export interface DataSourceOrdenacao<T> {
  campo: Extract<keyof T, string>;
  desc: boolean;
}

/**
 * O filtro de DataSource do DevExtreme.
 * @example ["campoA", "=", 2]
 * @example [["campoA", "=", 2], "and", ["campoB", "<>", 5]]
 * @example ["!", ["campoA", "=", 2]]
 * @example
 * [
 *   ["campoA", "=", 2],
 *   "and",
 *   [
 *     ["campoB", "=", 4],
 *     "or",
 *     ["campoB", "=", 5]
 *   ]
 * ]
 */
export type DataSourceRawFilter<T> = DataSourceRawFilterElem<T>[];

/**
 * O elemento do filtro, permite combinação entre filtros usando os {@link OperadoresComplexos}, {@link DataSourceRawFieldFilter} ou um array de elementos de filtro.
 * @example ["campoA", "=", 2]
 * @example [["campoA", "=", 2], "and", ["campoB", "<>", 5]]
 * @example ["!", ["campoA", "=", 2]]
 */
type DataSourceRawFilterElem<T> =
  | OperadoresComplexos
  | DataSourceRawFieldFilter<T>
  | DataSourceRawFilterElem<T>[];

/**
 * Operações que permitem combinar múltiplos filtros
 */
type OperadoresComplexos = "or" | "and" | "!";

/**
 * O elemento base do filtro, que permite filtrar um campo por um valor
 * @example ["campo", "contains", "Alguma coisa"]
 */
type DataSourceRawFieldFilter<T> = {
  [K in keyof T]: [K, OperadoresFiltragem, T[K]];
}[keyof T];

type OperadoresFiltragem =
  | "="
  | "<>"
  | ">"
  | ">="
  | "<"
  | "<="
  | "startswith"
  | "endswith"
  | "contains"
  | "notcontains";

export type DataSourceFiltragem<T> = {
  [K in keyof T]: {
    campo: K;
    operador: OperadoresFiltragem;
    valor: T[K];
  };
}[keyof T];

export interface DxGrupoDados<TItem, TKey = any> {
  count: number | null;
  items: TItem[];
  key: TKey;
  summary: any; // Não sei qual o tipo.
}

/**
 * Classe responsável por criar a instância do data source a ser utilizado pela grid ou pelo select box lazy.
 */
export default class DataSourceFactory {
  /**
   * Método responsável por criar a instância do datasource para grid.
   * @param urlRelativa Path com o caminho relativo do endpoint da grid
   * @param filtroPadrao Parâmetro opcional com configurações do filtro padrão a ser realizado no servidor.
   * @returns DataSource para a grid
   */
  public static CriarParaGrid<TGrid, TKey = number>(
    urlRelativa: string,
    filtroPadrao?: DataSourceFiltragem<TGrid>[],
    filtroPadraoExato?: DataSourceRawFilter<TGrid>,
    funcaoPostProcess?: (data: Array<TGrid>) => Array<TGrid>
  ) {
    const customStore = DataStoreFactory.Criar({ urlRelativa, key: "id" });

    const ds = new DataSource<TGrid, TKey>({
      store: customStore,
      postProcess: funcaoPostProcess,
    });

    this.AplicarFiltragem(ds, filtroPadrao, filtroPadraoExato);

    return ds;
  }

  /**
   * Método responsável por criar a instância do datasource para o selectbox lazy.
   * @param urlRelativa Path com o caminho relativo do endpoint da grid
   * @param opcoes Parâmetro com as informações de configurações do datasource
   * @returns DataSource para o selectbox lazy
   */
  public static CriarParaSelectBoxLazy<TGrid, TKey = number>(
    urlRelativa: string,
    opcoes: DataSourceOpcoes<TGrid>
  ) {
    const customStore = DataStoreFactory.Criar({
      urlRelativa,
      key: "id",
      loadParams: {
        select: JSON.stringify(opcoes.camposRetorno),
      },
    });

    const ds = new DataSource<TGrid, TKey>({
      store: customStore,
      pageSize: opcoes.quantidadeRegistros ?? 50,
      requireTotalCount: false,
    });

    this.AplicarSelecao(ds, opcoes.camposRetorno);
    this.AplicarOrdenacao(ds, opcoes.camposOrdenacao);
    this.AplicarFiltragem(ds, opcoes.camposFiltro, opcoes.filtroExato);

    return ds;
  }

  private static AplicarSelecao<TGrid, TKey = number>(
    dataSource: DataSource<TGrid, TKey>,
    campos: string[]
  ) {
    dataSource.select(campos);
  }

  private static AplicarOrdenacao<TGrid, TKey = number>(
    dataSource: DataSource<TGrid, TKey>,
    ordenacao: DataSourceOrdenacao<TGrid>[]
  ) {
    const camposOrdenacao: SortDescriptor<TGrid>[] = [];

    ordenacao.map((x) => {
      camposOrdenacao.push({ selector: x.campo, desc: x.desc });
    });

    dataSource.sort(camposOrdenacao);
  }

  private static AplicarFiltragem<TGrid, TKey = number>(
    dataSource: DataSource<TGrid, TKey>,
    filtros?: DataSourceFiltragem<TGrid>[],
    filtroDireto?: DataSourceRawFilter<TGrid>
  ) {
    if (!filtros && !filtroDireto) {
      return;
    }

    const todosFiltros = [];
    if (filtros && filtros.length) {
      todosFiltros.push(...filtros.map((x) => [x.campo, x.operador, x.valor]));
    }
    if (filtroDireto && filtroDireto.length) {
      todosFiltros.push(...filtroDireto);
    }
    dataSource.filter(todosFiltros);
  }
}
