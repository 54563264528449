import DataGrid, { Column, DataGridRef } from "devextreme-react/cjs/data-grid";
import DataSource from "devextreme/data/data_source";
import { useMemo, useRef } from "react";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import { useSeletorGridMxp } from "../../../../../hooks/seletor.hooks";
import { GridMxpProps } from "../../../../../models/shared/ui/grid";
import GetColunasDeAuditoria from "../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import criarNameof from "../../../../../utils/common/cria-name-of";
import GridBuilder from "../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import { ContaContabilGridModel } from "../../models/conta-contabil";
import { ContaContabilService } from "../../servicos/conta-contabil.service";

const contaContabilService = new ContaContabilService();

const nameOfGridHandler = criarNameof<ContaContabilGridModel>();

export default function GridContaContabil(
  props: GridMxpProps<ContaContabilGridModel>
) {
  const gridRef = useRef<DataGridRef>(null);

  const dataSource = new DataSource({
    store: contaContabilService.GetGridSource(),
  });

  useSeletorGridMxp(() => gridRef.current);

  if (props.filtrosNoServidor) {
    dataSource.filter(
      props.filtrosNoServidor.map((x) => [x?.campo, x?.operador, x?.valor])
    );
  }

  const gridController = new GridController<ContaContabilGridModel>(() =>
    gridRef.current?.instance()
  );

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar("conta-contabil", () =>
      gridRef.current?.instance()
    )
      .definirStyles({ height: "100%" })
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .definirSelecao()
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirOrdenacao()
      .build();
  }, [props.filtrosNoServidor]);

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  return (
    <>
      <ProvedorAjuda id={"tooltips-grid-conta-contabil"}>
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          <Column
            key={nameOfGridHandler("codigo")}
            dataField={nameOfGridHandler("codigo")}
            {...obterConfiguracaoColuna("stringP")}
            caption="Código"
          />
          <Column
            key={nameOfGridHandler("classificacao")}
            dataField={nameOfGridHandler("classificacao")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Classificação"
            sortOrder="asc"
          />
          <Column
            key={nameOfGridHandler("descricao")}
            dataField={nameOfGridHandler("descricao")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Descrição"
          />
          {GetColunasDeAuditoria()}
        </DataGrid>
      </ProvedorAjuda>
    </>
  );
}
