import { LoadPanel } from "devextreme-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ResponseBase } from "../../../models/api/comum/response-base";
import { tratarErroApi } from "../../../utils/api/api-utils";
import { JanelasDeNotificacaoTitulos } from "../../../utils/common/notificacoes-utils";
import { exibirAlerta } from "../../../utils/dialogos";
import { renderToStringClient } from "../../../utils/react/react-utils";
import { HomeBackground } from "../../layout/login-bg/styles";
import { QuebrarLinhas } from "../../texto/quebrar-linhas";

interface VerificacaoBackendProps {
  checagem: () => Promise<ResponseBase>;
  children: React.ReactNode;
}

// Validação apenas para o strict mode
let verificacaoBackendIniciada = false;

export default function VerificacaoBackend({
  checagem,
  children,
}: VerificacaoBackendProps) {
  useEffect(() => {
    efetuarChecagem();
  }, []);

  const [checando, setChecando] = useState(true);
  const navigate = useNavigate();

  async function efetuarChecagem() {
    if (verificacaoBackendIniciada) {
      return;
    }

    verificacaoBackendIniciada = true;

    try {
      const resposta = await checagem();

      if (resposta.sucesso) {
        setChecando(false);
      } else {
        const msg = renderToStringClient(
          <QuebrarLinhas texto={resposta.mensagem} />
        );

        await exibirAlerta(JanelasDeNotificacaoTitulos.Atencao, msg);
        navigate("/");
      }
    } catch (erro) {
      tratarErroApi(erro);
      navigate("/");
    } finally {
      verificacaoBackendIniciada = false;
    }
  }

  return (
    <>
      {checando ? (
        <HomeBackground>
          <LoadPanel showPane={true} showIndicator={true} />
        </HomeBackground>
      ) : (
        children
      )}
    </>
  );
}
