import { DataGridRef } from "devextreme-react/cjs/data-grid";
import { createContext } from "react";
import { FinalidadeModeloRelatorio } from "../models/relatorio-personalizado.enums";

export interface ItemDoMenuDeImpressaoDeRelatorios {
  texto: string;
  modeloRelatorioId: number;
  modeloPadrao: boolean;
  excel: boolean;
  word: boolean;
}

export interface DetalhamentoGrid {
  filtros: string | null;
  idsRegistrosSelecionados: number[];
  quantidadeRegistrosFiltrados: number;
}

interface RelatorioPersonalizadoContextProps {
  finalidade?: FinalidadeModeloRelatorio;
  itensDoMenuDeImpressaoDeRelatorios: ItemDoMenuDeImpressaoDeRelatorios[];
  definirItensDoMenuDeImpressaoDeRelatorios: (
    itens: ItemDoMenuDeImpressaoDeRelatorios[]
  ) => void;
  modalConfigurarModeloDeRelatorioVisivel: boolean;
  definirModalConfigurarModeloDeRelatorioVisivel: (visivel: boolean) => void;
  modalImpressaoDoRelatorioVisivel: boolean;
  definirModalImpressaoDoRelatorioVisivel: (visivel: boolean) => void;
  modalDicionarioTagsVisivel: boolean;
  definirModalDicionarioTagsVisivel: (visivel: boolean) => void;
  definirReferenciaGrid: (x: () => DataGridRef | null | undefined) => void;
  referenciaGrid: DataGridRef | null | undefined;
  modeloDeRelatorioSelecionadoParaImpressao?: ItemDoMenuDeImpressaoDeRelatorios;
  definirModeloDeRelatorioSelecionadoParaImpressao: (
    modelo: ItemDoMenuDeImpressaoDeRelatorios
  ) => void;
  detalhamentoGrid: DetalhamentoGrid;
  carregarMenu: () => void;
}

const RelatorioPersonalizadoContexto =
  createContext<RelatorioPersonalizadoContextProps>({
    finalidade: undefined,
    itensDoMenuDeImpressaoDeRelatorios: [],
    definirItensDoMenuDeImpressaoDeRelatorios: () => {
      //Implementação default
    },
    modalConfigurarModeloDeRelatorioVisivel: false,
    definirModalConfigurarModeloDeRelatorioVisivel: () => {
      //Implementação default
    },
    modalDicionarioTagsVisivel: false,
    definirModalDicionarioTagsVisivel: () => {
      //Implementação default
    },
    modalImpressaoDoRelatorioVisivel: false,
    definirModalImpressaoDoRelatorioVisivel: () => {
      //Implementação default
    },
    definirReferenciaGrid: () => {
      //Implementação default
    },
    referenciaGrid: null,
    modeloDeRelatorioSelecionadoParaImpressao: undefined,
    definirModeloDeRelatorioSelecionadoParaImpressao: () => {
      //Implementação default
    },
    detalhamentoGrid: {} as DetalhamentoGrid,
    carregarMenu: () => {
      //Implementação default
    },
  });

export default RelatorioPersonalizadoContexto;
