import { Menu } from "devextreme-react";
import { useContext, useEffect, useState } from "react";
import AjudaPaginasPage from "../../../features/ajuda/ajuda-paginas/paginas/listagem";
import RelatorioPersonalizadoContexto from "../../../features/relatorio/relatorio-personalizado/contexts/relatorio-personalizado.context";
import { useAppSelector } from "../../../hooks/store.hooks";
import { navegarParaPaginaExterna } from "../../../utils/common/menu-utils";
import NomesTelas from "../../../utils/common/nomes-telas";
import {
  ItemContextMenuMxp,
  ItemContextMenuNativo,
} from "../../../utils/context-menu/context-menu-utils";
import DefineTituloDaAba from "../../../utils/header/define-titulo-da-aba";
import { ProvedorLinkAjuda } from "../../../utils/help/provedor-links";
import ContextoMenus from "../../menus/contexto-menus";
import { Modal } from "../modal";
import "./index.scss";
import {
  Area,
  Conteudo,
  Header,
  Separador,
  Titulo,
  TituloContainer,
  TituloWrapper,
} from "./styles";

interface PaginaMxpProps {
  id: string | null;
  children: React.ReactNode;
  titulo?: string;
  area?: string[];
  omitirLinksDeAjuda?: boolean;
  omitirRelatorios?: boolean;
}

const SeparadorArea = " > ";

export default function PaginaMxp({
  id,
  children,
  area,
  titulo,
  omitirLinksDeAjuda,
  omitirRelatorios,
}: PaginaMxpProps) {
  DefineTituloDaAba(titulo);
  const [editorVisivel, setEditorVisivel] = useState(false);
  const [menus, setMenus] = useState<ItemContextMenuNativo[]>([]);
  const master = useAppSelector(
    (state) => state.sessao.dadosSessao?.usuario.isUsuarioMaster
  );

  const linkHomeHelp = process.env.REACT_APP_HELP_HOME as string;
  const { itensMenu } = useContext(ContextoMenus);
  const {
    itensDoMenuDeImpressaoDeRelatorios,
    finalidade,
    definirModalConfigurarModeloDeRelatorioVisivel,
    definirModalDicionarioTagsVisivel,
    definirModalImpressaoDoRelatorioVisivel,
    definirModeloDeRelatorioSelecionadoParaImpressao,
  } = useContext(RelatorioPersonalizadoContexto);

  useEffect(() => {
    carregarMenus();
  }, [itensMenu, itensDoMenuDeImpressaoDeRelatorios]);

  async function carregarMenus() {
    let exibirAjuda = false;
    const links: ItemContextMenuNativo[] = [];

    if (id && !omitirLinksDeAjuda) {
      const provedor = await ProvedorLinkAjuda.criarProvedor();
      const dados = provedor.obterLinks(id);
      exibirAjuda = !!dados.length;

      for (const link of dados) {
        links.push({
          text: link.textoExibicao,
          hint: link.textoExibicao,
          name: link.textoExibicao,
          onClick: () =>
            navegarParaPaginaExterna(`${linkHomeHelp}${link.slug}`),
        });
      }
    }

    if (master && !omitirLinksDeAjuda) {
      links.push({
        text: "Editar links de ajuda [master]",
        name: "Editar links de ajuda [master]",
        onClick: editarLinks,
      });
      exibirAjuda = true;
    }

    const itensAdicionar = itensMenu
      ?.filter((x) => x.exibirNoMenuPrincipal)
      .map(convertItemContextMenu);

    const itens = [...(itensAdicionar ?? [])];

    if (finalidade && !omitirRelatorios) {
      definirMenuImpressaoDeRelatorios(itens);
    }

    if (exibirAjuda) {
      const sessaoAjuda: ItemContextMenuNativo = {
        icon: "help",
        hint: "Ajuda",
        text: "\0",
        name: "\0",
        items: links,
      };

      itens.push(sessaoAjuda);
    }

    setMenus(itens);
  }

  function convertItemContextMenu(x: ItemContextMenuMxp) {
    const novoItem: ItemContextMenuNativo = {
      ...x,
      onClick: x.gestorEventoClick?.eventoParaMenuSuperior(),
      items: x?.items?.map(convertItemContextMenu),
    };
    return novoItem;
  }

  function editarLinks() {
    setEditorVisivel(true);
  }

  function handleModalFechado() {
    setEditorVisivel(false);
  }

  function definirMenuImpressaoDeRelatorios(itens: ItemContextMenuNativo[]) {
    const sessaoImpressaoRelatorios: ItemContextMenuNativo = {
      text: "Impressão de relatórios",
      icon: "ic-material-symbols-outlined ic-print",
      hint: "Menu com opções de impressão de relatórios",
      items: [],
    };

    // Insere os modelos padrões
    itensDoMenuDeImpressaoDeRelatorios
      .filter((x) => x.modeloPadrao)
      .sort((a, b) => a.texto.localeCompare(b.texto))
      .map((x) => {
        sessaoImpressaoRelatorios.items!.push({
          text: x.texto,
          onClick: () => {
            definirModeloDeRelatorioSelecionadoParaImpressao(x);
            definirModalImpressaoDoRelatorioVisivel(true);
          },
        });
      });

    // Insere os modelos do assinante
    itensDoMenuDeImpressaoDeRelatorios
      .filter((x) => !x.modeloPadrao)
      .sort((a, b) => a.texto.localeCompare(b.texto))
      .map((x, i) => {
        sessaoImpressaoRelatorios.items!.push({
          text: x.texto,
          onClick: () => {
            definirModeloDeRelatorioSelecionadoParaImpressao(x);
            definirModalImpressaoDoRelatorioVisivel(true);
          },
          beginGroup: i == 0,
        });
      });

    sessaoImpressaoRelatorios.items!.push({
      text: "Configurar modelos de relatórios",
      icon: "ic-material-symbols-outlined ic-print-add",
      onClick: () => {
        definirModalConfigurarModeloDeRelatorioVisivel(true);
      },
      beginGroup: true,
    });

    sessaoImpressaoRelatorios.items!.push({
      text: "Consultar",
      icon: "ic-material-symbols-outlined ic-manage-search",
      hint: "Menu com opções de consultar",
      beginGroup: true,
      items: [
        {
          text: "Dicionário de tags",
          icon: "ic-material-symbols-outlined ic-data-array",
          onClick: () => {
            definirModalDicionarioTagsVisivel(true);
          },
        },
      ],
    });

    itens.push(sessaoImpressaoRelatorios);
  }

  return (
    <>
      <Header data-testid="sub-header-component">
        {titulo && (
          <TituloContainer>
            {area && <Area>{area.join(SeparadorArea)}</Area>}
            <TituloWrapper>
              <Titulo>{titulo}</Titulo>
            </TituloWrapper>
          </TituloContainer>
        )}
        {titulo && !!menus.length && <Separador>|</Separador>}

        <Menu
          items={menus}
          displayExpr="text"
          showFirstSubmenuMode="onHover"
          showSubmenuMode="onHover"
          orientation={"horizontal"}
          hideSubmenuOnMouseLeave={false}
        />
      </Header>
      <Conteudo>{children}</Conteudo>
      {master && !!id && (
        <Modal
          titulo={NomesTelas.linkAjudaPaginaMaster}
          visivel={editorVisivel}
          mostrarSombreado
          fecharAoClicarFora={false}
          onFechar={handleModalFechado}
        >
          <AjudaPaginasPage
            callBackFecharModal={handleModalFechado}
            codigo={id}
          />
        </Modal>
      )}
    </>
  );
}
