import DataGrid, { Column, DataGridRef } from "devextreme-react/cjs/data-grid";
import ArrayStore from "devextreme/data/array_store";
import { useCallback, useEffect, useMemo, useRef } from "react";
import ProvedorAjuda from "../../../../../../../components/ajuda/provedor-ajuda";
import GridColunaAcoes from "../../../../../../../components/grid-mxp/grid-mxp-coluna-acoes";
import { ModalMxp } from "../../../../../../../components/layout/modal-mxp";
import { useRegistrarAtalhosGrid } from "../../../../../../../hooks/atalhos.hooks";
import { useControlarFormDeEdicao } from "../../../../../../../hooks/form.hooks";
import { useParametroId } from "../../../../../../../hooks/route.hooks";
import { decodificaBooleanoEmSimNao } from "../../../../../../../models/api/comum/decodificadores";
import { LancamentoNaParteBLacsGridModel } from "../../../../../../../models/api/lancamento-parte-b/lancamento-parte-b-lacs/lancamento-parte-b-lacs";
import { PermissoesApuracaoLalurIrpj } from "../../../../../../../models/permissoes/fiscal/apuracao-lalur-irpj/permissoes-apuracao-lalur-irpj";
import { ResultadoAcaoFormulario } from "../../../../../../../models/shared/ui/formularios";
import GetColunasDeAuditoria from "../../../../../../../parts/layout/grid-defaults/colunasDeAuditoria";
import { checarResponseExibeMensagemExclusaoDeSucesso } from "../../../../../../../utils/api/api-utils";
import criarNameof from "../../../../../../../utils/common/cria-name-of";
import NomesModais from "../../../../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../../../../utils/common/nomes-telas";
import NormalizaTituloModal from "../../../../../../../utils/common/normaliza-titulo";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../../../../utils/dialogos";
import GridBuilder from "../../../../../../../utils/grid/grid-builder";
import { GridController } from "../../../../../../../utils/grid/grid-controller";
import obterConfiguracaoColuna from "../../../../../../../utils/grid/padroes-colunas";
import UrlUtils from "../../../../../../../utils/url/url-utils";
import { LancamentoNaParteBDoLacsService } from "../../../../servicos/lancamento-parte-b-lacs.service";
import EditFormLancamentoNaParteBLacs from "./edit-form";

const service = new LancamentoNaParteBDoLacsService();

const nameOfGridHandler = criarNameof<LancamentoNaParteBLacsGridModel>();

interface GridInternaLancamentoParteBDoLacsProps {
  idApuracaoDaLacsEDaCsll: number;
  modalPaiVisivel: boolean;
}

export default function GridInternaLancamentoParteBDoLacs(
  props: GridInternaLancamentoParteBDoLacsProps
) {
  const gridRef = useRef<DataGridRef>(null);
  const parametroId = useParametroId();

  const {
    idRegistroEmEdicao,
    setIdRegistroEmEdicao,
    modalVisivel,
    encerrarEdicao,
  } = useControlarFormDeEdicao(NaN);

  const dataSource = useMemo(
    () =>
      !props.modalPaiVisivel
        ? new ArrayStore()
        : service.ObterDataSourceParaGrid<LancamentoNaParteBLacsGridModel>([
            {
              campo: "apuracaoId",
              operador: "=",
              valor: props.idApuracaoDaLacsEDaCsll,
            },
          ]),
    [props.modalPaiVisivel, props.idApuracaoDaLacsEDaCsll]
  );

  function atualizarGrid() {
    gridRef.current?.instance()?.refresh();
  }

  const handleNovoRegistro = useCallback(() => {
    setIdRegistroEmEdicao(0);
  }, []);

  const handleEditarRegistro = useCallback(
    (registro: LancamentoNaParteBLacsGridModel) => {
      setIdRegistroEmEdicao(registro.id);
    },
    []
  );

  const handleExcluirRegistro = useCallback(
    async (registro: LancamentoNaParteBLacsGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesApuracaoLalurIrpj.Consultar,
        ])
      ) {
        return;
      }
      const excluir = await exibirConfirmacao(
        "Confirmar exclusão",
        obterMensagemExclusao(registro)
      );

      if (excluir) {
        const resposta = await service.Excluir(registro.id);

        if (resposta) {
          checarResponseExibeMensagemExclusaoDeSucesso(resposta);
          atualizarGrid();
        }
      }
    },
    []
  );

  function obterMensagemExclusao(registro: LancamentoNaParteBLacsGridModel) {
    return `Tem certeza que deseja excluir o lançamento da parte B da conta ${registro.contaDaParteB}?`;
  }

  const gridController = new GridController<LancamentoNaParteBLacsGridModel>(
    () => gridRef.current?.instance()
  );

  const configuracoesGrid = useMemo(() => {
    return GridBuilder.criar(
      "grid-lancamento-parte-b-lacs-editform-apuracao",
      () => gridRef.current?.instance(),
      true
    )
      .definirDataSource(dataSource)
      .definirFiltros()
      .definirRolagem()
      .configurarSelecionadorDeColunas()
      .definirGravacaoPreferenciasGrid()
      .definirPaginacao()
      .configurarExportacao(NomesTelas.lancancamentoDaParteB)
      .definirBotaoNovo(handleNovoRegistro)
      .definirBotaoRefresh(handleAtualizarGrid)
      .definirSelecao()
      .definirOrdenacao()
      .definirDuploCliqueLinha(handleEditarRegistro)
      .build();
  }, [props.modalPaiVisivel, props.idApuracaoDaLacsEDaCsll]);

  useEffect(() => {
    if (parametroId) {
      setIdRegistroEmEdicao(parametroId);
    }
  }, []);

  function handleAtualizarGrid() {
    gridController.atualizar();
  }

  useRegistrarAtalhosGrid<LancamentoNaParteBLacsGridModel>({
    controller: gridController,
    handleNovo: handleNovoRegistro,
    handleEditar: handleEditarRegistro,
    handleExcluir: handleExcluirRegistro,
  });

  const handleCallbackFormulario = useCallback(
    (resultado: ResultadoAcaoFormulario) => {
      encerrarEdicao();

      if (resultado == ResultadoAcaoFormulario.AcaoConcluida) {
        handleAtualizarGrid();
      }

      UrlUtils.RemoverParametroId(parametroId);
    },
    [parametroId]
  );

  const handleFecharModal = useCallback(() => {
    encerrarEdicao();
    UrlUtils.RemoverParametroId(parametroId);
  }, [parametroId]);

  return (
    <>
      <ProvedorAjuda
        id={"tooltips-grid-lancamento-parte-b-lacs-editform-apuracao"}
      >
        <DataGrid ref={gridRef} {...configuracoesGrid}>
          {GridColunaAcoes<LancamentoNaParteBLacsGridModel>({
            handleEditar: handleEditarRegistro,
            handleExcluir: handleExcluirRegistro,
          })}
          <Column {...obterConfiguracaoColuna("colunaDeEspaco")} />
          <Column
            key={nameOfGridHandler("contaDaParteB")}
            dataField={nameOfGridHandler("contaDaParteB")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Conta da parte B"
          />
          <Column
            key={nameOfGridHandler("valor")}
            dataField={nameOfGridHandler("valor")}
            {...obterConfiguracaoColuna("monetario")}
            caption="Valor"
          />
          <Column
            key={nameOfGridHandler("indicadorDoLancamento")}
            dataField={nameOfGridHandler("indicadorDoLancamento")}
            {...obterConfiguracaoColuna("stringG")}
            caption="Indicador do lançamento"
          />
          <Column
            key={nameOfGridHandler("historico")}
            dataField={nameOfGridHandler("historico")}
            {...obterConfiguracaoColuna("stringM")}
            caption="Histórico"
          />
          <Column
            key={nameOfGridHandler("tributacaoDiferida")}
            dataField={nameOfGridHandler("tributacaoDiferida")}
            {...obterConfiguracaoColuna("boolSimNao")}
            caption="Tributação diferida"
            width={150}
            cellRender={({ data }) => {
              const dados = data as LancamentoNaParteBLacsGridModel;
              if (dados.tributacaoDiferida != undefined) {
                return decodificaBooleanoEmSimNao(dados.tributacaoDiferida);
              }
            }}
          />
          <Column
            key={nameOfGridHandler("incluirNaParteA")}
            dataField={nameOfGridHandler("incluirNaParteA")}
            {...obterConfiguracaoColuna("boolSimNao")}
            caption="Incluir na parte A"
            width={150}
            cellRender={({ data }) => {
              const dados = data as LancamentoNaParteBLacsGridModel;
              if (dados.incluirNaParteA != undefined) {
                return decodificaBooleanoEmSimNao(dados.incluirNaParteA);
              }
            }}
          />
          <Column
            key={nameOfGridHandler(
              "adicaoOuExclusaoOuCompensacaoDoLacsDoLucroReal"
            )}
            dataField={nameOfGridHandler(
              "adicaoOuExclusaoOuCompensacaoDoLacsDoLucroReal"
            )}
            {...obterConfiguracaoColuna("stringXG")}
            caption="Adição ou exclusão do ou compensação do LACS/LALUR"
          />
          {GetColunasDeAuditoria()}
        </DataGrid>
      </ProvedorAjuda>

      <ModalMxp
        titulo={NormalizaTituloModal.Normalizar(
          idRegistroEmEdicao,
          NomesModais.lancamentoNaParteB
        )}
        visivel={modalVisivel}
        handleFechar={handleFecharModal}
        largura={"max(40vw, 700px)"}
      >
        <EditFormLancamentoNaParteBLacs
          idRegistroEmEdicao={idRegistroEmEdicao}
          idApuracaoDoLacsEDaCsll={props.idApuracaoDaLacsEDaCsll}
          handleCallback={handleCallbackFormulario}
        />
      </ModalMxp>
    </>
  );
}
