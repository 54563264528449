import getApi, {
  processarRequestComPossivelArquivo,
} from "../../../../configs/api";
import { AnexoResponse } from "../../../../models/api/comum/anexo-response";
import { ResponseModel } from "../../../../models/api/comum/response-base";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { ServiceBase } from "../../../../services/comum/serviceBase";
import {
  GerarRelatorioPersonalizadoRequestDTO,
  SchemaTags,
} from "../models/relatorio-personalizado.api";
import { FinalidadeModeloRelatorio } from "../models/relatorio-personalizado.enums";

export default class RelatorioPersonalizadoService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.RelatorioPersonalizado);
  }

  public async gerarRelatorio(request: GerarRelatorioPersonalizadoRequestDTO) {
    try {
      const api = getApi();
      const response = await api.get(
        `${this._nomeEndpoint}/GerarRelatorio?relatorioId=${
          request.relatorioId
        }&filtroGrid=${encodeURIComponent(request.filtroGrid ?? "")}&ids=${
          request.ids
        }&pdf=${request.pdf}&dimensionamento=${request.dimensionamento ?? ""}`,
        { responseType: "blob" }
      );

      return await processarRequestComPossivelArquivo(response);
    } catch (erro) {
      return {
        response: (erro as any).response.data,
      } as AnexoResponse;
    }
  }

  public async obterSchema(tipo: FinalidadeModeloRelatorio) {
    const api = getApi();
    const response = await api.get<ResponseModel<SchemaTags>>(
      `${this._nomeEndpoint}/ObterSchema?tipo=${tipo}`
    );
    return response.data;
  }
}
