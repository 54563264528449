import { Column, IColumnProps } from "devextreme-react/cjs/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { ReactElement } from "react";
import { DxGrupoDados } from "../../../utils/grid/data-source-factory";

type GrupoOuItem<TItem = any, TKey = any> =
  | DxGrupoDados<GrupoOuItem<TItem, TKey> | TItem, TKey>
  | TItem;

type ItemMapeado<TItem = any> = {
  text: string;
  value: any[];
  items?: ItemMapeado<TItem>[];
};

export function gerarHeaderFilterAninhado<
  TItem = any,
  TKey extends TItem[Extract<keyof TItem, string>] = TItem[Extract<
    keyof TItem,
    string
  >]
>({
  dataStore,
  campoColecao,
  niveis,
  camposBusca,
}: {
  dataStore: CustomStore<TItem, TKey>;
  campoColecao: string[];
  niveis: (
    | {
        campoChave: Extract<keyof TItem, string>;
        campoDescricao: Extract<keyof TItem, string>;
      }
    | {
        campoChave: Extract<keyof TItem, string>;
        formatarDescricao: (obj: TItem) => string;
      }
  )[];
  camposBusca: Extract<keyof TItem, string>[];
}): {
  headerFilter: IColumnProps["headerFilter"];
  colunas: ReactElement[];
} {
  const filtro: IColumnProps["headerFilter"] = {
    allowSelectAll: false,
    search: {
      enabled: true,
      mode: "contains",
      searchExpr: camposBusca,
    },
    groupInterval: [1, 2, 3], // HACK: Gambiarra para habilitar aninhamento independente do dataType.
    dataSource: {
      store: dataStore,
      postProcess(grupos: DxGrupoDados<GrupoOuItem<TItem, TKey>, TKey>[]) {
        // Contém as descrições de cada nível.
        const descricoes = new Array<string | undefined>(niveis.length - 1);

        function mapear(
          grupoOuItem: GrupoOuItem<TItem, TKey>,
          profundidade = 0
        ): ItemMapeado<TItem> {
          // Mapeamos grupos até o penúltimo nível, o último são itens
          if (profundidade < niveis.length - 1) {
            // Antes do último nível, só temos grupos.
            const grupo = grupoOuItem as DxGrupoDados<
              GrupoOuItem<TItem, TKey>,
              TKey
            >;

            // Limpar a descrição do nível atual para que seja setado pelos mapeares dos itens abaixo desse nível.
            descricoes[profundidade] = undefined;

            // Mapeia os itens abaixo desse nível e, consequentemente, define a descrição do nível atual.
            const itens = grupo.items.map((grupo) =>
              mapear(grupo, profundidade + 1)
            );

            // Retornamos o grupo mapeado após ter processado os sub-itens.
            // Precisamos fazer isso pois se tentarmos acessar a descrição antes
            // de mapear os sub-itens, o elemento na descrição não vai ter sido
            // definido.
            return {
              text: descricoes[profundidade]!,
              value: gerarFiltros(
                campoColecao,
                niveis[profundidade].campoChave,
                "=",
                grupo.key
              ),
              items: itens,
            };
          } else {
            // Para o último nível, nós temos os itens individuais
            const obj = grupoOuItem as TItem;

            // Setar as descrições para todos os outros níveis acima
            for (let idx = 0; idx < descricoes.length; idx++) {
              // Somente setamos a descrição se for necessário (o nível tiver limpado ela).
              if (!descricoes[idx]) {
                const nivel = niveis[idx];

                // Usamos a função de formatação se tiver, senão usamos o campo de descrição.
                descricoes[idx] =
                  "formatarDescricao" in nivel
                    ? nivel.formatarDescricao(obj)
                    : `${obj[nivel.campoDescricao]}`;
              }
            }

            // Mapear o item
            const ultimoNivel = niveis[profundidade];

            return {
              text:
                "formatarDescricao" in ultimoNivel
                  ? ultimoNivel.formatarDescricao(obj)
                  : `${obj[ultimoNivel.campoDescricao]}`,
              value: gerarFiltros(
                campoColecao,
                ultimoNivel.campoChave,
                "=",
                obj[ultimoNivel.campoChave]
              ),
            };
          }
        }

        const retorno = grupos.map((grupo) => mapear(grupo));
        return retorno;
      },
      group: niveis.slice(0, -1).map((nivel) => ({
        selector: nivel.campoChave,
        isExpanded: true,
      })),
    },
  };
  const colunas: ReactElement[] = niveis.map((nivel) => (
    <Column
      key={`${campoColecao}.${nivel.campoChave}`}
      dataField={`${campoColecao}.${nivel.campoChave}`}
      dataType="object"
      visible={false}
      showInColumnChooser={false}
      visibleIndex={100000}
    />
  ));

  return { headerFilter: filtro, colunas };
}

const gerarFiltros = (
  propriedadesPrimeiroNivel: string[],
  propriedadeSegundoNivel: string,
  selectedFilterOperation: string,
  filterValue: any
) => {
  return propriedadesPrimeiroNivel.reduce((acc, nome, index) => {
    const filterExpression = [
      `${nome}.${propriedadeSegundoNivel}`,
      selectedFilterOperation,
      filterValue,
    ];

    if (index === 0) {
      return filterExpression;
    }

    return [acc, "or", filterExpression];
  }, [] as any[]);
};
